import React, { Component } from "react";
import { Container, Button, Row, Col} from "react-bootstrap";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../Config/Theme";

import GeneralEmployee from "./Questionnaire/General/GeneralEmployee";
import GeneralLead from "./Questionnaire/General/GeneralLead";

import CultureEmployee from "./Questionnaire/Culture/CultureEmployee";
import CultureSupervisor from "./Questionnaire/Culture/CultureSupervisor";
import CultureLead from "./Questionnaire/Culture/CultureLead";
import CultureExec from "./Questionnaire/Culture/CultureExec";

import PerformanceEmployee from "./Questionnaire/Performance/PerformanceEmployee";
import PerformanceSupervisor from "./Questionnaire/Performance/PerformanceSupervisor";
import PerformanceLead from "./Questionnaire/Performance/PerformanceLead";
import PerformanceExec from "./Questionnaire/Performance/PerformanceExec";

import PoliciesEmployee from "./Questionnaire/Policies/PoliciesEmployee";
import PoliciesLead from "./Questionnaire/Policies/PoliciesLead";
import PoliciesSupervisor from "./Questionnaire/Policies/PoliciesSupervisor";
import PoliciesExec from "./Questionnaire/Policies/PoliciesExec";

import RiskLead from "./Questionnaire/Risk/RiskLead";
import RiskSupervisor from "./Questionnaire/Risk/RiskSupervisor";

import StrategyEmployee from "./Questionnaire/Strategy/StrategyEmployee";
import StrategyExec from "./Questionnaire/Strategy/StrategyExec";
import StrategySupervisor from "./Questionnaire/Strategy/StrategySupervisor";
import StrategyLead from "./Questionnaire/Strategy/StrategyLead";

import RewardsEmployee from "./Questionnaire/Rewards/RewardsEmployee";
import RewardsSupervisor from "./Questionnaire/Rewards/RewardsSupervisor";
import RewardsLead from "./Questionnaire/Rewards/RewardsLead";
import RewardsExec from "./Questionnaire/Rewards/RewardsExec";

import WorkforceSupervisor from "./Questionnaire/Workforce/WorkforceSupervisor";
import WorkforceExec from "./Questionnaire/Workforce/WorkforceExec";
import WorkforceLead from "./Questionnaire/Workforce/WorkforceLead";

import debounce from "debounce";
import { config } from "../../Config/Constants";
import NavBar from "./NavBar";
import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import LinkInterviewButtons from './LinkInterviewButtons';


const API_URL = config.url.API_URL;

const Div = styled.div`
  background: ${(props) => props.theme.colors.white} !important;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    margin-left: 31px;
    margin-right: 31px;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }
`;

const StyledButton = styled(Button)`
    width: 6em;
    height: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.dark_blue};
    border: 1px solid ${props => props.theme.colors.dark_blue};
    &:hover{
        color: ${props => props.theme.colors.orange};
        background: ${props => props.theme.colors.dark_blue};
        border: 1px solid ${props => props.theme.colors.dark_blue};
    }

    div{
        margin-top: 1em;
        font-size: .75em;
        color: yellow;
    }

`

class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionSection: "audit_general",
      questionSectionDisplay: "General Organization Information",
      
      //General
      title: "",
      supervisor: "",
      department: "",
      worksite: "",
      hiredate: "",
      fulltimeparttime: "",
      reports: "",
      report_locations: "", 

      organization: "",
      address: "",
      website: "",
      industry: "",
      co_status: "",
      mission: "",

      headcount_fulltime: 0,
      headcount_parttime: 0,
      headcount_temp: 0,
      headcount_exempt: 0,
      headcount_nonexempt: 0,
      headcount_interns: 0,
      headcount_consultants: 0,

      locations: "",

      seniorlead_title: "",
      seniorlead_name: "",
      seniorlead_email: "",
      seniorlead_phone: "",

      pointofcontact_title: "",
      pointofcontact_name: "",
      pointofcontact_email: "",
      pointofcontact_phone: "",

      hrlead_title: "",
      hrlead_name: "",
      hrlead_email: "",
      hrlead_phone: "",

      payroll_provider: "",
      benefits_broker: "",
      retirement_admin: "",
      COBRA_admin: "",
      workers_comp: "",

      //Culture
      formCulture1: 0,
      formCulture2: 0,
      formCulture3: 0,
      formCulture4: 0,
      formCulture5a: "",
      formCulture5b: "",
      formCulture5c: "",
      formCulture6a: "",
      formCulture6b: "",
      formCulture6c: "",
      formCulture7: "",

      formCultureEngagement1: "",
      formCultureEngagement2: 0,
      formCultureEngagement3: 0,
      formCultureEngagement4: "",
      formCultureEngagement5: "",
      formCultureEngagement6: "",

      formCultureDEI1: 0,
      formCultureDEI2: 0,
      formCultureDEI3: 0,
      formCultureDEI4: "",
      formCultureDEI5: "",

      formCultureRewardsService: false,
      formCultureRewardsRetirement: false,
      formCultureRewardsPeerRecognition: false,
      formCultureRewardsSpot: false,
      formCultureRewardsManagerial: false,
      formCultureRewardsOrganization: false,
      formCultureRewardsPerformance: false,
      formCultureRewardsEmployeeMonth: false,
      formCultureRewardsAppreciation: false,
      formCultureRewardsGoal: false,
      formCultureRewardsSuggestion: false,
      formCultureRewardsOther: false,
      formCultureRewardsOtherAnswer: "",

      formCultureRewards2: 0,
      formCultureRewards3: "",
      formCultureRewards4: "",

      formCommunicationNewsletter: false,
      formCommunicationEmail: false,
      formCommunicationTownHall: false,
      formCommunicationDepartment: false,
      formCommunicationWebsite: false,
      formCommunicationNone: false,
      formCommunicationOther: false,
      formCommunicationOtherAnswer: "",

      formCultureCommunication1: 0,
      formCultureCommunication2: 0,
      formCultureCommunication3: 0,
      formCultureCommunication4: 0,
      formCultureCommunication5: "",
      formCultureCommunication6: "",

      formConflictPeer: false,
      formConflictManager: false,
      formConflictChain: false,
      formConflictHR: false,
      formConflictGrievance: false,
      formConflictOther: false,
      formConflictOtherAnswer: "",

      formCultureConflict1: 0,
      formCultureConflict2: 0,
      formCultureConflict3: "",
      formCultureConflict4: "",
      formCultureConflict5: "",

      //Performance

      formPerformanceManagement1on1: false,
      formPerformanceManagementAnnual: false,
      formPerformanceManagementReviews: false,
      formPerformanceManagementTeam: false,
      formPerformanceManagementGoals: false,
      formPerformanceManagement360: false,
      formPerformanceManagementOther: false,
      formPerformanceManagementOtherAnswer: "",

      formPerformanceReviews1: 0,
      formPerformanceReviews2: 0,
      formPerformanceReviews4: "",
      formPerformanceReviews5: "",

      formPerformanceGoals1: "",
      formPerformanceGoals2: "",
      formPerformanceGoals3: 0,
      formPerformanceGoals4: 0,
      formPerformanceGoals5: "",
      formPerformanceGoals6: "",

      formPerformanceDevelopmentReimbursement: false,
      formPerformanceDevelopmentDiscount: false,
      formPerformanceDevelopmentUniversities: false,
      formPerformanceDevelopmentLearning: false,
      formPerformanceDevelopmentConference: false,
      formPerformanceDevelopmentVirtual: false,
      formPerformanceDevelopmentCoaching: false,
      formPerformanceDevelopmentMentoring: false,
      formPerformanceDevelopmentLeadership: false,
      formPerformanceDevelopmentInternship: false,
      formPerformanceDevelopmentOther: false,
      formPerformanceDevelopmentOtherAnswer: "",

      formPerformanceDevelopment1: 0,
      formPerformanceDevelopment2: 0,
      formPerformanceDevelopment3: "",
      formPerformanceDevelopment4: "",
      formPerformanceDevelopment5: "",

      formPerformanceCorrectiveVerbal: false,
      formPerformanceCorrectiveWritten: false,
      formPerformanceCorrectiveCut: false,
      formPerformanceCorrectivePlan: false,
      formPerformanceCorrectiveDecision: false,
      formPerformanceCorrectiveSuspension: false,
      formPerformanceCorrectiveDemotion: false,
      formPerformanceCorrectiveTermination: false,
      formPerformanceCorrectiveOther: false,
      formPerformanceCorrectiveOtherAnswer: "",

      formPerformanceCorrective1: 0,
      formPerformanceCorrective2: "",
      formPerformanceCorrective3: "",
      formPerformanceCorrective4: "",
      formPerformanceCorrective5: "",

      formPerformanceLeadershipDevelopment1: 0,
      formPerformanceLeadershipDevelopment2: 0,
      formPerformanceLeadershipDevelopment3: 0,
      formPerformanceLeadershipDevelopment4: "",
      formPerformanceLeadershipDevelopment5: "",

      formPerformanceJobDescriptions1: 0,
      formPerformanceJobDescriptions3: "",
      formPerformanceJobDescriptions4: "",
      formPerformanceJobDescriptions5: "",

      //Policies
      formPolicyHandbook1: 0,
      formPolicyHandbook2: 0,
      formPolicyHandbook3: 0,
      formPolicyHandbook5: "",
      formPolicyHandbook6: "",
      formPolicyHandbook7: "",
      formPolicyHandbook8: "",
      formPolicyHandbook9: "",

      formPolicyOnboarding1: 0,
      formPolicyOnboarding2: 0,
      formPolicyOnboarding3: 0,
      formPolicyOnboarding4: 0,

      formPolicyOnboardingNonExistent: false,
      formPolicyOnboardingInformalManager: false,
      formPolicyOnboardingFormalManager: false,
      formPolicyOnboardingFormalHR: false,
      formPolicyOnboardingInformalHR: false,
      formPolicyOnboardingOther: false,
      formPolicyOnboardingOtherAnswer: "",

      formPolicyOnboarding6: "",
      formPolicyOnboarding6Answer: "",
      formPolicyOnboarding7: "",
      formPolicyOnboarding7Answer: "",
      formPolicyOnboarding8: "",
      formPolicyOnboarding9: "",
      formPolicyOnboarding10: "",

      formPolicyOffboarding1: 0,
      formPolicyOffboarding2: "",
      formPolicyOffboarding3: "",
      formPolicyOffboarding4: "",
      formPolicyOffboarding5: "",
      formPolicyOffboarding6: "",
      formPolicyOffboarding6a: "",
      formPolicyOffboarding7: "",
      formPolicyOffboarding7a: "",
      formPolicyOffboarding8: "",
      formPolicyOffboarding9: "",
      formPolicyOffboarding10: "",
      formPolicyOffboarding11: "",
      formPolicyOffboarding12: "",
      formPolicyOffboarding12a: "",

      formPolicyLOA1: 0,
      formPolicyLOA2: "",
      formPolicyLOA3: "",

      formPolicyPersonnel1: "",
      formPolicyPersonnel1a: "",
      formPolicyPersonnel2: "",
      formPolicyPersonnel2a: "",
      formPolicyPersonnel3: "",
      formPolicyPersonnel4: "",
      formPolicyPersonnel5: "",
      formPolicyPersonnel6: "",

      //Risk
      formRiskNotices1: "",
      formRiskNotices2: "",
      formRiskNotices3: "",
      formRiskNotices4: "",

      formRiskEEO1: "",
      formRiskEEO2: "",
      formRiskEEO3: "",
      formRiskEEO4: "",
      formRiskEEO5: "",
      formRiskEEO6: "",
      formRiskEEO7: "",
      formRiskEEO8: "",
      formRiskEEO9: "",
      formRiskEEO10: "",
      formRiskEEO11: "",

      formRiskClassification1: 0,
      formRiskClassification2: "",
      formRiskClassification3: "",
      formRiskClassification4: "",
      formRiskClassification5: "",
      formRiskClassification6: "",
      formRiskClassification7: "",
      formRiskClassification8: "",
      formRiskClassification9: "",

      formRiskADA1: "",
      formRiskADA2: "",
      formRiskADA3: "",
      formRiskADA4: "",

      formRiskCompliance1: "",
      formRiskCompliance1a: "",
      formRiskCompliance2: "",
      formRiskCompliance3: "",
      formRiskCompliance4: "",

      formRiskSafety1: "",
      formRiskSafety1a: "",
      formRiskSafety2: "",
      formRiskSafety2a: "",
      formRiskSafety3: "",
      formRiskSafety4: "",
      formRiskSafety4a: "",
      formRiskSafety5: "",
      formRiskSafety6: "",
      formRiskSafety7: "",
      formRiskSafety9: "",

      //Strategy
      formStrategy1: 0,
      formStrategy2: "",
      formStrategy2Answer: "",
      formStrategy3: "",
      formStrategy3Answer: "",
      formStrategy4: "",
      formStrategy5: "",
      formStrategy5a: "",
      formStrategy5b: "",
      formStrategy5c: "",

      formStrategyFeedback: false,
      formStrategyLead: false,
      formStrategyDiscussion: false,
      formStrategyOther: false,
      formStrategyOtherAnswer: "",

      formStrategyEmail: false,
      formStrategyFaceMeetings: false,
      formStrategyDeptMeetings: false,
      formStrategy7Other: false,
      formStrategy7OtherAnswer: "",

      formStrategy8: "",
      formStrategy9: "",
      formStrategy9Answer: "",
      formStrategy10: "",
      formStrategy11: "",

      //Rewards
      formRewardsBenefitsMedical: false,
      formRewardsBenefitsDental: false,
      formRewardsBenefitsVision: false,
      formRewardsBenefitsRX: false,
      formRewardsBenefitsFSA: false,
      formRewardsBenefitsHRA: false,
      formRewardsBenefitsHSA: false,
      formRewardsBenefitsLife: false,
      formRewardsBenefitsADD: false,
      formRewardsBenefitsSTD: false,
      formRewardsBenefitsLTD: false,
      formRewardsBenefitsOther: false,
      formRewardsBenefitsOtherAnswer: "",

      formRewardsDependentsNone: false,
      formRewardsDependentsFull: false,
      formRewardsDependentsHalf: false,
      formRewardsDependentsOther: false,
      formRewardsDependentsOtherAnswer: "",

      formRewardsBenefits2: 0,
      formRewardsBenefits3: "",
      formRewardsBenefits4: "",
      formRewardsBenefits5: "",
      formRewardsBenefits6: "",
      formRewardsBenefits7: "",
      formRewardsBenefits8: "",
      formRewardsBenefits9: "",
      formRewardsBenefits10: "",

      formRewardsPaidLeaveVacation: false,
      formRewardsPaidLeaveTimeOff: false,
      formRewardsPaidLeaveHoliday: false,
      formRewardsPaidLeaveSick: false,
      formRewardsPaidLeaveBereavement: false,
      formRewardsPaidLeaveMedical: false,
      formRewardsPaidLeaveSabbatical: false,
      formRewardsPaidLeaveAdoption: false,
      formRewardsPaidLeaveOther: false,
      formRewardsPaidLeaveOtherAnswer: "",

      formRewardsPaidLeave1: 0,
      formRewardsPaidLeave2: "",
      formRewardsPaidLeave2a: "",
      formRewardsPaidLeave3: "",
      formRewardsPaidLeave4: "",
      formRewardsPaidLeave5: "",

      formRewardsBalance1: 0,

      formRewardsBalance2Flex: false,
      formRewardsBalance2Remote: false,
      formRewardsBalance2Compressed: false,
      formRewardsBalance2Sharing: false,
      formRewardsBalance2PartTime: false,
      formRewardsBalance2Seasonal: false,

      formRewardsBalance4: "",
      formRewardsBalance4a: "",
      formRewardsBalance5: "",
      formRewardsBalance6: "",

      formRewardsPayroll1: 0,
      formRewardsPayroll2: 0,
      formRewardsPayroll3: "",
      formRewardsPayroll4: "",

      formRewardsHRISPayroll: false,
      formRewardsHRISSelf: false,
      formRewardsHRISCompany: false,
      formRewardsHRISTimekeeping: false,
      formRewardsHRISApplicant: false,
      formRewardsHRISPerformance: false,
      formRewardsHRISOSHA: false,
      formRewardsHRISTraining: false,
      formRewardsHRISOther: false,
      formRewardsHRISOtherAnswer: "",

      formRewardsHRIS4: "",
      formRewardsHRIS5: "",

      formRewardsCompPremiumShift: false,
      formRewardsCompPremiumWeekend: false,
      formRewardsCompPremiumHoliday: false,
      formRewardsCompPremiumOnCall: false,
      formRewardsCompPremiumHazard: false,
      formRewardsCompPremiumBiLingual: false,
      formRewardsCompPremiumSkill: false,

      formRewardsCompVariableCommission: false,
      formRewardsCompVariableSharing: false,
      formRewardsCompVariableReferral: false,
      formRewardsCompVariableHiring: false,
      formRewardsCompVariableRetention: false,
      formRewardsCompVariableCompletion: false,
      formRewardsCompVariableOther: false,
      formRewardsCompVariableOtherAnswer: "",

      formRewardsCompTermsPerformance: false,
      formRewardsCompTermsLiving: false,
      formRewardsCompTermsMarket: false,

      formRewardsCompensation1: 0,
      formRewardsCompensation2: "",
      formRewardsCompensation3: "",
      formRewardsCompensation4: "",
      formRewardsCompensation5: "",
      formRewardsCompensation6: "",
      formRewardsCompensation9: "",

      formRewardsRetirement5: "",
      formRewardsRetirement5Answer: "",
      formRewardsRetirement6: "",
      formRewardsRetirement6a: "",
      formRewardsRetirement7: "",
      formRewardsRetirement9: "",

      //Workforce
      formWorkforceAttraction1: 0,
      formWorkforceAttraction2: 0,
      formWorkforceAttraction3: 0,
      formWorkforceAttraction4: "",
      formWorkforceAttraction5: "",
      formWorkforceAttraction6: "",
      formWorkforceAttraction7: "",
      formWorkforceAttraction8: "",
      formWorkforceAttraction9: "",
      formWorkforceAttraction9a: "",
      formWorkforceAttraction11: "",
      formWorkforceAttraction13: "",
      formWorkforceAttraction14a: "",
      formWorkforceAttraction15: "",

      formWorkforceContractors1: "",
      formWorkforceContractors1a: "",
      formWorkforceContractors1b: "",
      formWorkforceContractors1c: "",
      formWorkforceContractors2: "",

      formWorkforceTemp2: "",
      formWorkforceTemp2a: "",
      formWorkforceTemp2b: "",
      formWorkforceTemp2c: "",
      formWorkforceTemp3: "",

      formWorkforceInterns1: "",
      formWorkforceInterns1a: "",
      formWorkforceInterns1b: "",
      formWorkforceInterns2: "",
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.grabTableData = this.grabTableData.bind(this);
    this.displayQuestionSection = this.displayQuestionSection.bind(this);
    this.updateStates = this.updateStates.bind(this);
    this.handleChangeAsInt = this.handleChangeAsInt.bind(this);
    this.linkDisplaySectionsNext = this.linkDisplaySectionsNext.bind(this);
    this.linkDisplaySectionsBack = this.linkDisplaySectionsBack.bind(this);
  }

  componentDidMount() {
    this.updateStates();
  }

  updateStates() {
    const tableSections = [
      'audit_general',
      'audit_culture',
      'audit_performance',
      'audit_policies',
      'audit_risk',
      'audit_strategy',
      'audit_rewards',
      'audit_workforce'
    ];

    
    
    for (let i = 0; i < tableSections.length; i++){
      this.grabTableData(tableSections[i]);
    }
  }

  async grabTableData (tableName) {
    await fetch(API_URL + `/responses/${tableName}/${this.props.employeeId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((result) => {
        this.setState({
          ...result[0],
        });
        console.log("the results should be here");
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleCheck(e) {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
    this.setState({ [e.target.name]: e.target.checked }, () => this.updateDB());
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => this.updateDB());
  };

  handleChangeAsInt = (e) => {
    const targetInt = parseInt(e.target.value, 10);
    this.setState({ [e.target.name]: targetInt }, () => this.updateDB());
  }

  updateDB = debounce(() => {
    let data = {};
    if (this.state.questionSection === "audit_general") {
      data = {
        title: this.state.title,
        supervisor: this.state.supervisor,
        department: this.state.department,
        worksite: this.state.worksite,
        hiredate: this.state.hiredate,
        fulltimeparttime: this.state.fulltimeparttime,
        reports: this.state.reports,
        report_locations: this.state.report_locations,

        organization: this.state.organization,
        address: this.state.address,
        website: this.state.website,
        industry: this.state.industry,
        co_status: this.state.co_status,
        mission: this.state.mission,

        headcount_fulltime: this.state.headcount_fulltime,
        headcount_parttime: this.state.headcount_parttime,
        headcount_temp: this.state.headcount_temp,
        headcount_exempt: this.state.headcount_exempt,
        headcount_nonexempt: this.state.headcount_nonexempt,
        headcount_interns: this.state.headcount_interns,
        headcount_consultants: this.state.headcount_consultants,

        locations: this.state.locations,
        // locations_headcount: this.state.locations_headcount,

        seniorlead_title: this.state.seniorlead_title,
        seniorlead_name: this.state.seniorlead_name,
        seniorlead_email: this.state.seniorlead_email,
        seniorlead_phone: this.state.seniorlead_phone,

        pointofcontact_title: this.state.pointofcontact_title,
        pointofcontact_name: this.state.pointofcontact_name,
        pointofcontact_email: this.state.pointofcontact_email,
        pointofcontact_phone: this.state.pointofcontact_phone,

        hrlead_title: this.state.hrlead_title,
        hrlead_name: this.state.hrlead_name,
        hrlead_email: this.state.hrlead_email,
        hrlead_phone: this.state.hrlead_phone,

        payroll_provider: this.state.payroll_provider,
        benefits_broker: this.state.benefits_broker,
        retirement_admin: this.state.retirement_admin,
        COBRA_admin: this.state.COBRA_admin,
        workers_comp: this.state.workers_comp,
      };
    } else if (this.state.questionSection === "audit_culture") {
      data = {
        formCulture1: parseInt(this.state.formCulture1, 10),
        formCulture2: parseInt(this.state.formCulture2, 10),
        formCulture3: parseInt(this.state.formCulture3, 10),
        formCulture4: parseInt(this.state.formCulture4, 10),
        formCulture5a: this.state.formCulture5a,
        formCulture5b: this.state.formCulture5b,
        formCulture5c: this.state.formCulture5c,
        formCulture6a: this.state.formCulture6a,
        formCulture6b: this.state.formCulture6b,
        formCulture6c: this.state.formCulture6c,
        formCulture7: this.state.formCulture7,

        formCultureEngagement1: this.state.formCultureEngagement1,
        formCultureEngagement2: this.state.formCultureEngagement2,
        formCultureEngagement3: this.state.formCultureEngagement3,
        formCultureEngagement4: this.state.formCultureEngagement4,
        formCultureEngagement5: this.state.formCultureEngagement5,
        formCultureEngagement6: this.state.formCultureEngagement6,

        formCultureDEI1: this.state.formCultureDEI1,
        formCultureDEI2: this.state.formCultureDEI2,
        formCultureDEI3: this.state.formCultureDEI3,
        formCultureDEI4: this.state.formCultureDEI4,
        formCultureDEI5: this.state.formCultureDEI5,

        formCultureRewardsService: this.state.formCultureRewardsService,
        formCultureRewardsRetirement: this.state.formCultureRewardsRetirement,
        formCultureRewardsPeerRecognition: this.state.formCultureRewardsPeerRecognition,
        formCultureRewardsSpot: this.state.formCultureRewardsSpot,
        formCultureRewardsManagerial: this.state.formCultureRewardsManagerial,
        formCultureRewardsOrganization: this.state.formCultureRewardsOrganization,
        formCultureRewardsPerformance: this.state.formCultureRewardsPerformance,
        formCultureRewardsEmployeeMonth: this.state.formCultureRewardsEmployeeMonth,
        formCultureRewardsAppreciation: this.state.formCultureRewardsAppreciation,
        formCultureRewardsGoal: this.state.formCultureRewardsGoal,
        formCultureRewardsSuggestion: this.state.formCultureRewardsSuggestion,
        formCultureRewardsOther: this.state.formCultureRewardsOther,
        formCultureRewardsOtherAnswer: this.state.formCultureRewardsOtherAnswer,

        formCultureRewards2: this.state.formCultureRewards2,
        formCultureRewards3: this.state.formCultureRewards3,
        formCultureRewards4: this.state.formCultureRewards4,

        formCommunicationNewsletter: this.state.formCommunicationNewsletter,
        formCommunicationEmail: this.state.formCommunicationEmail,
        formCommunicationTownHall: this.state.formCommunicationTownHall,
        formCommunicationDepartment: this.state.formCommunicationDepartment,
        formCommunicationWebsite: this.state.formCommunicationWebsite,
        formCommunicationNone: this.state.formCommunicationNone,
        formCommunicationOther: this.state.formCommunicationOther,
        formCommunicationOtherAnswer: this.state.formCommunicationOtherAnswer,

        formCultureCommunication1: this.state.formCultureCommunication1,
        formCultureCommunication2: this.state.formCultureCommunication2,
        formCultureCommunication3: this.state.formCultureCommunication3,
        formCultureCommunication4: this.state.formCultureCommunication4,
        formCultureCommunication5: this.state.formCultureCommunication5,
        formCultureCommunication6: this.state.formCultureCommunication6,

        formConflictPeer: this.state.formConflictPeer,
        formConflictManager: this.state.formConflictManager,
        formConflictChain: this.state.formConflictChain,
        formConflictHR: this.state.formConflictHR,
        formConflictGrievance: this.state.formConflictGrievance,
        formConflictOther: this.state.formConflictOther,
        formConflictOtherAnswer: this.state.formConflictOtherAnswer,

        formCultureConflict1: this.state.formCultureConflict1,
        formCultureConflict2: this.state.formCultureConflict2,
        formCultureConflict3: this.state.formCultureConflict3,
        formCultureConflict4: this.state.formCultureConflict4,
        formCultureConflict5: this.state.formCultureConflict5,
      };
    } else if (this.state.questionSection === "audit_performance") {
      data = {
        formPerformanceManagement1on1: this.state.formPerformanceManagement1on1,
        formPerformanceManagementAnnual: this.state.formPerformanceManagementAnnual,
        formPerformanceManagementReviews: this.state.formPerformanceManagementReviews,
        formPerformanceManagementTeam: this.state.formPerformanceManagementTeam,
        formPerformanceManagementGoals: this.state.formPerformanceManagementGoals,
        formPerformanceManagement360: this.state.formPerformanceManagement360,
        formPerformanceManagementOther: this.state.formPerformanceManagementOther,
        formPerformanceManagementOtherAnswer: this.state.formPerformanceManagementOtherAnswer,

        formPerformanceReviews1: this.state.formPerformanceReviews1,
        formPerformanceReviews2: this.state.formPerformanceReviews2,
        formPerformanceReviews4: this.state.formPerformanceReviews4,
        formPerformanceReviews5: this.state.formPerformanceReviews5,

        formPerformanceGoals1: this.state.formPerformanceGoals1,
        formPerformanceGoals2: this.state.formPerformanceGoals2,
        formPerformanceGoals3: this.state.formPerformanceGoals3,
        formPerformanceGoals4: this.state.formPerformanceGoals4,
        formPerformanceGoals5: this.state.formPerformanceGoals5,
        formPerformanceGoals6: this.state.formPerformanceGoals6,

        formPerformanceDevelopmentReimbursement: this.state.formPerformanceDevelopmentReimbursement,
        formPerformanceDevelopmentDiscount: this.state.formPerformanceDevelopmentDiscount,
        formPerformanceDevelopmentUniversities: this.state.formPerformanceDevelopmentUniversities,
        formPerformanceDevelopmentLearning: this.state.formPerformanceDevelopmentLearning,
        formPerformanceDevelopmentConference: this.state.formPerformanceDevelopmentConference,
        formPerformanceDevelopmentVirtual: this.state.formPerformanceDevelopmentVirtual,
        formPerformanceDevelopmentCoaching: this.state.formPerformanceDevelopmentCoaching,
        formPerformanceDevelopmentMentoring: this.state.formPerformanceDevelopmentMentoring,
        formPerformanceDevelopmentLeadership: this.state.formPerformanceDevelopmentLeadership,
        formPerformanceDevelopmentInternship: this.state.formPerformanceDevelopmentInternship,
        formPerformanceDevelopmentOther: this.state.formPerformanceDevelopmentOther,
        formPerformanceDevelopmentOtherAnswer: this.state.formPerformanceDevelopmentOtherAnswer,

        formPerformanceDevelopment1: this.state.formPerformanceDevelopment1,
        formPerformanceDevelopment2: this.state.formPerformanceDevelopment2,
        formPerformanceDevelopment3: this.state.formPerformanceDevelopment3,
        formPerformanceDevelopment4: this.state.formPerformanceDevelopment4,
        formPerformanceDevelopment5: this.state.formPerformanceDevelopment5,

        formPerformanceCorrectiveVerbal: this.state.formPerformanceCorrectiveVerbal,
        formPerformanceCorrectiveWritten: this.state.formPerformanceCorrectiveWritten,
        formPerformanceCorrectiveCut: this.state.formPerformanceCorrectiveCut,
        formPerformanceCorrectivePlan: this.state.formPerformanceCorrectivePlan,
        formPerformanceCorrectiveDecision: this.state.formPerformanceCorrectiveDecision,
        formPerformanceCorrectiveSuspension: this.state.formPerformanceCorrectiveSuspension,
        formPerformanceCorrectiveDemotion: this.state.formPerformanceCorrectiveDemotion,
        formPerformanceCorrectiveTermination: this.state.formPerformanceCorrectiveTermination,
        formPerformanceCorrectiveOther: this.state.formPerformanceCorrectiveOther,
        formPerformanceCorrectiveOtherAnswer: this.state.formPerformanceCorrectiveOtherAnswer,

        formPerformanceCorrective1: this.state.formPerformanceCorrective1,
        formPerformanceCorrective2: this.state.formPerformanceCorrective2,
        formPerformanceCorrective3: this.state.formPerformanceCorrective3,
        formPerformanceCorrective4: this.state.formPerformanceCorrective4,
        formPerformanceCorrective5: this.state.formPerformanceCorrective5,

        formPerformanceLeadershipDevelopment1: this.state.formPerformanceLeadershipDevelopment1,
        formPerformanceLeadershipDevelopment2: this.state.formPerformanceLeadershipDevelopment2,
        formPerformanceLeadershipDevelopment3: this.state.formPerformanceLeadershipDevelopment3,
        formPerformanceLeadershipDevelopment4: this.state.formPerformanceLeadershipDevelopment4,
        formPerformanceLeadershipDevelopment5: this.state.formPerformanceLeadershipDevelopment5,

        formPerformanceJobDescriptions1: this.state.formPerformanceJobDescriptions1,
        formPerformanceJobDescriptions3: this.state.formPerformanceJobDescriptions3,
        formPerformanceJobDescriptions4: this.state.formPerformanceJobDescriptions4,
        formPerformanceJobDescriptions5: this.state.formPerformanceJobDescriptions5
      };
    } else if (this.state.questionSection === "audit_policies") {
      data = {
        formPolicyHandbook1: this.state.formPolicyHandbook1,
        formPolicyHandbook2: this.state.formPolicyHandbook2,
        formPolicyHandbook3: this.state.formPolicyHandbook3,
        formPolicyHandbook5: this.state.formPolicyHandbook5,
        formPolicyHandbook6: this.state.formPolicyHandbook6,
        formPolicyHandbook7: this.state.formPolicyHandbook7,
        formPolicyHandbook8: this.state.formPolicyHandbook8,
        formPolicyHandbook9: this.state.formPolicyHandbook9,

        formPolicyOnboarding1: this.state.formPolicyOnboarding1,
        formPolicyOnboarding2: this.state.formPolicyOnboarding2,
        formPolicyOnboarding3: this.state.formPolicyOnboarding3,
        formPolicyOnboarding4: this.state.formPolicyOnboarding4,

        formPolicyOnboardingNonExistent: this.state.formPolicyOnboardingNonExistent,
        formPolicyOnboardingInformalManager: this.state.formPolicyOnboardingInformalManager,
        formPolicyOnboardingFormalManager: this.state.formPolicyOnboardingFormalManager,
        formPolicyOnboardingFormalHR: this.state.formPolicyOnboardingFormalHR,
        formPolicyOnboardingInformalHR: this.state.formPolicyOnboardingInformalHR,
        formPolicyOnboardingOther: this.state.formPolicyOnboardingOther,
        formPolicyOnboardingOtherAnswer: this.state.formPolicyOnboardingOtherAnswer,

        formPolicyOnboarding6: this.state.formPolicyOnboarding6,
        formPolicyOnboarding6Answer: this.state.formPolicyOnboarding6Answer,
        formPolicyOnboarding7: this.state.formPolicyOnboarding7,
        formPolicyOnboarding7Answer: this.state.formPolicyOnboarding7Answer,
        formPolicyOnboarding8: this.state.formPolicyOnboarding8,
        formPolicyOnboarding9: this.state.formPolicyOnboarding9,
        formPolicyOnboarding10: this.state.formPolicyOnboarding10,

        formPolicyOffboarding1: this.state.formPolicyOffboarding1,
        formPolicyOffboarding2: this.state.formPolicyOffboarding2,
        formPolicyOffboarding3: this.state.formPolicyOffboarding3,
        formPolicyOffboarding4: this.state.formPolicyOffboarding4,
        formPolicyOffboarding5: this.state.formPolicyOffboarding5,
        formPolicyOffboarding6: this.state.formPolicyOffboarding6,
        formPolicyOffboarding6a: this.state.formPolicyOffboarding6a,
        formPolicyOffboarding7: this.state.formPolicyOffboarding7,
        formPolicyOffboarding7a: this.state.formPolicyOffboarding7a,
        formPolicyOffboarding8: this.state.formPolicyOffboarding8,
        formPolicyOffboarding9: this.state.formPolicyOffboarding9,
        formPolicyOffboarding10: this.state.formPolicyOffboarding10,
        formPolicyOffboarding11: this.state.formPolicyOffboarding11,
        formPolicyOffboarding12: this.state.formPolicyOffboarding12,
        formPolicyOffboarding12a: this.state.formPolicyOffboarding12a,

        formPolicyLOA1: this.state.formPolicyLOA1,
        formPolicyLOA2: this.state.formPolicyLOA2,
        formPolicyLOA3: this.state.formPolicyLOA3,

        formPolicyPersonnel1: this.state.formPolicyPersonnel1,
        formPolicyPersonnel1a: this.state.formPolicyPersonnel1a,
        formPolicyPersonnel2: this.state.formPolicyPersonnel2,
        formPolicyPersonnel2a: this.state.formPolicyPersonnel2a,
        formPolicyPersonnel3: this.state.formPolicyPersonnel3,
        formPolicyPersonnel4: this.state.formPolicyPersonnel4,
        formPolicyPersonnel5: this.state.formPolicyPersonnel5,
        formPolicyPersonnel6: this.state.formPolicyPersonnel6,
      };
    } else if (this.state.questionSection === "audit_risk") {
      data = {
        formRiskNotices1: this.state.formRiskNotices1,
        formRiskNotices2: this.state.formRiskNotices2,
        formRiskNotices3: this.state.formRiskNotices3,
        formRiskNotices4: this.state.formRiskNotices4,

        formRiskEEO1: this.state.formRiskEEO1,
        formRiskEEO2: this.state.formRiskEEO2,
        formRiskEEO3: this.state.formRiskEEO3,
        formRiskEEO4: this.state.formRiskEEO4,
        formRiskEEO5: this.state.formRiskEEO5,
        formRiskEEO6: this.state.formRiskEEO6,
        formRiskEEO7: this.state.formRiskEEO7,
        formRiskEEO8: this.state.formRiskEEO8,
        formRiskEEO9: this.state.formRiskEEO9,
        formRiskEEO10: this.state.formRiskEEO10,
        formRiskEEO11: this.state.formRiskEEO11,

        formRiskClassification1: this.state.formRiskClassification1,
        formRiskClassification2: this.state.formRiskClassification2,
        formRiskClassification4: this.state.formRiskClassification4,
        formRiskClassification5: this.state.formRiskClassification5,
        formRiskClassification6: this.state.formRiskClassification6,
        formRiskClassification7: this.state.formRiskClassification7,
        formRiskClassification8: this.state.formRiskClassification8,
        formRiskClassification9: this.state.formRiskClassification9,

        formRiskADA1: this.state.formRiskADA1,
        formRiskADA2: this.state.formRiskADA2,
        formRiskADA3: this.state.formRiskADA3,
        formRiskADA4: this.state.formRiskADA4,

        formRiskCompliance1: this.state.formRiskCompliance1,
        formRiskCompliance1a: this.state.formRiskCompliance1a,
        formRiskCompliance2: this.state.formRiskCompliance2,
        formRiskCompliance3: this.state.formRiskCompliance3,
        formRiskCompliance4: this.state.formRiskCompliance4,

        formRiskSafety1: this.state.formRiskSafety1,
        formRiskSafety1a: this.state.formRiskSafety1a,
        formRiskSafety2: this.state.formRiskSafety2,
        formRiskSafety2a: this.state.formRiskSafety2a,
        formRiskSafety3: this.state.formRiskSafety3,
        formRiskSafety4: this.state.formRiskSafety4,
        formRiskSafety4a: this.state.formRiskSafety4a,
        formRiskSafety5: this.state.formRiskSafety5,
        formRiskSafety6: this.state.formRiskSafety6,
        formRiskSafety7: this.state.formRiskSafety7,
        formRiskSafety9: this.state.formRiskSafety9,
      };
    } else if (this.state.questionSection === "audit_strategy") {
      data = {
        formStrategy1: this.state.formStrategy1,
        formStrategy2: this.state.formStrategy2,
        formStrategy2Answer: this.state.formStrategy2Answer,
        formStrategy3: this.state.formStrategy3,
        formStrategy3Answer: this.state.formStrategy3Answer,
        formStrategy4: this.state.formStrategy4,
        formStrategy5: this.state.formStrategy5,
        formStrategy5a: this.state.formStrategy5a,
        formStrategy5b: this.state.formStrategy5b,
        formStrategy5c: this.state.formStrategy5c,

        formStrategyFeedback: this.state.formStrategyFeedback,
        formStrategyLead: this.state.formStrategyLead,
        formStrategyDiscussion: this.state.formStrategyDiscussion,
        formStrategyOther: this.state.formStrategyOther,
        formStrategyOtherAnswer: this.state.formStrategyOtherAnswer,

        formStrategyEmail: this.state.formStrategyEmail,
        formStrategyFaceMeetings: this.state.formStrategyFaceMeetings,
        formStrategyDeptMeetings: this.state.formStrategyDeptMeetings,
        formStrategy7Other: this.state.formStrategy7Other,
        formStrategy7OtherAnswer: this.state.formStrategy7OtherAnswer,

        formStrategy8: this.state.formStrategy8,
        formStrategy9: this.state.formStrategy9,
        formStrategy9Answer: this.state.formStrategy9Answer,
        formStrategy10: this.state.formStrategy10,
        formStrategy11: this.state.formStrategy11,
      };
    } else if (this.state.questionSection === "audit_rewards") {
      data = {
        formRewardsBenefitsMedical: this.state.formRewardsBenefitsMedical,
        formRewardsBenefitsDental: this.state.formRewardsBenefitsDental,
        formRewardsBenefitsVision: this.state.formRewardsBenefitsVision,
        formRewardsBenefitsRX: this.state.formRewardsBenefitsRX,
        formRewardsBenefitsFSA: this.state.formRewardsBenefitsFSA,
        formRewardsBenefitsHRA: this.state.formRewardsBenefitsHRA,
        formRewardsBenefitsHSA: this.state.formRewardsBenefitsHSA,
        formRewardsBenefitsLife: this.state.formRewardsBenefitsLife,
        formRewardsBenefitsADD: this.state.formRewardsBenefitsADD,
        formRewardsBenefitsSTD: this.state.formRewardsBenefitsSTD,
        formRewardsBenefitsLTD: this.state.formRewardsBenefitsLTD,
        formRewardsBenefitsOther: this.state.formRewardsBenefitsOther,
        formRewardsBenefitsOtherAnswer: this.state.formRewardsBenefitsOtherAnswer,

        formRewardsDependentsNone: this.state.formRewardsDependentsNone,
        formRewardsDependentsFull: this.state.formRewardsDependentsFull,
        formRewardsDependentsHalf: this.state.formRewardsDependentsHalf,
        formRewardsDependentsOther: this.state.formRewardsDependentsOther,
        formRewardsDependentsOtherAnswer: this.state.formRewardsDependentsOtherAnswer,

        formRewardsBenefits2: this.state.formRewardsBenefits2,
        formRewardsBenefits3: this.state.formRewardsBenefits3,
        formRewardsBenefits4: this.state.formRewardsBenefits4,
        formRewardsBenefits5: this.state.formRewardsBenefits5,
        formRewardsBenefits6: this.state.formRewardsBenefits6,
        formRewardsBenefits7: this.state.formRewardsBenefits7,
        formRewardsBenefits8: this.state.formRewardsBenefits8,
        formRewardsBenefits9: this.state.formRewardsBenefits9,
        formRewardsBenefits10: this.state.formRewardsBenefits10,

        formRewardsPaidLeaveVacation: this.state.formRewardsPaidLeaveVacation,
        formRewardsPaidLeaveTimeOff: this.state.formRewardsPaidLeaveTimeOff,
        formRewardsPaidLeaveHoliday: this.state.formRewardsPaidLeaveHoliday,
        formRewardsPaidLeaveSick: this.state.formRewardsPaidLeaveSick,
        formRewardsPaidLeaveBereavement: this.state.formRewardsPaidLeaveBereavement,
        formRewardsPaidLeaveMedical: this.state.formRewardsPaidLeaveMedical,
        formRewardsPaidLeaveSabbatical: this.state.formRewardsPaidLeaveSabbatical,
        formRewardsPaidLeaveAdoption: this.state.formRewardsPaidLeaveAdoption,
        formRewardsPaidLeaveOther: this.state.formRewardsPaidLeaveOther,
        formRewardsPaidLeaveOtherAnswer: this.state.formRewardsPaidLeaveOtherAnswer,

        formRewardsPaidLeave1: this.state.formRewardsPaidLeave1,
        formRewardsPaidLeave2: this.state.formRewardsPaidLeave2,
        formRewardsPaidLeave2a: this.state.formRewardsPaidLeave2a,
        formRewardsPaidLeave3: this.state.formRewardsPaidLeave3,
        formRewardsPaidLeave4: this.state.formRewardsPaidLeave4,
        formRewardsPaidLeave5: this.state.formRewardsPaidLeave5,

        formRewardsBalance1: this.state.formRewardsBalance1,

        formRewardsBalance2Flex: this.state.formRewardsBalance2Flex,
        formRewardsBalance2Remote: this.state.formRewardsBalance2Remote,
        formRewardsBalance2Compressed: this.state.formRewardsBalance2Compressed,
        formRewardsBalance2Sharing: this.state.formRewardsBalance2Sharing,
        formRewardsBalance2PartTime: this.state.formRewardsBalance2PartTime,
        formRewardsBalance2Seasonal: this.state.formRewardsBalance2Seasonal,

        formRewardsBalance4: this.state.formRewardsBalance4,
        formRewardsBalance4a: this.state.formRewardsBalance4a,
        formRewardsBalance5: this.state.formRewardsBalance5,
        formRewardsBalance6: this.state.formRewardsBalance6,

        formRewardsPayroll1: this.state.formRewardsPayroll1,
        formRewardsPayroll2: this.state.formRewardsPayroll2,
        formRewardsPayroll3: this.state.formRewardsPayroll3,
        formRewardsPayroll4: this.state.formRewardsPayroll4,

        formRewardsHRISPayroll: this.state.formRewardsHRISPayroll,
        formRewardsHRISSelf: this.state.formRewardsHRISSelf,
        formRewardsHRISCompany: this.state.formRewardsHRISCompany,
        formRewardsHRISTimekeeping: this.state.formRewardsHRISTimekeeping,
        formRewardsHRISApplicant: this.state.formRewardsHRISApplicant,
        formRewardsHRISPerformance: this.state.formRewardsHRISPerformance,
        formRewardsHRISOSHA: this.state.formRewardsHRISOSHA,
        formRewardsHRISTraining: this.state.formRewardsHRISTraining,
        formRewardsHRISOther: this.state.formRewardsHRISOther,
        formRewardsHRISOtherAnswer: this.state.formRewardsHRISOtherAnswer,

        formRewardsHRIS4: this.state.formRewardsHRIS4,
        formRewardsHRIS5: this.state.formRewardsHRIS5,

        formRewardsCompPremiumShift: this.state.formRewardsCompPremiumShift,
        formRewardsCompPremiumWeekend: this.state.formRewardsCompPremiumWeekend,
        formRewardsCompPremiumHoliday: this.state.formRewardsCompPremiumHoliday,
        formRewardsCompPremiumOnCall: this.state.formRewardsCompPremiumOnCall,
        formRewardsCompPremiumHazard: this.state.formRewardsCompPremiumHazard,
        formRewardsCompPremiumBiLingual: this.state.formRewardsCompPremiumBiLingual,
        formRewardsCompPremiumSkill: this.state.formRewardsCompPremiumSkill,

        formRewardsCompVariableCommission: this.state.formRewardsCompVariableCommission,
        formRewardsCompVariableSharing: this.state.formRewardsCompVariableSharing,
        formRewardsCompVariableReferral: this.state.formRewardsCompVariableReferral,
        formRewardsCompVariableHiring: this.state.formRewardsCompVariableHiring,
        formRewardsCompVariableRetention: this.state.formRewardsCompVariableRetention,
        formRewardsCompVariableCompletion: this.state.formRewardsCompVariableCompletion,
        formRewardsCompVariableOther: this.state.formRewardsCompVariableOther,
        formRewardsCompVariableOtherAnswer: this.state.formRewardsCompVariableOtherAnswer,

        formRewardsCompTermsPerformance: this.state.formRewardsCompTermsPerformance,
        formRewardsCompTermsLiving: this.state.formRewardsCompTermsLiving,
        formRewardsCompTermsMarket: this.state.formRewardsCompTermsMarket,

        formRewardsCompensation1: this.state.formRewardsCompensation1,
        formRewardsCompensation2: this.state.formRewardsCompensation2,
        formRewardsCompensation3: this.state.formRewardsCompensation3,
        formRewardsCompensation4: this.state.formRewardsCompensation4,
        formRewardsCompensation5: this.state.formRewardsCompensation5,
        formRewardsCompensation6: this.state.formRewardsCompensation6,
        formRewardsCompensation9: this.state.formRewardsCompensation9,

        formRewardsRetirement5: this.state.formRewardsRetirement5,
        formRewardsRetirement5Answer: this.state.formRewardsRetirement5Answer,
        formRewardsRetirement6: this.state.formRewardsRetirement6,
        formRewardsRetirement6a: this.state.formRewardsRetirement6a,
        formRewardsRetirement7: this.state.formRewardsRetirement7,
        formRewardsRetirement9: this.state.formRewardsRetirement9,
      };
    } else if (this.state.questionSection === "audit_workforce") {
      data = {
        formWorkforceAttraction1: this.state.formWorkforceAttraction1,
        formWorkforceAttraction2: this.state.formWorkforceAttraction2,
        formWorkforceAttraction3: this.state.formWorkforceAttraction3,
        formWorkforceAttraction4: this.state.formWorkforceAttraction4,
        formWorkforceAttraction5: this.state.formWorkforceAttraction5,
        formWorkforceAttraction6: this.state.formWorkforceAttraction6,
        formWorkforceAttraction7: this.state.formWorkforceAttraction7,
        formWorkforceAttraction8: this.state.formWorkforceAttraction8,
        formWorkforceAttraction9: this.state.formWorkforceAttraction9,
        formWorkforceAttraction9a: this.state.formWorkforceAttraction9a,
        formWorkforceAttraction11: this.state.formWorkforceAttraction11,
        formWorkforceAttraction13: this.state.formWorkforceAttraction13,
        formWorkforceAttraction14a: this.state.formWorkforceAttraction14a,
        formWorkforceAttraction15: this.state.formWorkforceAttraction15,

        formWorkforceContractors1: this.state.formWorkforceContractors1,
        formWorkforceContractors1a: this.state.formWorkforceContractors1a,
        formWorkforceContractors1b: this.state.formWorkforceContractors1b,
        formWorkforceContractors1c: this.state.formWorkforceContractors1c,
        formWorkforceContractors2: this.state.formWorkforceContractors2,

        formWorkforceTemp2: this.state.formWorkforceTemp2,
        formWorkforceTemp2a: this.state.formWorkforceTemp2a,
        formWorkforceTemp2b: this.state.formWorkforceTemp2b,
        formWorkforceTemp2c: this.state.formWorkforceTemp2c,
        formWorkforceTemp3: this.state.formWorkforceTemp3,

        formWorkforceInterns1: this.state.formWorkforceInterns1,
        formWorkforceInterns1a: this.state.formWorkforceInterns1a,
        formWorkforceInterns1b: this.state.formWorkforceInterns1b,
        formWorkforceInterns2: this.state.formWorkforceInterns2,
      };
    }
    this.updateApiCall(this.state.questionSection, data);
  }, 1000);

  updateApiCall(questionSection, data) {
    if (questionSection === "audit_general") {
      fetch(API_URL + `/interview/general`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err))
      console.log(questionSection);
      console.log(data)
        
    } else if (questionSection === "audit_culture") {
      fetch(API_URL + `/interview/culture`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_performance") {
      fetch(API_URL + `/interview/performance`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_policies") {
      fetch(API_URL + `/interview/policies`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_risk") {
      fetch(API_URL + `/interview/risk`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_strategy") {
      fetch(API_URL + `/interview/strategy`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_rewards") {
      fetch(API_URL + `/interview/rewards`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    } else if (questionSection === "audit_workforce") {
      fetch(API_URL + `/interview/workforce`, {
        method: "PUT",
        body: JSON.stringify({
          employeeId: this.props.employeeId,
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      console.log(questionSection);
      console.log(data);
    }
  }

  displayQuestionSection(section) {
    this.setState({ questionSection: section }, () => this.displaySectionHeading());
  }

  displaySectionHeading() {
    if (this.state.questionSection === "audit_general") {
      this.setState({ questionSectionDisplay: "General Organization Information" })
      
    } else if (this.state.questionSection === "audit_culture") {
      this.setState({ questionSectionDisplay: "Culture Development" })
    
    } else if (this.state.questionSection === "audit_performance") {
      this.setState({ questionSectionDisplay: "Performance Management" })
      
    } else if (this.state.questionSection === "audit_policies") {
      this.setState({ questionSectionDisplay: "HR Policies & Procedures" })

    } else if (this.state.questionSection === "audit_risk") {
      this.setState({ questionSectionDisplay: "Risk Management" })

    } else if (this.state.questionSection === "audit_strategy") {
      this.setState({ questionSectionDisplay: "Strategy & Change" })

    } else if (this.state.questionSection === "audit_rewards") {
      this.setState({ questionSectionDisplay: "Total Rewards" })

    } else if (this.state.questionSection === "audit_workforce") {
      this.setState({ questionSectionDisplay: "Workforce Planning & Talent Attraction" })
    }
  }

  linkDisplaySectionsNext() {
    window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    if (this.props.employment_level === "Employee") {
      if (this.state.questionSection === "audit_general") {
        this.setState({ questionSection: "audit_culture" });
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({ questionSection: "audit_performance" });
      } else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection: "audit_policies"})
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection : "audit_strategy"})
      } else if (this.state.questionSection === "audit_strategy") {
        this.setState({questionSection: "audit_rewards"})
      }
    } else if (this.props.employment_level === "Supervisor" || this.props.employment_level === "HR Lead") {
      if (this.state.questionSection === "audit_general") {
        this.setState({ questionSection: "audit_culture" });
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({ questionSection: "audit_performance" });
      } else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection: "audit_policies"})
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection : "audit_risk"})
      } else if (this.state.questionSection === "audit_risk") {
        this.setState({questionSection: "audit_strategy"})
      }else if (this.state.questionSection === "audit_strategy") {
        this.setState({questionSection : "audit_rewards"})
      } else if (this.state.questionSection === "audit_rewards") {
        this.setState({questionSection: "audit_workforce"})
      }
    } else if (this.props.employment_level === "Executive") {
      if (this.state.questionSection === "audit_general") {
        this.setState({ questionSection: "audit_culture" });
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({ questionSection: "audit_performance" });
      } else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection: "audit_policies"})
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection: "audit_strategy"})
      }else if (this.state.questionSection === "audit_strategy") {
        this.setState({questionSection : "audit_rewards"})
      } else if (this.state.questionSection === "audit_rewards") {
        this.setState({questionSection: "audit_workforce"})
      }
    }
  }

  linkDisplaySectionsBack() {
    window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    if (this.props.employment_level === "Employee") {
      if (this.state.questionSection === "audit_rewards") {
        this.setState({ questionSection: "audit_strategy" });
      } else if (this.state.questionSection === "audit_strategy") {
        this.setState({ questionSection: "audit_policies" });
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection: "audit_performance"})
      } else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection : "audit_culture"})
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({questionSection: "audit_general"})
      }
    } else if (this.props.employment_level === "Supervisor" || this.props.employment_level === "HR Lead") {
      if (this.state.questionSection === "audit_workforce") {
        this.setState({ questionSection: "audit_rewards" });
      } else if (this.state.questionSection === "audit_rewards") {
        this.setState({ questionSection: "audit_strategy" });
      } else if (this.state.questionSection === "audit_strategy") {
        this.setState({questionSection: "audit_risk"})
      } else if (this.state.questionSection === "audit_risk") {
        this.setState({questionSection : "audit_policies"})
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection: "audit_performance"})
      }else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection : "audit_culture"})
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({questionSection: "audit_general"})
      }
    } else if (this.props.employment_level === "Executive") {
      if (this.state.questionSection === "audit_workforce") {
        this.setState({ questionSection: "audit_rewards" });
      } else if (this.state.questionSection === "audit_rewards") {
        this.setState({ questionSection: "audit_strategy" });
      } else if (this.state.questionSection === "audit_strategy") {
        this.setState({questionSection: "audit_policies"})
      } else if (this.state.questionSection === "audit_policies") {
        this.setState({questionSection: "audit_performance"})
      }else if (this.state.questionSection === "audit_performance") {
        this.setState({questionSection : "audit_culture"})
      } else if (this.state.questionSection === "audit_culture") {
        this.setState({questionSection: "audit_general"})
      }
    }
  }

    render() {
      const empLevel = this.props.employment_level;
      
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Div>
            {this.props.linkInterview === false && (
                <NavBar displayQuestionSection={this.displayQuestionSection} employment_level={this.props.employment_level} />
              )}
            
            <Row className="form-header">
              <Col md="auto" className="heading-name">
                <h4>
                      {this.state.questionSectionDisplay} - {this.props.employee}
                </h4>
              </Col>
              {this.props.linkInterview === false && (
                <Col xs lg="2" className="ml-auto heading-button">
                  <StyledButton
                    onClick={() => this.props.showList(this.props.auditId, this.props.clientName)}
                  >
                    <i className="fal fa-clipboard-list-check fa-2x"></i>
                    <div>Back to HCA</div>
                  </StyledButton>
              </Col>
              )}
            </Row>
            {this.state.questionSection === "audit_general" && (

              empLevel === "HR Lead" ? 
                <GeneralLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                :
              <GeneralEmployee
                handleChange={this.handleChange}
                handleCheck={this.handleCheck}
                {...this.state}
                employeeId={this.props.employeeId}
                employee={this.props.employee}
                employment_level={this.props.employment_level}
              />

            )}

             
            {this.state.questionSection === "audit_culture" && (

              empLevel === "HR Lead" ? 
                <CultureLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <CultureExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : empLevel === "Supervisor" ?
                    <CultureSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />
                    : 
                    <CultureEmployee
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />              
            )}
            {this.state.questionSection === "audit_performance" && (
              
              empLevel === "HR Lead" ? 
                <PerformanceLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <PerformanceExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : empLevel === "Supervisor" ?
                    <PerformanceSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />
                    : 
                    <PerformanceEmployee
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    /> 

            )}
            {this.state.questionSection === "audit_policies" && (
              
              empLevel === "HR Lead" ? 
                <PoliciesLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <PoliciesExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : empLevel === "Supervisor" ?
                    <PoliciesSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />
                    : 
                    <PoliciesEmployee
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    /> 

            )}
            {this.state.questionSection === "audit_risk" && (
              
              empLevel === "HR Lead" ? 
                <RiskLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                  : <RiskSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />

            )}
            {this.state.questionSection === "audit_strategy" && (
               
              empLevel === "HR Lead" ? 
                <StrategyLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <StrategyExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : empLevel === "Supervisor" ?
                    <StrategySupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />
                    : 
                    <StrategyEmployee
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    /> 

            )}
            {this.state.questionSection === "audit_rewards" && (
              
              empLevel === "HR Lead" ? 
                <RewardsLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <RewardsExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : empLevel === "Supervisor" ?
                    <RewardsSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />
                    : 
                    <RewardsEmployee
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    /> 

            )}
            {this.state.questionSection === "audit_workforce" && (
              
              empLevel === "HR Lead" ? 
                <WorkforceLead
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                />
                : empLevel === "Executive" ?
                  <WorkforceExec
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleChangeAsInt={this.handleChangeAsInt}
                  {...this.state}
                  employeeId={this.props.employeeId}
                  employee={this.props.employee}
                  employment_level={this.props.employment_level}
                  />
                  : <WorkforceSupervisor
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                      employeeId={this.props.employeeId}
                      employee={this.props.employee}
                      employment_level={this.props.employment_level}
                    />

            )}
            {this.props.linkInterview === true ?
                <Row>
                  <Col>
                    <LinkInterviewButtons 
                    questionSection={this.state.questionSection}
                    displayQuestionSection={this.state.questionSection}
                    linkDisplaySectionsNext={this.linkDisplaySectionsNext}
                    linkDisplaySectionsBack={this.linkDisplaySectionsBack}
                    employment_level={this.props.employment_level}
                  />
                  </Col>
                </Row> : null  
            }
          </Div>
         
              
          <ScrollToTop />
        </Container>
      </ThemeProvider>
    );
  }
}

export default Interview;