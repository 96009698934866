import React, { Component } from 'react';
import {Accordion, Card} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';
import AtWill from './Policies/3_TermsOfEmployment/AtWill';
import Bonus from './Policies/3_TermsOfEmployment/Bonus';
import EmployeeClassification from './Policies/3_TermsOfEmployment/EmployeeClassifications';
import GeneralHiringReqs from './Policies/3_TermsOfEmployment/GeneralHiringReq';
import GettingPaid from './Policies/3_TermsOfEmployment/GettingPaid';
import HoursOfWork from './Policies/3_TermsOfEmployment/HoursOfWork';
import JobPerformance from './Policies/3_TermsOfEmployment/JobPerformance';
import MedicalEvaluations from './Policies/3_TermsOfEmployment/MedicalEvaluations';
import NonSolicitation from './Policies/3_TermsOfEmployment/NonSolicitation';
import PersonnelData from './Policies/3_TermsOfEmployment/PersonnelData';
import Relatives from './Policies/3_TermsOfEmployment/Relatives';
import RemoteWork from './Policies/3_TermsOfEmployment/RemoteWork';
import Safety from './Policies/3_TermsOfEmployment/Safety';
import Separation from './Policies/3_TermsOfEmployment/Separation';

const Div = styled.div`
     
    .accordion{
        color: white;
        background: black;
        border: 1px solid black;
        
    }

`

class SectionEmployment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionName: 'Introduction'
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Accordion className="my-5">
                                <Card>
                                    <Accordion.Toggle className="accordion" as={Card.Header} eventKey="0">
                                        3. Terms of Employment
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        At Will
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><AtWill clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Bonus
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Bonus clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Employment Classifications
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><EmployeeClassification clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        General Hiring Requirements
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><GeneralHiringReqs clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>

                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Getting Paid
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><GettingPaid clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Hours of Work
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><HoursOfWork clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Job Performance
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><JobPerformance clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Employee Medical Evaluations
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><MedicalEvaluations clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Non-Solicitation and Distribution
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><NonSolicitation clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Personnel Data
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><PersonnelData clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Employment of Relatives
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Relatives clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Remote Work 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><RemoteWork clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Safety Procedures & Preventing Injuries
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Safety clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                     <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Separation from Employment
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Separation clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                </Div>
            </ThemeProvider>
        )
    }
}

export default SectionEmployment;