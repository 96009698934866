import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class AtWill extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Employment At Will</h1>
                    <p>Employment at the {this.props.clientName} is at-will. This means your employment is for an indefinite period of time and it is subject to termination by you or {this.props.clientName}, with or without cause, with or without notice, and at any time. Nothing in this policy or any other policy of {this.props.clientName} shall be interpreted to be in conflict with or to eliminate or modify in any way, the at-will employment status of {this.props.clientName}’s employees.  Any written or oral statement to the contrary by any employee of {this.props.clientName} is invalid and should not be relied upon by any prospective or existing employee. Any exception to at-will employment must be in writing and signed by the CEO and must specifically state that the at-will relationship has been so altered.</p>

                    <p>In addition, an employee’s job duties, pay, hours of work, and other terms of employment may be subject to change at {this.props.clientName}’s discretion, though {this.props.clientName} will strive to provide employees with advance notice of any potential change when possible.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default AtWill;
