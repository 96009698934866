import React, { Component } from 'react';
import { Jumbotron, Row, Col} from 'react-bootstrap';
import styled, {ThemeProvider} from 'styled-components'
import theme from "../../Config/Theme";

import StatsCandaceA from './Team/StatsCandaceA';
import StatsDeniseMF from './Team/StatsDeniseMF';
import StatsGabrielaN from './Team/StatsGabrielaN';
import StatsHayleyT from './Team/StatsHayleyT';
import StatsJazminK from './Team/StatsJazminK';
import StatsKacyeB from './Team/StatsKacyeB';
import StatsKathyB from './Team/StatsKathyB';
import StatsMercedesC from './Team/StatsMercedesC';
import StatsLaurenL from './Team/StatsLaurenL';
import StatsLukeB from './Team/StatsLukeB';

import StatsTracyM from './Team/StatsTracyM';

const Div = styled.div `

    .score-cards-big{
        background-color: ${(props) => props.theme.colors.dark_blue};
        color: white;
        border: 1px solid white;
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 1rem;
    }

    .score-cards-sml{
        background-color: white;
        color: black;
        border: 1px solid white;
        border-radius: 0.25rem;
        padding: .25rem;
        margin: 0.5rem;
    }
`

class FullStatsEmployee extends Component {



    render() {
        return (
            <Jumbotron className='text-dark'>
                <ThemeProvider theme={theme}>
                    <Div>
                        <h3 className='display-4'>Stats by Employee</h3>

                        
                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Candace Anderson</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.candaceScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.candaceScore/this.props.candaceScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsCandaceA surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Denise Maloney-Flores</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.deniseScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.deniseScore/this.props.deniseScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsDeniseMF surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Gabriela Norton</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.gabrielaScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.gabrielaScore/this.props.gabrielaScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsGabrielaN surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Hayley Tibbetts</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.hayleyScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.hayleyScore/this.props.hayleyScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsHayleyT surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Jazmin Kelly</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.jazminScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.jazminScore/this.props.jazminScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsJazminK surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Kacye Benson</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.kacyeScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.kacyeScore/this.props.kacyeScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsKacyeB surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Kathy Bennett</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.kathyScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.kathyScore/this.props.kathyScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsKathyB surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Lauren Lloyd</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.laurenScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.laurenScore/this.props.laurenScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsLaurenL surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Luke Benson</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.lukeScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.lukeScore/this.props.lukeScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsLukeB surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Mercedes Contreras</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.mercedesScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.mercedesScore/this.props.mercedesScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsMercedesC surveyData={this.props.surveyData} />
                            </Row> 
                        </div>

                        

                        <div className='score-cards-big lead'>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h3 className='display-4'>Tracy Miller</h3>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className='text-center score-cards-sml'>
                                            <p className='font-weight-bold'>Overall Rating</p>
                                            {
                                                this.props.tracyScore > 0 ?
                                                    <h4 className='display-1'>{(this.props.tracyScore/this.props.tracyScoreDiv).toFixed(2)}</h4> : <h4 className='display-1'>--</h4>
                                                }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <StatsTracyM surveyData={this.props.surveyData} />
                            </Row> 
                        </div>
                    </Div>
                </ThemeProvider>
            </Jumbotron>
        )
    }
}

export default FullStatsEmployee;
                                                