import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class CivicDuty extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Civic Duty - Texas and Federal</h1>
                    <p>{this.props.clientName} encourages employees to fulfill their civic responsibility and will not retaliate against any employee who requests time off to vote, serve as a juror or respond to a subpoena. In addition, time off for military duty will be granted for those employees who are inducted or enlisted in the Armed Forces of the U.S. or who serve on active or inactive duty with the Armed Forces while a member of the Reserve Unit.  {this.props.clientName} will provide employees with up to three days of paid jury duty leave each year; otherwise, employees may utilize PTO or request unpaid time off from work to fulfill these civic responsibilities, except where otherwise unauthorized by law.</p>

                    <p>Where applicable, employees must show the subpoena or jury duty summons to their supervisor as soon as possible so that the supervisor may arrange to accommodate their absence.  Of course, employees are expected to report for work whenever the court schedule permits.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default CivicDuty;
