import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class CompanyCulture extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Company Culture</h4>
                                <hr />
                                <p>Mission: a concise explanation of the organization’s reason for existence. It describes the organization’s purpose and overall intentions.</p>
                                <p>Vision: a statement that captures the core goals and aspirations of an organization focusing on what the organization wants to ultimately become. </p>
                                <p>Values: guiding beliefs and foundational principles upon which an organization is based, aiding a team in functioning together and forming the way employees should interact.</p>
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Lowest/Worst and
                                5-Highest/Best)...
                                </p>
                                <Form.Group
                                as={Row}
                                name="formCulture"
                                controlId="formCulture"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    1. Our culture reflects the mission, vision, and values of the organization?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formCulture"
                                    id="formCulture-1"
                                    defaultChecked={this.props.formCulture === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formCulture"
                                    id="formCulture-2"
                                    defaultChecked={this.props.formCulture === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formCulture"
                                    id="formCulture-3"
                                    defaultChecked={this.props.formCulture === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formCulture"
                                    id="formCulture-4"
                                    defaultChecked={this.props.formCulture === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formCulture"
                                    id="formCulture-5"
                                    defaultChecked={this.props.formCulture === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                    name="formCultureWords"
                                    as={Row}
                                    controlId="formWords"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        2.Choose from the following words that describe the company culture. (Check all that apply)
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Inviting"
                                        name="formWordsInviting"
                                        id="formWordsInviting"
                                        defaultChecked={this.props.formWordsInviting}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Engaging"
                                        name="formWordsEngaging"
                                        id="formWordsEngaging"
                                        defaultChecked={
                                            this.props.formWordsEngaging
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Transparent"
                                        name="formWordsTransparent"
                                        id="formWordsTransparent"
                                        defaultChecked={
                                            this.props.formWordsTransparent
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Open Communication"
                                        name="formWordsOpen"
                                        id="formWordsOpen"
                                        defaultChecked={this.props.formWordsOpen}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Valued"
                                        name="formWordsValued"
                                        id="formWordsValued"
                                        defaultChecked={
                                            this.props.formWordsValued
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Supportive"
                                        name="formWordsSupportive"
                                        id="formWordsSupportive"
                                        defaultChecked={
                                            this.props.formWordsSupportive
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Demanding"
                                        name="formWordsDemanding"
                                        id="formWordsDemanding"
                                        defaultChecked={
                                            this.props.formWordsDemanding
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Unethical"
                                        name="formWordsUnethical"
                                        id="formWordsUnethical"
                                        defaultChecked={
                                            this.props.formWordsUnethical
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Rigid"
                                        name="formWordsRigid"
                                        id="formWordsRigid"
                                        defaultChecked={
                                            this.props.formWordsRigid
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Boring"
                                        name="formWordsBoring"
                                        id="formWordsBoring"
                                        defaultChecked={this.props.formWordsBoring}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Hostile"
                                        name="formWordsHostile"
                                        id="formWordsHostile"
                                        defaultChecked={
                                            this.props.formWordsHostile
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Stressful"
                                        name="formWordsStressful"
                                        id="formWordsStressful"
                                        defaultChecked={this.props.formWordsStressful}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Silenced"
                                        name="formWordsSilenced"
                                        id="formWordsSilenced"
                                        defaultChecked={this.props.formWordsSilenced}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Micromanaged"
                                        name="formWordsMicromanaged"
                                        id="formWordsMicromanaged"
                                        defaultChecked={this.props.formWordsMicromanaged}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Disengaged"
                                        name="formWordsDisengaged"
                                        id="formWordsDisengaged"
                                        defaultChecked={this.props.formWordsDisengaged}
                                        />
                                    </Col>
                                </Form.Group>
                            </div>

                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default CompanyCulture;