import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Questions extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>                
                <Div>
                    <Container>
                        <Form className='p-3'>
                            <div className='form-section'>
                                <h4>Talent Acquisition</h4>
                                <hr />
                                <p className="font-weight-bold">This set of questions pertains to your experience <em>interviewing with the PPR team.</em></p>
                                <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>
                                <Form.Group
                                    as={Row}
                                    name="formProfessional"
                                    controlId="formProfessional"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        The talent consultant(s) was/were professional
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formProfessional"
                                        id="formProfessional-1"
                                        defaultChecked={this.props.formProfessional === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formProfessional"
                                        id="formProfessional-2"
                                        defaultChecked={this.props.formProfessional === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formProfessional"
                                        id="formProfessional-3"
                                        defaultChecked={this.props.formProfessional === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formProfessional"
                                        id="formProfessional-4"
                                        defaultChecked={this.props.formProfessional === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formProfessional"
                                        id="formProfessional-5"
                                        defaultChecked={this.props.formProfessional === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formPolite"
                                    controlId="formPolite"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        The talent consultant(s) was/were polite
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formPolite"
                                        id="formPolite-1"
                                        defaultChecked={this.props.formPolite === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formPolite"
                                        id="formPolite-2"
                                        defaultChecked={this.props.formPolite === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formPolite"
                                        id="formPolite-3"
                                        defaultChecked={this.props.formPolite === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formPolite"
                                        id="formPolite-4"
                                        defaultChecked={this.props.formPolite === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formPolite"
                                        id="formPolite-5"
                                        defaultChecked={this.props.formPolite === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formKnowledgeable"
                                    controlId="formKnowledgeable"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        The talent consultant(s) was/were knowledgeable about the hiring company and open position.
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formKnowledgeable"
                                        id="formKnowledgeable-1"
                                        defaultChecked={this.props.formKnowledgeable === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formKnowledgeable"
                                        id="formKnowledgeable-2"
                                        defaultChecked={this.props.formKnowledgeable === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formKnowledgeable"
                                        id="formKnowledgeable-3"
                                        defaultChecked={this.props.formKnowledgeable === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formKnowledgeable"
                                        id="formKnowledgeable-4"
                                        defaultChecked={this.props.formKnowledgeable === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formKnowledgeable"
                                        id="formKnowledgeable-5"
                                        defaultChecked={this.props.formKnowledgeable === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formJobRequirements"
                                    controlId="formJobRequirements"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        The talent consultant(s) very clearly described all the job requirements and details.
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formJobRequirements"
                                        id="formJobRequirements-1"
                                        defaultChecked={this.props.formJobRequirements === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formJobRequirements"
                                        id="formJobRequirements-2"
                                        defaultChecked={this.props.formJobRequirements === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formJobRequirements"
                                        id="formJobRequirements-3"
                                        defaultChecked={this.props.formJobRequirements === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formJobRequirements"
                                        id="formJobRequirements-4"
                                        defaultChecked={this.props.formJobRequirements === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formJobRequirements"
                                        id="formJobRequirements-5"
                                        defaultChecked={this.props.formJobRequirements === 5}
                                    />
                                </Form.Group>


                                


                                <Form.Group
                                    as={Row}
                                    name="formClear"
                                    controlId="formClear"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        The talent consultant(s) was/were clear about the next steps.
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formClear"
                                        id="formClear-1"
                                        defaultChecked={this.props.formClear === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formClear"
                                        id="formClear-2"
                                        defaultChecked={this.props.formClear === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formClear"
                                        id="formClear-3"
                                        defaultChecked={this.props.formClear === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formClear"
                                        id="formClear-4"
                                        defaultChecked={this.props.formClear === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formClear"
                                        id="formClear-5"
                                        defaultChecked={this.props.formClear === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formProcess"
                                    controlId="formProcess"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        I was kept in the loop throughout the whole selection process.
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formProcess"
                                        id="formProcess-1"
                                        defaultChecked={this.props.formProcess === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formProcess"
                                        id="formProcess-2"
                                        defaultChecked={this.props.formProcess === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formProcess"
                                        id="formProcess-3"
                                        defaultChecked={this.props.formProcess === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formProcess"
                                        id="formProcess-4"
                                        defaultChecked={this.props.formProcess === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formProcess"
                                        id="formProcess-5"
                                        defaultChecked={this.props.formProcess === 5}
                                    />
                                </Form.Group>

                                <hr />
                                <p className="font-weight-bold">This set of questions pertains to your experience <em>interviewing the hiring organization's team</em></p>
                                <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>


                                <Form.Group
                                    as={Row}
                                    name="formPrepared"
                                    controlId="formPrepared"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        In general, how prepared were the interviewers during your time with them?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formPrepared"
                                        id="formPrepared-1"
                                        defaultChecked={this.props.formPrepared === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formPrepared"
                                        id="formPrepared-2"
                                        defaultChecked={this.props.formPrepared === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formPrepared"
                                        id="formPrepared-3"
                                        defaultChecked={this.props.formPrepared === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formPrepared"
                                        id="formPrepared-4"
                                        defaultChecked={this.props.formPrepared === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formPrepared"
                                        id="formPrepared-5"
                                        defaultChecked={this.props.formPrepared === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formResponsibilities"
                                    controlId="formResponsibilities"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        How clear were the interviewers in describing the role and associated responsibilities?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formResponsibilities"
                                        id="formResponsibilities-1"
                                        defaultChecked={this.props.formResponsibilities === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formResponsibilities"
                                        id="formResponsibilities-2"
                                        defaultChecked={this.props.formResponsibilities === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formResponsibilities"
                                        id="formResponsibilities-3"
                                        defaultChecked={this.props.formResponsibilities === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formResponsibilities"
                                        id="formResponsibilities-4"
                                        defaultChecked={this.props.formResponsibilities === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formResponsibilities"
                                        id="formResponsibilities-5"
                                        defaultChecked={this.props.formResponsibilities === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formHighlight"
                                    controlId="formHighlight"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        How well did the interviewers allow you to highlight your strengths for this role?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formHighlight"
                                        id="formHighlight-1"
                                        defaultChecked={this.props.formHighlight === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formHighlight"
                                        id="formHighlight-2"
                                        defaultChecked={this.props.formHighlight === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formHighlight"
                                        id="formHighlight-3"
                                        defaultChecked={this.props.formHighlight === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formHighlight"
                                        id="formHighlight-4"
                                        defaultChecked={this.props.formHighlight === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formHighlight"
                                        id="formHighlight-5"
                                        defaultChecked={this.props.formHighlight === 5}
                                    />
                                </Form.Group>


                                <Form.Group
                                    as={Row}
                                    name="formUnderstand"
                                    controlId="formUnderstand"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        After your interviews, how well would you say you understand the organization's mission, vision, and values?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formUnderstand"
                                        id="formUnderstand-1"
                                        defaultChecked={this.props.formUnderstand === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formUnderstand"
                                        id="formUnderstand-2"
                                        defaultChecked={this.props.formUnderstand === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formUnderstand"
                                        id="formUnderstand-3"
                                        defaultChecked={this.props.formUnderstand === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formUnderstand"
                                        id="formUnderstand-4"
                                        defaultChecked={this.props.formUnderstand === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formUnderstand"
                                        id="formUnderstand-5"
                                        defaultChecked={this.props.formUnderstand === 5}
                                    />
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                    name="formQuestions"
                                    controlId="formQuestions"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label column sm={12} md={5}>
                                        Between the organization interviews and my interactions with the talent consultant(s), all of my questions were answered.
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formQuestions"
                                        id="formQuestions-1"
                                        defaultChecked={this.props.formQuestions === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formQuestions"
                                        id="formQuestions-2"
                                        defaultChecked={this.props.formQuestions === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formQuestions"
                                        id="formQuestions-3"
                                        defaultChecked={this.props.formQuestions === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formQuestions"
                                        id="formQuestions-4"
                                        defaultChecked={this.props.formQuestions === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formQuestions"
                                        id="formQuestions-5"
                                        defaultChecked={this.props.formQuestions === 5}
                                    />
                                </Form.Group>
                                <hr />


                                <Form.Group
                                    as={Row}
                                    name="formTime"
                                    controlId="formTime"
                                    onChange={this.props.handleChange}
                                    >
                                    <Form.Label className='font-weight-bold' column sm={5}>
                                    Did the interviewers leave you enough time to ask questions during your interview?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                            <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formTime"
                                        id="formTime-yes"
                                        defaultChecked={
                                        this.props.formTime === "yes"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formTime"
                                        id="formTime-no"
                                        defaultChecked={
                                        this.props.formTime === "no"
                                        }                               
                                    /></Row></Col>
                                </Form.Group>

                                {/* <Form.Group
                                    as={Row}
                                    name="formDiverse"
                                    controlId="formDiverse"
                                    onChange={this.props.handleChange}
                                    >
                                    <Form.Label className='font-weight-bold' column sm={5}>
                                    Do you feel like you met with a diverse panel of interviewers?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formDiverse"
                                        id="formDiverse-yes"
                                        defaultChecked={
                                        this.props.formDiverse === "yes"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formDiverse"
                                        id="formDiverse-no"
                                        defaultChecked={
                                        this.props.formDiverse === "no"
                                        }                               
                                    /></Row></Col>
                                </Form.Group> */}
                                <hr />


                                <Form.Group controlId="formBetterPerformance">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            A lot of different factors make up the overall interview experience. With this in mind, complete the following sentence:  <span className='font-weight-bold'>I would have performed better during my interviews if...</span> 
                                        </Form.Label>
                                        <Form.Control
                                            name="formBetterPerformance"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formBetterPerformance || ""
                                            }
                                        />
                                </Form.Group>

                                {/* <Form.Group controlId="formBiased">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            Do you think any part of the interview process was biased, and if so why?
                                        </Form.Label>
                                        <Form.Control
                                            name="formBiased"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formBiased || ""
                                            }
                                        />
                                </Form.Group> */}


                                <Form.Group controlId="formAuthentic">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            How comfortable did you feel being able to be your authentic self throughout your interview process?
                                        </Form.Label>
                                        <Form.Control
                                            name="formAuthentic"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formAuthentic || ""
                                            }
                                        />
                                </Form.Group>


                                <Form.Group
                                    name="formOutcome"
                                    
                                controlId="formOutcome"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label className='font-weight-bold' column sm={5}>
                                    What was the outcome of your interview?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                            <Form.Check
                                        inline
                                        type="radio"
                                        label="A. Your organization made me an offer, and I accepted it"
                                        value="accepted offer"
                                        name="formOutcome"
                                        id="formOutcome-accepted-offer"
                                        defaultChecked={
                                        this.props.formOutcome === "accepted offer"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="B. Your org made me an offer, but I declined it"
                                        value="declined offer"
                                        name="formOutcome"
                                        id="formOutcome-declined-offer"
                                        defaultChecked={
                                        this.props.formOutcome === "declined offer"
                                        }                               
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="C. I declined to move forward in the process before receiving an offer"
                                        value="left process"
                                        name="formOutcome"
                                        id="formOutcome-left-process"
                                        defaultChecked={
                                        this.props.formOutcome === "left the process"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="D. Your org did not make me an offer"
                                        value="no offer made"
                                        name="formOutcome"
                                        id="formOutcome-no-offer"
                                        defaultChecked={
                                        this.props.formOutcome === "no offer"
                                        }
                                    /></Row></Col>
                                </Form.Group>

                                
                                
                                <Form.Group
                                    
                                    name="formOverallRate"
                                    controlId="formOverallRate"
                                    onChange={this.props.handleChangeAsInt}
                                    >
                                    <Form.Label className="font-weight-bold" column sm={5}>
                                        How would you rate your overall experience with the recruiting process at PPR?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="1"
                                        value="1"
                                        name="formOverallRate"
                                        id="formOverallRate-1"
                                        defaultChecked={this.props.formOverallRate === 1}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="2"
                                        value="2"
                                        name="formOverallRate"
                                        id="formOverallRate-2"
                                        defaultChecked={this.props.formOverallRate === 2}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="3"
                                        value="3"
                                        name="formOverallRate"
                                        id="formOverallRate-3"
                                        defaultChecked={this.props.formOverallRate === 3}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="4"
                                        value="4"
                                        name="formOverallRate"
                                        id="formOverallRate-4"
                                        defaultChecked={this.props.formOverallRate === 4}
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="5"
                                        value="5"
                                        name="formOverallRate"
                                        id="formOverallRate-5"
                                        defaultChecked={this.props.formOverallRate === 5}
                                    />
                                </Form.Group>


                                <Form.Group controlId="formTopThree">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            What are the top three things we could do to improve our recruiting process at PPR?
                                        </Form.Label>
                                        <Form.Control
                                            name="formTopThree"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTopThree || ""
                                            }
                                        />
                                </Form.Group>


                                <Form.Group controlId="formAnything">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            Do you have anything else to add?
                                        </Form.Label>
                                        <Form.Control
                                            name="formAnything"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formAnything || ""
                                            }
                                        />
                                </Form.Group>


                                <Form.Group controlId="formQuote">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            Please provide us a quote we could share about your experience?
                                        </Form.Label>
                                        <Form.Control
                                            name="formQuote"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formQuote || ""
                                            }
                                        />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Questions;