import React, { Component } from 'react';
import { Container, Form} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Header extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                        <div className="form-section">
                            <h1>Client Satisfaction Survey</h1>
                        </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Header;