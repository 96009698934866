import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';


const Div = styled.div`
    background-color: white;
    color: black;
`

const Img = styled.img`
    width: 7rem;
    border-radius: 7rem;
    margin-top: 1.5rem;
`

class Team extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Your Talent Acquisition Team</h4>
                                
                                {(this.props.deniseMF === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Denise</h3>
                            <h3>Maloney-Flores</h3>
                            <Img src='https://pprhr.com/assets/images/team/denise.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formDeniseMF_Rating"
                                controlId="formDeniseMF_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Denise is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-1"
                                    defaultChecked={this.props.formDeniseMF_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-2"
                                    defaultChecked={this.props.formDeniseMF_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-3"
                                    defaultChecked={this.props.formDeniseMF_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-4"
                                    defaultChecked={this.props.formDeniseMF_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDeniseMF_Rating"
                                    id="formDeniseMF_Rating-5"
                                    defaultChecked={this.props.formDeniseMF_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formDeniseMF_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDeniseMF_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDeniseMF_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div> : null)}


                                {(this.props.gabrielaN === true ? 
                                <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Gabriela</h3>
                            <h3>Norton</h3>
                            <Img src='https://pprhr.com/assets/images/team/gabriela.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formGabrielaN_Rating"
                                controlId="formGabrielaN_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Gabriela is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-1"
                                    defaultChecked={this.props.formGabrielaN_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-2"
                                    defaultChecked={this.props.formGabrielaN_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-3"
                                    defaultChecked={this.props.formGabrielaN_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-4"
                                    defaultChecked={this.props.formGabrielaN_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formGabrielaN_Rating"
                                    id="formGabrielaN_Rating-5"
                                    defaultChecked={this.props.formGabrielaN_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formGabrielaN_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formGabrielaN_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formGabrielaN_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                 : null)}
                                
                                {(this.props.kathyB === true ? <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Kathy</h3>
                            <h3>Bennett</h3>
                            <Img src='https://pprhr.com/assets/images/team/kathy.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formKathyB_Rating"
                                controlId="formKathyB_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Kathy is a highly talented search consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-1"
                                    defaultChecked={this.props.formKathyB_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-2"
                                    defaultChecked={this.props.formKathyB_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-3"
                                    defaultChecked={this.props.formKathyB_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-4"
                                    defaultChecked={this.props.formKathyB_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formKathyB_Rating"
                                    id="formKathyB_Rating-5"
                                    defaultChecked={this.props.formKathyB_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formKathyB_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formKathyB_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formKathyB_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                                </div> : null)}
                                
                                
                                
                                {(this.props.laurenL === true ? 
                                    <div>
                    <hr/>
                    <Row>
                        <Col xs lg="3" >
                            <h3>Lauren</h3>
                            <h3>Lloyd</h3>
                            <Img src='https://pprhr.com/assets/images/team/lauren.jpg' />
                        </Col>
                        <Col xs lg="9">
                            <p className="font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                            </p>
                            <Form.Group
                                as={Row}
                                name="formLaurenL_Rating"
                                controlId="formLaurenL_Rating"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    Lauren is a highly talented consultant with the right experience, skills and knowledge for my organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-1"
                                    defaultChecked={this.props.formLaurenL_Rating === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-2"
                                    defaultChecked={this.props.formLaurenL_Rating === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-3"
                                    defaultChecked={this.props.formLaurenL_Rating === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-4"
                                    defaultChecked={this.props.formLaurenL_Rating === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formLaurenL_Rating"
                                    id="formLaurenL_Rating-5"
                                    defaultChecked={this.props.formLaurenL_Rating === 5}
                                />
                            </Form.Group>
                            
                            <div>
                                    
                                        <Form.Group controlId="formLaurenL_Comments">
                                        <Form.Label column sm={5}>
                                            Comments:
                                        </Form.Label>
                                        <Form.Control
                                            name="formLaurenL_Comments"
                                            as="textarea"
                                            rows="3"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formLaurenL_Comments || ""
                                            }
                                        />
                                        </Form.Group>

                                </div>
                        </Col>
                    </Row>
                </div>
                                    : null)}
                                
                            </div>
                        </Form>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Team;