import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from  "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class OpenDoor extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Open Door Communication</h1>
                    <p>{this.props.clientName} has an “open door” policy and encourages employees to share their questions, concerns, or suggestions with those who can address them properly. In most cases, an employee’s supervisor is in the best position to address an area of concern.  However, if an employee is not comfortable speaking with their supervisor, or is not satisfied with a supervisor’s response, the employee is encouraged to speak with the {this.props.clientName} HR Partner, the President & CEO, as appropriate, or alternatively, with anyone in management the employee is comfortable approaching.  While it is not always possible to achieve the result the employee wants, our experience has shown that when employees share their concerns, seek information, and provide input to resolve work-related concerns it creates a positive work environment with open and productive communication.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default OpenDoor;
