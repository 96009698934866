import React, { Component } from 'react';
import { Container, Button, Modal, Form, Col, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';

import debounce from "debounce";
// import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import CompanyCulture from './Questionnaire/Culture/CompanyCulture';
import EmployeeEngagement from './Questionnaire/Culture/EmployeeEngagement';
import DEI from './Questionnaire/Culture/DEI';
import Rewards from './Questionnaire/Culture/Rewards';
import Communication from './Questionnaire/Culture/Communication';
import Conflict from './Questionnaire/Culture/Conflict';
import Performance from './Questionnaire/Culture/Performance';
import Goal from './Questionnaire/Culture/Goal';
import Development from './Questionnaire/Culture/Development';
import JobDescriptions from './Questionnaire/Culture/JobDescriptions';
import Handbook from './Questionnaire/Culture/Handbook';
import Orientation from './Questionnaire/Culture/Orientation';
import Onboarding from './Questionnaire/Culture/Onboarding';
import Strategy from './Questionnaire/Culture/Strategy';
import Benefits from './Questionnaire/Culture/Benefits';
import Leave from './Questionnaire/Culture/Leave';
import Integration from './Questionnaire/Culture/Integration';
import Payroll from './Questionnaire/Culture/Payroll';
import Compensation from './Questionnaire/Culture/Compensation';
import Other from './Questionnaire/Culture/Other';
import CultureManagers from './Questionnaire/Culture/CultureManagers';
import Demographics from './Questionnaire/Culture/Demographics';



const API_URL = config.url.API_URL;

const Div = styled.div`
  background: ${(props) => props.theme.colors.white} !important;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    margin-left: 31px;
    margin-right: 31px;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }
`;

// const StyledButton = styled(Button)`
//     width: 6em;
//     height: 6em;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     color: ${props => props.theme.colors.white};
//     background: ${props => props.theme.colors.dark_blue};
//     border: 1px solid ${props => props.theme.colors.dark_blue};
//     &:hover{
//         color: ${props => props.theme.colors.orange};
//         background: ${props => props.theme.colors.dark_blue};
//         border: 1px solid ${props => props.theme.colors.dark_blue};
//     }

//     div{
//         margin-top: 1em;
//         font-size: .75em;
//         color: yellow;
//     }

// `

class HCA_Culture extends Component{
    constructor(props) {
        super(props);

      this.state = {
        configDone: false,
        showHideModal: true,
        displayThanks: false,
        displaySubmit: true,

        hca_id: 2,
        firstName: "",
        lastName: "",
        email: "",
        employeePosition: "",

        // Company Culture
        formCulture: "",
        formWordsInviting: "",
        formWordsEngaging: "",
        formWordsTransparent: "",
        formWordsOpen: "",
        formWordsValued: "",
        formWordsSupportive: "",
        formWordsDemanding: "",
        formWordsUnethical: "",
        formWordsRigid: "",
        formWordsBoring: "",
        formWordsHostile: "",
        formWordsStressful: "",
        formWordsSilenced: "",
        formWordsMicromanaged: "",
        formWordsDisengaged: "",

        // Employee Engagement
        formEngagementCommitted: "",
        formEngagementSatisfied: "",

        // DEI
        formDEIDiversity: "",
        formDEIEquity: "",
        formDEIInclusion: "",

        // Rewards & Recognition
        formRewardsActivities: "",
        formRewardsRecognition: "",

        // Communication
        formCommIdeas: "",
        formCommLevels: "",

        // Conflict Resolution
        formConflictPeer: false,
        formConflictManager: false,
        formConflictCommand: false,
        formConflictHR: false,
        formConflictGrievance: false,
        formConflictOther: false,
        formConflictOtherAnswer: "",

        formConflictEffective: "",

        // Performance Management
        formPerformanceProcess: "",
        formPerformanceFeedback: "",

        // Goal Setting
        formGoalsOrganization: "",
        formGoalsDepartment: "",
        formGoalsManager: "",

        // Employee Development
        formDevelopment: "",

        // Job Descriptions
        formJobDescFamiliar: "",
        formJobDescUnderstand: "",

        // Handbook
        formHandbookExist: "",
        formHandbookWhere: "",
        formHandbookAccess: "",

        // Orientation
        formOrientation: "",

        // Onboarding
        formOnboarding: "",

        // Strategy & Change
        formStrategicPlan: "",
        formUnderstandPlan: "",

        // Benefits
        formBenefits: "",

        // Leave
        formLeave: "",

        // Integration
        formFlexibleWork: "",
        formFlexiblePrograms: "",

        // Payroll
        formTimeTracking: "",
        formPayrollSystem: "",

        // Compensation
        formPay: "",

        // Other
        formOtherShare: "",

        // Demographics
        formDemoYears: "",
        formDemoAge: "",
        formDemoGender: "",
        formDemoGenderAnswer: "",
        formDemoOrientation: "",
        formDemoOrientationAnswer: "",

        formRaceBlack: false,
        formRaceAsian: false,
        formRaceLatino: false,
        formRaceMiddleEastern: false,
        formRaceHawaiian: false,
        formRaceWhite: false,
        formRaceOther: false,
        formRaceOtherAnswer: "",

        formDemoDisabled: "",
        formDemoEducation: "",
        formDemoEducationAnswer: "",
        formDemoFamily: "",
        formDemoCaregiver: "",
        formDemoVeteran: "",

        // Culture Managers
        formManOnboardWelcome: "",
        formManOrientation: "",
        formManInterviewConsistent: "",
        formManInterviewEffective: "",

        formManStrategyVersed: "",
        formManBusCont: "",

        formPerformanceMonitorMeetings: false,
        formPerformanceMonitorAnnual: false,
        formPerformanceMonitorFrequent: false,
        formPerformanceMonitorEvaluations: false,
        formPerformanceMonitorGoal: false,
        formPerformanceMonitor360: false,
        formPerformanceMonitorOther: false,
        formPerformanceMonitorOtherAnswer: "",

        formManFlexHours: false,
        formManRemote: false,
        formManCompressed: false,
        formManJobShare: false,
        formManPartTime: false,
        formManSeasonal: false,

        formManSalaryDecide: "",
        formManSalaryComm: "",

        formManCorrectives: "",

        formManLeaders: "",

        formManOther: ""
          
      };

        this.handleModalHide = this.handleModalHide.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleChangeAsInt = this.handleChangeAsInt.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

  handleModalHide() {
    this.setState({ showHideModal: false });
  }

    handleCheck(e) {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
      console.log(e.target.value);
    };

    handleChangeAsInt = (e) => {
    const targetInt = parseInt(e.target.value, 10);
    this.setState({ [e.target.name]: targetInt });
  }

  componentDidMount() {
    if (this.props.match.params) {
      this.setState({
        hca_id: this.props.match.params.hca_id
      })
    }
  }
  
  surveyConfig() {
    if (this.state.employeePosition !== "") {
      this.setState({ configDone: true });
      this.handleModalHide();
    } else {
      console.log("fill in the required fields");
    }
  }
  
  submitSurvey = debounce(() => {
    const data = {
      hca_id: this.state.hca_id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      employeePosition: this.state.employeePosition,

      // Company Culture
      formCulture: this.state.formCulture,
      formWordsInviting: this.state.formWordsInviting,
      formWordsEngaging: this.state.formWordsEngaging,
      formWordsTransparent: this.state.formWordsTransparent,
      formWordsOpen: this.state.formWordsOpen,
      formWordsValued: this.state.formWordsValued,
      formWordsSupportive: this.state.formWordsSupportive,
      formWordsDemanding: this.state.formWordsDemanding,
      formWordsUnethical: this.state.formWordsUnethical,
      formWordsRigid: this.state.formWordsRigid,
      formWordsBoring: this.state.formWordsBoring,
      formWordsHostile: this.state.formWordsHostile,
      formWordsStressful: this.state.formWordsStressful,
      formWordsSilenced: this.state.formWordsSilenced,
      formWordsMicromanaged: this.state.formWordsMicromanaged,
      formWordsDisengaged: this.state.formWordsDisengaged,

      // Employee Engagement
      formEngagementCommitted: this.state.formEngagementCommitted,
      formEngagementSatisfied: this.state.formEngagementSatisfied,

      // DEI
      formDEIDiversity: this.state.formDEIDiversity,
      formDEIEquity: this.state.formDEIEquity,
      formDEIInclusion: this.state.formDEIInclusion,

      // Rewards & Recognition
      formRewardsActivities: this.state.formRewardsActivities,
      formRewardsRecognition: this.state.formRewardsRecognition,

      // Communication
      formCommIdeas: this.state.formCommIdeas,
      formCommLevels: this.state.formCommLevels,

      // Conflict Resolution
      formConflictPeer: this.state.formConflictPeer,
      formConflictManager: this.state.formConflictManager,
      formConflictCommand: this.state.formConflictCommand,
      formConflictHR: this.state.formConflictHR,
      formConflictGrievance: this.state.formConflictGrievance,
      formConflictOther: this.state.formConflictOther,
      formConflictOtherAnswer: this.state.formConflictOtherAnswer,

      formConflictEffective: this.state.formConflictEffective,

      // Performance Management
      formPerformanceProcess: this.state.formPerformanceProcess,
      formPerformanceFeedback: this.state.formPerformanceFeedback,

      // Goal Setting
      formGoalsOrganization: this.state.formGoalsOrganization,
      formGoalsDepartment: this.state.formGoalsDepartment,
      formGoalsManager: this.state.formGoalsManager,

      // Employee Development
      formDevelopment: this.state.formDevelopment,

      // Job Descriptions
      formJobDescFamiliar: this.state.formJobDescFamiliar,
      formJobDescUnderstand: this.state.formJobDescUnderstand,

      // Handbook
      formHandbookExist: this.state.formHandbookExist,
      formHandbookWhere: this.state.formHandbookWhere,
      formHandbookAccess: this.state.formHandbookAccess,

      // Orientation
      formOrientation: this.state.formOrientation,

      // Onboarding
      formOnboarding: this.state.formOnboarding,

      // Strategy & Change
      formStrategicPlan: this.state.formStrategicPlan,
      formUnderstandPlan: this.state.formUnderstandPlan,

      // Benefits
      formBenefits: this.state.formBenefits,

      // Leave
      formLeave: this.state.formLeave,

      // Integration
      formFlexibleWork: this.state.formFlexibleWork,
      formFlexiblePrograms: this.state.formFlexiblePrograms,

      // Payroll
      formTimeTracking: this.state.formTimeTracking,
      formPayrollSystem: this.state.formPayrollSystem,

      // Compensation
      formPay: this.state.formPay,

      // Other
      formOtherShare: this.state.formOtherShare,

      // Demographics
      formDemoYears: this.state.formDemoYears,
      formDemoAge: this.state.formDemoAge,
      formDemoGender: this.state.formDemoGender,
      formDemoGenderAnswer: this.state.formDemoGenderAnswer,
      formDemoOrientation: this.state.formDemoOrientation,
      formDemoOrientationAnswer: this.state.formDemoOrientationAnswer,

      formRaceBlack: this.state.formRaceBlack,
      formRaceAsian: this.state.formRaceAsian,
      formRaceLatino: this.state.formRaceLatino,
      formRaceMiddleEastern: this.state.formRaceMiddleEastern,
      formRaceHawaiian: this.state.formRaceHawaiian,
      formRaceWhite: this.state.formRaceWhite,
      formRaceOther: this.state.formRaceOther,
      formRaceOtherAnswer: this.state.formRaceOtherAnswer,

      formDemoDisabled: this.state.formDemoDisabled,
      formDemoEducation: this.state.formDemoEducation,
      formDemoEducationAnswer: this.state.formDemoEducationAnswer,
      formDemoFamily: this.state.formDemoFamily,
      formDemoCaregiver: this.state.formDemoCaregiver,
      formDemoVeteran: this.state.formDemoVeteran,

      // Culture Managers
      formManOnboardWelcome: this.state.formManOnboardWelcome,
      formManOrientation: this.state.formManOrientation,
      formManInterviewConsistent: this.state.formManInterviewConsistent,
      formManInterviewEffective: this.state.formManInterviewEffective,

      formManStrategyVersed: this.state.formManStrategyVersed,
      formManBusCont: this.state.formManBusCont,

      formPerformanceMonitorMeetings: this.state.formPerformanceMonitorMeetings,
      formPerformanceMonitorAnnual: this.state.formPerformanceMonitorAnnual,
      formPerformanceMonitorFrequent: this.state.formPerformanceMonitorFrequent,
      formPerformanceMonitorEvaluations: this.state.formPerformanceMonitorEvaluations,
      formPerformanceMonitorGoal: this.state.formPerformanceMonitorGoal,
      formPerformanceMonitor360: this.state.formPerformanceMonitor360,
      formPerformanceMonitorOther: this.state.formPerformanceMonitorOther,
      formPerformanceMonitorOtherAnswer: this.state.formPerformanceMonitorOtherAnswer,

      formManFlexHours: this.state.formManFlexHours,
      formManRemote: this.state.formManRemote,
      formManCompressed: this.state.formManCompressed,
      formManJobShare: this.state.formManJobShare,
      formManPartTime: this.state.formManPartTime,
      formManSeasonal: this.state.formManSeasonal,

      formManSalaryDecide: this.state.formManSalaryDecide,
      formManSalaryComm: this.state.formManSalaryComm,

      formManCorrectives: this.state.formManCorrectives,

      formManLeaders: this.state.formManLeaders,

      formManOther: this.state.formManOther
    }
    this.writeSurvey(data);
  }, 1000);

  writeSurvey(data) {
    fetch(API_URL + `/culture/hca`, {
      method: "PUT",
      body: JSON.stringify({
        data: data,
      }),
      headers: {"Content-Type": "application/json"},
    }).then((res) => {
      if (!res.ok) {
        throw new Error();
      }
      return res.json();
    }).then((data) => console.log(data))
    .catch((err) => console.log(err))
  }
  

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <Div>
                      <CompanyCulture
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                      />
                
                        <EmployeeEngagement
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />
                
                        <DEI
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />
                
                        <Rewards
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />
                
                        <Communication
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Conflict
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Performance
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Goal
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Development
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <JobDescriptions
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Handbook
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Orientation
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Onboarding
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Strategy
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Benefits
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Leave
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Integration
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Payroll
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Compensation
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        <Other
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />

                        {
                          this.state.employeePosition !== "Not a Manager or Supervisor" ?
                            <CultureManagers
                            handleChange={this.handleChange}
                            handleCheck={this.handleCheck}
                            handleChangeAsInt={this.handleChangeAsInt}
                            {...this.state}
                          /> : null
                        }        

                        <Demographics
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                          handleChangeAsInt={this.handleChangeAsInt}
                          {...this.state}
                        />
                        <Div className="text-right">
                              <Button
                                className="m-4"
                                size="sm"
                                variant="primary"
                                onClick={() => this.submitSurvey()}
                              >
                                Submit
                              </Button>
                </Div>
                
                <Modal show={this.state.showHideModal} size="lg" centered>
                      <Modal.Header>
                        <Modal.Title>Company Culture Survey</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        
                  <Form>
                    {/* <Form.Row>
                      <Form.Group as={Col} controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          firstName="firstname"
                          onChange={this.handleChange}
                        />
                        </Form.Group>
                        <Form.Group as={Col} controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          lastName="lastname"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row> */}

                    <fieldset>
                      <Form.Group as={Row} onChange={this.handleChange}>
                        <Form.Label as="legend" column sm={2}>
                          Employment Level
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            type="radio"
                            label="Sr / Executive Leadership"
                            value="Sr / Executive Leadership"
                            name="employeePosition"
                            id="employeePosition1"
                          />
                          <Form.Check
                            type="radio"
                            label="Manager / Supervisor"
                            value="Manager / Supervisor"
                            name="employeePosition"
                            id="employeePosition2"
                          />
                          <Form.Check
                            type="radio"
                            label="Front Line Supervisor"
                            value="Front Line Supervisor"
                            name="employeePosition"
                            id="employeePosition3"
                          />
                          <Form.Check
                            type="radio"
                            label="Not a Manager or Supervisor"
                            value="Not a Manager or Supervisor"
                            name="employeePosition"
                            id="employeePosition4"
                          />
                        </Col>
                      </Form.Group>
                  </fieldset>
                          </Form>
                    
                    {
                      this.state.employeePosition === "" ? <Div className="text-right">
                              <Button
                                className="m-4"
                                size="lg"
                                variant="secondary"
                                disabled
                              >
                                Begin Survey
                              </Button>
                      </Div> :
                        <Div className="text-right">
                              <Button
                                className="m-4"
                                size="lg"
                                variant="primary"
                                onClick={() => this.surveyConfig()}
                              >
                                Begin Survey
                              </Button>
                            </Div>
                    }
                        
                          

                      </Modal.Body>
                    </Modal>
                    </Div>
                </Container>
            </ThemeProvider>
        )
    }

}

export default HCA_Culture;