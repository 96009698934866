import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class WorkInc extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Workplace Inclusiveness</h1>
                    <h2>Background</h2>
                    <p>{this.props.clientName} does not discriminate in any way, race, color, religion, religious creed, national origin, ancestry, age, sex, sexual orientation, gender, gender identity, gender expression, physical disability, mental disability, pregnancy, medical condition, genetic information, marital status, citizenship status, military status, veteran status, or any other characteristic. {this.props.clientName} does not discriminate against employees or applicants with human immunodeficiency virus (HIV) or acquired immune deficiency syndrome (AIDS).</p>

                    <p>This policy is to make sure all employees, contractors, volunteers, clients and visitors of {this.props.clientName} are in a safe and productive environment that is free of discrimination. Wherever possible, {this.props.clientName} will make every attempt to reasonably accommodate persons with disabilities, provided their disability does not present health or safety threats to themselves or their co-workers.</p>

                    <p>It is unlawful and a violation of this policy to discriminate in any way (including, but not limited to, failure to hire, failure to promote, or unlawful termination) against an employee because of the employee’s actual or perceived inclusion in any of the classes identified. It is also a violation of this policy to retaliate against any person objecting to, or supporting enforcement of legal protections against, discrimination in the workplace.</p>

                    <p>Any incident of discrimination, harassment, or violence will be reviewed and investigated. Information obtained in that investigation will determine what action is required, which could be additional training, additional disciplinary action, or termination. </p>

                    <h2>Definitions</h2>

                    <p>Terms defined in this policy are included to provide clarification to the intent of the policy. These terms, in themselves, can be considered labels that are sometimes the source for discrimination. It is not the intent of this policy to categorize employees, but to illustrate full inclusion within the workplace. All people are welcome, including those that identify with one or more of these groups and any others. </p>

                    <p>Most of the classes identified in this policy are self-explanatory, while others may not be clearly understood by some employees. These definitions will assist staff in recognizing and understanding various terms related to some of the classes identified in the Background section. Employees individually determine which, if any, of these terms are used to describe themselves.</p>

                    <ul>
                        <li>LGBTQ: A common abbreviation that refers to the lesbian, gay, bisexual, transgender, and questioning community (see additional definitions below). </li>
                        <li>Lesbian: Lesbian refers to a homosexual woman, or women who feel a same-sex attraction to other women. </li>
                        <li>Gay: Gay refers to people who identify as having a same-sex attraction. Gay most commonly refers to homosexual men but can also be used to refer to homosexual women. </li>
                        <li>Bisexual: Bisexual refers to people who identify as having a romantic or sexual attraction to people of any gender identity, which is also known as pansexuality. </li>
                        <li>Transgender: An umbrella term that can be used to describe people whose gender identity and/or expression is different from their sex assigned at birth.
                            <ul>
                                <li>A person whose sex assigned at birth was female but who identifies as male is a transgender man (also known as female-to-male transgender person, or FTM).</li>
                                <li>A person whose sex assigned at birth was male but who identifies as female is a transgender woman (also known as male-to-female transgender person, or MTF).</li>
                                <li>Some people described by this definition do not consider themselves transgender – they may use other words or may identify simply as a man or woman. A person does not need to identify as transgender for this nondiscrimination policy to apply to them.</li>
                            </ul>
                        </li>
                        <li>Queer: In 2016, Company Name recognized “Q” as a representation of queer/questioning. “Queer” is used by some people who find terms such as lesbian, gay, bisexual or cisgender/transgender to be too limiting to describe their sexual orientation or gender identity. “Questioning” describes an individual’s self-examination of their own identity.</li>
                        <li>Gender identity: A person’s internal, deeply-felt sense of being male, female, in-between, or something other, regardless of the sex they were assigned at birth. Everyone has a gender identity.</li>
                        <li>Gender expression: An individual’s characteristics and behaviors (such as appearance, dress, mannerisms, speech patterns, and social interactions) that may be perceived as masculine or feminine. Everyone has a gender expression.</li>
                        <li>Cisgender: Cisgender is a term used to describe people who, for the most part, identify as the gender they were assigned at birth. </li>
                        <li>Gender non-conforming: This term describes people who have, or are perceived to have, gender characteristics and/or behaviors that do not conform to traditional or societal expectations. Keep in mind that these expectations can vary across cultures and have changed over time.</li>
                        <li>Non-binary: This term is a spectrum of gender identities that are not exclusively masculine or feminine (identities that are outside the gender binary). Genderqueer is an earlier term with the same meaning. Non-binary people may identify as having two or more genders (being bi-gender or tri-gender); having no gender (agender, nongendered, genderless or genderfree); moving between genders or having fluctuating gender identity (genderfluid).</li>
                        <li>Bigender: This term describes people who feel they exhibit two genders or identify as more than one gender.</li>
                        <li>Transition: The process of changing one’s gender from the sex assigned at birth to one’s gender identity. There are many ways to transition. For some people, it is a complex process that takes place over a long period of time, while for others it is a one- or two-step process that happens more quickly. Transition may include “coming out” (telling family, friends, and coworkers); changing the name and/or sex on legal documents; and, for many transgender people, accessing medical treatment such as hormones and surgery.</li>
                        <li>Sexual orientation: A person’s physical or emotional attraction to people of the same and/or other gender. Straight, gay, and bisexual are some ways to describe sexual orientation. It is important to note that sexual orientation is distinct from gender identity and expression. Transgender people can be gay, lesbian, bisexual, or straight, just like non-transgender people.</li>
                    </ul>

                    <h2>Name and Personal Gender Pronouns ("PGP")</h2>
                    <p>An individual’s pronouns relate to their gender identity as a part of creating more visible and inclusive environments that reflect and affirm LGBTQ individuals. It is important to give everyone a chance to be recognized by pronouns that reflect their gender identify. If you are not sure how someone identifies, ask politely if they have a preferred pronoun.</p>

                    <p>Through this policy, employees are required to address others (employees, contractors, volunteers, visitors, etc.) by the name and pronoun that corresponds to the employee’s gender identity. It is a violation of this policy to intentionally refuse to respect an employee’s gender identity when it is made known. Persistently referring to the employee by a name or pronoun that does not correspond to the employee’s gender identity is a violation. </p>
                    <ul>
                        <li>It’s okay to ask someone’s personal gender pronoun.</li>
                        <li>Use the names and pronouns your co-workers prefer.</li>
                        <li>It’s ok to make mistakes but do your best to adapt.</li>
                        <li>Respectfully correct your colleagues when you hear them using the wrong gender pronouns for someone.</li>
                        <li>Examples of PGPs</li>
                        <li>she/her/hers</li>
                        <li>he/him/his</li>
                        <li>just my name</li>
                        <li>they/them/theirs</li>
                        <li>ze/zhim/zher</li>
                    </ul>

                    <h2>Privacy</h2>
                    <p>Employees have the right to discuss their gender identity or expression openly, or to keep that information private. The employee gets to decide when, with whom, and how much to share their private information. No employee should purposely disclose information that may reveal another employee’s transgender status or gender non-conforming presentation to others. </p>

                    <h2>Records</h2>
                    <p>Company Name will reflect a change in name or gender upon request from the employee. Some records, including payroll, retirement accounts, and other legal records, may require Company Name to identify the employee by their birth name and gender, and may require a legal name change before records can be modified. Most records, however, can reflect a person’s preferred information. If an employee has questions about company records, they can contact Human Resources.</p>

                    <h2>Restroom Accessibility</h2>
                    <p>All employees have a right to safe and appropriate restroom facilities, including the right to use a restroom that corresponds to the employee’s gender identity, regardless of the employee’s sex assigned at birth.</p>

                    <h2>Dress Code</h2>
                    <p>All employees, including, transgender and gender non-conforming employees, have the right to comply with company dress codes in a manner consistent with their gender identity or gender expression. Company Name has a casual dress code and employees should feel free to express their gender identity in whatever manner they feel is appropriate so long as their clothing is neat, clean and work appropriate. More information about dress codes is located later in this handbook.</p>

                    <h2>Transitioning in the Workplace</h2>
                    <p>Employees who transition during their employment at Company Name can expect the support of management and human resources. Human Resources will work with each transitioning employee individually to ensure a successful transition with supervisors and co-workers.</p>
                    <p>The transitioning employee should meet with human resources to make them aware of the employee’s upcoming transition. Human Resources will help set a plan for communicating the transition to those that need to know and those that the transitioning employee wants to inform. Meetings can be scheduled, as appropriate, to make sure the employee’s supervisor understands Company Name’s policies and to make necessary schedule modifications during the transition.</p>
                    
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default WorkInc;
