import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class RemoteWork extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Remote Work</h1>
                    <p>{this.props.clientName} considers a remote working arrangement to be a viable alternative work arrangement in cases where individual, environmental, job and supervisor characteristics are best suited to such an arrangement. Working remotely allows employees to work at home, on the road, or in a satellite location for part of an employee’s regular workweek. </p>

                    <p>{this.props.clientName} will determine on a case-by-case basis whether a specific job may be performed effectively off-site and whether an individual is effective working without supervision in a remote work environment. Working remotely is not an entitlement, it is not a company-wide benefit, and in no way changes the terms and conditions of employment with {this.props.clientName}.  </p>

                    <p>Either an employee or a supervisor can suggest remote work as a possible work arrangement provided the following criteria are met:
                        <ul>
                            <li>The employee’s work duties are conducive to a remote work arrangement.</li>
                            <li>Remote work would not adversely affect {this.props.clientName} departmental assignments and projects, or relations with its constituents. </li>
                            <li>There is adequate and suitable work available for the employee to perform remotely with no supervision; and,</li>
                            <li>The employee has maintained a good work record.</li>
                        </ul>
                    </p>

                    <p>To ensure {this.props.clientName} business operations are not negatively impacted by such arrangement, the following guidelines must be met:
                        <ul>
                            <li>Choose a quiet and distraction-free working space where meetings can be conducted via teleconferencing and video conferencing, and email and documents can be transmitted electronically.  </li>
                            <li>Dedicate full attention to job duties during working hours and be accessible by phone within a reasonable time during regular business hours, by the employee’s supervisor, team members, and other individuals with whom {this.props.clientName} conducts business.</li>
                            <li>Adhere to all {this.props.clientName} policies and procedures. </li>
                            <li>Take every precaution to ensure {this.props.clientName} information is protected. This includes accessing {this.props.clientName}’ network and documents only through a secured network authorized and approved by {this.props.clientName}. </li>
                            <li>Be aware of potential fatigue and schedule working meetings and work hours to ensure adequate engagement. </li>
                        </ul>
                    </p>
                    <p>All remote work arrangements may be altered at the discretion of {this.props.clientName}.</p>

                    <h2>Equipment and Expenses</h2>
                    <p>{this.props.clientName} will provide remote employees with equipment that is essential to their job duties, such as laptops, headsets, and cell phones (when applicable). {this.props.clientName} will install VPN and Company-required software when employees receive their equipment.  Employees may be required to use their own equipment for work should company-provided equipment be unavailable. {this.props.clientName} will reimburse the employee for that use in accordance with {this.props.clientName}’s Expense Policy. </p>
                    <p>Equipment provided by {this.props.clientName} remains {this.props.clientName}’s property. {this.props.clientName} retains control over the property and reserves the right to monitor Company property even when used at your remote location. Employees must keep equipment safe and avoid any misuse. Equipment supplied by {this.props.clientName} is to be used for business purposes only. The remote worker will sign an inventory of all Company property received and agree to take appropriate action to protect the items from damage or theft. Employees must take proper measures to secure Company information, assets, and systems. Specifically, employees must:
                        <ul>
                            <li>Keep their equipment password protected </li>
                            <li>Store equipment in a safe and clean space when not in use </li>
                            <li>Follow all data encryption, protection standards and settings </li>
                            <li>Refrain from visiting untrustworthy or suspicious sites. </li>
                            <li>Only download authorized software with prior approval. </li>
                            <li>Keep confidential information in locked file cabinets and desks. </li>
                        </ul>
                    </p>
                    <p>Remote employees will also receive expense reimbursement to cover reasonable and necessary business-related expenses that are incurred in accordance with {this.props.clientName}’s Employee Expense Policy. </p>

                    <h2>Safety Guidelines for the Home Workspace</h2>
                    <p>Employees should use the following guidelines to assist them in a survey of the overall safety and adequacy of their alternate work site. The following are only recommendations, and do not encompass every situation that may be encountered. Employees are encouraged to obtain professional assistance with issues concerning appropriate electrical service and circuit capacity for residential work sites.
                        <ul>
                            <li>Develop and practice a fire evacuation plan for use in the event of an emergency. </li>
                            <li>Check your smoke detectors regularly and replace batteries once a year. </li>
                            <li>Always have a working fire extinguisher conveniently located in your home and check the charge regularly. </li>
                            <li>Computers are heavy. Always place them on sturdy, level, well-maintained furniture. </li>
                            <li>Choose office chairs that provide good supporting backrests and allow adjustments to fit you comfortably. </li>
                            <li>Locate your computer to eliminate noticeable glare from windows and lighting. Place the computer monitor at a height that is comfortable and does not produce neck or back strain. Locate computer keyboards at heights that do not cause wrist strain or place the keyboard on an adjustable surface. </li>
                            <li>Install sufficient lighting in locations to reduce glare on the work surface. </li>
                            <li>Arrange file cabinets so that opened drawers do not block aisles. </li>
                            <li>Be sure to leave aisle space, where possible, to reduce tripping hazards. </li>
                            <li>Always make sure electrical equipment is connected to grounded outlets. </li>
                            <li>Avoid fire hazards by never overloading electrical circuits. </li>
                            <li>Inspect and repair carpeting with frayed edges or loose seams. Avoid using throw rugs that can cause tripping hazards in your workspace. </li>
                            <li>Locate computers, phones, and other electrical equipment in a manner that keeps power cords out of walkways. </li>
                            <li>Always power down computers after the workday is over and always turn off all electrical equipment during thunderstorms. </li>
                            <li>Keep your work area clean and avoid clutter, which can cause fire and tripping hazards. </li>
                            <li>Do not allow unauthorized persons to operate or repair the company’s equipment.</li>
                        </ul>
                    </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default RemoteWork;
