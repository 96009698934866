import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class ConsultingQuestions extends Component {
    render() {
        return(
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Human Capital Consulting</h4>
                            <hr />
                            <Form.Group
                                    name="formConsultingServices"
                                    as={Row}
                                    controlId="formConsultingServices"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label className='font-weight-bold' column sm={5}>
                                        1. Please select the service(s) received from the following options (Check all that apply).
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Human Capital Consulting"
                                        name="formHumanCapitalConsulting"
                                        id="formHumanCapitalConsulting"
                                        defaultChecked={this.props.formHumanCapitalConsulting}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Payroll and HRIS Implementation"
                                        name="formPayroll"
                                        id="formPayroll"
                                        defaultChecked={this.props.formPayroll}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Handbook"
                                        name="formHandbook"
                                        id="formHandbook"
                                        defaultChecked={this.props.formHandbook}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Compensation Analysis"
                                        name="formCompensation"
                                        id="formCompensation"
                                        defaultChecked={
                                            this.props.formCompensation
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Executive Coaching"
                                        name="formCoaching"
                                        id="formCoaching"
                                        defaultChecked={
                                            this.props.formCoaching
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Human Capital Analysis (Audit)"
                                        name="formHCA"
                                        id="formHCA"
                                        defaultChecked={this.props.formHCA}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Succession Planning"
                                        name="formSuccession"
                                        id="formSuccession"
                                        defaultChecked={
                                            this.props.formSuccession
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="StrengthsFinder Training/Cultural Support"
                                        name="formStrengthsFinder"
                                        id="formStrengthsFinder"
                                        defaultChecked={
                                            this.props.formStrengthsFinder
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Talent Planning Analysis (Strategic Org. Overview)"
                                        name="formTPA"
                                        id="formTPA"
                                        defaultChecked={
                                            this.props.formTPA
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Training"
                                        name="formTraining"
                                        id="formTraining"
                                        defaultChecked={
                                            this.props.formTraining
                                        }
                                        />
                                        
                                        <Form.Check
                                        type="checkbox"
                                        label="Other Special Projects"
                                        name="formServicesOther"
                                        id="formServicesOther"
                                        defaultChecked={this.props.formServicesOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formServicesOther === true ||
                                        this.props.formServicesOther === 1) && (
                                        <Form.Group controlId="formServicesOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other Special Projects:
                                        </Form.Label>
                                        <Form.Control
                                            name="formServicesOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formServicesOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>


                                <p className="font-weight-bold">
                                2. On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formTalentedConsultants"
                                controlId="formTalentedConsultants"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR provides highly talented consultants with the right experience, skills, and knowledge for my organization
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentedConsultants"
                                    id="formTalentedConsultants-1"
                                    defaultChecked={this.props.formTalentedConsultants === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentedConsultants"
                                    id="formTalentedConsultants-2"
                                    defaultChecked={this.props.formTalentedConsultants === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentedConsultants"
                                    id="formTalentedConsultants-3"
                                    defaultChecked={this.props.formTalentedConsultants === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentedConsultants"
                                    id="formTalentedConsultants-4"
                                    defaultChecked={this.props.formTalentedConsultants === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentedConsultants"
                                    id="formTalentedConsultants-5"
                                    defaultChecked={this.props.formTalentedConsultants === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formConsultantUnderstands"
                                controlId="formConsultantUnderstands"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR consultants take the time to understand our company culture and get to know our key stakeholders
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formConsultantUnderstands"
                                    id="formConsultantUnderstands-1"
                                    defaultChecked={this.props.formConsultantUnderstands === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formConsultantUnderstands"
                                    id="formConsultantUnderstands-2"
                                    defaultChecked={this.props.formConsultantUnderstands === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formConsultantUnderstands"
                                    id="formConsultantUnderstands-3"
                                    defaultChecked={this.props.formConsultantUnderstands === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formConsultantUnderstands"
                                    id="formConsultantUnderstands-4"
                                    defaultChecked={this.props.formConsultantUnderstands === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formConsultantUnderstands"
                                    id="formConsultantUnderstands-5"
                                    defaultChecked={this.props.formConsultantUnderstands === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formTimely"
                                controlId="formTimely"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR delivers projects in a timely manner
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTimely"
                                    id="formTimely-1"
                                    defaultChecked={this.props.formTimely === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTimely"
                                    id="formTimely-2"
                                    defaultChecked={this.props.formTimely === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTimely"
                                    id="formTimely-3"
                                    defaultChecked={this.props.formTimely === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTimely"
                                    id="formTimely-4"
                                    defaultChecked={this.props.formTimely === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTimely"
                                    id="formTimely-5"
                                    defaultChecked={this.props.formTimely === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formSolutionAttitude"
                                controlId="formSolutionAttitude"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR addresses my organization's challenges with a solution-oriented attitude
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formSolutionAttitude"
                                    id="formSolutionAttitude-1"
                                    defaultChecked={this.props.formSolutionAttitude === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formSolutionAttitude"
                                    id="formSolutionAttitude-2"
                                    defaultChecked={this.props.formSolutionAttitude === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formSolutionAttitude"
                                    id="formSolutionAttitude-3"
                                    defaultChecked={this.props.formSolutionAttitude === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formSolutionAttitude"
                                    id="formSolutionAttitude-4"
                                    defaultChecked={this.props.formSolutionAttitude === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formSolutionAttitude"
                                    id="formSolutionAttitude-5"
                                    defaultChecked={this.props.formSolutionAttitude === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formFairPrice"
                                controlId="formFairPrice"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR's fees are priced fairly
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formFairPrice"
                                    id="formFairPrice-1"
                                    defaultChecked={this.props.formFairPrice === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formFairPrice"
                                    id="formFairPrice-2"
                                    defaultChecked={this.props.formFairPrice === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formFairPrice"
                                    id="formFairPrice-3"
                                    defaultChecked={this.props.formFairPrice === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formFairPrice"
                                    id="formFairPrice-4"
                                    defaultChecked={this.props.formFairPrice === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formFairPrice"
                                    id="formFairPrice-5"
                                    defaultChecked={this.props.formFairPrice === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formStakeholders"
                                controlId="formStakeholders"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is able to work well with a wide variety of our stakeholders
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formStakeholders"
                                    id="formStakeholders-1"
                                    defaultChecked={this.props.formStakeholders === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formStakeholders"
                                    id="formStakeholders-2"
                                    defaultChecked={this.props.formStakeholders === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formStakeholders"
                                    id="formStakeholders-3"
                                    defaultChecked={this.props.formStakeholders === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formStakeholders"
                                    id="formStakeholders-4"
                                    defaultChecked={this.props.formStakeholders === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formStakeholders"
                                    id="formStakeholders-5"
                                    defaultChecked={this.props.formStakeholders === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formTalentedConsultants"
                                controlId="formTalentedConsultants"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is timely with their communications
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formCommunications"
                                    id="formCommunications-1"
                                    defaultChecked={this.props.formCommunications === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formCommunications"
                                    id="formCommunications-2"
                                    defaultChecked={this.props.formCommunications === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formCommunications"
                                    id="formCommunications-3"
                                    defaultChecked={this.props.formCommunications === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formCommunications"
                                    id="formCommunications-4"
                                    defaultChecked={this.props.formCommunications === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formCommunications"
                                    id="formCommunications-5"
                                    defaultChecked={this.props.formCommunications === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formProjects"
                                controlId="formProjects"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR customizes their methodologies, tools and templates to meet the unique needs of our project(s)
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formProjects"
                                    id="formProjects-1"
                                    defaultChecked={this.props.formProjects === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formProjects"
                                    id="formProjects-2"
                                    defaultChecked={this.props.formProjects === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formProjects"
                                    id="formProjects-3"
                                    defaultChecked={this.props.formProjects === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formProjects"
                                    id="formProjects-4"
                                    defaultChecked={this.props.formProjects === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formProjects"
                                    id="formProjects-5"
                                    defaultChecked={this.props.formProjects === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formOwnership"
                                controlId="formOwnership"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR takes ownership of opportunities to improve our processes, tools and capabilities
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formOwnership"
                                    id="formOwnership-1"
                                    defaultChecked={this.props.formOwnership === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formOwnership"
                                    id="formOwnership-2"
                                    defaultChecked={this.props.formOwnership === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formOwnership"
                                    id="formOwnership-3"
                                    defaultChecked={this.props.formOwnership === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formOwnership"
                                    id="formOwnership-4"
                                    defaultChecked={this.props.formOwnership === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formOwnership"
                                    id="formOwnership-5"
                                    defaultChecked={this.props.formOwnership === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formLearn"
                                controlId="formLearn"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR takes the time to learn about our business, people, culture and what matters to me most
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formLearn"
                                    id="formLearn-1"
                                    defaultChecked={this.props.formLearn === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formLearn"
                                    id="formLearn-2"
                                    defaultChecked={this.props.formLearn === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formLearn"
                                    id="formLearn-3"
                                    defaultChecked={this.props.formLearn === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formLearn"
                                    id="formLearn-4"
                                    defaultChecked={this.props.formLearn === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formLearn"
                                    id="formLearn-5"
                                    defaultChecked={this.props.formLearn === 5}
                                />
                                </Form.Group>

                                 <Form.Group
                                as={Row}
                                name="formExpectations"
                                controlId="formExpectations"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    In regard to overall value, I believe PPR meets or exceeds my expectations
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formExpectations"
                                    id="formExpectations-1"
                                    defaultChecked={this.props.formExpectations === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formExpectations"
                                    id="formExpectations-2"
                                    defaultChecked={this.props.formExpectations === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formExpectations"
                                    id="formExpectations-3"
                                    defaultChecked={this.props.formExpectations === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formExpectations"
                                    id="formExpectations-4"
                                    defaultChecked={this.props.formExpectations === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formExpectations"
                                    id="formExpectations-5"
                                    defaultChecked={this.props.formExpectations === 5}
                                />
                                </Form.Group>
                                      <hr/>

                                <Form.Group
                                    name="formConsultingRefer"
                                    as={Row}
                                controlId="formConsultingRefer"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label className='font-weight-bold' column sm={5}>
                                    3. Based on your experience with our performance, would you engage or refer PPR to others for future projects?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                            <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes, I would re-engage or refer PPR"
                                        value="yes"
                                        name="formConsultingRefer"
                                        id="formConsultingRefer-yes"
                                        defaultChecked={
                                        this.props.formConsultingRefer === "yes"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No, I would NOT re-engage or refer PPR"
                                        value="no"
                                        name="formConsultingRefer"
                                        id="formConsultingRefer-no"
                                        defaultChecked={
                                        this.props.formConsultingRefer === "no"
                                        }                               
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Other"
                                        value="other"
                                        name="formConsultingRefer"
                                        id="formConsultingRefer-other"
                                        defaultChecked={
                                        this.props.formConsultingRefer === "other"
                                        }
                                    /></Row></Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formConsultingRefer === "other") && (
                                        <Form.Group controlId="formConsultingReferOtherAnswer">
                                            <Form.Label className='font-weight-bold px-0' column sm={5}>
                                                Please elaborate:
                                            </Form.Label>
                                            <Form.Control
                                                name="formConsultingReferOtherAnswer"
                                                as="textarea"
                                                rows="4"
                                                onChange={this.props.handleChange}
                                                value={
                                                    this.props.formConsultingReferOtherAnswer || ""
                                                }
                                            />
                                        </Form.Group>
                                    )}
                                </div>
                                <p className='font-weight-bold' >If yes, we appreciate your trust and referrals.</p>
                                

                                <Form.Group controlId="formConsultingAreas">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            If not, please describe areas where you would like to see us improve. Your guidance is both appreciated and it is music to our ears. 
                                        </Form.Label>
                                        <Form.Control
                                            name="formConsultingAreas"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formConsultingAreas || ""
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formConsultingMore">
                                        <Form.Label className='font-weight-bold px-0'  column sm={5}>
                                            4. Please list any services that you would like to see us do more.
                                        </Form.Label>
                                        <Form.Control
                                            name="formConsultingMore"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formConsultingMore || ""
                                            }
                                        />
                                </Form.Group>
                                
                                

                                <Form.Group controlId="formConsultingReasons">
                                        <Form.Label className='font-weight-bold px-0'   column sm={5}>
                                            5. In your opinion, what are the three reasons PPR was selected as your expert partner?  Or, if you have participated in this survey in the past, what are three reasons that you continue to select PPR as your expert partner?
                                        </Form.Label>
                                        <Form.Control
                                            name="formConsultingReasons"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formConsultingReasons || ""
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formConsultingQuote">
                                        <Form.Label className='font-weight-bold px-0'   column sm={5}>
                                            6. Could you please provide us with a quote for us to share on our website or with future clients considering our services?
                                        </Form.Label>
                                        <Form.Control
                                            name="formConsultingQuote"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formConsultingQuote || ""
                                            }
                                        />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default ConsultingQuestions;