import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Attendance extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Attendance and Punctuality</h1>
                    <h2>Flex or Professional-Level Employees</h2>
                    <p>It is important to remember that excessive absenteeism and lateness leaves the burden of filling in for the employee to fall on other employees within the organization and can impact our relationship with clients.  An employee who is going to be absent, tardy, or leave early from work or a scheduled meeting is responsible for notifying their supervisor in advance as soon as reasonably possible. Excessive occurrences of unscheduled absence, late arrival, or early departure may be considered excessive and will subject an employee to corrective action.  .</p>

                    <p>Additional Considerations: </p>

                    <p>If the employee is absent for more than two consecutive days, they may be asked to bring a doctor’s note to ensure they are well enough to return to work.  If an employee is absent for illness reasons, they must be fever free for at least 24 hours before returning to work.</p>

                    <p>An employee who has been absent three consecutive days without calling to speak with their supervisor will be considered to have voluntarily resigned. </p>

                    <h2>Employees Required to Work Specific Start/Stop Times (Occurrences Counted)</h2>
                    <p>It is important to remember that excessive absenteeism, tardiness, and/or leaving early causes the burden of filling in for the absent employee to fall on other employees within the organization.  As such, employees should make every effort to arrive to work punctually and work all scheduled work hours as well as any required overtime.  </p>
                    <p>Three or more occurrences of unscheduled absence, late arrival, or early departure in a three-month period may be considered excessive and will subject an employee to corrective action.  An employee is considered late if they reported to work after the scheduled starting time; and, an early departure is one in which the employee leaves before the scheduled end of his or her shift.  If the employee is absent for more than two consecutive days, they may be asked to bring a doctor’s note.  If an employee is absent for illness reasons, they must be fever free for at least 24 hours before returning to work.</p>
                    <p>An employee who is going to be absent, tardy, or leave early from work is responsible for notifying their supervisor at least one hour in advance of their absence. An employee who has been absent three consecutive days without calling to speak with their supervisor will be considered to have voluntarily resigned. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Attendance;
