import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
   
`

class PoliciesLead extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Employee Handbook</h5>
                    <hr />
                    
                    <Form.Group
                      name="formPolicyHandbook6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyHandbook6"
                    >
                      <Form.Label column sm={5}>
                        20.	Do you have a Company Handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-Yes"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-No"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-Unknown"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyHandbook8"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyHandbook8"
                    >
                      <Form.Label column sm={5}>
                        21.	Do you require employees to acknowledge receipt of the company handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyHandbook8"
                        id="formPolicyHandbook8-Yes"
                        defaultChecked={
                          this.props.formPolicyHandbook8 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyHandbook8"
                        id="formPolicyHandbook8-No"
                        defaultChecked={
                          this.props.formPolicyHandbook8 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyHandbook8"
                        id="formPolicyHandbook8-Unknown"
                        defaultChecked={
                          this.props.formPolicyHandbook8 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyHandbook9"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyHandbook9"
                    >
                      <Form.Label column sm={5}>
                        22.	Are employees made aware of how to access a copy of the handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyHandbook9"
                        id="formPolicyHandbook9-Yes"
                        defaultChecked={
                          this.props.formPolicyHandbook9 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyHandbook9"
                        id="formPolicyHandbook9-No"
                        defaultChecked={
                          this.props.formPolicyHandbook9 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyHandbook9"
                        id="formPolicyHandbook9-Unknown"
                        defaultChecked={
                          this.props.formPolicyHandbook9 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook7">
                      <Form.Label>23.	How do you communicate changes to the company handbook?</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook7 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Onboarding Procedures</h5>
                    <hr />

                    <Form.Group
                      name="formPolicyOnboarding9"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding9"
                    >
                      <Form.Label column sm={5}>
                        24.	Do you have a process to onboard a new hire on their first day and/or first 90 days on the job?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOnboarding9"
                        id="formPolicyOnboarding9-Yes"
                        defaultChecked={
                          this.props.formPolicyOnboarding9 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOnboarding9"
                        id="formPolicyOnboarding9-No"
                        defaultChecked={
                          this.props.formPolicyOnboarding9 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOnboarding9"
                        id="formPolicyOnboarding9-Unknown"
                        defaultChecked={
                          this.props.formPolicyOnboarding9 === "unknown"
                        }
                      />
                    </Form.Group>

                     <Form.Group controlId="formPolicyOnboarding10">
                      <Form.Label>24a.	If yes, please describe.</Form.Label>
                      <Form.Control
                        name="formPolicyOnboarding10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOnboarding10 || ""}
                      />
                    </Form.Group>
                           

                    <Form.Group controlId="formPolicyOnboarding8">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyOnboarding8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOnboarding8 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                            //
                            //
                            //
                            // */}

                  <div className="form-section">
                    <h5>Off-boarding Procedures</h5>
                    <hr />
                    

                    <Form.Group
                      name="formPolicyOffboarding2"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding2"
                    >
                      <Form.Label column sm={5}>
                        25. Do you have a standard general release or separation
                        agreements you are currently using?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding2"
                        id="formPolicyOffboarding2-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding2"
                        id="formPolicyOffboarding2-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding2"
                        id="formPolicyOffboarding2-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding3"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding3"
                    >
                      <Form.Label column sm={5}>
                        26. Do you currently provide any type of outplacement
                        services to terminated employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding3"
                        id="formPolicyOffboarding3-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding3"
                        id="formPolicyOffboarding3-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding3"
                        id="formPolicyOffboarding3-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding4"
                    >
                      <Form.Label column sm={5}>
                        27. Do you request a resignation letter from all
                        employees who voluntarily leave the company?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding4"
                        id="formPolicyOffboarding4-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding4 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding4"
                        id="formPolicyOffboarding4-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding4 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding4"
                        id="formPolicyOffboarding4-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding4 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding5"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding5"
                    >
                      <Form.Label column sm={5}>
                        28. Do you pay out accrued, but unused PTO at
                        termination?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding5"
                        id="formPolicyOffboarding5-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding5 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding5"
                        id="formPolicyOffboarding5-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding5 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding5"
                        id="formPolicyOffboarding5-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding5 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding6"
                    >
                      <Form.Label column sm={5}>
                        29. Do you have a termination checklist?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding6"
                        id="formPolicyOffboarding6-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding6 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding6"
                        id="formPolicyOffboarding6-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding6 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding6"
                        id="formPolicyOffboarding6-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding6 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding6a"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding6a"
                    >
                      <Form.Label column sm={5}>
                        29a. Does it include procedures to obtain company property?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding6a"
                        id="formPolicyOffboarding6a-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding6a === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding6a"
                        id="formPolicyOffboarding6a-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding6a === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding6a"
                        id="formPolicyOffboarding6a-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding6a === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOffboarding7"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding7"
                    >
                      <Form.Label column sm={5}>
                        30. Do you conduct exit interviews with terminating
                        employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding7"
                        id="formPolicyOffboarding7-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding7 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding7"
                        id="formPolicyOffboarding7-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding7 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding7"
                        id="formPolicyOffboarding7-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding7 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding7a">
                      <Form.Label>
                        30a. If so, who does this and do you have a procedure to
                        follow-up on the exit interview results?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding7a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding7a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding8">
                      <Form.Label>
                        31. What is your process for off-boarding employees
                        (voluntary and involuntary)?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding8 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding9">
                      <Form.Label>
                        32. What is your current procedure to review liability
                        before terminating an employee?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding9 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding10">
                      <Form.Label>
                        33. How do you handle unpaid commissions for sales
                        employees at termination?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding10 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding11">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding11"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding11 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Leave of Absence(LOA) Procedures</h5>
                    <hr />
                                
                    <Form.Group
                      name="formPolicyOffboarding12"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOffboarding12"
                    >
                      <Form.Label column sm={5}>
                        34.	Do you currently have a LOA policy in place?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyOffboarding12"
                        id="formPolicyOffboarding12-Yes"
                        defaultChecked={
                          this.props.formPolicyOffboarding12 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyOffboarding12"
                        id="formPolicyOffboarding12-No"
                        defaultChecked={
                          this.props.formPolicyOffboarding12 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyOffboarding12"
                        id="formPolicyOffboarding12-Unknown"
                        defaultChecked={
                          this.props.formPolicyOffboarding12 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding12a">
                      <Form.Label>
                        34a. If yes, how do employees request a LOA and how do you respond to the employees when they request a leave, and they qualify or do not qualify for the policy?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding12a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding12a || ""}
                      />
                    </Form.Group>
                    
                    <Form.Group controlId="formPolicyLOA2">
                      <Form.Label>
                        35.	If the company has 50+ employees:  Do you have a FMLA policy in place? {" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyLOA2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyLOA3">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formPolicyLOA3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA3 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Personnel Files</h5>
                    <hr />

                    <Form.Group
                      name="formPolicyPersonnel1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyPersonnel1"
                    >
                      <Form.Label column sm={5}>
                        36. Do you currently keep paper or electronic employee
                        records at the office?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyPersonnel1"
                        id="formPolicyPersonnel1-Yes"
                        defaultChecked={
                          this.props.formPolicyPersonnel1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyPersonnel1"
                        id="formPolicyPersonnel1-No"
                        defaultChecked={
                          this.props.formPolicyPersonnel1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyPersonnel1"
                        id="formPolicyPersonnel1-Unknown"
                        defaultChecked={
                          this.props.formPolicyPersonnel1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyPersonnel1a">
                      <Form.Label>
                        36a. Describe where and how they are stored. Who has
                        access?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyPersonnel1a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyPersonnel1a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyPersonnel2"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyPersonnel2"
                    >
                      <Form.Label column sm={5}>
                        37. Do you keep any medical records of employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyPersonnel2"
                        id="formPolicyPersonnel2-Yes"
                        defaultChecked={
                          this.props.formPolicyPersonnel2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyPersonnel2"
                        id="formPolicyPersonnel2-No"
                        defaultChecked={
                          this.props.formPolicyPersonnel2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyPersonnel2"
                        id="formPolicyPersonnel2-Unknown"
                        defaultChecked={
                          this.props.formPolicyPersonnel2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyPersonnel2a">
                      <Form.Label>
                        37a. Who has authorization / access to review medical
                        records?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyPersonnel2a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyPersonnel2a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyPersonnel3"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyPersonnel3"
                    >
                      <Form.Label column sm={5}>
                        38. Are medical documents kept separate from other
                        employee records?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyPersonnel3"
                        id="formPolicyPersonnel3-Yes"
                        defaultChecked={
                          this.props.formPolicyPersonnel3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyPersonnel3"
                        id="formPolicyPersonnel3-No"
                        defaultChecked={
                          this.props.formPolicyPersonnel3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyPersonnel3"
                        id="formPolicyPersonnel3-Unknown"
                        defaultChecked={
                          this.props.formPolicyPersonnel3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyPersonnel4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyPersonnel4"
                    >
                      <Form.Label column sm={5}>
                        39. Are employee investigation files kept separate?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyPersonnel4"
                        id="formPolicyPersonnel4-Yes"
                        defaultChecked={
                          this.props.formPolicyPersonnel4 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyPersonnel4"
                        id="formPolicyPersonnel4-No"
                        defaultChecked={
                          this.props.formPolicyPersonnel4 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyPersonnel4"
                        id="formPolicyPersonnel4-Unknown"
                        defaultChecked={
                          this.props.formPolicyPersonnel4 === "unknown"
                        }
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formPolicyPersonnel5">
                      <Form.Label>
                        40. What is the process for submitting changes to
                        personnel information?
                      </Form.Label>
                      <Form.Control
                        name="formPolicyPersonnel5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyPersonnel5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyPersonnel6">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyPersonnel6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyPersonnel6 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PoliciesLead;