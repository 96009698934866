import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Ethics extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Ethics</h1>
                    <p>{this.props.clientName} reputation is built as much on the actions of its employees as it is on the importance and value we provide.  Our employees must adhere to high standards that emphasize professionalism, courtesy, and avoidance of even the appearance of illegal or unethical conduct at all times.  Employees are expected to give a full day's work, to carry out efficiently and effectively the work items assigned as their responsibility, and to do their parts in maintaining good relationships with the community, their supervisors, clients, vendors, and other stakeholders.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Ethics;
