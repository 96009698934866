import React, { Component } from 'react';
import {Accordion, Card} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';
import DEI from './Policies/2_CompanyInfo/DEI';
import DrugFree from './Policies/2_CompanyInfo/DrugFree';
import EligForEmploy from './Policies/2_CompanyInfo/EligforEmployees';
import EmergencyClosings from './Policies/2_CompanyInfo/EmergencyClosings';
import EqualEmp from './Policies/2_CompanyInfo/EqualEmp';
import Fraternization from './Policies/2_CompanyInfo/Fraternization';
import InfoNotices from './Policies/2_CompanyInfo/InfoNotices';
import MediaInquiries from './Policies/2_CompanyInfo/MediaInquiries';
import OpenDoor from './Policies/2_CompanyInfo/OpenDoor';
import Privacy from './Policies/2_CompanyInfo/Privacy';
import Tobacco from './Policies/2_CompanyInfo/Tobacco';
import Violence from './Policies/2_CompanyInfo/Violence';
import WorkInc from './Policies/2_CompanyInfo/WorkInc';

const Div = styled.div`
     
    .accordion{
        color: white;
        background: black;
        border: 1px solid black;
        
    }

`

class SectionInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionName: 'Introduction'
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Accordion className="my-5">
                                <Card>
                                    <Accordion.Toggle className="accordion" as={Card.Header} eventKey="0">
                                        2. Company Information
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Diversity, Equity & Inclusion
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><DEI clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Drug-Free Workplace
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><DrugFree clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Eligibility for Employment
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><EligForEmploy clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Emergency Closings
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><EmergencyClosings clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>

                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Equal Employment Opportunity
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><EqualEmp clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Fraternization with Customers & Co-Workers 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Fraternization clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Company Notices
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><InfoNotices clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Media Inquiries
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><MediaInquiries clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Open Door Communication
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><OpenDoor clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Privacy
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Privacy clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Tobacco-Free Workplace
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Tobacco clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Workplace Violence 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Violence clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Workplace Inclusiveness
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><WorkInc clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                </Div>
            </ThemeProvider>
        )
    }
}

export default SectionInfo;