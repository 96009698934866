import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

    
`

class PerformanceSupervisor extends Component {
    


    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Performance Reviews</h5>
                    <hr />
                    <Form.Group
                      name="formPerformanceManagement"
                      as={Row}
                      controlId="formPerformanceManagement"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        22. How does your organization manage/monitor employee
                        performance? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="1:1 Employee/Manager Meetings"
                          name="formPerformanceManagement1on1"
                          id="formPerformanceManagement-1"
                          defaultChecked={
                            this.props.formPerformanceManagement1on1
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Annual Performance Reviews"
                          name="formPerformanceManagementAnnual"
                          id="formPerformanceManagement-2"
                          defaultChecked={
                            this.props.formPerformanceManagementAnnual
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="More Frequent Performance Reviews"
                          name="formPerformanceManagementReviews"
                          id="formPerformanceManagement-3"
                          defaultChecked={
                            this.props.formPerformanceManagementReviews
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Project Completion/Team Evaluations"
                          name="formPerformanceManagementTeam"
                          id="formPerformanceManagement-4"
                          defaultChecked={
                            this.props.formPerformanceManagementTeam
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Individual Goal Setting "
                          name="formPerformanceManagementGoals"
                          id="formPerformanceManagement-5"
                          defaultChecked={
                            this.props.formPerformanceManagementGoals
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="360 Reviews"
                          name="formPerformanceManagement360"
                          id="formPerformanceManagement-6"
                          defaultChecked={
                            this.props.formPerformanceManagement360
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceManagementOther"
                          id="formPerformanceManagement-7"
                          defaultChecked={
                            this.props.formPerformanceManagementOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceManagementOther === 1 ||
                        this.props.formPerformanceManagementOther === true) && (
                        <Form.Group controlId="formPerformanceManagementOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceManagementOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props.formPerformanceManagementOtherAnswer ||
                              ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceReviews1"
                      as={Row}
                      controlId="formPerformanceReviews1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        23. How effective is your performance review process at
                        improving your team's performance?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-1"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-2"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-3"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-4"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-5"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-6"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceReviews2"
                      as={Row}
                      controlId="formPerformanceReviews2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        24. How consistently is the performance review process
                        used?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-1"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-2"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-3"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-4"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-5"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-6"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceReviews4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceReviews4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceReviews4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Goal Setting</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceGoals1"
                      as={Row}
                      controlId="formPerformanceGoals1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        25. Do you develop goals for yourself, your
                        employees or your department?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-1"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-2"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-3"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals2"
                      as={Row}
                      controlId="formPerformanceGoals2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        26. Are goals measured and tracked throughout the year?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-1"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-2"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-3"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceGoals3"
                      as={Row}
                      controlId="formPerformanceGoals3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        27. How effective is your goal setting process at
                        achieving outcomes?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-1"
                        defaultChecked={this.props.formPerformanceGoals3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-2"
                        defaultChecked={this.props.formPerformanceGoals3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-3"
                        defaultChecked={this.props.formPerformanceGoals3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-4"
                        defaultChecked={this.props.formPerformanceGoals3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-5"
                        defaultChecked={this.props.formPerformanceGoals3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-6"
                        defaultChecked={this.props.formPerformanceGoals3 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals4"
                      as={Row}
                      controlId="formPerformanceGoals4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        28. How in line are the organization/department and
                        individual goals?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-1"
                        defaultChecked={this.props.formPerformanceGoals4 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-2"
                        defaultChecked={this.props.formPerformanceGoals4 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-3"
                        defaultChecked={this.props.formPerformanceGoals4 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-4"
                        defaultChecked={this.props.formPerformanceGoals4 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-5"
                        defaultChecked={this.props.formPerformanceGoals4 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-6"
                        defaultChecked={this.props.formPerformanceGoals4 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceGoals5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceGoals5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceGoals5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                </div>
                            

                  <div className="form-section">
                    <h5>Employee Development</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceDevelopment"
                      as={Row}
                      controlId="formPerformanceDevelopment"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        29. What types of learning opportunities do you provide your team? Check  Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Tuition Reimbursement"
                          name="formPerformanceDevelopmentReimbursement"
                          id="formPerformanceDevelopment-1"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentReimbursement
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Tuition Discounts"
                          name="formPerformanceDevelopmentDiscount"
                          id="formPerformanceDevelopment-2"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentDiscount
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Corporate Universities"
                          name="formPerformanceDevelopmentUniversities"
                          id="formPerformanceDevelopment-3"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentUniversities
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="On-the-job Learning"
                          name="formPerformanceDevelopmentLearning"
                          id="formPerformanceDevelopment-4"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentLearning
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Attendance at Seminars and Conferences "
                          name="formPerformanceDevelopmentConference"
                          id="formPerformanceDevelopment-5"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentConference
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Access to Virtual Learning, Podcasts, Webinars"
                          name="formPerformanceDevelopmentVirtual"
                          id="formPerformanceDevelopment-6"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentVirtual
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Executive Coaching"
                          name="formPerformanceDevelopmentCoaching"
                          id="formPerformanceDevelopment-7"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentCoaching
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Mentoring Program"
                          name="formPerformanceDevelopmentMentoring"
                          id="formPerformanceDevelopment-8"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentMentoring
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Leadership Training Program"
                          name="formPerformanceDevelopmentLeadership"
                          id="formPerformanceDevelopment-9"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentLeadership
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Internship Program"
                          name="formPerformanceDevelopmentInternship"
                          id="formPerformanceDevelopment-10"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentInternship
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceDevelopmentOther"
                          id="formPerformanceDevelopment-11"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceDevelopmentOther === 1 ||
                        this.props.formPerformanceDevelopmentOther ===
                          true) && (
                        <Form.Group controlId="formPerformanceDevelopmentOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceDevelopmentOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props
                                .formPerformanceDevelopmentOtherAnswer || ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceDevelopment1"
                      as={Row}
                      controlId="formPerformanceDevelopment1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        30. How satisfied are you with professional growth and development opportunities the organization provides those employees who report to you?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-1"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-2"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-3"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-4"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-5"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-6"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 0
                        }
                      />
                    </Form.Group>

                    
                    <hr />

                    <Form.Group
                      name="formPerformanceDevelopment3"
                      as={Row}
                      controlId="formPerformanceDevelopment3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        31. Does your manager provide you with regular, ongoing
                        feedback?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-1"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-2"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-3"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceDevelopment4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceDevelopment4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceDevelopment4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Corrective Action Process (Managers / Leaders Only)</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceCorrective"
                      as={Row}
                      controlId="formPerformanceCorrective"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        32. What types of corrective action measures does the
                        organization utilize to correct and improve employee
                        performance? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Documented Verbal Warning"
                          name="formPerformanceCorrectiveVerbal"
                          id="formPerformanceCorrective-1"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveVerbal
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Written Reprimand"
                          name="formPerformanceCorrectiveWritten"
                          id="formPerformanceCorrective-2"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveWritten
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Temporary Pay Cut"
                          name="formPerformanceCorrectiveCut"
                          id="formPerformanceCorrective-3"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveCut
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Performance Improvement Plan"
                          name="formPerformanceCorrectivePlan"
                          id="formPerformanceCorrective-4"
                          defaultChecked={
                            this.props.formPerformanceCorrectivePlan
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Career Decision Day"
                          name="formPerformanceCorrectiveDecision"
                          id="formPerformanceCorrective-5"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveDecision
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Suspension"
                          name="formPerformanceCorrectiveSuspension"
                          id="formPerformanceCorrective-6"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveSuspension
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Demotion"
                          name="formPerformanceCorrectiveDemotion"
                          id="formPerformanceCorrective-7"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveDemotion
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Termination"
                          name="formPerformanceCorrectiveTermination"
                          id="formPerformanceCorrective-8"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveTermination
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceCorrectiveOther"
                          id="formPerformanceCorrective-9"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceCorrectiveOther === 1 ||
                        this.props.formPerformanceCorrectiveOther === true) && (
                        <Form.Group controlId="formPerformanceCorrectiveOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceCorrectiveOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props.formPerformanceCorrectiveOtherAnswer ||
                              ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <Form.Group controlId="formPerformanceCorrective4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceCorrective4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceCorrective4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Leadership Development</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceLeadershipDevelopment1"
                      as={Row}
                      controlId="formPerformanceLeadershipDevelopment1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        33.	How confident are you that your organization adequately equips leaders to the next level of success?   
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceLeadershipDevelopment1"
                        id="formPerformanceLeadershipDevelopment1-1"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceLeadership11"
                        id="formPerformanceLeadershipDevelopment1-2"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceLeadershipDevelopment1"
                        id="formPerformanceLeadershipDevelopment1-3"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceLeadershipDevelopment1"
                        id="formPerformanceLeadershipDevelopment1-4"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceLeadershipDevelopment1"
                        id="formPerformanceLeadershipDevelopment1-5"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceLeadershipDevelopment1"
                        id="formPerformanceLeadershipDevelopment1-6"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceLeadershipDevelopment5">
                      <Form.Label>34.	What types of leadership development opportunities would you like to see implemented? </Form.Label>
                      <Form.Control
                        name="formPerformanceLeadershipDevelopment5"
                        as="textarea"
                        rows="4"
                        value={
                          this.props.formPerformanceLeadershipDevelopment5 || ""
                        }
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceLeadershipDevelopment4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceLeadershipDevelopment4"
                        as="textarea"
                        rows="4"
                        value={
                          this.props.formPerformanceLeadershipDevelopment4 || ""
                        }
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Job Descriptions</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceJobDescriptions1"
                      as={Row}
                      controlId="formPerformanceJobDescriptions1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        35. How confident are you that job descriptions for
                        employees are current and up to date?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-1"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-2"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-3"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-4"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-5"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-6"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 0
                        }
                      />
                    </Form.Group>

                    
                    <Form.Group controlId="formPerformanceJobDescriptions3">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceJobDescriptions3"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceJobDescriptions3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PerformanceSupervisor;