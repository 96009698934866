import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Handbook extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Employee Handbook</h4>
                                <hr />

                                <Form.Group
                                className="ml-3"
                                name="formHandbookExist"
                                as={Row}
                                controlId="formHandbookExist"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        22. Does your organization have a handbook?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formHandbookExist"
                                        id="formHandbookExist-Yes"
                                        defaultChecked={
                                        this.props.formHandbookExist === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formHandbookExist"
                                        id="formHandbookExist-No"
                                        defaultChecked={
                                        this.props.formHandbookExist === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formHandbookExist"
                                        id="formHandbookExist-NA"
                                        defaultChecked={
                                        this.props.formHandbookExist === "unknown"
                                        }
                                    />
                                </Form.Group>

                                
                                <Form.Group
                                className="ml-3"
                                name="formHandbookWhere"
                                as={Row}
                                controlId="formHandbookWhere"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        23. Do you know where it is?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formHandbookWhere"
                                        id="formHandbookWhere-Yes"
                                        defaultChecked={
                                        this.props.formHandbookWhere === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formHandbookWhere"
                                        id="formHandbookWhere-No"
                                        defaultChecked={
                                        this.props.formHandbookWhere === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formHandbookWhere"
                                        id="formHandbookWhere-NA"
                                        defaultChecked={
                                        this.props.formHandbookWhere === "unknown"
                                        }
                                    />
                                </Form.Group>


                                <Form.Group
                                className="ml-3"
                                name="formHandbookAccess"
                                as={Row}
                                controlId="formHandbookAccess"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        24. Do you know how to access it?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formHandbookAccess"
                                        id="formHandbookAccess-Yes"
                                        defaultChecked={
                                        this.props.formHandbookAccess === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formHandbookAccess"
                                        id="formHandbookAccess-No"
                                        defaultChecked={
                                        this.props.formHandbookAccess === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formHandbookAccess"
                                        id="formHandbookAccess-NA"
                                        defaultChecked={
                                        this.props.formHandbookAccess === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Handbook;