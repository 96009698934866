import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Email extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Expectations for Office Communications</h1>
                    <p>The email use policy provides employees with effective, consistent standards for the use of the electronic mail system (e-mail) provided by {this.props.clientName}.  </p>

                    <p>All use of the electronic mail system is for company business and for authorized purposes only. Brief and occasional personal use of the electronic mail system is acceptable as long as it is not excessive or inappropriate, occurs during personal time (lunch or other breaks), and does not result in expense or harm to {this.props.clientName} or otherwise violate company policies and procedures.  </p>

                    <p>Use is defined as "excessive" if it interferes with normal job functions, responsiveness, or the ability to perform daily job activities. Electronic communication should not be used to solicit or sell products or services that are unrelated to {this.props.clientName}’s business; distract, intimidate, or harass co-workers or third parties; or disrupt the workplace.  </p>

                    <h2>Sending Emails</h2>
                    <p>
                        <ul>
                            <li>If you cannot respond to an email promptly or by the end of the day, email back confirming your receipt and when the sender can expect your response.</li>
                            <li>Always use a new email when starting a new topic and be sure the subject field accurately reflects the contents of the email.</li>
                            <li>Address the recipient with the appropriate level of formality and make sure you spelled their name correctly.</li>
                            <li>Always open the email with an expression of thanks, such as “Thank you for your recent correspondence regarding…”</li>
                            <li>The tone of the email should correspond to the nature of the email.  If a donor wrote an email expressing satisfaction with an event, a friendly, enthusiastic response is required.  If the communication is in a negative tone, the response should be polite, professional, and should invite further communication to address the concerns.</li>
                            <li>Emails should be brief and to the point but should include all relevant details or information necessary to understand your request or point of view.  Long emails should be saved for face-to-face or phone conversations.</li>
                            <li>Emails should be grammatically correct using complete and proper sentence structure, grammar, spelling, and appropriate punctuation.  </li>
                            <li>If your email is emotionally charged, walk away from the computer and wait to reply. Review the Sender's email again so that you are sure you are not reading anything into the email that simply isn't there.  As necessary, respond to the sender via phone or in person to avoid any miscommunication.  </li>
                            <li>Only use the “reply all” feature when the response is necessary for all to see.  In most cases, replying to the sender alone is your best course of action.  </li>
                            <li>Always end your emails with a closing such as, "Thank you," "Sincerely," "Take it easy," "Best regards" or something similar.</li>
                        </ul>
                    </p>

                    <h2>Sending Secure Email</h2>
                    <p>Employees should always be aware that email can be read by anyone and can never be deleted.  As such, employees must take every precaution to protect confidential information and the personal information of our clients, employees and other constituents.  To prevent email-based data security breaches, employees must adhere to the following:
                        <ul>
                            <li>Never include bank account numbers or credit card numbers, or copies of voided or canceled checks, in the email body, or send unencrypted attachments that include this information.</li>
                            <li>Never include a social security number in the email body, or send unencrypted attachments that include social security numbers.</li>
                            <li>Never send a non-temporary password in an email, even if the User ID is not included.</li>
                            <li>Only send a User ID via email, as long as there is not an associated password (even a temporary one) included in the email.</li>
                            <li>Never send confidential company information via unencrypted email, whether it is in the email body or in the attachment.</li>
                        </ul>
                    </p>
                    <p>Employees required to email PII (Personally Identifiable Information), such as a social security number), or confidential documents such as those containing your business plans, financial data, or intellectual property, must utilize the Secure email process in order to encrypt the email message and any attachments.  </p>

                    <h2>Formatting Emails</h2>
                    <p>
                        <ul>
                            <li>Do not type in all caps as it may be perceived as yelling or reflecting a shouting emphasis.</li>
                            <li>Do not use patterned backgrounds. It makes your email harder to read.</li>
                            <li>Stay away from fancy fonts.  Only use the standard fonts are on all computers.</li>
                            <li>Use emoticons sparingly to ensure your tone and intent are clear.</li>
                            <li>Refrain from using multiple font colors in one email. It makes your email harder to view and can add to your intent being misinterpreted.</li>
                            <li>Use formatting sparingly. Instead, try to rely on choosing the most accurate words possible to reflect your tone and avoid misunderstandings in the process.</li>
                            <li>Refrain from using religious-themed and other non-business-related quotes or wording in Signature line.  </li>
                        </ul>
                    </p>

                    <h2>Email Attachments</h2>
                    <p>
                        <ul>
                            <li>Never open an attachment from someone you don't know.</li>
                            <li>Make sure the other side has the same software as you, before sending attachments, or they may not be able to open your attachment.  Use PDF when possible.</li>
                        </ul>
                    </p>

                    <h2>Using To, From, CC, Bcc, Subject</h2>
                    <p>
                        <ul>
                            <li>Only use “cc” when it is important for those you “cc” to know about the contents of the email.  </li>
                            <li>Include addresses in the “to” field for those who you would like a response from.</li>
                            <li>Include addresses in the “cc” field for those who you are just FYI'ing.</li>
                            <li>Make sure your name is displayed properly in the “from” field.</li>
                            <li>Remove addresses from the “to;” “cc;” and “bcc” field that do not need to see your reply.</li>
                            <li>Always include a brief subject reflective of the information contained in the email.  Failing to include a subject line can get your email flagged as spam.</li>
                            <li>Feel free to modify the Subject: field to more accurately reflect a conversation's direction.</li>
                            <li>When adding addresses to “to”, “cc”, and “bcc” use your discretion.  Never include anyone on an email for the purposes of spreading gossip. </li>
                        </ul>
                    </p>

                    <h2>Email Forwarding</h2>
                    <p>
                        <ul>
                            <li>Don’t forward chain emails or emails reflective of personal views. </li>
                            <li>When forwarding email make sure to include a personal comment before forwarding.   </li>
                            <li>Don't forward anything without editing out all the forwarding >>>>, other email addresses, headers and commentary from all the other forwarders.</li>
                            <li>If you must forward to more than one person, put your email address in the TO: field and all the others you are sending to in the Bcc: field to protect their email address from being published to those they do not know. This is a serious privacy issue.</li>
                        </ul>
                    </p>

                    <h2>Checking and Responding to Email Outside of Office Hours</h2>
                    <p>
                        <ul>
                            <li>In most cases, employees are not expected to check or respond to emails outside of office hours, Monday-Friday, 8:00 a.m. to 5:00 p.m.  In the rare event communication outside of regular office hours cannot wait until the next business day, utilize the phone and communicate via text or call. </li>
                            <li>When traveling for business, check and respond to email during regular business hours at least once a day.  If you are unable to check and respond at least once during regular business hours each day, utilize the Out-of-Office assistant autoreply providing both the phone and email of those individuals who are available to respond to any same-day requests.  </li>
                            <li>In most cases, employees are not expected to check or respond to emails while on vacation or other non-business-related time off.  In the rare event communication to an employee who is utilizing personal time off cannot wait until the employee returns, utilize the phone and communicate via text or call. </li>
                        </ul>
                    </p>

                    <h2>Electronic Mail Tampering</h2>
                    <p>Electronic mail messages received should not be altered without the sender's permission; nor should electronic mail be altered and forwarded to another user and/or unauthorized attachments be placed on another's electronic mail message.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Email;
