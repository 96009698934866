import React, { Component } from 'react';
import { Container, Form } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class ThankYou extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-5 mt-5">
                            <div className="form-section">
                                <h5>Thank you for taking our client satisfaction survey. We are grateful for your feedback and guidance. Count with our commitment to learn from the information you have shared with us and proactively improve our services to better support you. </h5>

                                <p className="mt-5 pt-5">Please do not hit back or reload this page. You may simply close this browser window</p>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
            
        
    }
}

export default ThankYou;