import React, { Component } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styled, { ThemeProvider } from "styled-components";
import theme from "../../Config/Theme";

const Buttons = styled(ToggleButtonGroup)`
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    
    .toggle-btns {
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.medium_blue};
        border: 1px solid ${props => props.theme.colors.medium_blue};
        &:hover{
            color: ${props => props.theme.colors.orange};
            background: ${props => props.theme.colors.dark_blue};
            border: 1px solid ${props => props.theme.colors.dark_blue};
        }
    }
    .toggle-btns.active{
        color: ${props => props.theme.colors.dark_blue} !important;
        background: ${props => props.theme.colors.light_blue} !important;
        border: 1px solid ${props => props.theme.colors.light_blue}!important;
    }
`

class HCA_NavBar extends Component {

    render() {
        // const empLevel = this.props.employment_level;
        return (
            <ThemeProvider theme={theme}>
                <Buttons type="radio" name="options" defaultValue={1}>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_personnel")} value={1}>
                        Personnel Files
                    </ToggleButton>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_onoff")}
                        value={2}>
                        On/Off Boarding
                    </ToggleButton>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_risk")}
                        value={3}>
                        Risk
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_benefits")}
                        value={4}>
                        Benefits
                    </ToggleButton>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_performance")}
                        value={5}>
                        Performance Management
                    </ToggleButton>


                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_payroll")}
                        value={6}>
                        Payroll
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_hris")}
                        value={7}>
                        HRIS
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_talent")}
                        value={8}>
                        Talent Attraction
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("hca_compliance_hr")}
                        value={9}>
                        HR
                    </ToggleButton>

                    


                </Buttons>

            
        </ThemeProvider>
          
        )
    }
}

export default HCA_NavBar;