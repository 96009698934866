import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Violence extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Workplace Violence</h1>
                    <p>{this.props.clientName} provides a safe workplace for all employees. To ensure a safe workplace and to reduce the risk of violence, all employees should review and understand all provisions of this workplace violence policy.</p>
                    <h2>Prohibited Conduct</h2>
                    <p>{this.props.clientName} does not tolerate any type of workplace violence committed by or against employees. Employees are prohibited from making threats or engaging in violent activities. This list of behaviors provides examples of conduct that is prohibited:
                    
                        <ul>
                            <li>Causing physical injury to another person.</li>
                            <li>Making threatening remarks.</li>
                            <li>Displaying aggressive or hostile behavior that creates a reasonable fear of injury to another person or subjects another individual to emotional distress.</li>
                            <li>Intentionally damaging employer property or property of another employee.</li>
                            <li>Possessing a weapon while on company property or while on company business.</li>
                            <li>Committing acts motivated by, or related to, sexual harassment or domestic violence.</li>
                        </ul>
                    </p>
                    <h2>Reporting Procedures</h2>
                    <p>Any potentially dangerous situations must be immediately reported to a supervisor or the human resource (HR) department. Reports can be made anonymously, and all reported incidents will be investigated. Reports or incidents warranting confidentiality will be handled appropriately, and information will be disclosed to others only on a need-to-know basis. All parties involved in a situation will be counseled, and the results of investigations will be discussed with them. {this.props.clientName} will actively intervene at any indication of a possibly hostile or violent situation.</p>

                    <h2>Risk Reduction Measures</h2>
                    <h3><i>Hiring</i></h3>
                    <p>The HR department takes reasonable measures to conduct background investigations to review candidates’ backgrounds and to reduce the risk of hiring individuals with a history of violent behavior.</p>

                    <h3><i>Safety</i></h3>
                    <p>{this.props.clientName} conducts annual inspections of the premises to evaluate and determine any vulnerabilities to workplace violence or hazards. Any necessary corrective action will be taken to reduce all risks.</p>

                    <h3><i>Individual Situations</i></h3>
                    <p>Although {this.props.clientName} does not expect employees to be skilled at identifying potentially dangerous persons, employees are expected to exercise good judgment and to inform the HR department if any employee exhibits behavior that could be a sign of a potentially dangerous situation. Such behavior includes:
                        <ul>
                            <li>Discussing weapons or bringing them to the workplace.</li>
                            <li>Displaying overt signs of extreme stress, resentment, hostility or anger.</li>
                            <li>Making threatening remarks.</li>
                            <li>Showing sudden or significant deterioration of performance.</li>
                            <li>Displaying irrational or inappropriate behavior.</li>
                        </ul>
                    </p>

                    <h2>Employees at Risk</h2>
                    <p>The HR department will identify and maintain a list of employees who have been determined to be at risk for becoming victims of violence because of the nature of their job or because they are subject to harassment, violence or threats from a non-employee. HR and security will design a plan with at-risk employees to prepare for any possible emergency situations.</p>

                    <h2>Dangerous/Emergency Situations</h2>
                    <p>Employees who confront or encounter an armed or dangerous person should not attempt to challenge or disarm the individual. Employees should remain calm, make constant eye contact and talk to the individual. If a supervisor can be safely notified of the need for assistance without endangering the safety of the employee or others, such notice should be given. Otherwise, employees should cooperate and follow the instructions given.</p>

                    <h2>Enforcement</h2>
                    <p>Threats, threatening conduct, or any other acts of aggression or violence in the workplace will not be tolerated. Any employee determined to have committed such acts will be subject to disciplinary action, up to and including termination. Non-employees engaged in violent acts on the employer’s premises will be reported to the proper authorities and fully prosecuted. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Violence;
