import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class UnpaidLOA extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Unpaid Leave of Absence</h1>
                    <h2>Eligibility</h2>
                    <p>In rare instances, {this.props.clientName} may consider granting an unpaid leave of absence. To be eligible, an employee must be employed full-time with a minimum of 12 months of continuous service with {this.props.clientName}. Length of leave of absence, job performance, absenteeism and departmental requirements all will be taken into consideration before a request is approved. </p>

                    <h2>Request for Unpaid Leave of Absence</h2>
                    <p>An Employee Leave of Absence Request form should be completed at least 30 days prior to the anticipated beginning of an unpaid leave of absence, or as soon as possible in the case of an emergency. An employee should submit a request to their supervisor. The supervisor will submit the request to the appropriate member of management or Human Resources for final approval. The form contains specific instructions for its completion. Requests for unpaid personal leave may be denied or granted by the company for any reason or no reason at the sole discretion of the {this.props.clientName}.</p>

                    <h2>Approval of Unpaid Leave of Absence</h2>
                    <p>Unpaid leaves of absence may be granted for a specified period of time. A supervisor may authorize an individual to take, in any calendar year, unpaid leave of a duration not to exceed 90 days. Requests for leaves of more than 90 days must be approved by the President & CEO and Human Resources. Unpaid leaves of absence may not exceed one year but may be renewed in special circumstances with the approval of the President & CEO and Human Resources.</p>

                    <p>If a leave of absence is granted, {this.props.clientName} cannot guarantee reinstatement to the same job or at the same salary as the employee held at the time of leave. Every effort, however, will be made to give the returning employee first consideration for any job opening for which the employee is qualified and to place the employee in a job and salary as nearly comparable to the one previously held.</p>

                    <p>Requests for unpaid personal leave may be denied or granted by {this.props.clientName} for any reason or no reason at the sole discretion of the {this.props.clientName}. {this.props.clientName} reserves the right to terminate employment for any reason or no reason during the leave of absence.</p>

                    <h2>Continuation of Benefits During Unpaid Leave of Absence</h2>
                    <p>Employees on an unpaid leave of absence may maintain benefits coverage by making arrangements with Human Resources to continue their contributions.</p>

                    <h2>PTO While on Leave Without Pay</h2>
                    <p>Employees on an approved leave of absence will be required to utilize all accrued PTO upon commencement of the leave. Once all PTO is used, the leave of absence will transition to unpaid. </p>
                    <p>An employee on an unpaid leave of absence will continue to accrue PTO at the normal rate for that year. Authorized unpaid leaves of absence will count as continuous service in determining length of service at the {this.props.clientName} for vacation purposes.</p>

                    <h2>Return to Work/Extension of Leave</h2>
                    <p>An employee is required to return from the unpaid leave of absence on the originally scheduled return date. If the employee is unable to return, he or she must request an extension of the leave in writing. If {this.props.clientName} declines to extend the leave, the employee must then return to work on the originally scheduled return date or be considered to have voluntarily resigned from his or her employment. {this.props.clientName} reserves the right to fill the employee’s position while the employee is on a leave of absence and may terminate the employee’s employment with {this.props.clientName} for any reason or no reason during the leave of absence. </p>

                    <h2>Unpaid Leave of Absence for Medical Reasons</h2>
                    <p>The terms of the Unpaid Leave of Absence Policy apply to employees who are not eligible for leave under the Family and Medical Leave Act (FMLA) but may need leave for the employee’s own personal illness or injury. The employee must provide a statement from the employee’s physician on a Certification of Health Care Provider Form obtained through Human Resources.</p>
                    <p>A leave of absence for the employee’s own serious health condition will be approved for only that period which the employee’s physician certifies in writing that the employee is restricted from work, or a maximum of 90 calendar days, whichever is less.</p>
                    <p>Pay will be issued during a leave of absence through the {this.props.clientName} Short Term Disability plan if the employee is eligible and approved and after the elimination period has been satisfied. The employee may use PTO during their Short-Term Disability elimination period. </p>
                    <p>Upon return from a leave granted for any health-related reason, the employee must show medical evidence of being physically able to perform the job offered.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default UnpaidLOA;
