import React, { Component } from 'react';
import {Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

`

class CultureEmployee extends Component {
     


    render() {



        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Company Culture</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    

                    <Form.Group
                      as={Row}
                      name="formCulture2"
                      controlId="formCulture2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        1. How well does the culture here reflect the mission,
                        vision, and values of the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture2"
                        id="formCulture2-1"
                        defaultChecked={this.props.formCulture2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture2"
                        id="formCulture2-2"
                        defaultChecked={this.props.formCulture2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture2"
                        id="formCulture2-3"
                        defaultChecked={this.props.formCulture2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture2"
                        id="formCulture2-4"
                        defaultChecked={this.props.formCulture2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture2"
                        id="formCulture2-5"
                        defaultChecked={this.props.formCulture2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture2"
                        id="formCulture2-6"
                        defaultChecked={this.props.formCulture2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCulture4"
                      as={Row}
                      controlId="formCulture4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        2. If nothing about your current culture changes, how
                        likely would it be that you would still be working here
                        in five years?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture4"
                        id="formCulture4-1"
                        defaultChecked={this.props.formCulture4 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture4"
                        id="formCulture4-2"
                        defaultChecked={this.props.formCulture4 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture4"
                        id="formCulture4-3"
                        defaultChecked={this.props.formCulture4 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture4"
                        id="formCulture4-4"
                        defaultChecked={this.props.formCulture4 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture4"
                        id="formCulture4-5"
                        defaultChecked={this.props.formCulture4 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture4"
                        id="formCulture4-6"
                        defaultChecked={this.props.formCulture4 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCulture5">
                      <Form.Label>
                        3. Give me three words that describe your company
                        culture.
                      </Form.Label>
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formCulture5a">
                        <Form.Control
                          name="formCulture5a"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5a || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture5b">
                        <Form.Control
                          name="formCulture5b"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5b || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture5c">
                        <Form.Control
                          name="formCulture5c"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5c || ""}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formCulture6">
                      <Form.Label>
                        4. Give me three words that describe what it means to be
                        a successful employee in this organization.
                      </Form.Label>
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formCulture6a">
                        <Form.Control
                          name="formCulture6a"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6a || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture6b">
                        <Form.Control
                          name="formCulture6b"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6b || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture6c">
                        <Form.Control
                          name="formCulture6c"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6c || ""}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formCulture7">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCulture7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCulture7 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Employee Engagement</h5>
                    <hr />
                    
                    <Form.Group
                      name="formCultureEngagement3"
                      as={Row}
                      controlId="formCultureEngagement3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        5. How likely are significant changes to be made as a
                        result of an engagement survey, if applicable?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-1"
                        defaultChecked={this.props.formCultureEngagement3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-2"
                        defaultChecked={this.props.formCultureEngagement3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-3"
                        defaultChecked={this.props.formCultureEngagement3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-4"
                        defaultChecked={this.props.formCultureEngagement3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-5"
                        defaultChecked={this.props.formCultureEngagement3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-6"
                        defaultChecked={this.props.formCultureEngagement3 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureEngagement4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureEngagement4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureEngagement4 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Diversity, Equity, and Inclusion(DEI)</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                   
                    <Form.Group
                      name="formCultureDEI3"
                      as={Row}
                      controlId="formCultureDEI3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        6. How satisfied are you with the diversity, equity, and
                        inclusion of the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureDEI3"
                        id="formCultureDEI3-1"
                        defaultChecked={this.props.formCultureDEI3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureDEI3"
                        id="formCultureDEI3-2"
                        defaultChecked={this.props.formCultureDEI3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureDEI3"
                        id="formCultureDEI3-3"
                        defaultChecked={this.props.formCultureDEI3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureDEI3"
                        id="formCultureDEI3-4"
                        defaultChecked={this.props.formCultureDEI3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureDEI3"
                        id="formCultureDEI3-5"
                        defaultChecked={this.props.formCultureDEI3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureDEI3"
                        id="formCultureDEI3-6"
                        defaultChecked={this.props.formCultureDEI3 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureDEI4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureDEI4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureDEI4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Rewards and Recognition</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureRewards2"
                      as={Row}
                      controlId="formCultureRewards2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        7. How effective are the rewards and recognition
                        activities offered by the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureRewards2"
                        id="formCultureRewards2-1"
                        defaultChecked={this.props.formCultureRewards2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureRewards2"
                        id="formCultureRewards2-2"
                        defaultChecked={this.props.formCultureRewards2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureRewards2"
                        id="formCultureRewards2-3"
                        defaultChecked={this.props.formCultureRewards2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureRewards2"
                        id="formCultureRewards2-4"
                        defaultChecked={this.props.formCultureRewards2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureRewards2"
                        id="formCultureRewards2-5"
                        defaultChecked={this.props.formCultureRewards2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureRewards2"
                        id="formCultureRewards2-6"
                        defaultChecked={this.props.formCultureRewards2 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCultureRewards3">
                      <Form.Label>
                        8. What types of reward and recognition activities would
                        you like to see implemented in the organization?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formCultureRewards3"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureRewards3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureRewards4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureRewards4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureRewards4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Employee Communication</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureCommunication1"
                      as={Row}
                      controlId="formCultureCommunication1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        9. How satisfied are you with the communication from the
                        organization to employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-1"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-2"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-3"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-4"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-5"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-6"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureCommunication2"
                      as={Row}
                      controlId="formCultureCommunication2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        10. How satisfied are you with the frequency of company
                        to employee communication?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-1"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-2"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-3"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-4"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-5"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-6"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureCommunication3"
                      as={Row}
                      controlId="formCultureCommunication3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        11. How satisfied are you with your ability to
                        communicate ideas, share comments, or talk about
                        concerns with your immediate supervisors?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-1"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-2"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-3"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-4"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-5"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-6"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureCommunication4"
                      as={Row}
                      controlId="formCultureCommunication4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        12. How satisfied are you with your ability to
                        communicate ideas, share comments, or talk about
                        concerns with the leadership team?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-1"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-2"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-3"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-4"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-5"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication4"
                        id="formCultureCommunication4-6"
                        defaultChecked={
                          this.props.formCultureCommunication4 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureCommunication5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureCommunication5"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureCommunication5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Conflict Resolution</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureConflict1"
                      as={Row}
                      controlId="formCultureConflict1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        13. How effective is the organization's conflict
                        resolution process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureConflict1"
                        id="formCultureConflict1-1"
                        defaultChecked={this.props.formCultureConflict1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureConflict1"
                        id="formCultureConflict1-2"
                        defaultChecked={this.props.formCultureConflict1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureConflict1"
                        id="formCultureConflict1-3"
                        defaultChecked={this.props.formCultureConflict1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureConflict1"
                        id="formCultureConflict1-4"
                        defaultChecked={this.props.formCultureConflict1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureConflict1"
                        id="formCultureConflict1-5"
                        defaultChecked={this.props.formCultureConflict1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureConflict1"
                        id="formCultureConflict1-6"
                        defaultChecked={this.props.formCultureConflict1 === 0}
                      />
                    </Form.Group>

                    
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default CultureEmployee;