import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Conflict extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Conflict Resolution</h4>
                                <hr />
                                
                                <Form.Group
                                    name="formConflict"
                                    as={Row}
                                    controlId="formConflict"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        12. How are conflicts in the organization generally resolved. (Check all that apply)
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Peer-to-Peer"
                                        name="formConflictPeer"
                                        id="formConflictPeer"
                                        defaultChecked={this.props.formConflictPeer}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Involving Manager"
                                        name="formConflictManager"
                                        id="formConflictManager"
                                        defaultChecked={
                                            this.props.formConflictManager
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Chain of Command"
                                        name="formConflictCommand"
                                        id="formConflictCommand"
                                        defaultChecked={
                                            this.props.formConflictCommand
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Human Resources"
                                        name="formConflictHR"
                                        id="formConflictHR"
                                        defaultChecked={this.props.formConflictHR}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Formal Grievance Process"
                                        name="formConflictGrievance"
                                        id="formConflictGrievance"
                                        defaultChecked={
                                            this.props.formConflictGrievance
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formConflictOther"
                                        id="formConflictOther"
                                        defaultChecked={
                                            this.props.formConflictOther
                                        }
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formConflictOther === true ||
                                        this.props.formConflictOther === 1) && (
                                        <Form.Group controlId="formConflictOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formConflictOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formConflictOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>
                                <Form.Group
                                as={Row}
                                name="formConflictEffective"
                                controlId="formConflictEffective"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    13. The organization's current conflict resolution process is effective?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formConflictEffective"
                                    id="formConflictEffective-1"
                                    defaultChecked={this.props.formConflictEffective === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formConflictEffective"
                                    id="formConflictEffective-2"
                                    defaultChecked={this.props.formConflictEffective === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formConflictEffective"
                                    id="formConflictEffective-3"
                                    defaultChecked={this.props.formConflictEffective === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formConflictEffective"
                                    id="formConflictEffective-4"
                                    defaultChecked={this.props.formConflictEffective === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formConflictEffective"
                                    id="formConflictEffective-5"
                                    defaultChecked={this.props.formConflictEffective === 5}
                                />
                                </Form.Group>
                            </div>

                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Conflict;