import React, { Component } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';
import debounce from 'debounce';
import Header from './Header';
import IntroQuestions from './IntroQuestions';
import Questions from './Questions';
import Team from './Team';
import ThankYou from './ThankYou';

const API_URL = config.url.API_URL;

const Div = styled.div`
  background: ${(props) => props.theme.colors.white} !important;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    margin-left: 31px;
    margin-right: 31px;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }
`;


class CandidateSatisfactionSurvey extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHideModal: true,
            configDone: false,
            displayQuestions: false,
            displaySubmit: false,
            displayTeam: false,
            displayThanks: false,
            displayKathyB: false,
            displayLaurenL: false,
            displayDeniseMF: false,
            displayGabrielaN: false,

            name: "",
            kathyB: false,
            laurenL: false,
            deniseMF: false,
            gabrielaN: false,

            formProfessional: "",
            formPolite: "",
            formKnowledgeable: "",
            formJobRequirements: "",
            formQuestions: "",
            formClear: "",
            formProcess: "",

            formPrepared: "",
            formResponsibilities: "",
            formHighlight: "",
            formUnderstand: "",

            formTime: "",
            formDiverse: "",

            formBetterPerformance: "",
            formBiased: "",
            formAuthentic: "",
            formOutcome: "",
            formOverallRate: "",
            formTopThree: "",
            formAnything: "",
            formQuote: "",

            //Team
            formDeniseMF_Rating: "",
            formDeniseMF_Comments: "",
            formKathyB_Rating: "",
            formKathyB_Comments: "",
            formLaurenL_Rating: "",
            formLaurenL_Comments: "",
            formGabrielaN_Rating: "",
            formGabrielaN_Comments: ""
        }

        this.handleModalHide = this.handleModalHide.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleChangeAsInt = this.handleChangeAsInt.bind(this);
    }

    handleModalHide() {
        this.setState({ showHideModal: false });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheck(e) {
        this.setState(({ isChecked }) => ({
        isChecked: !isChecked,
        }));
        this.setState({ [e.target.name]: e.target.checked })
    }

    handleChangeAsInt = (e) => {
        const targetInt = parseInt(e.target.value, 10);
        this.setState({ [e.target.name]: targetInt });
    }


    surveyConfig() {
        if (this.state.name !== "") {
            this.setState({ configDone: true, displaySubmit: true, displayQuestions: true, displayTeam: true });
            this.handleModalHide();
        } else {
            console.log(`fill in required fields`);
        }
    }

    submitSurvey = debounce(() => {
        const data = {
            year: new Date().getFullYear(),
            name: this.state.name,

            formProfessional: this.state.formProfessional,
            formPolite: this.state.formPolite,
            formKnowledgeable: this.state.formKnowledgeable,
            formJobRequirements: this.state.formJobRequirements,
            formQuestions: this.state.formQuestions,
            formClear: this.state.formClear,
            formProcess: this.state.formProcess,

            formPrepared: this.state.formPrepared,
            formResponsibilities: this.state.formResponsibilities,
            formHighlight: this.state.formHighlight,
            formUnderstand: this.state.formUnderstand,

            formTime: this.state.formTime,
            formDiverse: this.state.formDiverse,

            formBetterPerformance: this.state.formBetterPerformance,
            formBiased: this.state.formBiased,
            formAuthentic: this.state.formAuthentic,
            formOutcome: this.state.formOutcome,
            formOverallRate: this.state.formOverallRate,
            formTopThree: this.state.formTopThree,
            formAnything: this.state.formAnything,
            formQuote: this.state.formQuote,

            //Team
            formDeniseMF_Rating: this.state.formDeniseMF_Rating,
            formDeniseMF_Comments: this.state.formDeniseMF_Comments,
            formKathyB_Rating: this.state.formKathyB_Rating,
            formKathyB_Comments: this.state.formKathyB_Comments,
            formLaurenL_Rating: this.state.formLaurenL_Rating,
            formLaurenL_Comments: this.state.formLaurenL_Comments,
            formGabrielaN_Rating: this.state.formGabrielaN_Rating,
            formGabrielaN_Comments: this.state.formGabrielaN_Comments
        }
        this.writeSurvey(data);
    }, 1000);


    writeSurvey(data) {
        fetch(API_URL + `/candss`, {
            method: "PUT",
            body: JSON.stringify({
                data: data,
            }),
            headers: {"Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .then(this.setState({
                displayThanks: true,
                displaySubmit: false,
                displayQuestions: false,
                displayTeam: false
            }))
        .catch((err) => console.log(err))
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <Div>
                        {
                            this.state.configDone === true ? <Header /> : null
                        }

                        {
                            this.state.configDone === true && this.state.displayQuestions === true ? 
                                 <Questions
                                    handleChange={this.handleChange}
                                    handleCheck={this.handleCheck}
                                    handleChangeAsInt={this.handleChangeAsInt}
                                    {...this.state}
                                /> : null
                        }

                        {
                            this.state.configDone === true && this.state.displayTeam === true ? 
                                <Team
                                    handleChange={this.handleChange}
                                    handleCheck={this.handleCheck}
                                    handleChangeAsInt={this.handleChangeAsInt}
                                        {...this.state}    
                                /> : null
                        }

                        {
                            this.state.displaySubmit === true && this.state.configDone === true ?
                            <Div className="text-right">
                                <Button
                                className="m-4"
                                size="sm"
                                variant="primary"
                                onClick={() => this.submitSurvey()}
                                >
                                Submit
                                </Button>
                            </Div> : null
                        }

                        {
                            this.state.displayThanks ? 
                                <ThankYou /> : null
                        }
                        <Modal show={this.state.showHideModal} size="lg" centered>
                            <Modal.Header>
                                <Modal.Title>Candidate Satisfaction Survey</Modal.Title>
                            </Modal.Header>
                                <Modal.Body>
                                    <IntroQuestions 
                                        handleChange={this.handleChange}
                                        handleCheck={this.handleCheck}
                                        {...this.state}
                                    />
                                
                                {
                                this.state.name !== "" && (this.state.deniseMF === true || this.state.kathyB === true || this.state.laurenL === true || this.state.gabrielaN === true) ?
                                    <Div className="text-right">
                                        <Button
                                        className="m-4"
                                        size="lg"
                                        variant="primary"
                                        onClick={() => this.surveyConfig()}
                                        >
                                        Begin Survey
                                        </Button>
                                    </Div>
                                     :
                                    <Div className="text-right">
                                        <Button
                                            className="m-4"
                                            size="lg"
                                            variant="secondary"
                                            disabled
                                        >
                                            Begin Survey
                                        </Button>
                                    </Div>
                                    }
                                    
                                </Modal.Body>
                        </Modal>
                    </Div>
                </Container>
            </ThemeProvider>
        )
    }



}

export default CandidateSatisfactionSurvey