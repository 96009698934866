import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class EmployeeBenefits extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Employee Benefits</h4>
                                <hr />

                                <Form.Group
                                    name="formBenefitsTypes"
                                    as={Row}
                                    controlId="formBenefitsTypes"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        28.Types of benefits provided to employees. (Check all that apply).
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Medical Plan"
                                        name="formBenefitsTypesMedical"
                                        id="formBenefitsTypesMedical"
                                        defaultChecked={this.props.formBenefitsTypesMedical}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Dental Plan"
                                        name="formBenefitsTypesDental"
                                        id="formBenefitsTypesDental"
                                        defaultChecked={
                                            this.props.formBenefitsTypesDental
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Vision Plan"
                                        name="formBenefitsTypesVision"
                                        id="formBenefitsTypesVision"
                                        defaultChecked={
                                            this.props.formBenefitsTypesVision
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Prescription Drug Plan"
                                        name="formBenefitsTypesRX"
                                        id="formBenefitsTypesRX"
                                        defaultChecked={this.props.formBenefitsTypesRX}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Flexible Spending Account (FSA)"
                                        name="formBenefitsTypesFSA"
                                        id="formBenefitsTypesFSA"
                                        defaultChecked={
                                            this.props.formBenefitsTypesFSA
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Health Reimbursement Account (HRA)"
                                        name="formBenefitsTypesHRA"
                                        id="formBenefitsTypesHRA"
                                        defaultChecked={
                                            this.props.formBenefitsTypesHRA
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Health Savings Account"
                                        name="formBenefitsTypesHSA"
                                        id="formBenefitsTypesHSA"
                                        defaultChecked={
                                            this.props.formBenefitsTypesHSA
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Life Insurance"
                                        name="formBenefitsTypesLife"
                                        id="formBenefitsTypesLife"
                                        defaultChecked={
                                            this.props.formBenefitsTypesLife
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="AD&D Insurance"
                                        name="formBenefitsTypesADD"
                                        id="formBenefitsTypesADD"
                                        defaultChecked={
                                            this.props.formBenefitsTypesADD
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Short-Term Disability"
                                        name="formBenefitsTypesSTD"
                                        id="formBenefitsTypesSTD"
                                        defaultChecked={this.props.formBenefitsTypesSTD}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Long-Term Disability"
                                        name="formBenefitsTypesLTD"
                                        id="formBenefitsTypesLTD"
                                        defaultChecked={
                                            this.props.formBenefitsTypesLTD
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Retirement"
                                        name="formBenefitsTypesRetirement"
                                        id="formBenefitsTypesRetirement"
                                        defaultChecked={this.props.formBenefitsTypesRetirement}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Car Allowance"
                                        name="formBenefitsTypesCar"
                                        id="formBenefitsTypesCar"
                                        defaultChecked={this.props.formBenefitsTypesCar}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Phone Reimbursement"
                                        name="formBenefitsTypesPhone"
                                        id="formBenefitsTypesPhone"
                                        defaultChecked={this.props.formBenefitsTypesPhone}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formBenefitsTypesOther"
                                        id="formBenefitsTypesOther"
                                        defaultChecked={this.props.formBenefitsTypesOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formBenefitsTypesOther === true ||
                                        this.props.formBenefitsTypesOther === 1) && (
                                        <Form.Group controlId="formBenefitsTypesOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formBenefitsTypesOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formBenefitsTypesOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>
                                
                                <Form.Group
                                    name="formBenefitsDependent"
                                    as={Row}
                                    controlId="formBenefitsDependent"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            29. Dependent coverage available?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formBenefitsDependent"
                                            id="formBenefitsDependent-Yes"
                                            defaultChecked={
                                            this.props.formBenefitsDependent === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formBenefitsDependent"
                                            id="formBenefitsDependent-No"
                                            defaultChecked={
                                            this.props.formBenefitsDependent === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formBenefitsDependent"
                                            id="formBenefitsDependent-NA"
                                            defaultChecked={
                                            this.props.formBenefitsDependent === "unknown"
                                            }
                                        />
                                </Form.Group>
                                
                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formBenefitsSupplement">
                                    <Form.Label>
                                        i. How much does the organization supplement?
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsSupplement"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsSupplement || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsAccess">
                                    <Form.Label>
                                        30. How do employees access benefits information?
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsAccess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsAccess || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsBroker">
                                    <Form.Label>
                                        31. Benefits broker
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsBroker"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsBroker || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formBenefitsTenure">
                                    <Form.Label>
                                        i. Tenure
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsTenure"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsTenure || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formBenefitsService">
                                    <Form.Label>
                                        ii. Customer service of broker
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsService"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsService || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formBenefits5500"
                                    as={Row}
                                    controlId="formBenefits5500"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            32. Does your organization file 5500s for benefit plans?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formBenefits5500"
                                            id="formBenefits5500-Yes"
                                            defaultChecked={
                                            this.props.formBenefits5500 === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formBenefits5500"
                                            id="formBenefits5500-No"
                                            defaultChecked={
                                            this.props.formBenefits5500 === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formBenefits5500"
                                            id="formBenefits5500-NA"
                                            defaultChecked={
                                            this.props.formBenefits5500 === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group
                                    className="ml-5"
                                    name="formBenefitsBrokerFile"
                                    as={Row}
                                    controlId="formBenefitsBrokerFile"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            i. Does the broker file?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formBenefitsBrokerFile"
                                            id="formBenefitsBrokerFile-Yes"
                                            defaultChecked={
                                            this.props.formBenefitsBrokerFile === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formBenefitsBrokerFile"
                                            id="formBenefitsBrokerFile-No"
                                            defaultChecked={
                                            this.props.formBenefitsBrokerFile === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formBenefitsBrokerFile"
                                            id="formBenefitsBrokerFile-NA"
                                            defaultChecked={
                                            this.props.formBenefitsBrokerFile === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group
                                    name="formBenefitsDiscrimination"
                                    as={Row}
                                    controlId="formBenefitsDiscrimination"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            33. Is non-discrimination testing performed for benefit plans?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formBenefitsDiscrimination"
                                            id="formBenefitsDiscrimination-Yes"
                                            defaultChecked={
                                            this.props.formBenefitsDiscrimination === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formBenefitsDiscrimination"
                                            id="formBenefitsDiscrimination-No"
                                            defaultChecked={
                                            this.props.formBenefitsDiscrimination === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formBenefitsDiscrimination"
                                            id="formBenefitsDiscrimination-NA"
                                            defaultChecked={
                                            this.props.formBenefitsDiscrimination === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formBenefitsTesting">
                                    <Form.Label>
                                        i. Who performs the testing?
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsTesting"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formBenefitsTesting || ""}
                                    />
                                </Form.Group>

                                <p>34 Open Enrollment:</p>

                                <Form.Group className="ml-3" controlId="formBenefitsDate">
                                    <Form.Label>
                                        i. Date
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsDate"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsDate || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formBenefitsLead">
                                    <Form.Label>
                                        ii. Lead
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsLead"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formBenefitsLead || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formBenefitsProcess">
                                    <Form.Label>
                                        iii. Process
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsProcess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsProcess || ""}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Welfare Benefits</h5>
                                <hr />

                                <Form.Group controlId="formWelfareSchedule">
                                    <Form.Label>
                                        35. Schedule of Benefits
                                    </Form.Label>
                                    <Form.Control
                                        name="formWelfareSchedule"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formWelfareSchedule || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsSPD">
                                    <Form.Label>
                                        36. SPD and/or Wrap Document
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsSPD"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formBenefitsSPD || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsPlan">
                                    <Form.Label>
                                        37. Plan Document
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsPlan"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsPlan || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsSummary">
                                    <Form.Label>
                                        38. Summary of Benefits and Coverage
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsSummary"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsSummary || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBenefitsContract">
                                    <Form.Label>
                                        39. Broker of Record Letter and/or Broker Service Contract
                                    </Form.Label>
                                    <Form.Control
                                        name="formBenefitsContract"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formBenefitsContract || ""}
                                    />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h5>COBRA</h5>
                                <hr />

                                <Form.Group controlId="formCOBRAIndividual">
                                    <Form.Label>
                                        40. Responsible Individual
                                    </Form.Label>
                                    <Form.Control
                                        name="formCOBRAIndividual"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formCOBRAIndividual || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCOBRAProcedures">
                                    <Form.Label>
                                        41. Procedures
                                    </Form.Label>
                                    <Form.Control
                                        name="formCOBRAProcedures"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formCOBRAProcedures || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formCOBRAOversight">
                                    <Form.Label>
                                        42. Oversight
                                    </Form.Label>
                                    <Form.Control
                                        name="formCOBRAOversight"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formCOBRAOversight || ""}
                                    />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h5>Paid Leave</h5>
                                <hr />

                                <Form.Group
                                    name="formPaidLeaveTypes"
                                    as={Row}
                                    controlId="formPaidLeaveTypes"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        43. Types of Paid Leave available. (Check all that apply).
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Vacation"
                                        name="formPaidLeaveVacation"
                                        id="formPaidLeaveVacation"
                                        defaultChecked={this.props.formPaidLeaveVacation}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Paid Time Off"
                                        name="formPaidLeavePaidTimeOff"
                                        id="formPaidLeavePaidTimeOff"
                                        defaultChecked={
                                            this.props.formPaidLeavePaidTimeOff
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Holiday"
                                        name="formPaidLeaveHoliday"
                                        id="formPaidLeaveHoliday"
                                        defaultChecked={
                                            this.props.formPaidLeaveHoliday
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Sick Leave"
                                        name="formPaidLeaveSick"
                                        id="formPaidLeaveSick"
                                        defaultChecked={this.props.formPaidLeaveSick}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Bereavement Leave"
                                        name="formPaidLeaveBereavement"
                                        id="formPaidLeaveBereavement"
                                        defaultChecked={
                                            this.props.formPaidLeaveBereavement
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Jury Duty"
                                        name="formPaidLeaveJuryDuty"
                                        id="formPaidLeaveJuryDuty"
                                        defaultChecked={
                                            this.props.formPaidLeaveJuryDuty
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Family and/or Medical Leave (Paid)"
                                        name="formPaidLeaveMedical"
                                        id="formPaidLeaveMedical"
                                        defaultChecked={
                                            this.props.formPaidLeaveMedical
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Sabbatical"
                                        name="formPaidLeaveSabbatical"
                                        id="formPaidLeaveSabbatical"
                                        defaultChecked={
                                            this.props.formPaidLeaveSabbatical
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Adoption/Foster Placement Leave"
                                        name="formPaidLeaveAdoption"
                                        id="formPaidLeaveAdoption"
                                        defaultChecked={
                                            this.props.formPaidLeaveAdoption
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formPaidLeaveOther"
                                        id="formPaidLeaveOther"
                                        defaultChecked={this.props.formPaidLeaveOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formPaidLeaveOther === true ||
                                        this.props.formPaidLeaveOther === 1) && (
                                        <Form.Group controlId="formPaidLeaveOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formPaidLeaveOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                             value={
                                            this.props.formPaidLeaveOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group
                                    name="formPaidLeaveHandbook"
                                    as={Row}
                                    controlId="formPaidLeaveHandbook"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            44. Are these paid leave programs in the Employee Handbook/HR policies?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPaidLeaveHandbook"
                                            id="formPaidLeaveHandbook-Yes"
                                            defaultChecked={
                                            this.props.formPaidLeaveHandbook === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPaidLeaveHandbook"
                                            id="formPaidLeaveHandbook-No"
                                            defaultChecked={
                                            this.props.formPaidLeaveHandbook === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPaidLeaveHandbook"
                                            id="formPaidLeaveHandbook-NA"
                                            defaultChecked={
                                            this.props.formPaidLeaveHandbook === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If no:</p>

                                <Form.Group
                                    className="ml-5" controlId="formPaidLeaveDocumented">
                                    <Form.Label>
                                        i. Where are they documented?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPaidLeaveDocumented"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formPaidLeaveDocumented || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPaidLeaveTracked">
                                    <Form.Label>
                                        45. How are leave balances tracked?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPaidLeaveTracked"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formPaidLeaveTracked || ""}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Unpaid Leave</h5>
                                <hr />

                                <Form.Group
                                    name="formUnpaidLeaveLoA"
                                    as={Row}
                                    controlId="formUnpaidLeaveLoA"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            46. Is there a Leave of Absence policy in place?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formUnpaidLeaveLoA"
                                            id="formUnpaidLeaveLoA-Yes"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveLoA === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formUnpaidLeaveLoA"
                                            id="formUnpaidLeaveLoA-No"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveLoA === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formUnpaidLeaveLoA"
                                            id="formUnpaidLeaveLoA-NA"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveLoA === "unknown"
                                            }
                                        />
                                </Form.Group>
                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formUnpaidLeaveProcess">
                                    <Form.Label>
                                        i. What is the process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formUnpaidLeaveProcess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formUnpaidLeaveProcess || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formUnpaidLeaveRequest">
                                    <Form.Label>
                                        ii. How do employees request an LOA?
                                    </Form.Label>
                                    <Form.Control
                                        name="formUnpaidLeaveRequest"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formUnpaidLeaveRequest || ""}
                                    />
                                </Form.Group>

                                <Form.Group  className="ml-5" controlId="formUnpaidLeaveResponses">
                                    <Form.Label>
                                        iii. What do responses look like?
                                    </Form.Label>
                                    <Form.Control
                                        name="formUnpaidLeaveResponses"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formUnpaidLeaveResponses || ""}
                                    />
                                </Form.Group>

                                <Form.Group  className="ml-5" controlId="formUnpaidLeaveApprovals">
                                    <Form.Label>
                                        iv. How are approvals/denials communicated?
                                    </Form.Label>
                                    <Form.Control
                                        name="formUnpaidLeaveApprovals"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formUnpaidLeaveApprovals || ""}
                                    />
                                </Form.Group>

                                <p>If the company has 50+ employees</p>

                                <Form.Group
                                    name="formUnpaidLeaveFMLA"
                                    as={Row}
                                    controlId="formUnpaidLeaveFMLA"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            47. Is there an FMLA policy in place?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formUnpaidLeaveFMLA"
                                            id="formUnpaidLeaveFMLA-Yes"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveFMLA === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formUnpaidLeaveFMLA"
                                            id="formUnpaidLeaveFMLA-No"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveFMLA === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formUnpaidLeaveFMLA"
                                            id="formUnpaidLeaveFMLA-NA"
                                            defaultChecked={
                                            this.props.formUnpaidLeaveFMLA === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group  className="ml-5" controlId="formUnpaidLeaveTracking">
                                    <Form.Label>
                                        i. What is the process, including tracking?
                                    </Form.Label>
                                    <Form.Control
                                        name="formUnpaidLeaveTracking"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formUnpaidLeaveTracking || ""}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Retirement</h5>
                                <hr />

                                <Form.Group
                                    name="formRetirementTypes"
                                    as={Row}
                                    controlId="formRetirementTypes"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        43. Types of retirement plan. (Check all that apply).
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="401k"
                                        name="formRetirementTypes401k"
                                        id="formRetirementTypes401k"
                                        defaultChecked={this.props.formRetirementTypes401k}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="403b"
                                        name="formRetirementTypes403b"
                                        id="formRetirementTypes403b"
                                        defaultChecked={
                                            this.props.formRetirementTypes403b
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Simple IRA"
                                        name="formRetirementTypesSimple"
                                        id="formRetirementTypesSimple"
                                        defaultChecked={
                                            this.props.formRetirementTypesSimple
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Sep-IRA"
                                        name="formRetirementTypesSep"
                                        id="formRetirementTypesSep"
                                        defaultChecked={this.props.formRetirementTypesSep}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formRetirementOther"
                                        id="formRetirementOther"
                                        defaultChecked={this.props.formRetirementOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formRetirementOther === true ||
                                        this.props.formRetirementOther === 1) && (
                                        <Form.Group controlId="formRetirementOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formRetirementOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                             value={
                                            this.props.formRetirementOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group controlId="formRetirementTPA">
                                    <Form.Label>
                                        49. Who is the Third-Party Administrator (TPA)?
                                    </Form.Label>
                                    <Form.Control
                                        name="formRetirementTPA"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formRetirementTPA || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formRetirementEmpMatch"
                                    as={Row}
                                    controlId="formRetirementEmpMatch"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            50. Do you provide an employer match?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formRetirementEmpMatch"
                                            id="formRetirementEmpMatch-Yes"
                                            defaultChecked={
                                            this.props.formRetirementEmpMatch === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formRetirementEmpMatch"
                                            id="formRetirementEmpMatch-No"
                                            defaultChecked={
                                            this.props.formRetirementEmpMatch === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formRetirementEmpMatch"
                                            id="formRetirementEmpMatch-NA"
                                            defaultChecked={
                                            this.props.formRetirementEmpMatch === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formRetirementMatch">
                                    <Form.Label>
                                        i. What is the match?
                                    </Form.Label>
                                    <Form.Control
                                        name="formRetirementMatch"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formRetirementMatch || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formRetirementVested">
                                    <Form.Label>
                                        51. When is an employee vested?
                                    </Form.Label>
                                    <Form.Control
                                        name="formRetirementVested"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formRetirementVested || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formRetirementEnroll"
                                    as={Row}
                                    controlId="formRetirementEnroll"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            52. Are employees automatically enrolled in the plan upon hire?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formRetirementEnroll"
                                            id="formRetirementEnroll-Yes"
                                            defaultChecked={
                                            this.props.formRetirementEnroll === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formRetirementEnroll"
                                            id="formRetirementEnroll-No"
                                            defaultChecked={
                                            this.props.formRetirementEnroll === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formRetirementEnroll"
                                            id="formRetirementEnroll-NA"
                                            defaultChecked={
                                            this.props.formRetirementEnroll === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If not:</p>

                                <Form.Group className="ml-5" controlId="formRetirementEnrolledHow">
                                    <Form.Label>
                                        i. How are the enrolled?
                                    </Form.Label>
                                    <Form.Control
                                        name="formRetirementEnrolledHow"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formRetirementEnrolledHow || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formRetirement5500"
                                    as={Row}
                                    controlId="formRetirement5500"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            53. Does your organization meet the requirements to file a 5500 for the retirement plan?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formRetirement5500"
                                            id="formRetirement5500-Yes"
                                            defaultChecked={
                                            this.props.formRetirement5500 === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formRetirement5500"
                                            id="formRetirement5500-No"
                                            defaultChecked={
                                            this.props.formRetirement5500 === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formRetirement5500"
                                            id="formRetirement5500-NA"
                                            defaultChecked={
                                            this.props.formRetirement5500 === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formRetirement5500Process">
                                    <Form.Label>
                                        i. What is the process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formRetirement5500Process"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formRetirement5500Process || ""}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Work/Home Integration</h5>
                                <hr />

                                <Form.Group
                                    name="formIntegrationTypes"
                                    as={Row}
                                    controlId="formIntegrationTypes"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        54. What types of flexible work programs are available? (Check all that apply).
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Flexible Hours"
                                        name="formIntegrationTypesFlexHours"
                                        id="formIntegrationTypesFlexHours"
                                        defaultChecked={this.props.formIntegrationTypesFlexHours}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Remote Work"
                                        name="formIntegrationTypesRemote"
                                        id="formIntegrationTypesRemote"
                                        defaultChecked={
                                            this.props.formIntegrationTypesRemote
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Compressed Work Week"
                                        name="formIntegrationTypesCompressed"
                                        id="formIntegrationTypesCompressed"
                                        defaultChecked={
                                            this.props.formIntegrationTypesCompressed
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Job Sharing"
                                        name="formIntegrationTypesJobSharing"
                                        id="formIntegrationTypesJobSharing"
                                        defaultChecked={this.props.formIntegrationTypesJobSharing}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Part-time employment"
                                        name="formIntegrationTypesPartTime"
                                        id="formIntegrationTypesPartTime"
                                        defaultChecked={this.props.formIntegrationTypesPartTime}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Seasonal Schedules"
                                        name="formIntegrationTypesSeasonal"
                                        id="formIntegrationTypesSeasonal"
                                        defaultChecked={this.props.formIntegrationTypesSeasonal}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formIntegrationTypesOther"
                                        id="formIntegrationTypesOther"
                                        defaultChecked={this.props.formIntegrationTypesOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formIntegrationTypesOther === true ||
                                        this.props.formIntegrationTypesOther === 1) && (
                                        <Form.Group controlId="formIntegrationTypesOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formIntegrationTypesOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                             value={
                                            this.props.formIntegrationTypesOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>
                                
                                <Form.Group
                                    name="formIntegrationHandbook"
                                    as={Row}
                                    controlId="formIntegrationHandbook"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            55. Flexible work programs spelled out in the Employee Handbook?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formIntegrationHandbook"
                                            id="formIntegrationHandbook-Yes"
                                            defaultChecked={
                                            this.props.formIntegrationHandbook === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formIntegrationHandbook"
                                            id="formIntegrationHandbook-No"
                                            defaultChecked={
                                            this.props.formIntegrationHandbook === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formIntegrationHandbook"
                                            id="formIntegrationHandbook-NA"
                                            defaultChecked={
                                            this.props.formIntegrationHandbook === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <p className="ml-3">If not:</p>

                                <Form.Group className="ml-5" controlId="formIntegrationDocument">
                                    <Form.Label>
                                        i. What is the process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formIntegrationDocument"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formIntegrationDocument || ""}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Training and Development Practices</h5>
                                <hr />

                                <Form.Group controlId="formTrainingIndividuals">
                                    <Form.Label>
                                        56. Responsible Individual(s)?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingIndividuals"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingIndividuals || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTrainingMethodology">
                                    <Form.Label>
                                        a. Methodology?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingMethodology"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingMethodology || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTrainingFunction">
                                    <Form.Label>
                                        b. Internal or External Function?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingFunction"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingFunction || ""}
                                    />
                                </Form.Group>
                                <p className="ml-3">c. Training topics</p>

                                <Form.Group className="ml-5"
                                    name="formTrainingCompliance"
                                    as={Row}
                                    controlId="formTrainingCompliance"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            i. Compliance
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingCompliance"
                                            id="formTrainingCompliance-Yes"
                                            defaultChecked={
                                            this.props.formTrainingCompliance === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingCompliance"
                                            id="formTrainingCompliance-No"
                                            defaultChecked={
                                            this.props.formTrainingCompliance === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingCompliance"
                                            id="formTrainingCompliance-NA"
                                            defaultChecked={
                                            this.props.formTrainingCompliance === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingComplianceOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingComplianceOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formTrainingComplianceOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingProDev"
                                    as={Row}
                                    controlId="formTrainingProDev"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            ii. Professional Development
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingProDev"
                                            id="formTrainingProDev-Yes"
                                            defaultChecked={
                                            this.props.formTrainingProDev === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingProDev"
                                            id="formTrainingProDev-No"
                                            defaultChecked={
                                            this.props.formTrainingProDev === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingProDev"
                                            id="formTrainingProDev-NA"
                                            defaultChecked={
                                            this.props.formTrainingProDev === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingProDevOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingProDevOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingProDevOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingLeadership"
                                    as={Row}
                                    controlId="formTrainingLeadership"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            iii. Leadership
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingLeadership"
                                            id="formTrainingLeadership-Yes"
                                            defaultChecked={
                                            this.props.formTrainingLeadership === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingLeadership"
                                            id="formTrainingLeadership-No"
                                            defaultChecked={
                                            this.props.formTrainingLeadership === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingLeadership"
                                            id="formTrainingLeadership-NA"
                                            defaultChecked={
                                            this.props.formTrainingLeadership === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingLeadershipOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingLeadershipOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formTrainingLeadershipOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingSafety"
                                    as={Row}
                                    controlId="formTrainingSafety"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            iv. Safety
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingSafety"
                                            id="formTrainingSafety-Yes"
                                            defaultChecked={
                                            this.props.formTrainingSafety === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingSafety"
                                            id="formTrainingSafety-No"
                                            defaultChecked={
                                            this.props.formTrainingSafety === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingSafety"
                                            id="formTrainingSafety-NA"
                                            defaultChecked={
                                            this.props.formTrainingSafety === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingSafetyOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingSafetyOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formTrainingSafetyOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingExecCoaching"
                                    as={Row}
                                    controlId="formTrainingExecCoaching"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            V. Executive Coaching
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingExecCoaching"
                                            id="formTrainingExecCoaching-Yes"
                                            defaultChecked={
                                            this.props.formTrainingExecCoaching === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingExecCoaching"
                                            id="formTrainingExecCoaching-No"
                                            defaultChecked={
                                            this.props.formTrainingExecCoaching === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingExecCoaching"
                                            id="formTrainingExecCoaching-NA"
                                            defaultChecked={
                                            this.props.formTrainingExecCoaching === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingExecCoachingOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingExecCoachingOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingExecCoachingOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingManagement"
                                    as={Row}
                                    controlId="formTrainingManagement"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            vi. Management Training
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingManagement"
                                            id="formTrainingManagement-Yes"
                                            defaultChecked={
                                            this.props.formTrainingManagement === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingManagement"
                                            id="formTrainingManagement-No"
                                            defaultChecked={
                                            this.props.formTrainingManagement === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingManagement"
                                            id="formTrainingManagement-NA"
                                            defaultChecked={
                                            this.props.formTrainingManagement === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingManagementOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingManagementOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingManagementOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5"
                                    name="formTrainingHarassment"
                                    as={Row}
                                    controlId="formTrainingHarassment"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            vii. Harassment training for manager(different than what is given to employees)
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formTrainingHarassment"
                                            id="formTrainingHarassment-Yes"
                                            defaultChecked={
                                            this.props.formTrainingHarassment === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formTrainingHarassment"
                                            id="formTrainingHarassment-No"
                                            defaultChecked={
                                            this.props.formTrainingHarassment === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formTrainingHarassment"
                                            id="formTrainingHarassment-NA"
                                            defaultChecked={
                                            this.props.formTrainingHarassment === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingHarassmentOften">
                                    <Form.Label>
                                        a. If yes, how often?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingHarassmentOften"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingHarassmentOften || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTrainingOthers">
                                    <Form.Label>
                                        viii. Others?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTrainingOthers"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formTrainingOthers || ""}
                                    />
                                </Form.Group>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default EmployeeBenefits;