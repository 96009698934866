import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class TalentAttraction extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Talent Attraction</h4>
                                <hr />

                                <Form.Group controlId="formTAProcess">
                                    <Form.Label>
                                        97. Describe your full recruiting process and who manages what pieces?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAProcess"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAProcess || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group
                            name="formTAAdvertising"
                            as={Row}
                            controlId="formTAAdvertising"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    98. Advertising?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formTAAdvertising"
                                    id="formTAAdvertising-Yes"
                                    defaultChecked={
                                    this.props.formTAAdvertising === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formTAAdvertising"
                                    id="formTAAdvertising-No"
                                    defaultChecked={
                                    this.props.formTAAdvertising === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formTAAdvertising"
                                    id="formTAAdvertising-NA"
                                    defaultChecked={
                                    this.props.formTAAdvertising === "unknown"
                                    }
                                />
                                </Form.Group>
                                
                                <Form.Group className="ml-3" controlId="formTAWhere">
                                    <Form.Label>
                                        i. Where?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAWhere"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAWhere || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTAWhen">
                                    <Form.Label>
                                        ii. When?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAWhen"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAWhen || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTAWho">
                                    <Form.Label>
                                        iii. Who selects?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAWho"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAWho || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formTARecords">
                                    <Form.Label>
                                        99. Location of records of recruitment activities such as job postings, interview records, job application, resumes?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTARecords"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTARecords || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formTAPositions">
                                    <Form.Label>
                                        100. Number of currently open positions?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAPositions"
                                        as="textarea"
                                        rows="1"
                                        value={this.props.formTAPositions || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTAOpen">
                                    <Form.Label>
                                        i. How long have they been open?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAOpen"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAOpen || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group
                            name="formTAInternal"
                            as={Row}
                            controlId="formTAInternal"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    101. Internal Recruiter?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formTAInternal"
                                    id="formTAInternal-Yes"
                                    defaultChecked={
                                    this.props.formTAInternal === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formTAInternal"
                                    id="formTAInternal-No"
                                    defaultChecked={
                                    this.props.formTAInternal === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formTAInternal"
                                    id="formTAInternal-NA"
                                    defaultChecked={
                                    this.props.formTAInternal === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group
                            name="formTAOutside"
                            as={Row}
                            controlId="formTAOutside"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    101. Outside Agency?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formTAOutside"
                                    id="formTAOutside-Yes"
                                    defaultChecked={
                                    this.props.formTAOutside === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formTAOutside"
                                    id="formTAOutside-No"
                                    defaultChecked={
                                    this.props.formTAOutside === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formTAOutside"
                                    id="formTAOutside-NA"
                                    defaultChecked={
                                    this.props.formTAOutside === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTAOutsideCompany">
                                    <Form.Label>
                                        i. What company?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAOutsideCompany"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAOutsideCompany || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTAOutsideType">
                                    <Form.Label>
                                        ii. What type of positions do they assist with?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTAOutsideType"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTAOutsideType || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                            </div>

                            <div className="form-section">
                                <h5>Interview</h5>
                                <hr />

                                <Form.Group controlId="formInterviewProcess">
                                    <Form.Label>
                                        103. Interview process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewProcess"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewProcess || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewConducts">
                                    <Form.Label>
                                        i. Who conducts?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewConducts"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewConducts || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewTrains">
                                    <Form.Label>
                                        ii. Who trains if needed?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewTrains"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewTrains || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group
                            name="formInterviewOffer"
                            as={Row}
                            controlId="formInterviewOffer"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    104. Do new hires receive an offer letter of employment?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewOffer"
                                    id="formInterviewOffer-Yes"
                                    defaultChecked={
                                    this.props.formInterviewOffer === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewOffer"
                                    id="formInterviewOffer-No"
                                    defaultChecked={
                                    this.props.formInterviewOffer === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewOffer"
                                    id="formInterviewOffer-NA"
                                    defaultChecked={
                                    this.props.formInterviewOffer === "unknown"
                                    }
                                />
                                </Form.Group>

                                <p className="ml-3">If so:</p>

                                <Form.Group className="ml-5"
                            name="formInterviewTemplate"
                            as={Row}
                            controlId="formInterviewTemplate"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    i. Is it a standard pipeline?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewTemplate"
                                    id="formInterviewTemplate-Yes"
                                    defaultChecked={
                                    this.props.formInterviewTemplate === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewTemplate"
                                    id="formInterviewTemplate-No"
                                    defaultChecked={
                                    this.props.formInterviewTemplate === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewTemplate"
                                    id="formInterviewTemplate-NA"
                                    defaultChecked={
                                    this.props.formInterviewTemplate === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group
                            name="formInterviewNonCompete"
                            as={Row}
                            controlId="formInterviewNonCompete"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    105. Do you use non-compete / non-disclosure agreements?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewNonCompete"
                                    id="formInterviewNonCompete-Yes"
                                    defaultChecked={
                                    this.props.formInterviewNonCompete === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewNonCompete"
                                    id="formInterviewNonCompete-No"
                                    defaultChecked={
                                    this.props.formInterviewNonCompete === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewNonCompete"
                                    id="formInterviewNonCompete-NA"
                                    defaultChecked={
                                    this.props.formInterviewNonCompete === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group
                            name="formInterviewSubmission"
                            as={Row}
                            controlId="formInterviewSubmission"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    106. Application Submission
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewSubmission"
                                    id="formInterviewSubmission-Yes"
                                    defaultChecked={
                                    this.props.formInterviewSubmission === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewSubmission"
                                    id="formInterviewSubmission-No"
                                    defaultChecked={
                                    this.props.formInterviewSubmission === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewSubmission"
                                    id="formInterviewSubmission-NA"
                                    defaultChecked={
                                    this.props.formInterviewSubmission === "unknown"
                                    }
                                />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formInterviewSent">
                                    <Form.Label>
                                        i. How and when is it sent?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewSent"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewSent || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formInterviewAccommodation">
                                    <Form.Label>
                                        ii. Reasonable Accommodation option?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewAccommodation"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewAccommodation || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3"
                            name="formInterviewCompliant"
                            as={Row}
                            controlId="formInterviewCompliant"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    iii. Is Application form compliant?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewCompliant"
                                    id="formInterviewCompliant-Yes"
                                    defaultChecked={
                                    this.props.formInterviewCompliant === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewCompliant"
                                    id="formInterviewCompliant-No"
                                    defaultChecked={
                                    this.props.formInterviewCompliant === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewCompliant"
                                    id="formInterviewCompliant-NA"
                                    defaultChecked={
                                    this.props.formInterviewCompliant === "unknown"
                                    }
                                />
                                </Form.Group>

                                 <Form.Group className="ml-3"
                            name="formInterviewFlow"
                            as={Row}
                            controlId="formInterviewFlow"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    iv. Applicant flow logs?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewFlow"
                                    id="formInterviewFlow-Yes"
                                    defaultChecked={
                                    this.props.formInterviewFlow === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewFlow"
                                    id="formInterviewFlow-No"
                                    defaultChecked={
                                    this.props.formInterviewFlow === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewFlow"
                                    id="formInterviewFlow-NA"
                                    defaultChecked={
                                    this.props.formInterviewFlow === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group
                            name="formInterviewBackground"
                            as={Row}
                            controlId="formInterviewBackground"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    107. Background Checks
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewBackground"
                                    id="formInterviewBackground-Yes"
                                    defaultChecked={
                                    this.props.formInterviewBackground === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewBackground"
                                    id="formInterviewBackground-No"
                                    defaultChecked={
                                    this.props.formInterviewBackground === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewBackground"
                                    id="formInterviewBackground-NA"
                                    defaultChecked={
                                    this.props.formInterviewBackground === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewResponsibleInd">
                                    <Form.Label>
                                        i. Responsible Individual
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewResponsibleInd"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewResponsibleInd || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3"
                            name="formInterviewManaged"
                            as={Row}
                            controlId="formInterviewManaged"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    ii. Internally or externally managed
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewManaged"
                                    id="formInterviewManaged-Yes"
                                    defaultChecked={
                                    this.props.formInterviewManaged === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewManaged"
                                    id="formInterviewManaged-No"
                                    defaultChecked={
                                    this.props.formInterviewManaged === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewManaged"
                                    id="formInterviewManaged-NA"
                                    defaultChecked={
                                    this.props.formInterviewManaged === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3"
                            name="formInterviewRegulatory"
                            as={Row}
                            controlId="formInterviewRegulatory"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    iii. Regulatory release forms?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewRegulatory"
                                    id="formInterviewRegulatory-Yes"
                                    defaultChecked={
                                    this.props.formInterviewRegulatory === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewRegulatory"
                                    id="formInterviewRegulatory-No"
                                    defaultChecked={
                                    this.props.formInterviewRegulatory === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewRegulatory"
                                    id="formInterviewRegulatory-NA"
                                    defaultChecked={
                                    this.props.formInterviewRegulatory === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewCommunication">
                                    <Form.Label>
                                        iv. Communication of results process
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewCommunication"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewCommunication || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewTiming">
                                    <Form.Label>
                                        v. Timing compliance
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewTiming"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewTiming || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3"
                            name="formInterviewLanguage"
                            as={Row}
                            controlId="formInterviewLanguage"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    vi. Language in offer letter?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewLanguage"
                                    id="formInterviewLanguage-Yes"
                                    defaultChecked={
                                    this.props.formInterviewLanguage === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewLanguage"
                                    id="formInterviewLanguage-No"
                                    defaultChecked={
                                    this.props.formInterviewLanguage === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewLanguage"
                                    id="formInterviewLanguage-NA"
                                    defaultChecked={
                                    this.props.formInterviewLanguage === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group
                            name="formInterviewPostOffer"
                            as={Row}
                            controlId="formInterviewPostOffer"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    108. Post Offer / Pre-employment Physical
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewPostOffer"
                                    id="formInterviewPostOffer-Yes"
                                    defaultChecked={
                                    this.props.formInterviewPostOffer === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewPostOffer"
                                    id="formInterviewPostOffer-No"
                                    defaultChecked={
                                    this.props.formInterviewPostOffer === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewPostOffer"
                                    id="formInterviewPostOffer-NA"
                                    defaultChecked={
                                    this.props.formInterviewPostOffer === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewOversee">
                                    <Form.Label>
                                        i. Who oversees?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewOversee"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewOversee || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewLocation">
                                    <Form.Label>
                                        ii. If specific location is required, where?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewLocation"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewLocation || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewNeeded">
                                    <Form.Label>
                                        iii. What is needed?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewNeeded"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewNeeded || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewWhen">
                                    <Form.Label>
                                        iv. When (confirm post offer and pre-hire sequence and cases of FCRA usage for withdrawing offers)?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewWhen"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewWhen || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3"
                            name="formInterviewDrugTesting"
                            as={Row}
                            controlId="formInterviewDrugTesting"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    v. Drug testing
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterviewDrugTesting"
                                    id="formInterviewDrugTesting-Yes"
                                    defaultChecked={
                                    this.props.formInterviewDrugTesting === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterviewDrugTesting"
                                    id="formInterviewDrugTesting-No"
                                    defaultChecked={
                                    this.props.formInterviewDrugTesting === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterviewDrugTesting"
                                    id="formInterviewDrugTesting-NA"
                                    defaultChecked={
                                    this.props.formInterviewDrugTesting === "unknown"
                                    }
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formInterviewCommunicated">
                                    <Form.Label>
                                        vi. How are results communicated?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInterviewCommunicated"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInterviewCommunicated || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-section">
                                <h5>Temp Agencies</h5>
                                <hr />

                                <Form.Group
                            name="formTempAgencies"
                            as={Row}
                            controlId="formTempAgencies"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    109. Use temporary employment agencies?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formTempAgencies"
                                    id="formTempAgencies-Yes"
                                    defaultChecked={
                                    this.props.formTempAgencies === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formTempAgencies"
                                    id="formTempAgencies-No"
                                    defaultChecked={
                                    this.props.formTempAgencies === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formTempAgencies"
                                    id="formTempAgencies-NA"
                                    defaultChecked={
                                    this.props.formTempAgencies === "unknown"
                                    }
                                />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formTempNumber">
                                    <Form.Label>
                                        i. Number of agencies currently being used?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTempNumber"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTempNumber || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTempEmployment">
                                    <Form.Label>
                                        ii. Typical length of temp employment?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTempEmployment"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTempEmployment || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formTempBenefits">
                                    <Form.Label>
                                        iii. Are temp employees provided any company benefits?
                                    </Form.Label>
                                    <Form.Control
                                        name="formTempBenefits"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formTempBenefits || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-section">
                                <h5>Interns</h5>
                                <hr />

                                <Form.Group
                            name="formInterns"
                            as={Row}
                            controlId="formInterns"
                            onChange={this.props.handleChange}
                            >
                                <Form.Label column sm={5}>
                                    110. Are interns employed?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Yes"
                                    value="yes"
                                    name="formInterns"
                                    id="formInterns-Yes"
                                    defaultChecked={
                                    this.props.formInterns === "yes"
                                    }
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="No"
                                    value="no"
                                    name="formInterns"
                                    id="formInterns-No"
                                    defaultChecked={
                                    this.props.formInterns === "no"
                                    }                               
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Unknown"
                                    value="unknown"
                                    name="formInterns"
                                    id="formInterns-NA"
                                    defaultChecked={
                                    this.props.formInterns === "unknown"
                                    }
                                />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formInternsPaid">
                                    <Form.Label>
                                        i. Are they paid or unpaid?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInternsPaid"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInternsPaid || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formInternsUnpaid">
                                    <Form.Label>
                                        ii. If unpaid, are they working through an accredited college or high school?
                                    </Form.Label>
                                    <Form.Control
                                        name="formInternsUnpaid"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formInternsUnpaid || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default TalentAttraction;