import React, { Component } from 'react';
import { Container, Form} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Other extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Other</h4>
                                <hr />

                                <Form.Group className="ml-3" controlId="formOtherShare">
                                <Form.Label>
                                    36. Is there anything else you would like to share?
                                </Form.Label>
                                <Form.Control
                                    name="formOtherShare"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOtherShare || ""}
                                />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Other;