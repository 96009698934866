import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Harassment extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Unlawful Discrimination and Harassment</h1>
                    <p>We prohibit discrimination and harassment, including sexual harassment, of one employee by another employee, supervisor, or third party for any reason including, but not limited to discrimination and harassment based on an individual’s military or veteran status, race, color, religion, gender, sexual orientation, national origin, age, physical or mental disability or any other protected class under federal, state, or local law.  Harassment, including sexual harassment, of third parties by our employees is also prohibited.</p>

                    <p>The purpose of this policy is to ensure that in the workplace, all of the {this.props.clientName}  employees enjoy a work environment free from discrimination and harassment.  The conduct prohibited by this policy includes conduct in any form including but not limited to e-mail, voice mail, chat rooms, Internet use or history, text messages, pictures, images, language, or gestures.</p>

                    <p>While it is not easy to define precisely what harassment is, it includes: slurs, epithets, threats, derogatory comments or visual depictions, unwelcome jokes, and teasing based on a person’s protected status.  Sexual harassment may include unwelcome sexual advances, requests for sexual favors, and/or verbal or physical conduct of a sexual nature including, but not limited to, sexually-related drawings, pictures, jokes, teasing, uninvited touching, or other sexually-related comments.  </p>

                    <p>Any employee who believes that he/she has been discriminated against or harassed must report the situation immediately to a member of management or Human Resources. If an employee makes a report and the manager either does not respond or does not respond in a manner the employee deems satisfactory or consistent with this policy, the employee is required to report the situation to one of the other members of management or Human Resources.</p>

                    <p>In addition, {this.props.clientName} does not tolerate retaliation against any employee for making a good-faith complaint of discrimination or harassment. {this.props.clientName} will investigate all such reports as confidentially as possible.  Adverse action will not be taken against an employee because he or she, in good faith, reports or participates in the investigation of a violation of this policy.  Any incident of retaliation should be reported in the same manner as an incident of discrimination or harassment.  Retaliation reports will be investigated in the same manner as discrimination and harassment reports.  </p>

                    <p>Violations of this policy are not permitted and may result in disciplinary action, up to and including discharge.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Harassment;
