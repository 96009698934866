import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Payroll extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Payroll</h4>
                                <hr />

                                <Form.Group controlId="formPayrollResponsible">
                                <Form.Label>
                                    63. Responsible individual
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollResponsible"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollResponsible || ""}
                                />
                                </Form.Group>
                                
                                <Form.Group className="ml-3" controlId="formPayrollReviewer">
                                <Form.Label>
                                    i. Second reviewer if applicable:
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollReviewer"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollReviewer || ""}
                                />
                                </Form.Group>
                                
                                <Form.Group controlId="formPayrollProvider">
                                <Form.Label>
                                    64.Paroll Provider
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollProvider"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollProvider || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPayrollSystem">
                                <Form.Label>
                                    65. Payroll System
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollSystem"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollSystem || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPayrollTimeKeeping">
                                <Form.Label>
                                    66. Time-Keeping System
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollTimeKeeping"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollTimeKeeping || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPayrollWeek">
                                <Form.Label>
                                    i. Work Week
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollWeek"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollWeek || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPayrollSchedule">
                                <Form.Label>
                                    ii. Pay schedule (weekly, bi-weekly, semi-monthly)
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollSchedule"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollSchedule || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPayrollTime">
                                <Form.Label>
                                    iii. How is time entered/transferred
                                </Form.Label>
                                <Form.Control
                                    name="formPayrollTime"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayrollTime || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formPayrollMeals"
                                as={Row}
                                controlId="formPayrollMeals"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        67. Are meal and rest breaks automatically deducted?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formPayrollMeals"
                                        id="formPayrollMeals-Yes"
                                        defaultChecked={
                                        this.props.formPayrollMeals === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formPayrollMeals"
                                        id="formPayrollMeals-No"
                                        defaultChecked={
                                        this.props.formPayrollMeals === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formPayrollMeals"
                                        id="formPayrollMeals-NA"
                                        defaultChecked={
                                        this.props.formPayrollMeals === "unknown"
                                        }
                                    />
                            </Form.Group>
                            
                            </div>
                            <div className="form-section">
                                <h5>Pay / Compensation</h5>
                                <hr />
                                <p>Changes in Rate of Pay</p>

                                <Form.Group className="ml-3" controlId="formPayWho">
                                <Form.Label>
                                    68. Who
                                </Form.Label>
                                <Form.Control
                                    name="formPayWho"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayWho || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPayWhen">
                                <Form.Label>
                                    69. When
                                </Form.Label>
                                <Form.Control
                                    name="formPayWhen"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayWhen || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPayHow">
                                <Form.Label>
                                    70. How
                                </Form.Label>
                                <Form.Control
                                    name="formPayHow"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPayHow || ""}
                                />
                                </Form.Group>
                            

                            
                                <p>Overtime</p>

                                <Form.Group className="ml-3" controlId="formOvertimeApproval">
                                <Form.Label>
                                    71. Approval
                                </Form.Label>
                                <Form.Control
                                    name="formOvertimeApproval"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOvertimeApproval || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formOvertimeDistribution">
                                <Form.Label>
                                    72. Distribution
                                </Form.Label>
                                <Form.Control
                                    name="formOvertimeDistribution"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOvertimeDistribution || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formOvertimeLimitation">
                                <Form.Label>
                                    73. Limitation
                                </Form.Label>
                                <Form.Control
                                    name="formOvertimeLimitation"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOvertimeLimitation || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formOvertimeCompensatory"
                                as={Row}
                                controlId="formOvertimeCompensatory"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        74. Compensatory Time?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formOvertimeCompensatory"
                                        id="formOvertimeCompensatory-Yes"
                                        defaultChecked={
                                        this.props.formOvertimeCompensatory === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formOvertimeCompensatory"
                                        id="formOvertimeCompensatory-No"
                                        defaultChecked={
                                        this.props.formOvertimeCompensatory === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formOvertimeCompensatory"
                                        id="formOvertimeCompensatory-NA"
                                        defaultChecked={
                                        this.props.formOvertimeCompensatory === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                                <p>Temporary Employees</p>
                                <Form.Group className="ml-3" controlId="formTempResponsible">
                                <Form.Label>
                                    75. Responsible person
                                </Form.Label>
                                <Form.Control
                                    name="formTempResponsible"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formTempResponsible || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTempRecords">
                                <Form.Label>
                                    76. Payment records
                                </Form.Label>
                                <Form.Control
                                    name="formTempRecords"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formTempRecords || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formTempLength">
                                <Form.Label>
                                    77. Length of temp assignments
                                </Form.Label>
                                <Form.Control
                                    name="formTempLength"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formTempLength || ""}
                                />
                                </Form.Group>

                                <p>Withholdings</p>

                                <Form.Group
                                className="ml-3"
                                name="formWithholdingTaxes"
                                as={Row}
                                controlId="formWithholdingTaxes"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        78. State and Federal Taxes?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formWithholdingTaxes"
                                        id="formWithholdingTaxes-Yes"
                                        defaultChecked={
                                        this.props.formWithholdingTaxes === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formWithholdingTaxes"
                                        id="formWithholdingTaxes-No"
                                        defaultChecked={
                                        this.props.formWithholdingTaxes === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formWithholdingTaxes"
                                        id="formWithholdingTaxes-NA"
                                        defaultChecked={
                                        this.props.formWithholdingTaxes === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formWithholdingMedical"
                                as={Row}
                                controlId="formWithholdingMedical"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        79. Medical or other benefits
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formWithholdingMedical"
                                        id="formWithholdingMedical-Yes"
                                        defaultChecked={
                                        this.props.formWithholdingMedical === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formWithholdingMedical"
                                        id="formWithholdingMedical-No"
                                        defaultChecked={
                                        this.props.formWithholdingMedical === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formWithholdingMedical"
                                        id="formWithholdingMedical-NA"
                                        defaultChecked={
                                        this.props.formWithholdingMedical === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formWithholdingGarnishment"
                                as={Row}
                                controlId="formWithholdingGarnishment"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        80. Garnishment Procedures?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formWithholdingGarnishment"
                                        id="formWithholdingGarnishment-Yes"
                                        defaultChecked={
                                        this.props.formWithholdingGarnishment === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formWithholdingGarnishment"
                                        id="formWithholdingGarnishment-No"
                                        defaultChecked={
                                        this.props.formWithholdingGarnishment === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formWithholdingGarnishment"
                                        id="formWithholdingGarnishment-NA"
                                        defaultChecked={
                                        this.props.formWithholdingGarnishment === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formWithholdingOther">
                                <Form.Label>
                                    81. Other (authorizations)
                                </Form.Label>
                                <Form.Control
                                    name="formWithholdingOther"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formWithholdingOther || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                    name="formWithholdingsPremium"
                                    as={Row}
                                    controlId="formWithholdingsPremium"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        82. What types of Premium Pay does your organization offer? Check all that apply
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Shift Differential"
                                        name="formWithholdingsShift"
                                        id="formWithholdingsPremium-1"
                                        defaultChecked={this.props.formWithholdingsShift}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Weekend Pay"
                                        name="formWithholdingsWeekend"
                                        id="formWithholdingsPremium-2"
                                        defaultChecked={
                                            this.props.formWithholdingsWeekend
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Holiday Pay"
                                        name="formWithholdingsHoliday"
                                        id="formWithholdingsPremium-3"
                                        defaultChecked={
                                            this.props.formWithholdingsHoliday
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="On-Call Pay"
                                        name="formWithholdingsOnCall"
                                        id="formWithholdingsPremium-4"
                                        defaultChecked={this.props.formWithholdingsOnCall}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Hazard Pay"
                                        name="formWithholdingsHazard"
                                        id="formWithholdingsPremium-5"
                                        defaultChecked={
                                            this.props.formWithholdingsHazard
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Bi-Lingual Pay"
                                        name="formWithholdingsBiLingual"
                                        id="formWithholdingsPremium-6"
                                        defaultChecked={
                                            this.props.formWithholdingsBiLingual
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Skills-Based Pay"
                                        name="formWithholdingsSkills"
                                        id="formWithholdingsPremium-7"
                                        defaultChecked={
                                            this.props.formWithholdingsSkills
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formWithholdingsOther"
                                        id="formWithholdingsPremium-8"
                                        defaultChecked={this.props.formWithholdingsOther}
                                        />
                                    </Col>
                                </Form.Group>
                                    <div>
                                    {(this.props.formWithholdingsOther === true ||
                                        this.props.formWithholdingsOther === 1) && (
                                        <Form.Group controlId="formWithholdingsOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formWithholdingsOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formWithholdingsOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                    </div>

                                <Form.Group
                                    name="formVariablePay"
                                    as={Row}
                                    controlId="formVariablePay"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        83. What types of Variable Pay does your organization offer?
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Commission"
                                        name="formVariablePayCommission"
                                        id="formVariablePay-1"
                                        defaultChecked={this.props.formVariablePayCommission}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Profit Sharing"
                                        name="formVariablePayProfit"
                                        id="formVariablePay-2"
                                        defaultChecked={
                                            this.props.formVariablePayProfit
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Referral Bonus"
                                        name="formVariablePayReferral"
                                        id="formVariablePay-3"
                                        defaultChecked={
                                            this.props.formVariablePayReferral
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Hiring Bonus"
                                        name="formVariablePayHiring"
                                        id="formVariablePay-4"
                                        defaultChecked={this.props.formVariablePayHiring}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Retention Bonus"
                                        name="formVariablePayRetention"
                                        id="formVariablePay-5"
                                        defaultChecked={
                                            this.props.formVariablePayRetention
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Project Completion Bonus"
                                        name="formVariablePayProject"
                                        id="formVariablePay-6"
                                        defaultChecked={
                                            this.props.formVariablePayProject
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Top Performance"
                                        name="formVariablePayPerformance"
                                        id="formVariablePay-7"
                                        defaultChecked={
                                            this.props.formVariablePayPerformance
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Appreciation"
                                        name="formVariablePayAppreciation"
                                        id="formVariablePay-8"
                                        defaultChecked={
                                            this.props.formVariablePayAppreciation
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formVariablePayOther"
                                        id="formVariablePay-9"
                                        defaultChecked={this.props.formVariablePayOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formVariablePayOther === true ||
                                        this.props.formVariablePayOther === 1) && (
                                        <Form.Group controlId="formVariablePayOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formVariablePayOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formVariablePayOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>
                                
                                <p>If Bonuses/Commissions are offered:</p>

                                <Form.Group className="ml-3" controlId="formVariablePayResponsible">
                                <Form.Label>
                                    i. Responsible person
                                </Form.Label>
                                <Form.Control
                                    name="formVariablePayResponsible"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formVariablePayResponsible || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formVariablePayApproval">
                                <Form.Label>
                                    ii. Approval process
                                </Form.Label>
                                <Form.Control
                                    name="formVariablePayApproval"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formVariablePayApproval || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formVariablePayTiming">
                                <Form.Label>
                                    iii. Timing
                                </Form.Label>
                                <Form.Control
                                    name="formVariablePayTiming"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formVariablePayTiming || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formVariablePayBasis">
                                <Form.Label>
                                    iv. Basis
                                </Form.Label>
                                <Form.Control
                                    name="formVariablePayBasis"
                                    as="textarea"
                                    rows="1"
                                    onChange={this.props.handleChange}
                                    value={this.props.formVariablePayBasis || ""}
                                />
                                </Form.Group>

                                <p>84. Which term best describes your annual employee salary increases, if any?</p>

                                <Form.Group
                                className="ml-3"
                                name="formIncreasesPerformance"
                                as={Row}
                                controlId="formIncreasesPerformance"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        i. Performance-based increase
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formIncreasesPerformance"
                                        id="formIncreasesPerformance-Yes"
                                        defaultChecked={
                                        this.props.formIncreasesPerformance === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formIncreasesPerformance"
                                        id="formIncreasesPerformance-No"
                                        defaultChecked={
                                        this.props.formIncreasesPerformance === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formIncreasesPerformance"
                                        id="formIncreasesPerformance-NA"
                                        defaultChecked={
                                        this.props.formIncreasesPerformance === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formIncreasesCostOfLiving"
                                as={Row}
                                controlId="formIncreasesCostOfLiving"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        ii. Cost-of-living increase
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formIncreasesCostOfLiving"
                                        id="formIncreasesCostOfLiving-Yes"
                                        defaultChecked={
                                        this.props.formIncreasesCostOfLiving === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formIncreasesCostOfLiving"
                                        id="formIncreasesCostOfLiving-No"
                                        defaultChecked={
                                        this.props.formIncreasesCostOfLiving === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formIncreasesCostOfLiving"
                                        id="formIncreasesCostOfLiving-NA"
                                        defaultChecked={
                                        this.props.formIncreasesCostOfLiving === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formIncreasesMarket"
                                as={Row}
                                controlId="formIncreasesMarket"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        iii. Market-driven increase
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formIncreasesMarket"
                                        id="formIncreasesMarket-Yes"
                                        defaultChecked={
                                        this.props.formIncreasesMarket === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formIncreasesMarket"
                                        id="formIncreasesMarket-No"
                                        defaultChecked={
                                        this.props.formIncreasesMarket === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formIncreasesMarket"
                                        id="formIncreasesMarket-NA"
                                        defaultChecked={
                                        this.props.formIncreasesMarket === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group controlId="formStarting">
                                <Form.Label>
                                    85. How are starting salaries determined?
                                </Form.Label>
                                <Form.Control
                                    name="formStarting"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formStarting || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPhilosophy">
                                <Form.Label>
                                    86. Compensation philosophy (i.e. pay above/below market)??
                                </Form.Label>
                                <Form.Control
                                    name="formPhilosophy"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPhilosophy || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                name="formCompDecision"
                                as={Row}
                                controlId="formCompDecision"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        87. Are compensation decisions based on a formal performance review, such as a merit matrix?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formCompDecision"
                                        id="formCompDecision-Yes"
                                        defaultChecked={
                                        this.props.formCompDecision === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formCompDecision"
                                        id="formCompDecision-No"
                                        defaultChecked={
                                        this.props.formCompDecision === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formCompDecision"
                                        id="formCompDecision-NA"
                                        defaultChecked={
                                        this.props.formCompDecision === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                                <p className="ml-3">If not:</p>

                                <Form.Group className="ml-5" controlId="formCompDecisionMethod">
                                <Form.Label>
                                    i. What method is utilized to determine compensation decisions?
                                </Form.Label>
                                <Form.Control
                                    name="formCompDecisionMethod"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formCompDecisionMethod || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPromotions">
                                <Form.Label>
                                    88. How are advancement and promotions determined?
                                </Form.Label>
                                <Form.Control
                                    name="formPromotions"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPromotions || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formChanges">
                                <Form.Label>
                                    89. How are salary changes communicated to employees?
                                </Form.Label>
                                <Form.Control
                                    name="formChanges"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formChanges || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                name="formTermination"
                                as={Row}
                                controlId="formTermination"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        90. Upon termination, is final pay issued according to federal/state final pay regulations?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formTermination"
                                        id="formTermination-Yes"
                                        defaultChecked={
                                        this.props.formTermination === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formTermination"
                                        id="formTermination-No"
                                        defaultChecked={
                                        this.props.formTermination === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formTermination"
                                        id="formTermination-NA"
                                        defaultChecked={
                                        this.props.formTermination === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                                <p>Unemployment Compensation</p>
                                
                                <Form.Group controlId="formUnemploymentResponsible">
                                <Form.Label>
                                    91. Responsible Individual
                                </Form.Label>
                                <Form.Control
                                    name="formUnemploymentResponsible"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formUnemploymentResponsible || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formUnemploymentManagement">
                                <Form.Label>
                                    92. Management Service 
                                </Form.Label>
                                <Form.Control
                                    name="formUnemploymentManagement"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formUnemploymentManagement || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formUnemploymentAccount">
                                <Form.Label>
                                    93. Account Number
                                </Form.Label>
                                <Form.Control
                                    name="formUnemploymentAccount"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formUnemploymentAccount || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formUnemploymentRespond">
                                <Form.Label>
                                    94. Respond online or by paper
                                </Form.Label>
                                <Form.Control
                                    name="formUnemploymentRespond"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formUnemploymentRespond || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formUnemploymentRecords">
                                <Form.Label>
                                    95. Records
                                </Form.Label>
                                <Form.Control
                                    name="formUnemploymentRecords"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formUnemploymentRecords || ""}
                                />
                                </Form.Group>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default Payroll;