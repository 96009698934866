import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

    
`

class PerformanceLead extends Component {
    


    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Performance Management</h5>
                    <hr />
                    <Form.Group
                      name="formPerformanceManagement"
                      as={Row}
                      controlId="formPerformanceManagement"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        10. How does your organization manage/monitor employee
                        performance? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="1:1 Employee/Manager Meetings"
                          name="formPerformanceManagement1on1"
                          id="formPerformanceManagement-1"
                          defaultChecked={
                            this.props.formPerformanceManagement1on1
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Annual Performance Reviews"
                          name="formPerformanceManagementAnnual"
                          id="formPerformanceManagement-2"
                          defaultChecked={
                            this.props.formPerformanceManagementAnnual
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="More Frequent Performance Reviews"
                          name="formPerformanceManagementReviews"
                          id="formPerformanceManagement-3"
                          defaultChecked={
                            this.props.formPerformanceManagementReviews
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Project Completion/Team Evaluations"
                          name="formPerformanceManagementTeam"
                          id="formPerformanceManagement-4"
                          defaultChecked={
                            this.props.formPerformanceManagementTeam
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Individual Goal Setting "
                          name="formPerformanceManagementGoals"
                          id="formPerformanceManagement-5"
                          defaultChecked={
                            this.props.formPerformanceManagementGoals
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="360 Reviews"
                          name="formPerformanceManagement360"
                          id="formPerformanceManagement-6"
                          defaultChecked={
                            this.props.formPerformanceManagement360
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceManagementOther"
                          id="formPerformanceManagement-7"
                          defaultChecked={
                            this.props.formPerformanceManagementOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceManagementOther === 1 ||
                        this.props.formPerformanceManagementOther === true) && (
                        <Form.Group controlId="formPerformanceManagementOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceManagementOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props.formPerformanceManagementOtherAnswer ||
                              ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    

                    <Form.Group
                      name="formPerformanceReviews2"
                      as={Row}
                      controlId="formPerformanceReviews2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        11. How consistently is the performance review process
                        used throughout the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-1"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-2"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-3"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-4"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-5"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-6"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 0
                        }
                      />
                    </Form.Group>

                    
                  </div>

                  <div className="form-section">
                    <h5>Goal Setting</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceGoals1"
                      as={Row}
                      controlId="formPerformanceGoals1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        12.	Are you employees and/or managers required to develop goals? 
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-1"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-2"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-3"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals2"
                      as={Row}
                      controlId="formPerformanceGoals2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        13. Are goals measured and tracked throughout the year?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-1"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-2"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-3"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "unknown"
                        }
                      />
                    </Form.Group>

                    
                  </div>

                  <div className="form-section">
                    <h5>Employee Development</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceDevelopment"
                      as={Row}
                      controlId="formPerformanceDevelopment"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        14. What learning opportunities does your organization
                        provide employees? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Tuition Reimbursement"
                          name="formPerformanceDevelopmentReimbursement"
                          id="formPerformanceDevelopment-1"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentReimbursement
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Tuition Discounts"
                          name="formPerformanceDevelopmentDiscount"
                          id="formPerformanceDevelopment-2"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentDiscount
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Corporate Universities"
                          name="formPerformanceDevelopmentUniversities"
                          id="formPerformanceDevelopment-3"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentUniversities
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="On-the-job Learning"
                          name="formPerformanceDevelopmentLearning"
                          id="formPerformanceDevelopment-4"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentLearning
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Attendance at Seminars and Conferences "
                          name="formPerformanceDevelopmentConference"
                          id="formPerformanceDevelopment-5"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentConference
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Access to Virtual Learning, Podcasts, Webinars"
                          name="formPerformanceDevelopmentVirtual"
                          id="formPerformanceDevelopment-6"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentVirtual
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Executive Coaching"
                          name="formPerformanceDevelopmentCoaching"
                          id="formPerformanceDevelopment-7"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentCoaching
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Mentoring Program"
                          name="formPerformanceDevelopmentMentoring"
                          id="formPerformanceDevelopment-8"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentMentoring
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Leadership Training Program"
                          name="formPerformanceDevelopmentLeadership"
                          id="formPerformanceDevelopment-9"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentLeadership
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Internship Program"
                          name="formPerformanceDevelopmentInternship"
                          id="formPerformanceDevelopment-10"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentInternship
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceDevelopmentOther"
                          id="formPerformanceDevelopment-11"
                          defaultChecked={
                            this.props.formPerformanceDevelopmentOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceDevelopmentOther === 1 ||
                        this.props.formPerformanceDevelopmentOther ===
                          true) && (
                        <Form.Group controlId="formPerformanceDevelopmentOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceDevelopmentOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props
                                .formPerformanceDevelopmentOtherAnswer || ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    
                  </div>

                  <div className="form-section">
                    <h5>Corrective Action Process (Managers / Leaders Only)</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceCorrective"
                      as={Row}
                      controlId="formPerformanceCorrective"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        15. What types of corrective action measures does the
                        organization utilize to correct and improve employee
                        performance? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Documented Verbal Warning"
                          name="formPerformanceCorrectiveVerbal"
                          id="formPerformanceCorrective-1"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveVerbal
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Written Reprimand"
                          name="formPerformanceCorrectiveWritten"
                          id="formPerformanceCorrective-2"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveWritten
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Temporary Pay Cut"
                          name="formPerformanceCorrectiveCut"
                          id="formPerformanceCorrective-3"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveCut
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Performance Improvement Plan"
                          name="formPerformanceCorrectivePlan"
                          id="formPerformanceCorrective-4"
                          defaultChecked={
                            this.props.formPerformanceCorrectivePlan
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Career Decision Day"
                          name="formPerformanceCorrectiveDecision"
                          id="formPerformanceCorrective-5"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveDecision
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Suspension"
                          name="formPerformanceCorrectiveSuspension"
                          id="formPerformanceCorrective-6"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveSuspension
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Demotion"
                          name="formPerformanceCorrectiveDemotion"
                          id="formPerformanceCorrective-7"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveDemotion
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Termination"
                          name="formPerformanceCorrectiveTermination"
                          id="formPerformanceCorrective-8"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveTermination
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPerformanceCorrectiveOther"
                          id="formPerformanceCorrective-9"
                          defaultChecked={
                            this.props.formPerformanceCorrectiveOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPerformanceCorrectiveOther === 1 ||
                        this.props.formPerformanceCorrectiveOther === true) && (
                        <Form.Group controlId="formPerformanceCorrectiveOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPerformanceCorrectiveOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props.formPerformanceCorrectiveOtherAnswer ||
                              ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    

                    <Form.Group
                      name="formPerformanceCorrective2"
                      as={Row}
                      controlId="formPerformanceCorrective2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        16. Do you have a corrective action (also called a
                        progressive discipline) policy?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceCorrective2"
                        id="formPerformanceCorrective2-1"
                        defaultChecked={
                          this.props.formPerformanceCorrective2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceCorrective2"
                        id="formPerformanceCorrective2-2"
                        defaultChecked={
                          this.props.formPerformanceCorrective2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceCorrective2"
                        id="formPerformanceCorrective2-3"
                        defaultChecked={
                          this.props.formPerformanceCorrective2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceCorrective3"
                      as={Row}
                      controlId="formPerformanceCorrective3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        17. Are any employees currently being managed through the
                        corrective action process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-1"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-2"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-3"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceCorrective4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceCorrective4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceCorrective4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  
                  <div className="form-section">
                    <h5>Job Descriptions</h5>
                                <hr />
                                
                    <Form.Group controlId="formPerformanceJobDescriptions4">
                      <Form.Label>18. What is the process for ensuring job descriptions are up-to-date?</Form.Label>
                      <Form.Control
                        name="formPerformanceJobDescriptions4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceJobDescriptions4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                                
                    <Form.Group controlId="formPerformanceJobDescriptions5">
                      <Form.Label>19. Are job descriptions for employees are current and up to date?</Form.Label>
                      <Form.Control
                        name="formPerformanceJobDescriptions5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceJobDescriptions5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceJobDescriptions3">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceJobDescriptions3"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceJobDescriptions3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PerformanceLead;