import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
   
`

class PoliciesSupervisor extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Employee Handbook</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyHandbook1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyHandbook1"
                    >
                      <Form.Label column sm={5}>
                        38. How useful is the current Employee
                        Handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-1"
                        defaultChecked={this.props.formPolicyHandbook1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-2"
                        defaultChecked={this.props.formPolicyHandbook1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-3"
                        defaultChecked={this.props.formPolicyHandbook1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-4"
                        defaultChecked={this.props.formPolicyHandbook1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-5"
                        defaultChecked={this.props.formPolicyHandbook1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyHandbook1"
                        id="formPolicyHandbook1-6"
                        defaultChecked={this.props.formPolicyHandbook1 === 0}
                      />
                    </Form.Group>

                   

                    <Form.Group
                      name="formPolicyHandbook3"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyHandbook3"
                    >
                      <Form.Label column sm={5}>
                        39. How confident are you, that you know where your copy
                        of the handbook is located?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-1"
                        defaultChecked={this.props.formPolicyHandbook3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-2"
                        defaultChecked={this.props.formPolicyHandbook3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-3"
                        defaultChecked={this.props.formPolicyHandbook3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-4"
                        defaultChecked={this.props.formPolicyHandbook3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-5"
                        defaultChecked={this.props.formPolicyHandbook3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-6"
                        defaultChecked={this.props.formPolicyHandbook3 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook7">
                      <Form.Label>40. What types of improvements could be made to the Employee Handbook? </Form.Label>
                      <Form.Control
                        name="formPolicyHandbook7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook7 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Onboarding Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyOnboarding1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding1"
                    >
                      <Form.Label column sm={5}>
                        41.	How effective is the onboarding process/new hire orientation for new employees?   
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-1"
                        defaultChecked={this.props.formPolicyOnboarding1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-2"
                        defaultChecked={this.props.formPolicyOnboarding1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-3"
                        defaultChecked={this.props.formPolicyOnboarding1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-4"
                        defaultChecked={this.props.formPolicyOnboarding1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-5"
                        defaultChecked={this.props.formPolicyOnboarding1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-6"
                        defaultChecked={this.props.formPolicyOnboarding1 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding2"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding2"
                    >
                      <Form.Label column sm={5}>
                        42. How satisfied are you with the topics/information
                        covered during orientation?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-1"
                        defaultChecked={this.props.formPolicyOnboarding2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-2"
                        defaultChecked={this.props.formPolicyOnboarding2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-3"
                        defaultChecked={this.props.formPolicyOnboarding2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-4"
                        defaultChecked={this.props.formPolicyOnboarding2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-5"
                        defaultChecked={this.props.formPolicyOnboarding2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-6"
                        defaultChecked={this.props.formPolicyOnboarding2 === 0}
                      />
                    </Form.Group>

                    
                    <hr />

                    <Form.Group
                      name="formPolicyOnboarding4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding4"
                    >
                      <Form.Label column sm={5}>
                        43. How long have you been with the organization?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Less than a year"
                          value="Less than a year"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-1"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than a year"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Less than 5 years"
                          value="Less than 5 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-2"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than 5 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="5-10 years"
                          value="5-10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-3"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 === "5-10 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="More than 10 years"
                          value="More than 10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-4"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "More than 10 years"
                          }
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding"
                      as={Row}
                      controlId="formPolicyOnboarding"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        44.	What does new employee orientation look like for employees in your department?  
                        Check all that apply...
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Non-existent"
                          name="formPolicyOnboardingNonExistent"
                          id="formPolicyOnboarding-1"
                          defaultChecked={
                            this.props.formPolicyOnboardingNonExistent
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Informal with my Manager/Supervisor"
                          name="formPolicyOnboardingInformalManager"
                          id="formPolicyOnboarding-2"
                          defaultChecked={
                            this.props.formPolicyOnboardingInformalManager
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal with my Manager/Supervisor"
                          name="formPolicyOnboardingFormalManager"
                          id="formPolicyOnboarding-3"
                          defaultChecked={
                            this.props.formPolicyOnboardingFormalManager
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal with HR"
                          name="formPolicyOnboardingFormalHR"
                          id="formPolicyOnboarding-4"
                          defaultChecked={
                            this.props.formPolicyOnboardingFormalHR
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Informal with HR"
                          name="formPolicyOnboardingInformalHR"
                          id="formPolicyOnboarding-5"
                          defaultChecked={
                            this.props.formPolicyOnboardingInformalHR
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPolicyOnboardingOther"
                          id="formPolicyOnboarding-6"
                          defaultChecked={this.props.formPolicyOnboardingOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPolicyOnboardingOther === true ||
                        this.props.formPolicyOnboardingOther === 1) && (
                        <Form.Group controlId="formPolicyOnboardingOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPolicyOnboardingOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formPolicyOnboardingOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formPolicyOnboarding7"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding7"
                    >
                      <Form.Label as="legend" column sm={5}>
                        45. As a manager, what do you do to orient new employees?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Provide input when asked"
                          value="Provide input when asked"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-1"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 ===
                            "Provide input when asked"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Coach when I see there are knowledge gaps"
                          value="Coach when I see there are knowledge gaps"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-2"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 ===
                            "Coach when I see there are knowledge gaps"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Utilize other staff such as co-workers and HR"
                          value="Utilize other staff such as co-workers and HR"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-3"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 ===
                            "Utilize other staff such as co-workers and HRe"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Put together a formal orientation plan"
                          value="Put together a formal orientation plan"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-4"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 ===
                            "Put together a formal orientation plane"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="A combination of the above"
                          value="A combination of the above"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-5"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 ===
                            "A combination of the above"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formPolicyOnboarding7"
                          id="formPolicyOnboarding7-6"
                          defaultChecked={
                            this.props.formPolicyOnboarding7 === "Other"
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formPolicyOnboarding7 === "Other" && (
                        <Form.Group controlId="formPolicyOnboarding7Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPolicyOnboarding7Answer"
                            as="textarea"
                            rows="1"
                            value={this.props.formPolicyOnboarding7Answer || ""}
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formPolicyOnboarding8">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyOnboarding8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOnboarding8 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                            //
                            //
                            //
                            // */}

                  <div className="form-section">
                    <h5>Off-boarding Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyOffboarding1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOffboarding1"
                    >
                      <Form.Label column sm={5}>
                        46. How satisfied are you with the off-boarding process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-1"
                        defaultChecked={this.props.formPolicyOffboarding1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-2"
                        defaultChecked={this.props.formPolicyOffboarding1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-3"
                        defaultChecked={this.props.formPolicyOffboarding1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-4"
                        defaultChecked={this.props.formPolicyOffboarding1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-5"
                        defaultChecked={this.props.formPolicyOffboarding1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-6"
                        defaultChecked={this.props.formPolicyOffboarding1 === 0}
                      />
                    </Form.Group>

                    

                   <Form.Group controlId="formPolicyOffboarding9">
                      <Form.Label>
                        47. What is your current procedure to review liability
                        before terminating an employee?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding9 || ""}
                      />
                    </Form.Group>

                    
                  </div>

                  <div className="form-section">
                    <h5>Leave of Absence(LOA) Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyLOA1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyLOA1"
                    >
                      <Form.Label column sm={5}>
                        48. How satisfied are you with the leave of absence
                        policies and procedures?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-1"
                        defaultChecked={this.props.formPolicyLOA1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-2"
                        defaultChecked={this.props.formPolicyLOA1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-3"
                        defaultChecked={this.props.formPolicyLOA1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-4"
                        defaultChecked={this.props.formPolicyLOA1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-5"
                        defaultChecked={this.props.formPolicyLOA1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-6"
                        defaultChecked={this.props.formPolicyLOA1 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyLOA2">
                      <Form.Label>
                        49.	As a manager, have you ever had an employee with the need to take a leave of absence?  If so, tell us about that experience.  {" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyLOA2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyLOA3">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formPolicyLOA3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA3 || ""}
                      />
                    </Form.Group>
                  </div>

                  
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PoliciesSupervisor;