import React, { Component } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';
import debounce from 'debounce';

import IntroQuestions from './Questions/IntroQuestions';
import ConsultingQuestions from './Questions/ConsultingQuestions';
import TalentQuestions from './Questions/TalentQuestions';
import Team from './Team';
import ThankYou from './ThankYou';
import Header from './Header';

const API_URL = config.url.API_URL;

const Div = styled.div`
  background: ${(props) => props.theme.colors.white} !important;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    margin-left: 31px;
    margin-right: 31px;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }
`;

class ClientSatisfactionSurvey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHideModal: true,
      displayConsulting: false,
      displayTalent: false,
      configDone: false,
      displayThanks: false,
      displayTeam: true,
      displaySubmit: true,

      name: "",
      title: "",
      orgName: "",

      
      formCandace: false,
      formDenise: false,
      formGabriela: false,
      formHayley: false,
      formJazmin: false,
      formKacye: false,
      formKathy: false,
      formMercedes: false,
      formLauren: false,
      formLuke: false,
      formTracy: false,     

      formWhichServices: "",
      
      formHumanCapitalConsulting: false,
      formPayroll: false,
      formHandbook: false,
      formCompensation: false,
      formCoaching: false,
      formHCA: false,
      formSuccession: false,
      formStrengthsFinder: false,
      formTPA: false,
      formTraining: false,
      formServicesOther: false,
      formServicesOtherAnswer: " ",

      formTalentedConsultants: "",
      formConsultantUnderstands: "",
      formTimely: "",
      formSolutionAttitude: "",
      formFairPrice: "",
      formStakeholders: "",
      formCommunications: "",
      formProjects: "",
      formOwnership: "",
      formLearn: "",
      formExpectations: "",

      formConsultingRefer: "",
      formConsultingReferOtherAnswer: "",
      formConsultingMore: "",
      formConsultingAreas: "",
      formConsultingReasons: "",
      formConsultingQuote: "",

      // Talent Questions
      formPositions: "",
      formCandidates: "",
      formEfficiency: "",
      formTalentStakeholders: "",
      formTalentCommunications: "",
      formTalentMethod: "",
      formTalentPriorities: "",
      formTalentExpectations: "",
      formTalentRefer: "",
      formTalentReferOtherAnswer: "",
      formTalentApprove: "",
      formTalentReasons: "",
      formTalentQuote: " ",


      // Team
      
      formCandaceA_Rating: "",
      formCandaceA_Comments: "",
      formDeniseMF_Rating: "",
      formDeniseMF_Comments: "",
      formGabrielaN_Rating: "",
      formGabrielaN_Comments: "",
      formHayleyT_Rating: "",
      formHayleyT_Comments: "",
      formJazminK_Rating: "",
      formJazminK_Comments: "",
      formKacyeB_Rating: "",
      formKacyeB_Comments: "",
      formKathyB_Rating: "",
      formKathyB_Comments: "",
      
      formLaurenL_Rating: "",
      formLaurenL_Comments: "",
      formLukeB_Rating: "",
      formLukeB_Comments: "",
      formMercedesC_Rating: "",
      formMercedesC_Comments: "",
      formTracyM_Rating: "",
      formTracyM_Comments: ""

    }

    this.handleModalHide = this.handleModalHide.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeAsInt = this.handleChangeAsInt.bind(this);
  }

  handleModalHide() {
    this.setState({ showHideModal: false });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheck(e) {
    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
    }));
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChangeAsInt = (e) => {
    const targetInt = parseInt(e.target.value, 10);
    this.setState({ [e.target.name]: targetInt });
  }

  surveyConfig() {

    if (this.state.name !== "" && this.state.title !== "" && this.state.orgName !== "" && this.state.formWhichServices !== "") {
      this.setState({ configDone: true });
      this.handleModalHide();
    } else {
      console.log('fill in required fields');
    }
  }

  submitSurvey = debounce(() => {
    const data = {
      year: new Date().getFullYear(),
      name: this.state.name,
      title: this.state.title,
      orgName: this.state.orgName,
      formWhichServices: this.state.formWhichServices,
      formHumanCapitalConsulting: this.state.formHumanCapitalConsulting,
      formPayroll: this.state.formPayroll,
      formHandbook: this.state.formHandbook,
      formCompensation: this.state.formCompensation,
      formCoaching: this.state.formCoaching,
      formHCA: this.state.formHCA,
      formSuccession: this.state.formSuccession,
      formStrengthsFinder: this.state.formStrengthsFinder,
      formTPA: this.state.formTPA,
      formTraining: this.state.formTraining,
      formServicesOther: this.state.formServicesOther,
      formServicesOtherAnswer: this.state.formServicesOtherAnswer,
      formTalentedConsultants: this.state.formTalentedConsultants,
      formConsultantUnderstands: this.state.formConsultantUnderstands,
      formTimely: this.state.formTimely,
      formSolutionAttitude: this.state.formSolutionAttitude,
      formFairPrice: this.state.formFairPrice,
      formStakeholders: this.state.formStakeholders,
      formCommunications: this.state.formCommunications,
      formProjects: this.state.formProjects,
      formOwnership: this.state.formOwnership,
      formLearn: this.state.formLearn,
      formExpectations: this.state.formExpectations,
      formConsultingRefer: this.state.formConsultingRefer,
      formConsultingReferOtherAnswer: this.state.formConsultingReferOtherAnswer,
      formConsultingMore: this.state.formConsultingMore,
      formConsultingAreas: this.state.formConsultingAreas,
      formConsultingReasons: this.state.formConsultingReasons,
      formConsultingQuote: this.state.formConsultingQuote,
      // Talent Questions
      formPositions: this.state.formPositions,
      formCandidates: this.state.formCandidates,
      formEfficiency: this.state.formEfficiency,
      formTalentStakeholders: this.state.formTalentStakeholders,
      formTalentCommunications: this.state.formTalentCommunications,
      formTalentMethod: this.state.formTalentMethod,
      formTalentPriorities: this.state.formTalentPriorities,
      formTalentExpectations: this.state.formTalentExpectations,
      formTalentRefer: this.state.formTalentRefer,
      formTalentReferOtherAnswer: this.state.formTalentReferOtherAnswer,
      formTalentApprove: this.state.formTalentApprove,
      formTalentReasons: this.state.formTalentReasons,
      formTalentQuote: this.state.formTalentQuote,
      // Team
      
      formCandaceA_Rating: this.state.formCandaceA_Rating,
      formCandaceA_Comments: this.state.formCandaceA_Comments,
      formDeniseMF_Rating: this.state.formDeniseMF_Rating,
      formDeniseMF_Comments: this.state.formDeniseMF_Comments,
      formGabrielaN_Rating: this.state.formGabrielaN_Rating,
      formGabrielaN_Comments: this.state.formGabrielaN_Comments,
      formHayleyT_Rating: this.state.formHayleyT_Rating,
      formHayleyT_Comments: this.state.formHayleyT_Comments,
      formJazminK_Rating: this.state.formJazminK_Rating,
      formJazminK_Comments: this.state.formJazminK_Comments,
      formKacyeB_Rating: this.state.formKacyeB_Rating,
      formKacyeB_Comments: this.state.formKacyeB_Comments,
      formKathyB_Rating: this.state.formKathyB_Rating,
      formKathyB_Comments: this.state.formKathyB_Comments,
      formLaurenL_Rating: this.state.formLaurenL_Rating,
      formLaurenL_Comments: this.state.formLaurenL_Comments,
      formLukeB_Rating: this.state.formLukeB_Rating,
      formLukeB_Comments: this.state.formLukeB_Comments,
      formMercedesC_Rating: this.state.formMercedesC_Rating,
      formMercedesC_Comments: this.state.formMercedesC_Comments,
      formTracyM_Rating: this.state.formTracyM_Rating,
      formTracyM_Comments: this.state.formTracyM_Comments,
    }
    this.writeSurvey(data);
  }, 1000);

  
  
  writeSurvey(data) {
    fetch(API_URL + `/css`, {
        method: "PUT",
        body: JSON.stringify({
          data: data,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
      .then((data) => console.log(data))
      .then(this.setState({
        displayThanks: true,
        displayConsulting: false,
        displayTalent: false,
        displayTeam: false,
        displaySubmit: false,
        configDone: false,

      }))
        .catch((err) => console.log(err))
  }


    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <Div>
                
                {
                   this.state.configDone === true ?
                    <Header /> : null
                }

                {
                  (this.state.formWhichServices === "consulting" || this.state.formWhichServices === "all") && this.state.configDone === true ?
                    <ConsultingQuestions
                              handleChange={this.handleChange}
                              handleCheck={this.handleCheck}
                              handleChangeAsInt={this.handleChangeAsInt}
                                {...this.state}
                          /> : null
                }


                {
                  (this.state.formWhichServices === "talent" || this.state.formWhichServices === "all") && this.state.configDone === true ?
                    <TalentQuestions
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                    /> : null
                }

                {
                  this.state.displayTeam === true && this.state.configDone === true ?
                    <Team
                      handleChange={this.handleChange}
                      handleCheck={this.handleCheck}
                      handleChangeAsInt={this.handleChangeAsInt}
                      {...this.state}
                    /> : null
                }
                
                {
                  this.state.displaySubmit === true && this.state.configDone === true ?
                    <Div className="text-right">
                      <Button
                        className="m-4"
                        size="sm"
                        variant="primary"
                        onClick={() => this.submitSurvey()}
                      >
                        Submit
                      </Button>
                    </Div> : null
                }

                {this.state.displayThanks === true ? 
                  <ThankYou /> : null
                }
                        
                
                  {/* //Modal
                  //Modal 
                  //Modal */}
                    <Modal show={this.state.showHideModal} size="lg" centered>
                      <Modal.Header>
                        <Modal.Title>Client Satisfaction Survey</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <IntroQuestions
                          handleChange={this.handleChange}
                          handleCheck={this.handleCheck}
                              {...this.state}
                        />
                    
                    {
                      this.state.name === "" || this.state.title === "" || this.state.orgName === "" || this.state.formWhichServices === "" ? <Div className="text-right">
                              <Button
                                className="m-4"
                                size="lg"
                                variant="secondary"
                                disabled
                              >
                                Begin Survey
                              </Button>
                      </Div> :
                      <Div className="text-right">
                        <Button
                          className="m-4"
                          size="lg"
                          variant="primary"
                          onClick={() => this.surveyConfig()}
                        >
                          Begin Survey
                        </Button>
                      </Div>
                    }
                      </Modal.Body>
                    </Modal>

                  </Div>
                </Container>
            </ThemeProvider>
        )
    }
}

export default ClientSatisfactionSurvey;