import React, { Component } from 'react';
import {Form, Container, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div `
    background-color: white;
    color: black;

`

class GeneralLead extends Component {
    
  render() {
    
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container className="8,p-0">
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>General Information</h5>
                    <hr />
                    <Form.Group controlId="organizationName">
                      <Form.Label>Organization</Form.Label>
                      <Form.Control
                        name="organization"
                        type="text"
                        placeholder="Organization"
                        onChange={this.props.handleChange}
                        value={this.props.organization || ""}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name="address"
                        type="text"
                        placeholder="Address"
                        onChange={this.props.handleChange}
                        value={this.props.address || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="website">
                      <Form.Label>Website</Form.Label>
                      <Form.Control
                        name="website"
                        type="text"
                        placeholder="Website"
                        onChange={this.props.handleChange}
                        value={this.props.website || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="industry">
                      <Form.Label>Industry</Form.Label>
                      <Form.Control
                        name="industry"
                        type="text"
                        placeholder="Industry"
                        onChange={this.props.handleChange}
                        value={this.props.industry || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="co_status">
                      <Form.Label>Corporate Status</Form.Label>
                      <Form.Control
                        name="co_status"
                        as="select"
                        onChange={this.props.handleChange}
                        value={this.props.co_status || ""}
                        required
                      >
                        <option>Choose...</option>
                        <option>Public</option>
                        <option>Private</option>
                        <option>Non-Profit</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="mission">
                      <Form.Label>Mission</Form.Label>
                      <Form.Control
                        name="mission"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.mission || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Head Count</h5>
                    <hr />
                    <Form.Group as={Row} controlId="fulltime">
                      <Form.Label column sm={2}>
                        Full-Time:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_fulltime"
                          type="text"
                          placeholder="Full-Time"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_fulltime || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="parttime">
                      <Form.Label column sm={2}>
                        Part-Time:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_parttime"
                          type="text"
                          placeholder="Part-Time"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_parttime || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="temporary">
                      <Form.Label column sm={2}>
                        Temporary:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_temp"
                          type="text"
                          placeholder="Temporary"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_temp || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="exempt">
                      <Form.Label column sm={2}>
                        Exempt:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_exempt"
                          type="text"
                          placeholder="Exempt"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_exempt || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="non-exempt">
                      <Form.Label column sm={2}>
                        Non-Exempt:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_nonexempt"
                          type="text"
                          placeholder="Non-Exempt"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_nonexempt || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="interns">
                      <Form.Label column sm={2}>
                        Interns:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_interns"
                          type="text"
                          placeholder="Interns"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_interns || 0}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="consultants">
                      <Form.Label column sm={2}>
                        Consultants/1099:
                      </Form.Label>
                      <Col sm={2}>
                        <Form.Control
                          name="headcount_consultants"
                          type="text"
                          placeholder="Consultants/1099"
                          onChange={this.props.handleChange}
                          value={this.props.headcount_consultants || 0}
                        />
                      </Col>
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Locations</h5>
                    <hr />
                    <Form.Group controlId="locations">
                      <Form.Label>
                        In what cities/states do you operate/have employees, and how many employees to you have in each location?
                      </Form.Label>
                      <Form.Control
                        name="locations"
                        as="textarea"
                        rows="3"
                        onChange={this.props.handleChange}
                        value={this.props.locations || ""}
                      />
                    </Form.Group>
                   
                  </div>

                  <div className="form-section">
                    <h5>Senior Leader</h5>
                    <hr />
                    <Form.Row>
                      <Form.Group as={Col} controlId="lead-title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="seniorlead_title"
                          onChange={this.props.handleChange}
                          value={this.props.seniorlead_title || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="lead-name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="seniorlead_name"
                          onChange={this.props.handleChange}
                          value={this.props.seniorlead_name || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="lead-email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="seniorlead_email"
                          onChange={this.props.handleChange}
                          value={this.props.seniorlead_email || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="lead-phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          name="seniorlead_phone"
                          onChange={this.props.handleChange}
                          value={this.props.seniorlead_phone || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <div className="form-section">
                    <h4>Main Point of Contact(if different than above)</h4>
                    <hr />
                    <Form.Row>
                      <Form.Group as={Col} controlId="main-title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="pointofcontact_title"
                          onChange={this.props.handleChange}
                          value={this.props.pointofcontact_title || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="main-name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="pointofcontact_name"
                          onChange={this.props.handleChange}
                          value={this.props.pointofcontact_name || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="main-email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="pointofcontact_email"
                          onChange={this.props.handleChange}
                          value={this.props.pointofcontact_email || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="main-phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          name="pointofcontact_phone"
                          onChange={this.props.handleChange}
                          value={this.props.pointofcontact_phone || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <div className="form-section">
                    <h4>Human Resources Lead (if different than above)</h4>
                    <hr />
                    <Form.Row>
                      <Form.Group as={Col} controlId="hr-title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="hrlead_title"
                          onChange={this.props.handleChange}
                          value={this.props.hrlead_title || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="hr-name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="hrlead_name"
                          onChange={this.props.handleChange}
                          value={this.props.hrlead_name || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="hr-email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="hrlead_email"
                          onChange={this.props.handleChange}
                          value={this.props.hrlead_email || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="hr-phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          name="hrlead_phone"
                          onChange={this.props.handleChange}
                          value={this.props.hrlead_phone || ""}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>

                  <div className="form-section">
                    <h4>Partners</h4>
                    <hr />
                    <Form.Group controlId="broker-payroll">
                      <Form.Label>Payroll Service Provider / HRIS</Form.Label>
                      <Form.Control
                        name="payroll_provider"
                        onChange={this.props.handleChange}
                        type="text"
                        placeholder="Payroll Provider"
                        value={this.props.payroll_provider || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="broker-benefits">
                      <Form.Label>Benefits Broker</Form.Label>
                      <Form.Control
                        name="benefits_broker"
                        onChange={this.props.handleChange}
                        type="text"
                        placeholder="Benefits Broker"
                        value={this.props.benefits_broker || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="broker-retirement">
                      <Form.Label>Retirement/401k Administrator</Form.Label>
                      <Form.Control
                        name="retirement_admin"
                        onChange={this.props.handleChange}
                        type="text"
                        placeholder="Retirement/401k Administrator"
                        value={this.props.retirement_admin || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="broker-cobra">
                      <Form.Label>COBRA Administrator</Form.Label>
                      <Form.Control
                        name="COBRA_admin"
                        onChange={this.props.handleChange}
                        type="text"
                        placeholder="COBRA Administrator"
                        value={this.props.COBRA_admin || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="workers-comp">
                      <Form.Label>Workers' Injury/Workers' Compensation Insurance Provider</Form.Label>
                      <Form.Control
                        name="workers_comp"
                        onChange={this.props.handleChange}
                        type="text"
                        placeholder="Workers' Injury/Workers' Compensation"
                        value={this.props.workers_comp || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default GeneralLead;