import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class HoursOfWork extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Hours of Work</h1>
                    <p>{this.props.clientName} is generally open from 8:30 a.m. to 5:00 p.m., Monday through Friday.  It is the policy of {this.props.clientName} that the office will be covered during these hours to ensure that calls are answered and that both expected and unexpected visitors are met.  This means that employees will need to seek approval from their supervisor and delegate appropriate coverage during lunch and at other times when employees may be out of the office.  Exceptions to this policy may be approved by the President and CEO.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default HoursOfWork;
