import React, { Component } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

    
`

class PerformanceEmployee extends Component {
    


    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Performance Management</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceReviews1"
                      as={Row}
                      controlId="formPerformanceReviews1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        14.	How effective is the performance review process at providing you effective feedback? 
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-1"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-2"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-3"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-4"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-5"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-6"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 0
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Goal Setting</h5>
                    <hr />

                    <Form.Group
                      name="formPerformanceGoals1"
                      as={Row}
                      controlId="formPerformanceGoals1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        15. Are you required to develop goals for yourself?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-1"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-2"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals1"
                        id="formPerformanceGoals1-3"
                        defaultChecked={
                          this.props.formPerformanceGoals1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals2"
                      as={Row}
                      controlId="formPerformanceGoals2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        16. Are goals measured and tracked throughout the year?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-1"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-2"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceGoals2"
                        id="formPerformanceGoals2-3"
                        defaultChecked={
                          this.props.formPerformanceGoals2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceGoals3"
                      as={Row}
                      controlId="formPerformanceGoals3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        17. How effective is your goal setting process at
                        achieving outcomes?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-1"
                        defaultChecked={this.props.formPerformanceGoals3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-2"
                        defaultChecked={this.props.formPerformanceGoals3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-3"
                        defaultChecked={this.props.formPerformanceGoals3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-4"
                        defaultChecked={this.props.formPerformanceGoals3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-5"
                        defaultChecked={this.props.formPerformanceGoals3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-6"
                        defaultChecked={this.props.formPerformanceGoals3 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals4"
                      as={Row}
                      controlId="formPerformanceGoals4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        18. How in line are the organization/department and
                        individual goals?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-1"
                        defaultChecked={this.props.formPerformanceGoals4 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-2"
                        defaultChecked={this.props.formPerformanceGoals4 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-3"
                        defaultChecked={this.props.formPerformanceGoals4 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-4"
                        defaultChecked={this.props.formPerformanceGoals4 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-5"
                        defaultChecked={this.props.formPerformanceGoals4 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-6"
                        defaultChecked={this.props.formPerformanceGoals4 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceGoals5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceGoals5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceGoals5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Employee Development</h5>
                    <hr />


                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    
                    <Form.Group
                      name="formPerformanceDevelopment1"
                      as={Row}
                      controlId="formPerformanceDevelopment1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        19.	How satisfied are you with professional growth and development opportunities provided?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-1"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-2"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-3"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-4"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-5"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-6"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceDevelopment5">
                      <Form.Label>20.	What improvements, if any, would you like to see made to your professional growth and development?</Form.Label>
                      <Form.Control
                        name="formPerformanceDevelopment5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceDevelopment5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <hr />
                    

                    <Form.Group
                      name="formPerformanceDevelopment3"
                      as={Row}
                      controlId="formPerformanceDevelopment3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        21. Does your manager provide you with regular, ongoing
                        feedback?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-1"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-2"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceDevelopment3"
                        id="formPerformanceDevelopment3-3"
                        defaultChecked={
                          this.props.formPerformanceDevelopment3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceDevelopment4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceDevelopment4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceDevelopment4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  

                  <div className="form-section">
                    <h5>Job Descriptions</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceJobDescriptions1"
                      as={Row}
                      controlId="formPerformanceJobDescriptions1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        22. Are your job responsibilities accurately reflected in your job description?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-1"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-2"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-3"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-4"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-5"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceJobDescriptions1"
                        id="formPerformanceJobDescriptions1-6"
                        defaultChecked={
                          this.props.formPerformanceJobDescriptions1 === 0
                        }
                      />
                    </Form.Group>

                    

                    <Form.Group controlId="formPerformanceJobDescriptions3">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceJobDescriptions3"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceJobDescriptions3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PerformanceEmployee;