import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';


class StatsMercedesC extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teamMember: "MercedesC",
            filteredList: [],

            //Consulting
            talentedConScore: 0,
            talentedConDiv: 0,
            conUnderstandingScore: 0,
            conUnderstandingDiv: 0,
            timelyDelScore: 0,
            timelyDelDiv: 0,
            solutionScore: 0,
            solutionScoreDiv: 0,
            fairPriceScore: 0,
            fairPriceDiv: 0,
            stakeholderScore: 0,
            stakeholderDiv: 0,
            timelyCommScore: 0,
            timelyCommDiv: 0,
            customScore: 0,
            customDiv: 0,
            ownershipScore: 0,
            ownershipDiv: 0,
            learnScore: 0,
            learnDiv: 0,
            overallValScore: 0,
            overallValDiv: 0
        }
    }

    componentDidMount() {
        const companies = this.props.surveyData;
        const partialList = companies.filter(company => company.formMercedesC_Rating > 0);
        this.setState({ filteredList: partialList}, () => this.calculateScores());
    }

    calculateScores() {
        console.log(`Calculating scores for ${this.state.teamMember}...`);
        const result = this.state.filteredList;

        let talentedConScore = 0;
        let talentedConDiv = 0;
        let conUnderstandingScore = 0;
        let conUnderstandingDiv = 0;
        let timelyDelScore = 0;
        let timelyDelDiv = 0;
        let solutionScore = 0;
        let solutionScoreDiv = 0;
        let fairPriceScore = 0;
        let fairPriceDiv = 0;
        let stakeholderScore = 0;
        let stakeholderDiv = 0;
        let timelyCommScore = 0;
        let timelyCommDiv = 0;
        let customScore = 0;
        let customDiv = 0;
        let ownershipScore = 0;
        let ownershipDiv = 0;
        let learnScore = 0;
        let learnDiv = 0;
        let overallValScore = 0;
        let overallValDiv = 0;

        for (let i = 0; i < result.length; i++){
            talentedConScore = talentedConScore + +result[i].formTalentedConsultants;
            conUnderstandingScore = conUnderstandingScore + +result[i].formConsultantUnderstands;
            timelyDelScore = timelyDelScore + +result[i].formTimely;
            solutionScore = solutionScore + +result[i].formSolutionAttitude;
            fairPriceScore = fairPriceScore + +result[i].formFairPrice;
            stakeholderScore = stakeholderScore + +result[i].formStakeholders;
            timelyCommScore = timelyCommScore + +result[i].formCommunications;
            customScore = customScore + +result[i].formProjects;
            ownershipScore = ownershipScore + +result[i].formOwnership;
            learnScore = learnScore + +result[i].formLearn;
            overallValScore = overallValScore + +result[i].formExpectations;
            
                    if (result[i].formTalentedConsultants !== "") {
                        talentedConDiv = talentedConDiv + 1;
                    }
                    if (result[i].formConsultantUnderstands !== "") {
                        conUnderstandingDiv = conUnderstandingDiv + 1;
                    }
                    if (result[i].formTimely !== "") {
                        timelyDelDiv = timelyDelDiv + 1;
                    }
                    if (result[i].formSolutionAttitude !== "") {
                        solutionScoreDiv = solutionScoreDiv + 1;
                    }
                    if (result[i].formFairPrice !== "") {
                        fairPriceDiv = fairPriceDiv + 1;
                    }
                    if (result[i].formStakeholders !== "") {
                        stakeholderDiv = stakeholderDiv + 1;
                    }
                    if (result[i].formCommunications !== "") {
                        timelyCommDiv = timelyCommDiv + 1;
                    }
                    if (result[i].formProjects !== "") {
                        customDiv = customDiv + 1;
                    }
                    if (result[i].formOwnership !== "") {
                        ownershipDiv = ownershipDiv + 1;
                    }
                    if (result[i].formLearn !== "") {
                        learnDiv = learnDiv + 1;
                    }
                    if (result[i].formExpectations !== "") {
                        overallValDiv = overallValDiv + 1;
            }
            
            this.setState({
                        talentedConScore: talentedConScore,
                        talentedConDiv: talentedConDiv,
                        conUnderstandingScore: conUnderstandingScore,
                        conUnderstandingDiv: conUnderstandingDiv,
                        timelyDelScore: timelyDelScore,
                        timelyDelDiv: timelyDelDiv,
                        solutionScore: solutionScore,
                        solutionScoreDiv: solutionScoreDiv,
                        fairPriceScore: fairPriceScore,
                        fairPriceDiv: fairPriceDiv,
                        stakeholderScore: stakeholderScore,
                        stakeholderDiv: stakeholderDiv,
                        timelyCommScore: timelyCommScore,
                        timelyCommDiv: timelyCommDiv,
                        customScore: customScore,
                        customDiv: customDiv,
                        ownershipScore: ownershipScore,
                        ownershipDiv: ownershipDiv,
                        learnScore: learnScore,
                        learnDiv: learnDiv,
                        overallValScore: overallValScore,
                        overallValDiv: overallValDiv

                        
                    })
        }

    }

    render() {
        return (
            <div className='p-2'>
                <Table striped bordered variant='dark'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Client</th>
                            <th>Respondent</th>
                            <th>Score</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.filteredList.map((client) => (
                                <tr key={client.id}>
                                    <td>{client.orgName}</td>
                                    <td>{client.name}</td>
                                    <td>{client.formMercedesC_Rating}</td>
                                    <td>{client.formMercedesC_Comments}</td>
                                </tr>
                            ))

                            
                        }
                    </tbody>
                </Table>

                <h4>Consulting Categories - Client Ratings</h4>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Talented</div>
                                    <div>Consultants</div>
                                    {
                                        this.state.talentedConScore > 0 ? <h4>{(this.state.talentedConScore/this.state.talentedConDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Consultant</div>
                                    <div>Understanding</div>
                                    {
                                        this.state.conUnderstandingScore > 0 ? <h4>{(this.state.conUnderstandingScore/this.state.conUnderstandingDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Timely</div>
                                    <div>Delivery</div>
                                    {
                                        this.state.timelyDelScore > 0 ? <h4>{(this.state.timelyDelScore/this.state.timelyDelDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Solution</div>
                                    <div>Oriented</div>
                                    {
                                        this.state.solutionScore > 0 ? <h4>{(this.state.solutionScore/this.state.solutionScoreDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Fair</div>
                                    <div>Price</div>
                                    {
                                        this.state.fairPriceScore > 0 ? <h4>{(this.state.fairPriceScore/this.state.fairPriceDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Stakeholder</div>
                                    <div>Cooperation</div>
                                    {
                                        this.state.stakeholderScore > 0 ? <h4>{(this.state.stakeholderScore/this.state.stakeholderDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Timely</div>
                                    <div>Communication</div>
                                    {
                                        this.state.timelyCommScore > 0 ? <h4>{(this.state.timelyCommScore/this.state.timelyCommDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Project</div>
                                    <div>Customization</div>
                                    {
                                        this.state.customScore > 0 ? <h4>{(this.state.customScore/this.state.customDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center score-cards-sml">
                                    <div>Ownership</div>
                                    <div>Opportunities</div>
                                    {
                                        this.state.ownershipScore > 0 ? <h4>{(this.state.ownershipScore/this.state.ownershipDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Learns</div>
                                    <div>Culture</div>
                                    {
                                        this.state.learnScore > 0 ? <h4>{(this.state.learnScore/this.state.learnDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                                <Col className="text-center score-cards-sml">
                                    <div>Overall</div>
                                    <div>Value</div>
                                    {
                                        this.state.overallValScore > 0 ? <h4>{(this.state.overallValScore/this.state.overallValDiv).toFixed(2)}</h4> : <h4>--</h4>
                                    }
                                </Col>
                            </Row>
            </div>
        )
    }
}

export default StatsMercedesC;