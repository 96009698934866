import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
   
`

class PoliciesExec extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Employee Handbook</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    

                    <Form.Group
                      name="formPolicyHandbook2"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyHandbook2"
                    >
                      <Form.Label column sm={5}>
                        34. How effective is your Employee Handbook at
                        communicating important company information about
                        policies and benefits?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-1"
                        defaultChecked={this.props.formPolicyHandbook2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-2"
                        defaultChecked={this.props.formPolicyHandbook2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-3"
                        defaultChecked={this.props.formPolicyHandbook2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-4"
                        defaultChecked={this.props.formPolicyHandbook2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-5"
                        defaultChecked={this.props.formPolicyHandbook2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-6"
                        defaultChecked={this.props.formPolicyHandbook2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyHandbook3"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyHandbook3"
                    >
                      <Form.Label column sm={5}>
                        35.	How confident are you, that employees know where to locate a copy of the handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-1"
                        defaultChecked={this.props.formPolicyHandbook3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-2"
                        defaultChecked={this.props.formPolicyHandbook3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-3"
                        defaultChecked={this.props.formPolicyHandbook3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-4"
                        defaultChecked={this.props.formPolicyHandbook3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-5"
                        defaultChecked={this.props.formPolicyHandbook3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyHandbook3"
                        id="formPolicyHandbook3-6"
                        defaultChecked={this.props.formPolicyHandbook3 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook7">
                      <Form.Label>36. What types of improvements could be made to the Employee Handbook?</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook7 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Onboarding Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyOnboarding1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding1"
                    >
                      <Form.Label column sm={5}>
                        37. How effective is your onboarding program in engaging
                        and retaining employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-1"
                        defaultChecked={this.props.formPolicyOnboarding1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-2"
                        defaultChecked={this.props.formPolicyOnboarding1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-3"
                        defaultChecked={this.props.formPolicyOnboarding1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-4"
                        defaultChecked={this.props.formPolicyOnboarding1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-5"
                        defaultChecked={this.props.formPolicyOnboarding1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding1"
                        id="formPolicyOnboarding1-6"
                        defaultChecked={this.props.formPolicyOnboarding1 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding2"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding2"
                    >
                      <Form.Label column sm={5}>
                        38. How satisfied are you with the topics/information
                        covered during orientation?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-1"
                        defaultChecked={this.props.formPolicyOnboarding2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-2"
                        defaultChecked={this.props.formPolicyOnboarding2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-3"
                        defaultChecked={this.props.formPolicyOnboarding2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-4"
                        defaultChecked={this.props.formPolicyOnboarding2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-5"
                        defaultChecked={this.props.formPolicyOnboarding2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-6"
                        defaultChecked={this.props.formPolicyOnboarding2 === 0}
                      />
                    </Form.Group>

                    
                    <Form.Group controlId="formPolicyOnboarding10">
                      <Form.Label>39. What types of improvements could be made to the onboarding process in the organization?</Form.Label>
                      <Form.Control
                        name="formPolicyOnboarding10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOnboarding10 || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding4"
                    >
                      <Form.Label column sm={5}>
                        4. How long have you been with the organization?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Less than a year"
                          value="Less than a year"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-1"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than a year"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Less than 5 years"
                          value="Less than 5 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-2"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than 5 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="5-10 years"
                          value="5-10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-3"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 === "5-10 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="More than 10 years"
                          value="More than 10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-4"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "More than 10 years"
                          }
                        />
                      </Col>
                    </Form.Group>

                    
                  </div>

                  {/* //
                            //
                            //
                            //
                            // */}

                  <div className="form-section">
                    <h5>Off-boarding Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyOffboarding1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOffboarding1"
                    >
                      <Form.Label column sm={5}>
                        41. How satisfied are you with the off-boarding process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-1"
                        defaultChecked={this.props.formPolicyOffboarding1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-2"
                        defaultChecked={this.props.formPolicyOffboarding1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-3"
                        defaultChecked={this.props.formPolicyOffboarding1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-4"
                        defaultChecked={this.props.formPolicyOffboarding1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-5"
                        defaultChecked={this.props.formPolicyOffboarding1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOffboarding1"
                        id="formPolicyOffboarding1-6"
                        defaultChecked={this.props.formPolicyOffboarding1 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyOffboarding8">
                      <Form.Label>
                        42.	What types of improvements could be made to the off-boarding process in the organization?  
                      </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding8 || ""}
                      />
                    </Form.Group>

                    
                    <Form.Group controlId="formPolicyOffboarding11">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formPolicyOffboarding11"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyOffboarding11 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Leave of Absence(LOA) Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPolicyLOA1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyLOA1"
                    >
                      <Form.Label column sm={5}>
                        43. How satisfied are you with the leave of absence
                        policies and procedures?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-1"
                        defaultChecked={this.props.formPolicyLOA1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-2"
                        defaultChecked={this.props.formPolicyLOA1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-3"
                        defaultChecked={this.props.formPolicyLOA1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-4"
                        defaultChecked={this.props.formPolicyLOA1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-5"
                        defaultChecked={this.props.formPolicyLOA1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyLOA1"
                        id="formPolicyLOA1-6"
                        defaultChecked={this.props.formPolicyLOA1 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyLOA2">
                      <Form.Label>
                        44.	What types of improvements could be made to the LOA process in the organization?  {" "}
                      </Form.Label>
                      <Form.Control
                        name="formPolicyLOA2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyLOA3">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formPolicyLOA3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyLOA3 || ""}
                      />
                    </Form.Group>
                  </div>

                  
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PoliciesExec;