import React, { Component } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import styled, { ThemeProvider } from "styled-components";
import theme from "../../Config/Theme";

const Buttons = styled(ToggleButtonGroup)`
    display: flex;
    justify-content: space-evenly;
    background-color: white;
    
    .toggle-btns {
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.colors.medium_blue};
        border: 1px solid ${props => props.theme.colors.medium_blue};
        &:hover{
            color: ${props => props.theme.colors.orange};
            background: ${props => props.theme.colors.dark_blue};
            border: 1px solid ${props => props.theme.colors.dark_blue};
        }
    }
    .toggle-btns.active{
        color: ${props => props.theme.colors.dark_blue} !important;
        background: ${props => props.theme.colors.light_blue} !important;
        border: 1px solid ${props => props.theme.colors.light_blue}!important;
    }
`

class NavBar extends Component {

    render() {
        const empLevel = this.props.employment_level;
        return (
            <ThemeProvider theme={theme}>
                <Buttons type="radio" name="options" defaultValue={1}>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_general")} value={1}>
                        General
                    </ToggleButton>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_culture")}
                        value={2}>
                        Culture
                    </ToggleButton>
                    
                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_performance")}
                        value={3}>
                        Performance
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_policies")}
                        value={4}>
                        Policies
                    </ToggleButton>
                    
                    {empLevel === "Employee" ? null
                        : empLevel === "Executive" ? null
                            : <ToggleButton className="toggle-btns"
                                onClick={() => this.props.displayQuestionSection("audit_risk")} 
                                value={5}>
                                Risk
                            </ToggleButton>
                         }


                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_strategy")}
                        value={6}>
                        Strategy
                    </ToggleButton>

                    <ToggleButton className="toggle-btns"
                        onClick={() => this.props.displayQuestionSection("audit_rewards")}
                        value={7}>
                        Rewards
                    </ToggleButton>

                    {empLevel === "Employee" ? null 
                        : <ToggleButton className="toggle-btns"
                            onClick={() => this.props.displayQuestionSection("audit_workforce")}
                            value={8}>
                            Workforce
                        </ToggleButton>
                    }


                </Buttons>

            
        </ThemeProvider>
          
        )
    }
}

export default NavBar;