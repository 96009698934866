import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Bereavement extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Bereavement Leave</h1>
                    <p>{this.props.clientName} will provide up to three days of paid bereavement leave in the event an employee must take time off for the death of an immediate family member. Employees may also, with supervisor approval, utilize any available PTO for additional time off as necessary. PPR defines “immediate family” as the employee’s spouse, parent, child, or sibling; the employee’s spouse’s parent, child, or sibling. In addition, {this.props.clientName} will provide up to one day of paid bereavement leave in the event an employee must take time off for the death of an extended family member such as the employee’s grandparent or grandchildren or any other person with whom the employee has a close familial relationship, which will be determined at {this.props.clientName}’s discretion.   </p>

                    <p>{this.props.clientName} reserves the right to request all pertinent information including deceased relative's name, the name and address of the funeral home, and the date of the funeral. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Bereavement;
