import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class PersonalAppearance extends Component {
  

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Personal Appearance</h1>
                    <p>Maintenance of a good personal appearance contributes to success in business. {this.props.clientName} is a place of business frequently visited by benefactors and others upon whom we wish to make a good impression.  Accordingly, it is important that employees’ appearance be professional, with clothing that is clean, modest and neat, when conducting business both on and offsite. If an employee is meeting with a donor, Trustee or other visitor; or, if an employee is attending a special event on behalf of {this.props.clientName} the employee is expected to dress in a manner appropriate for the occasion (i.e. business professional for meetings with Trustees).  In any case, your attire should be a positive representation of {this.props.clientName}.  </p>

                    <h2>Appropriate Attire</h2>
                    <p>
                        <ul>
                            <li>Dress or khaki slacks</li>
                            <li>Dress, banded collar shirts for men</li>
                            <li>Dress, banded collar, or business blouses for women</li>
                            <li>Cardigan or pull-over sweaters</li>
                            <li>Business dresses for women</li>
                            <li>Natural hair color and style</li>
                            <li>Professional and well-kept shoes (if open-toe or backless shoes are worn, the wearer’s feet should be clean and well-manicured)</li>
                        </ul>
                    </p>

                    <h2>Inappropriate Attire Includes:</h2>
                    <p>
                        <ul>
                            <li>Any casual clothing or shorts </li>
                            <li>Cargo pants or pants with lettering or symbols of any kind </li>
                            <li>Denim (in any color) unless specifically approved by the President and CEO </li>
                            <li>Mini-skirts or skorts </li>
                            <li>Tank tops, T-shirts, polo shirts, Hawaiian shirts</li>
                            <li>Shirts showing cleavage, midriffs, or see-through clothing </li>
                            <li>Tight or provocative clothing</li>
                            <li>Dresses or shirts with spaghetti straps without a jacket </li>
                            <li>Visible undergarments</li>
                            <li>Hats, caps, or sunglasses</li>
                            <li>Visible body piercings (other than ears)</li>
                            <li>Visible tattoos</li>
                        </ul>
                    </p>

                    <p>If employees require a reasonable accommodation regarding their dress for bona fide health and/or religious reasons, they should contact {this.props.clientName} HR partner to discuss an exception to the personal appearance guidelines.  Unless it would constitute an undue hardship or safety hazard, {this.props.clientName} will accommodate such requests.</p>

                    <p>The employee’s supervisor, at his or her discretion, may determine if an employee’s appearance meets {this.props.clientName} standard for professional appearance as deemed appropriate for the employee’s job and specific job duties.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default PersonalAppearance;
