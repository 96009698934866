import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Holidays extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Holidays</h1>
                    <p>Regular full-time and part-time employees receive time off with pay for all the following regularly observed holidays. Employees will receive their basic rate of pay during a holiday.</p>

                    <p>
                        <ul>
                            <li>New Year's Day</li>
                            <li>Memorial Day</li>
                            <li>Independence Day</li>
                            <li>Labor Day</li>
                            <li>Thanksgiving</li>
                            <li>Day after Thanksgiving</li>
                            <li>Christmas Eve</li>
                            <li>Christmas Day</li>
                        </ul>
                    </p>

                    <p>When a holiday falls on a Saturday, the preceding Friday is observed as the paid holiday. When a holiday falls on a Sunday, the following Monday will be observed as the paid holiday.</p>

                    <p>If {this.props.clientName} elects to close early on any given day in observance of a holiday, and an employee works that day, nonexempt employees will be paid for the hours normally worked but were not required to work due to the office closing early.</p>

                    <p>Part-time employees receive holiday pay pro-rated according to the number of hours regularly worked. For example, an employee who usually works six hours a day would receive six hours of holiday pay. To receive compensation for a paid holiday, the part-time employee must be scheduled to work on that holiday.</p>

                    <p>Employees that work during paid holidays are not eligible for additional pay. They will be paid for the holiday whether or not they work. Employees can work with their supervisors to modify their schedule in the remainder of the holiday pay period to take a different day off within the period. This arrangement must be approved by the supervisor and is not automatically granted. </p>

                    <p>{this.props.clientName} respects the religious beliefs and practices of all employees and will make, on request, an accommodation for such observances when a reasonable accommodation is available that does not create an undue hardship on the {this.props.clientName} business. An employee whose religious beliefs or practices conflict with his or her job, work schedule, or with {this.props.clientName} holiday policy, or with other aspects of employment, and who seeks a religious accommodation must submit a written request for the accommodation to Human Resources. The written request should include the type of religious conflict that exists and the employee’s suggested accommodation.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Holidays;
