import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class InfectionControl extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Infection Control</h1>
                    <p>{this.props.clientName} will take proactive steps to protect the workplace in the event of an infectious disease outbreak. It is the goal of {this.props.clientName} during any such time period to strive to operate effectively and ensure that all essential services are continuously provided and that employees are safe within the workplace. </p>

                    <p>{this.props.clientName} is committed to providing authoritative information about the nature and spread of infectious diseases, including symptoms and signs to watch for, as well as required steps to be taken in the event of an illness or outbreak. </p>

                    <h2>Preventing the Spread of Infection in the Workplace</h2>
                    <p>We ask all employees to cooperate in taking steps to reduce the transmission of infectious disease in the workplace. The best strategy remains the most obvious—frequent hand washing with warm, soapy water; covering your mouth whenever you sneeze or cough; and discarding used tissues in wastebaskets. Alcohol-based hand sanitizers are available throughout the workplace and in common areas.</p>

                    <p>Unless otherwise notified, our normal attendance and leave policies will remain in place. Individuals who believe they may face particular challenges reporting to work during an infectious disease outbreak should take steps to develop any necessary contingency plans. For example, employees might want to arrange for alternative sources of child care should schools close and/or speak with supervisors about the potential to work from home temporarily or on an alternative work schedule. </p>

                    <h2>Limiting Travel</h2>
                    <p>All nonessential travel should be avoided. Employees should avoid crowded public transportation when possible. Alternative scheduling options, ride-share resources and/or parking assistance will be provided on a case-by-case basis. </p>

                    <h2>Remote Work</h2>
                    <p>Requests to work remotely will be handled on a case-by-case basis. While not all positions will be eligible, all requests for temporary telecommuting should be submitted to your manager for consideration. </p>

                    <h2>Staying Home When Ill</h2>
                    <p>Many times, with the best of intentions, employees report to work even though they feel ill. We provide PTO and other benefits to compensate employees who are unable to work due to illness. </p>
                    <p>During an infectious disease outbreak, it is critical that employees do not report to work while they are ill and/or experiencing the following symptoms: fever, cough, sore throat, runny or stuffy nose, body aches, headache, chills and fatigue. Currently, the Centers for Disease Control and Prevention recommends that people with an infectious illness such as the flu remain at home until at least 24 hours after they are free of fever (100 degrees F or 37.8 degrees C) or signs of a fever without the use of fever-reducing medications. Employees who report to work ill will be sent home in accordance with these health guidelines. </p>

                    <h2>Requests for Medical Information and/or Documentation</h2>
                    <p>If you are out sick or show symptoms of being ill, it may become necessary to request information from you and/or your health care provider. In general, we would request medical information to confirm your need to be absent, to show whether and how an absence relates to the infection, and to know that it is appropriate for you to return to work. As always, we expect and appreciate your cooperation if and when medical information is sought. </p>

                    <h2>Confidentiality of Medical Information</h2>
                    <p>Our policy is to treat any medical information as a confidential medical record. In furtherance of this policy, any disclosure of medical information is in limited circumstances with supervisors, managers, first aid and safety personnel, and government officials as required by law.</p>

                    <h2>Social Distancing Guidelines for Workplace Infectious Disease Outbreaks</h2>
                    <p>In the event of an infectious disease outbreak, {this.props.clientName} may implement the below social distancing guidelines to minimize the spread of the disease among the staff: </p>

                    <p>During the workday, employees are requested to:
                        <ol>
                            <li>Avoid meeting people face-to-face. Employees are encouraged to use the telephone, online conferencing, e-mail, or instant messaging to conduct business as much as possible, even when participants are in the same building. </li>
                            <li>If a face-to-face meeting is unavoidable, minimize the meeting time, choose a large meeting room and sit at least one yard from each other if possible; avoid person-to-person contact such as shaking hands. </li>
                            <li>Avoid any unnecessary travel and cancel or postpone nonessential meetings, gatherings, workshops, and training sessions. </li>
                            <li>Do not congregate in work rooms, pantries, copier rooms, or other areas where people socialize. </li>
                            <li>Bring lunch and eat at your desk or away from others (avoid lunchrooms and crowded restaurants). </li>
                            <li>Encourage members and others to request information and orders via phone and e-mail to minimize person-to-person contact. Have the orders, materials, and information ready for fast pick-up or delivery. </li>
                        </ol>
                    </p>

                    <h2>Outside Activities</h2>
                    <p>Employees might be encouraged to the extent possible to:
                        <ol>
                            <li>Avoid public transportation (walk, cycle, drive a car) or go early or late to avoid rush-hour crowding on public transportation. </li>
                            <li>Avoid recreational or other leisure classes, meetings, activities, etc., where employees might come into contact with contagious people.</li>
                        </ol>
                    </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default InfectionControl;
