import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";


const Div = styled.div`
    color: black;
`

class About extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div><button onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}}>Copy</button>
                    <h1>About this Handbook</h1>
                    <p>This Handbook is designed to provide you with information about your employment at {this.props.clientName} and its affiliates and subsidiaries (referred to throughout this Handbook as “{this.props.clientName}” or the “Company”). It outlines the policies, programs, and benefits available to eligible employees. It was also developed to describe some of the expectations we have for you.  It is not an express or implied contract, nor does it otherwise create any legally enforceable obligations on the part of {this.props.clientName} or its employees. This Handbook supersedes and replaces all previous personnel policies, practices, and guidelines.</p>
                    <p>No handbook can anticipate every circumstance or question about every one of our policies.  Consequently, the need may arise for us to revise, add, or cancel policies, subject to the approval of the President, the Chief Operations Officer, or the Director of Human Resources. We will attempt to notify you of any changes as they occur.  The only policy that will not change is the employment-at-will policy described in this Handbook, unless otherwise authorized by the President or Chief Executive Officer (CEO) in writing. </p>
                    <p>Questions you have regarding the interpretation and application of individual policies should be directed to Human Resources or your supervisor. Throughout this Handbook, the term “supervisor” will refer to the person to whom you directly report, whether that person is known as a manager or other title. {this.props.clientName} reserves sole discretion to determine the policy that will apply in the event this Handbook conflicts with any addendum to it or other actual or perceived statement of {this.props.clientName} policy or practice.</p>
                    <p>This Handbook is the property of {this.props.clientName} and contains proprietary information intended solely for use by its employees.  As such, the Handbook should not be circulated outside of {this.props.clientName}.</p>
                    <p>Optional:</p>
                    <p>If PPR is to be the point of contact, add… People Performance Resources(PPR)</p>
                </Div>
            </ThemeProvider>
            
        )
            
    }
}

export default About;
