import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";;

const Div = styled.div`
    color: black;
`

class Fraternization extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Fraternization</h1>
                    <p>{this.props.clientName} strongly believes that a work environment where employees maintain clear boundaries between employee personal and business interactions is necessary for effective business operations. Although this policy does not prevent the development of friendships or romantic relationships between co-workers, it does establish boundaries as to how relationships are conducted during working hours and within the working environment.</p>
                    <p>Individuals in supervisory or managerial roles, and those with authority over others’ terms and conditions of employment, are subject to more stringent requirements under this policy due to their status as role models, their access to sensitive information, and their ability to affect the employment of individuals in subordinate positions.</p>
                    <p>This policy does not preclude or interfere with the rights of employees protected by the National Labor Relations Act or any other applicable statute concerning the employment relationship.</p>
                    <h2>Procedures</h2>
                    <ol>
                        <li>During working time and in working areas, employees are expected to conduct themselves in an appropriate workplace manner that does not interfere with others or with overall productivity.</li>
                        <li>During nonworking time, such as lunches, breaks, and before and after work periods, employees engaging in personal exchanges in non-work areas should observe an appropriate workplace manner to avoid offending other workers or putting others in an uncomfortable position. </li>
                        <li>Employees are strictly prohibited from engaging in physical contact that would in any way be deemed inappropriate in the workplace by a reasonable person while anywhere on company premises, whether during working hours or not.</li>
                        <li>Employees who allow personal relationships with co-workers to adversely affect the work environment will be subject to {this.props.clientName} disciplinary policy, including counseling for minor problems. Failure to change behavior and maintain expected work responsibilities is viewed as a serious disciplinary matter.</li>
                        <li>Employee off-duty conduct is generally regarded as private, as long as such conduct does not create problems within the workplace. An exception to this principle, however, is romantic or sexual relationships between supervisors and subordinates.</li>
                        <li>Any supervisor, manager, executive or other company official in a sensitive or influential position with {this.props.clientName} must disclose the existence of a romantic or sexual relationship with another co-worker. Disclosure may be made to the individual’s immediate supervisor or the director of human resources (HR). {this.props.clientName} will review the circumstances to determine whether any conflict of interest exists.</li>
                        <li>When a conflict-of-interest or potential risk is identified due to a company official’s relationship with a co-worker, {this.props.clientName} will work with the parties involved to consider options for resolving the problem. The initial solution may be to make sure the parties no longer work together on matters where one is able to influence the other or take action for the other. Matters such as hiring, firing, promotions, performance management, compensation decisions and financial transactions are examples of situations that may require reallocation of duties to avoid any actual or perceived reward or disadvantage. In some cases, other measures may be necessary, such as transfer of one or both parties to other positions or departments. If one or both parties refuse to accept a reasonable solution, such refusal will be deemed a voluntary resignation.</li>
                        <li>Failure to cooperate with {this.props.clientName} to resolve a conflict or problem caused by a romantic or sexual relationship between co-workers or among managers, supervisors or others in positions of authority in a mutually agreeable fashion may be deemed insubordination and result in disciplinary action up to and including termination. </li>
                        <li>The provisions of this policy apply regardless of the sexual orientation of the parties involved.</li>
                        <li>Where doubts exist as to the specific meaning of the terms used above, employees should make judgments based on the overall spirit and intent of this policy.</li>
                        <li>Any concerns about the administration of this policy should be addressed to the director of HR.</li>
                    </ol>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Fraternization;
