import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Risk extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Risk</h4>
                                <hr />
                                <h5>Posters / Required Notices</h5>

                                <Form.Group controlId="formNoticesLocation">
                                    <Form.Label>
                                        15. Location?
                                    </Form.Label>
                                    <Form.Control
                                        name="formNoticesLocation"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formNoticesLocation || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formNoticesLocationAll"
                                as={Row}
                                controlId="formNoticesLocationAll"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        16. Is the location frequented by all employees?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formNoticesLocationAll"
                                        id="formNoticesLocationAll-Yes"
                                        defaultChecked={
                                        this.props.formNoticesLocationAll === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formNoticesLocationAll"
                                        id="formNoticesLocationAll-No"
                                        defaultChecked={
                                        this.props.formNoticesLocationAll === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formNoticesLocationAll"
                                        id="formNoticesLocationAll-NA"
                                        defaultChecked={
                                        this.props.formNoticesLocationAll === "unknown"
                                        }
                                    />
                            </Form.Group>

                                <Form.Group controlId="formADAManaged">
                                    <Form.Label>
                                        17. Location for remote workers, if applicable?
                                    </Form.Label>
                                    <Form.Control
                                        name="formNoticesRemote"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formNoticesRemote || ""}
                                    />
                                </Form.Group>
                                
                            </div>

                            <div className="form-section">
                                <h5>Americans with Disabilities Act (ADA)</h5>
                                <hr />
                                <Form.Group
                                name="formADACases"
                                as={Row}
                                controlId="formADACases"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        18. Previous ADA cases?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formADACases"
                                        id="formADACases-Yes"
                                        defaultChecked={
                                        this.props.formADACases === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formADACases"
                                        id="formADACases-No"
                                        defaultChecked={
                                        this.props.formADACases === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formADACases"
                                        id="formADACases-NA"
                                        defaultChecked={
                                        this.props.formADACases === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>
                                
                                <Form.Group className="ml-5" controlId="formNoticesRemoteADAManaged">
                                    <Form.Label>
                                        i. How were they managed?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAManaged"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAManaged || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formADADisability"
                                as={Row}
                                controlId="formADADisability"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        19. Current employees with a known or suspected disability?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formADADisability"
                                        id="formADADisability-Yes"
                                        defaultChecked={
                                        this.props.formADADisability === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formADADisability"
                                        id="formADADisability-No"
                                        defaultChecked={
                                        this.props.formADADisability === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formADADisability"
                                        id="formADADisability-NA"
                                        defaultChecked={
                                        this.props.formADADisability === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formADAProcess">
                                    <Form.Label>
                                        i. What is the process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAProcess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAProcess || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formADAManager">
                                    <Form.Label>
                                        ii. Who manages?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAManager"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAManager || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formADAEmpRequest">
                                    <Form.Label>
                                        iii. How do employees request?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAEmpRequest"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAEmpRequest || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formADAResponses">
                                    <Form.Label>
                                        iv. What do responses look like? Including notice issue process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAResponses"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAResponses || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formADAApprovals">
                                    <Form.Label>
                                        v. How are approvals/denials communicated?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADAApprovals"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADAApprovals || ""}
                                    />
                                </Form.Group>


                                <Form.Group className="ml-5" controlId="formADARecords">
                                    <Form.Label>
                                        vi. Where are records kept?
                                    </Form.Label>
                                    <Form.Control
                                        name="formADARecords"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADARecords || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formADARetention">
                                    <Form.Label>
                                        vii. Retention
                                    </Form.Label>
                                    <Form.Control
                                        name="formADARetention"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formADARetention || ""}
                                    />
                                </Form.Group>

                            </div>
                            <div className="form-section">
                                <h5>Safety</h5>
                                

                                <Form.Group
                                name="formSafety"
                                as={Row}
                                controlId="formSafety"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        20. Any inherent risks in work environment to employees?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafety"
                                        id="formSafety-Yes"
                                        defaultChecked={
                                        this.props.formSafety === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafety"
                                        id="formSafety-No"
                                        defaultChecked={
                                        this.props.formSafety === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafety"
                                        id="formSafety-NA"
                                        defaultChecked={
                                        this.props.formSafety === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formSafetyRisks">
                                    <Form.Label>
                                        If yes, what are those risks?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyRisks"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyRisks || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formSafetyHistory"
                                as={Row}
                                controlId="formSafetyHistory"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        21. History of safety issues?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyHistory"
                                        id="formSafetyHistory-Yes"
                                        defaultChecked={
                                        this.props.formSafetyHistory === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyHistory"
                                        id="formSafetyHistory-No"
                                        defaultChecked={
                                        this.props.formSafetyHistory === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyHistory"
                                        id="formSafetyHistory-NA"
                                        defaultChecked={
                                        this.props.formSafetyHistory === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formSafetyHandled">
                                    <Form.Label>
                                        If yes, how were they handled?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyHandled"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyHandled || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formSafetyWorkersComp"
                                as={Row}
                                controlId="formSafetyWorkersComp"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        22. Workers' compensation subscriber?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyWorkersComp"
                                        id="formSafetyWorkersComp-Yes"
                                        defaultChecked={
                                        this.props.formSafetyWorkersComp === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyWorkersComp"
                                        id="formSafetyWorkersComp-No"
                                        defaultChecked={
                                        this.props.formSafetyWorkersComp === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyWorkersComp"
                                        id="formSafetyWorkersComp-NA"
                                        defaultChecked={
                                        this.props.formSafetyWorkersComp === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formSafetyManager">
                                    <Form.Label>
                                        i. Who manages?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyManager"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyManager || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formSafetyInsurer">
                                    <Form.Label>
                                        ii. Who is the insurer or TPA?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyInsurer"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyInsurer || ""}
                                    />
                                </Form.Group>

                                <p className="ml-3">Records:</p>

                                <Form.Group className="ml-5" controlId="formSafetyResponsible">
                                    <Form.Label>
                                        i. Responsible person?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyResponsible"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyResponsible || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formSafetyLocation">
                                    <Form.Label>
                                        ii. Location?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyLocation"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyLocation || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formSafetyContent">
                                    <Form.Label>
                                        iii. Content?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyContent"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyContent || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formSafetyAccess">
                                    <Form.Label>
                                        iv. Who has access?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyAccess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyAccess || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formSafetyRetention">
                                    <Form.Label>
                                        v. Retention?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyRetention"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formSafetyRetention || ""}
                                    />
                                </Form.Group>

                                <p className="ml-3">If no:</p>

                                <Form.Group className="ml-5" controlId="formSafetyInsurance">
                                    <Form.Label>
                                        i. What type of insurance do you maintain for employee injuries?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyInsurance"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyInsurance || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formSafetyProcedures"
                                as={Row}
                                controlId="formSafetyProcedures"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        23. Established procedures for employees to follow in the event of a workplace injury or illness?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyProcedures"
                                        id="formSafetyProcedures-Yes"
                                        defaultChecked={
                                        this.props.formSafetyProcedures === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyProcedures"
                                        id="formSafetyProcedures-No"
                                        defaultChecked={
                                        this.props.formSafetyProcedures === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyProcedures"
                                        id="formSafetyProcedures-NA"
                                        defaultChecked={
                                        this.props.formSafetyProcedures === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">
                                    If yes:
                                </p>

                                 <Form.Group className="ml-5" controlId="formSafetyFollow">
                                    <Form.Label>
                                        i. What are they?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyFollow"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                         value={this.props.formSafetyFollow || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formSafetyOSHA"
                                as={Row}
                                controlId="formSafetyOSHA"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        24. Exempt from OSHA recordkeeping? (Exempt industries are those with 10 or less employees or in exempt industries)
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyOSHA"
                                        id="formSafetyOSHA-Yes"
                                        defaultChecked={
                                        this.props.formSafetyOSHA === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyOSHA"
                                        id="formSafetyOSHA-No"
                                        defaultChecked={
                                        this.props.formSafetyOSHA === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyOSHA"
                                        id="formSafetyOSHA-NA"
                                        defaultChecked={
                                        this.props.formSafetyOSHA === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If no:</p>

                                <Form.Group
                                    className="ml-5"
                                    name="formSafetyOSHA300"
                                    as={Row}
                                    controlId="formSafetyOSHA300"
                                    onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        i. OSHA 300
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyOSHA300"
                                        id="formSafetyOSHA300-Yes"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300 === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyOSHA300"
                                        id="formSafetyOSHA300-No"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300 === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyOSHA300"
                                        id="formSafetyOSHA300-NA"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300 === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="ml-5"
                                    name="formSafetyOSHA301"
                                    as={Row}
                                    controlId="formSafetyOSHA301"
                                    onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        ii. OSHA 301
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyOSHA301"
                                        id="formSafetyOSHA301-Yes"
                                        defaultChecked={
                                        this.props.formSafetyOSHA301 === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyOSHA301"
                                        id="formSafetyOSHA301-No"
                                        defaultChecked={
                                        this.props.formSafetyOSHA301 === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyOSHA301"
                                        id="formSafetyOSHA301-NA"
                                        defaultChecked={
                                        this.props.formSafetyOSHA301 === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="ml-5"
                                    name="formSafetyOSHA300A"
                                    as={Row}
                                    controlId="formSafetyOSHA300A"
                                    onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        iii. OSHA 300A
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyOSHA300A"
                                        id="formSafetyOSHA300A-Yes"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300A === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyOSHA300A"
                                        id="formSafetyOSHA300A-No"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300A === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyOSHA300A"
                                        id="formSafetyOSHA300A-NA"
                                        defaultChecked={
                                        this.props.formSafetyOSHA300A === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formSafetyCovid"
                                    as={Row}
                                    controlId="formSafetyCovid"
                                    onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        25. Infection control or COVID-19 specific policy?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyCovid"
                                        id="formSafetyCovid-Yes"
                                        defaultChecked={
                                        this.props.formSafetyCovid === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyCovid"
                                        id="formSafetyCovid-No"
                                        defaultChecked={
                                        this.props.formSafetyCovid === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyCovid"
                                        id="formSafetyCovid-NA"
                                        defaultChecked={
                                        this.props.formSafetyCovid === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    name="formSafetyManual"
                                    as={Row}
                                    controlId="formSafetyManual"
                                    onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        26. Safety manual?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formSafetyManual"
                                        id="formSafetyManual-Yes"
                                        defaultChecked={
                                        this.props.formSafetyManual === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formSafetyManual"
                                        id="formSafetyManual-No"
                                        defaultChecked={
                                        this.props.formSafetyManual === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formSafetyManual"
                                        id="formSafetyManual-NA"
                                        defaultChecked={
                                        this.props.formSafetyManual === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group controlId="formSafetyDrugTest">
                                    <Form.Label>
                                        27. Drug testing procedures/processes?
                                    </Form.Label>
                                    <Form.Control
                                        name="formSafetyDrugTest"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formSafetyDrugTest || ""}
                                    />
                                </Form.Group>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Risk;