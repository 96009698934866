import React, { Component } from 'react';
import {Form, Container} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div `
    background-color: white;
    color: black;

`

class GeneralEmployee extends Component {
    
  render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container className="8,p-0">
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>General Information</h5>
                    <hr />
                    
                    <Form.Group controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        type="text"
                        placeholder="Title"
                        onChange={this.props.handleChange}
                        value={this.props.title || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="supervisor">
                      <Form.Label>Supervisor</Form.Label>
                      <Form.Control
                        name="supervisor"
                        type="text"
                        placeholder="Supervisor"
                        onChange={this.props.handleChange}
                        value={this.props.supervisor || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="department">
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        name="department"
                        type="text"
                        placeholder="Department"
                        onChange={this.props.handleChange}
                        value={this.props.department || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="worksite">
                      <Form.Label>Worksite Location</Form.Label>
                      <Form.Control
                        name="worksite"
                        type="text"
                        placeholder="Worksite Location"
                        onChange={this.props.handleChange}
                        value={this.props.worksite || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="hireDate">
                      <Form.Label>Hire Date</Form.Label>
                      <Form.Control
                        name="hiredate"
                        type="text"
                        placeholder="Hire Date"
                        onChange={this.props.handleChange}
                        value={this.props.hiredate || ""}
                      />
                    </Form.Group>
                    <Form.Group controlId="fulltimeparttime">
                      <Form.Label>Full-time or Part-time</Form.Label>
                      <Form.Control
                        name="fulltimeparttime"
                        as="select"
                        onChange={this.props.handleChange}
                        value={this.props.fulltimeparttime || ""}
                        required
                      >
                        <option>Choose...</option>
                        <option>Full-Time</option>
                        <option>Part-Time</option>
                      </Form.Control>
                    </Form.Group>

                    {this.props.employment_level !== "Employee" && (
                      <div>
                        <Form.Group controlId="reports">
                          <Form.Label>Number of Direct Reports</Form.Label>
                          <Form.Control
                            name="reports"
                            type="text"
                            placeholder="Number of Reports"
                            onChange={this.props.handleChange}
                            value={this.props.reports || ""}
                          />
                          </Form.Group>
                          <Form.Group controlId="report_locations">
                            <Form.Label>In what cities/states are your direct reports located?</Form.Label>
                            <Form.Control
                              name="report_locations"
                              as="textarea"
                              rows="4"
                              onChange={this.props.handleChange}
                              value={this.props.report_locations || ""}
                            />
                          </Form.Group>
                      </div>
                        
                    )}
                    
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default GeneralEmployee;