import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class UpkeepVehicle extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Maintenance and Upkeep of Company Vehicles</h1>
                    <p>Drivers are responsible for keeping their assigned company vehicle well-maintained. The assigned driver is responsible for taking the vehicle to approved service stations to have scheduled fluid changes, brake jobs, tire changes, and other repairs completed. Turn in all repair receipts and maintenance records for the vehicle’s maintenance file. The employee is responsible for ensuring the vehicle is safe to operate on the road and complies with all federal, state, and local laws. The following regulations also apply:
                        <ul>
                            <li>Company vehicles are non-smoking. No persons authorized as an operator or passenger shall smoke while in the company vehicle. The non-smoking policy includes flameless cigarettes and e-cigarettes.</li>
                            <li>Employees should verify that the current insurance verification is in the vehicle at all times.</li>
                            <li>Drivers must maintain a vehicle mileage form and turn in the form in accordance with company policy. The form is used to track the miles driven each day and the jobsites visited.</li>
                            <li>Employees should take reasonable care that the vehicle is kept in a clean and orderly state, and that visibility is maintained for all of the vehicle components.</li>
                            <li>Company vehicles are subject to spot-checks by company management to ensure that the vehicle is well-maintained.</li>
                        </ul>
                    </p>

                    
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default UpkeepVehicle;
