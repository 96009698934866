import React, { Component } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class StrategyEmployee extends Component {
    

    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Strategy</h5>
                    <hr />
                    

                    <Form.Group
                      name="formStrategy2"
                      as={Row}
                      controlId="formStrategy2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        30. Does the company have a strategic plan in place?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I don't know"
                          value="I don't know"
                          name="formStrategy2"
                          id="formStrategy2-1"
                          defaultChecked={
                            this.props.formStrategy2 === "I don't know"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, but we don't talk about it"
                          value="Yes, but we don't talk about it"
                          name="formStrategy2"
                          id="formStrategy2-2"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, but we don't talk about it"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, I hear about it often"
                          value="Yes, I hear about it often"
                          name="formStrategy2"
                          id="formStrategy2-3"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, I hear about it often"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy2"
                          id="formStrategy2-4"
                          defaultChecked={this.props.formStrategy2 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy2 === "Other" && (
                        <Form.Group controlId="formStrategy2Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy2Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy2Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formStrategy3"
                      as={Row}
                      controlId="formStrategy3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        31.	Does the company have any initiatives in the works or on the horizon?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I don't know"
                          value="I don't know"
                          name="formStrategy3"
                          id="formStrategy3-1"
                          defaultChecked={
                            this.props.formStrategy3 === "I don't know"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, but we don't talk about it"
                          value="Yes, but we don't talk about it"
                          name="formStrategy3"
                          id="formStrategy3-2"
                          defaultChecked={
                            this.props.formStrategy3 ===
                            "Yes, but we don't talk about it"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, I hear about it often"
                          value="Yes, I hear about it often"
                          name="formStrategy3"
                          id="formStrategy3-3"
                          defaultChecked={
                            this.props.formStrategy3 ===
                            "Yes, I hear about it often"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy3"
                          id="formStrategy3-4"
                          defaultChecked={this.props.formStrategy3 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy3 === "Other" && (
                        <Form.Group controlId="formStrategy3Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy3Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy3Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formStrategy5a">
                      <Form.Label>32. If so, what are they? </Form.Label>
                      <Form.Control
                        name="formStrategy5a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy5b">
                      <Form.Label>
                        33. Is employee feedback solicited from the organization on new initiatives?
                      </Form.Label>
                      <Form.Control
                        name="formStrategy5b"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5b || ""}
                      />
                    </Form.Group>

                    
                    <hr />

                    
                    

                    <Form.Group
                      name="formStrategy7"
                      as={Row}
                      controlId="formStrategy7"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        34. How are changes communicated company-wide? Select all
                        that apply...
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Email"
                          name="formStrategyEmail"
                          id="formStrategy7-1"
                          defaultChecked={this.props.formStrategyEmail}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In face-to-face meetings with leadership/President/CEO"
                          name="formStrategyFaceMeetings"
                          id="formStrategy7-2"
                          defaultChecked={this.props.formStrategyFaceMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In department meetings"
                          name="formStrategyDeptMeetings"
                          id="formStrategy7-3"
                          defaultChecked={this.props.formStrategyDeptMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formStrategy7Other"
                          id="formStrategy7-4"
                          defaultChecked={this.props.formStrategy7Other}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formStrategy7Other === true ||
                        this.props.formStrategy7Other === 1) && (
                        <Form.Group controlId="formStrategy7OtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy7OtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy7OtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default StrategyEmployee;