import React, { Component } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
    
`

class RewardsSupervisor extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  

                  <div className="form-section">
                    <h5>Benefits</h5>
                    <hr />

                    

                    

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                   

                    <Form.Group
                      name="formRewardsBenefits2"
                      as={Row}
                      controlId="formRewardsBenefits2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        57. How satisfied are you with your benefits offering?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-1"
                        defaultChecked={this.props.formRewardsBenefits2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-2"
                        defaultChecked={this.props.formRewardsBenefits2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-3"
                        defaultChecked={this.props.formRewardsBenefits2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-4"
                        defaultChecked={this.props.formRewardsBenefits2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-5"
                        defaultChecked={this.props.formRewardsBenefits2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formRewardsBenefits2"
                        id="formRewardsBenefits2-6"
                        defaultChecked={this.props.formRewardsBenefits2 === 0}
                      />
                    </Form.Group>
                    <hr />
                    

                    <Form.Group controlId="formRewardsBenefits9">
                      <Form.Label>
                        58. What improvements, if any, would you like to
                        see made to your benefits offering?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBenefits9"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits9 || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRewardsBenefits10">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsBenefits10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits10 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Paid Leave</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Low and 5-High)...
                    </p>

                    <Form.Group
                      name="formRewardsPaidLeave1"
                      as={Row}
                      controlId="formRewardsPaidLeave1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        59. How satisfied are you with your paid leave programs(PTO, vacation, sick, holidays, leave of absence)?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-1"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-2"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-3"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-4"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-5"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formRewardsPaidLeave1"
                        id="formRewardsPaidLeave1-6"
                        defaultChecked={this.props.formRewardsPaidLeave1 === 0}
                      />
                    </Form.Group>


                    <Form.Group controlId="formRewardsPaidLeave4">
                      <Form.Label>
                        60. What improvements, if any, would you like to
                        see made to your paid leave programs?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave4 || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRewardsPaidLeave5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Work/Home Balance/Integration</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formRewardsBalance1"
                      as={Row}
                      controlId="formRewardsBalance1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        61. How satisfied are you with your flexible work
                        programs?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-1"
                        defaultChecked={this.props.formRewardsBalance1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-2"
                        defaultChecked={this.props.formRewardsBalance1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-3"
                        defaultChecked={this.props.formRewardsBalance1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-4"
                        defaultChecked={this.props.formRewardsBalance1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-5"
                        defaultChecked={this.props.formRewardsBalance1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formRewardsBalance1"
                        id="formRewardsBalance1-6"
                        defaultChecked={this.props.formRewardsBalance1 === 0}
                      />
                    </Form.Group>
                    <hr />


                    <Form.Group controlId="formRewardsBalance5">
                      <Form.Label>
                        62. What improvements, if any, would you like to
                        see made to your flexible work programs?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBalance5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBalance5 || ""}
                      />
                    </Form.Group>
                    <hr />
                    <Form.Group controlId="formRewardsBalance6">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsBalance6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBalance6 || ""}
                      />
                    </Form.Group>
                  </div>

                  

                  {/* //
                            /
                            /
                            / */}

                  <div className="form-section">
                    <h5>Pay / Compensation</h5>
                    <hr />

                    

                    

                    <Form.Group controlId="formRewardsCompensation4">
                      <Form.Label>
                        63. How do you determine which employees are ready for advancement or promotions?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation4 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation5">
                      <Form.Label>
                        64. How are salary changes communicated?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation6">
                      <Form.Label>
                        65. what improvements, if any, would you like to see made to your compensation plan(s)?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation6 || ""}
                      />
                    </Form.Group>
                    <hr />                    
                  
                    <Form.Group controlId="formRewardsCompensation9">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsCompensation9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation9 || ""}
                      />
                    </Form.Group>
                  </div>

                  
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default RewardsSupervisor;