import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Conduct extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>How We Conduct Ourselves</h1>
                    <p>As a valued member of our team, we trust you to do what is best for “Company”. We believe that by trusting you with the freedom and information you need to make good decisions you will in turn act with greater responsibility and self-discipline to do your best work for “Company”.</p>

                    <p>Our desire is to create a sense of ownership, responsibility and initiative so that this behavior comes naturally. We work to have an organization of people we trust will discover and fix issues without being told to do so.  </p>

                    <p>With those intentions in mind, we ask that your words and actions always be reflective of our values and principles. We have spelled out a few conduct expectations below, however, not every circumstance you encounter can be specifically addressed in this Handbook.  As such, we ask that you always use “Company”. values and principles as a conduct guide. </p>

                    <p>
                        <ol>
                            <li>
                                Always act with integrity and in the best interest of “Company”. For example, avoid situations in which you
                                <ol>
                                    <li>Receive personal gain (or a relative receives personal gain) based on a decision or transaction you made or influenced at “Company”, </li>
                                    <li>Allow personal interests, such as second job, serve as a distraction to your job at “Company”,</li>
                                    <li>Employ or manage a relative or close friend,</li>
                                    <li>Engage in relationships that may be perceived to conflict with the interests of “Company”.</li>
                                    <li>Ensure “Company” is compliant with all laws and regulations, and if not, properly notify a manager, HR or another member of the senior management team. </li>
                                </ol>
                            </li>
                            <li>Treat your co-workers, donors and others with whom you interact with respect and empathy. </li>
                            <li>Support “Company”Equal Employment Opportunity and Harassment-Free Workplace policies by reporting discrimination or harassment concerns.  </li>
                            <li>Promote a safe workplace by reporting any accidents, injuries, potential safety hazards, safety suggestions, potential acts of violence or any other and health and safety-related issues</li>
                            <li>Say “thank you”</li>
                            <li>Give and receive feedback to promote the greater good of “Company”, and do so with an open mind and in a respectful manner</li>
                            <li>Protect confidential business information </li>
                            <li>Refrain from speaking on behalf of “Company” without prior authorization</li>
                            <li>Share information and communicate with co-workers to promote workplace efficiencies</li>
                            <li>Learn from mistakes and be open to correction</li>
                            <li>Build trust with the customers and others by using good judgment in determining your dress and appearance for the meetings and events you attend on any given day.   </li>
                        </ol>
                    </p>

                    <h2>Culture Driven Policies</h2>
                    <h3>Knowledge of Premier Services</h3>
                    <p>After having learned to competently perform your own duties, your next step is to familiarize yourself with other {this.props.clientName} activities. This can prove valuable to you, our customers, and {this.props.clientName} as well. {this.props.clientName} may provide additional “cross-training.” </p>
                    <p>Knowledge of the services and products of {this.props.clientName} will help you avoid the “I don't know” syndrome. Our customers' confidence in you increases as you are able to answer their basic questions. However, please don't pretend you know the answer or try to guess the answer when you are uncertain. If you are unsure of the correct information, refer the inquiry to your manager, or to a person more qualified to respond. </p>

                    <h3>We Need Your Ideas</h3>
                    <p>There may be areas in {this.props.clientName}’s operations needing improvement.  These could be in service, production methods, equipment, communications, safety, marketing, and measures to reduce costs, losses, and/or waste.  Please give us the benefit of your unique experience and thoughts.  Also, make sure to document your innovative or money-saving ideas for placement in your personnel file (include dates, detailed descriptions of your contributions, estimates from the accounting department regarding cost-savings or profits generated, etc.) – these may favorably affect your wage, salary or promotion reviews.</p>

                    <h3>Importance of Safety</h3>
                    <p>{this.props.clientName} places the highest emphasis on employee safety and strives to ensure all working areas are safe and healthful. Each employee's responsibility is to work safely and to do everything possible to prevent accidents or injuries. Every employee is expected to report unsafe conditions, think before acting, and conscientiously avoid unnecessary risks.</p>

                    <p>Your safety and welfare are a great concern to {this.props.clientName}. However, no matter what or how much the Company provides in safety devices and training, the effort is not effective unless you consider “SAFETY” your responsibility. It is your duty to promptly report safety problems to your Manager. </p>

                    <p>Accidents can be prevented. Most accidents result from the actions of people rather than the failure of equipment. You can help prevent accidents by supplying information to your Manager concerning dangerous conditions and warning new or inexperienced employees of situations to avoid.</p>

                    <p>{this.props.clientName} management cannot stand over you every moment, so you need to be very aggressive with self-protection. The Company is dedicated to providing all employees with a safe workplace and abides by all government safety laws and guidelines.</p>


                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Conduct;
