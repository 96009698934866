import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Vehicle extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Use of Personal or Company Vehicles to Conduct Business</h1>
                    <p>In certain circumstances, an employee may be responsible for driving a company-owned vehicle. In all cases, employees who drive company vehicles or drive their own vehicle while conducting business on behalf of the company are responsible for obeying all safety and traffic laws, maintaining a valid driver’s license and adequate insurance coverage and will be responsible for all fines and traffic tickets. If an employee is involved in a traffic accident while driving a company vehicle or driving a personal vehicle while conducting company-business, they must report it to the police and their supervisor immediately.  </p>

                    <p>An employee who operates a company vehicle should adhere to the following guidelines:
                        <ul>
                            <li>Company vehicles are to be used for company business only. Personal use of a company vehicle is prohibited. Company vehicles may be driven home and used as transportation to and from work only if approved by management.</li>
                            <li>Employees must ensure that the company vehicle and any containers or toolboxes with company equipment is reasonably stored or secured to prevent theft.</li>
                            <li>Employees must ensure that equipment (laptops, cell phones, electronic devices, etc.) is not visible when the vehicle is left unattended. The vehicle must be locked while unattended and equipment inside the vehicle must be reasonably stored or secured to prevent theft.</li>
                            <li>Employees are required to report any mechanical and unsafe conditions as soon as possible.</li>
                            <li>Employees driving company vehicles must exercise due diligence to drive safely and maintain the security, and appearance of the vehicle and its contents.</li>
                            <li>Unauthorized persons are not permitted within company vehicles at any time.</li>
                        </ul>
                    </p>

                    <p>In addition, an employee who operates a vehicle while conducting company business (whether a personal vehicle or company-owned vehicle) should adhere to the following guidelines:
                        <ul>
                            <li>Use of alcohol or illegal drugs while driving on company business is strictly prohibited by the Company.</li>
                            <li>Employees are not permitted, under any circumstances, to operate a company vehicle at any time when any physical or mental impairment causes employee to be unable to drive safely.</li>
                            <li>Employees should refrain from the use of personal electronic devices while driving and are strictly prohibited from texting or using a cell phone while driving without a hand’s free device.  Any phone calls necessary to conduct business should be delayed until such time that the employee can utilize a hand’s free device.    </li>
                            <li>Employees who hold jobs requiring regular driving for business as an essential function must, as a condition of employment, be able to meet the standards of this policy at all times.</li>
                        </ul>
                    </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Vehicle;
