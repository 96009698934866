import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class EqualEmp extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Equal Employment Opportunity</h1>
                    <p>Our commitment to providing equal opportunity to all is a primary consideration in the success of our organization.  Employment decisions will be made only on occupational qualifications and job-related factors such as skill, knowledge, education, experience, and ability to perform a specific job.  {this.props.clientName} prohibits discrimination on race, color, religion, sex, national origin, ancestry, age, medical condition, disability, genetic information, military status or any other category protected under applicable laws.  </p>
                    <p>{this.props.clientName} will also make reasonable accommodations for otherwise qualified individuals with known disabilities unless doing so would create an undue hardship.  If an employee needs a reasonable accommodation, the employee is expected to notify Human Resources, specify the accommodation desired and enter an interactive discussion with {this.props.clientName} concerning accommodations which may be reasonable.</p>
                    <p>This policy applies to all areas of employment, including recruitment, hiring, company-sponsored training, promotion, demotion, compensation, benefits, transfer, social and recreational programs, termination, and resolution of employee complaints.</p>
                    
                </Div>
            </ThemeProvider>
            
        )
            
    }
}

export default EqualEmp;
