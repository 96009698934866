import React, { Component } from 'react';
import { Form, Container, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class RiskSupervisor extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  
                  <div className="form-section">
                    <h5>Employment Classification</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formRiskClassification1"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formRiskClassification1"
                    >
                      <Form.Label column sm={5}>
                        50. How satisfied are you with the time tracking system?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formRiskClassification1"
                        id="formRiskClassification1-1"
                        defaultChecked={
                          this.props.formRiskClassification1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formRiskClassification1"
                        id="formRiskClassification1-2"
                        defaultChecked={
                          this.props.formRiskClassification1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formRiskClassification1"
                        id="formRiskClassification1-3"
                        defaultChecked={
                          this.props.formRiskClassification1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formRiskClassification1"
                        id="formRiskClassification1-4"
                        defaultChecked={
                          this.props.formRiskClassification1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formRiskClassification1"
                        id="formRiskClassification1-5"
                        defaultChecked={
                          this.props.formRiskClassification1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formRiskClassification1"
                        id="formRiskClassification1-6"
                        defaultChecked={
                          this.props.formRiskClassification1 === 0
                        }
                      />
                                </Form.Group>
                   </div>             
                

                   

                  <div className="form-section">
                    <h5>Americans with Disabilities Act(ADA)</h5>
                    <hr />
                    <Form.Group
                      name="formRiskADA1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskADA1"
                    >
                      <Form.Label column sm={5}>
                        51. Do you have any employees with a known or suspected
                        disability?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskADA1"
                        id="formRiskADA1-1"
                        defaultChecked={this.props.formRiskADA1 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskADA1"
                        id="formRiskADA1-2"
                        defaultChecked={this.props.formRiskADA1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskADA1"
                        id="formRiskADA1-3"
                        defaultChecked={this.props.formRiskADA1 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskADA2">
                      <Form.Label>
                        51a. If yes, how do you
                        communicate to an employee that they have been approved
                        or denied for a specific accommodation request?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskADA2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskADA2 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskADA4">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRiskADA4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskADA4 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Compliance Training</h5>
                    <hr />
                    <Form.Group
                      name="formRiskCompliance1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskCompliance1"
                    >
                      <Form.Label column sm={5}>
                        52.	Have you completed harassment prevention training for managers? 
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-1"
                        defaultChecked={
                          this.props.formRiskCompliance1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-2"
                        defaultChecked={this.props.formRiskCompliance1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-3"
                        defaultChecked={
                          this.props.formRiskCompliance1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskCompliance1a">
                      <Form.Label>52a. If so, when and how often </Form.Label>
                      <Form.Control
                        name="formRiskCompliance1a"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance1a || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRiskCompliance3">
                      <Form.Label>
                        53. What other types of training do you provide managers
                        employees?
                      </Form.Label>
                      <Form.Control
                        name="formRiskCompliance3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance3 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskCompliance4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRiskCompliance4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance4 || ""}
                      />
                    </Form.Group>
                  </div>

                  
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default RiskSupervisor;