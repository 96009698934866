import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Misconduct extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Misconduct</h1>
                    <p>Misconduct of employees will not be accepted, and certain behaviors will result in immediate termination.  Misconduct that is not cause for immediate termination may result in other types of corrective action based on the severity and/or frequency of conduct, as determined by {this.props.clientName}.  Examples of misconduct that may result in immediate termination include the following:
                        <ul>
                            <li>Knowingly falsifying or altering records or numbers with incorrect information, including falsification of time records;</li>
                            <li>The acceptance of monetary compensation that is a conflict of interest, including falsifying records for financial gain;</li>
                            <li>Misuse of privileged information, or of one’s position;</li>
                            <li>Theft;</li>
                            <li>Engaging in fraudulent/deceptive practices, such as use of {this.props.clientName} property, credit, services or employment relationship in a manner that violates federal, state or local laws, or ”Company” policy or procedures;</li>
                            <li>Misuse or destruction of {this.props.clientName} property or the property of another on {this.props.clientName} premises or while conducting {this.props.clientName} business;</li>
                            <li>Physical assault of another employee or any other person while on {this.props.clientName} property or while conducting the {this.props.clientName} business;</li>
                            <li>Failing to notify {this.props.clientName} of a conviction of a felony, or serious misdemeanor;</li>
                            <li>Being impaired by being under the influence of, using, manufacturing, dispensing, distributing, possessing, or selling alcohol, drugs, or illegal or controlled substances on the Foundation’s property or while conducting {this.props.clientName} business;</li> 
                        </ul>
                    </p>

                    <p>Other misconduct that may lead to corrective action or immediate termination:
                        <ul>
                            <li>Insubordination;</li>
                            <li>Discourtesy;</li>
                            <li>Unauthorized use or disclosure of confidential or proprietary information;</li>
                            <li>Excessive absenteeism, late arrivals or early departures;</li>
                            <li>Interference with the work performance of others;</li>
                            <li>Arguing with another employee in a manner that causes a disruption to {this.props.clientName}</li>
                            <li>Discrimination against or harassment of another employee;</li>
                            <li>Failing to report to {this.props.clientName} within five days of any conviction for violation of a criminal drug statute occurring in the workplace.</li>
                        </ul>
                    </p>
                    <p>This is not an exhaustive list and immediate termination, or progressive corrective action may be taken for other work-related misconduct, as well.</p>

                    
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Misconduct;
