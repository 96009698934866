import React, { Component } from 'react';
import { Container } from "react-bootstrap";

class AuditThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
            useless: "no"
        }
    }

    render() {
        return (
            <Container className="mt-5">
                <div className="pt-5">
                    <h1 className="pt-5">Thank You</h1>
                    <br/>
                    <h3>Your responses have been recorded. You may close your browser window now.</h3>
                    <br />
                    <p>If you need to add to, or change your responses, you may do so by visiting the link again in the email we sent to you.</p>
                </div>
            </Container>
        )
    }
}

export default AuditThankYou;