import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class PersonnelFiles extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Personnel Files</h4>
                                <hr />
                            
                                <Form.Group
                                name="formPersonnelFiles"
                                as={Row}
                                controlId="formPersonnelFiles"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        1. Are employee records kept in the office?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formPersonnelFiles"
                                        id="formPersonnelFiles-Yes"
                                        defaultChecked={
                                        this.props.formPersonnelFiles === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formPersonnelFiles"
                                        id="formPersonnelFiles-No"
                                        defaultChecked={
                                        this.props.formPersonnelFiles === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formPersonnelFiles"
                                        id="formPersonnelFiles-NA"
                                        defaultChecked={
                                        this.props.formPersonnelFiles === "unknown"
                                        }
                                    />
                                </Form.Group>
                            
                                <Form.Group
                                className="ml-3"
                                name="formPersonnelFilesPaper"
                                as={Row}
                                controlId="formPersonnelFilesPaper"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        a. Paper?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formPersonnelFilesPaper"
                                        id="formPersonnelFilesPaper-Yes"
                                        defaultChecked={
                                        this.props.formPersonnelFilesPaper === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formPersonnelFilesPaper"
                                        id="formPersonnelFilesPaper-No"
                                        defaultChecked={
                                        this.props.formPersonnelFilesPaper === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formPersonnelFilesPaper"
                                        id="formPersonnelFilesPaper-NA"
                                        defaultChecked={
                                        this.props.formPersonnelFilesPaper === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                                <Form.Group
                                    className="ml-3"
                                    name="formPersonnelFilesElectronic"
                                    as={Row}
                                    controlId="formPersonnelFilesElectronic"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            b. Electronic?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPersonnelFilesElectronic"
                                            id="formPersonnelFilesElectronic-Yes"
                                            defaultChecked={
                                            this.props.formPersonnelFilesElectronic === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPersonnelFilesElectronic"
                                            id="formPersonnelFilesElectronic-No"
                                            defaultChecked={
                                            this.props.formPersonnelFilesElectronic === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPersonnelFilesElectronic"
                                            id="formPersonnelFilesElectronic-NA"
                                            defaultChecked={
                                            this.props.formPersonnelFilesElectronic === "unknown"
                                            }
                                        />
                                </Form.Group>
                                
                                <Form.Group className="ml-3" controlId="formPersonnelFilesStored">
                                    <Form.Label>
                                        c. Where and how are they stored?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesStored"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesStored || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formPersonnelFilesAccess">
                                    <Form.Label>
                                        d. Who has access?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesAccess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesAccess || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="ml-3"
                                    name="formPersonnelFilesMedKept"
                                    as={Row}
                                    controlId="formPersonnelFilesMedKept"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            e. Are medical records kept?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPersonnelFilesMedKept"
                                            id="formPersonnelFilesMedKept-Yes"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedKept === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPersonnelFilesMedKept"
                                            id="formPersonnelFilesMedKept-No"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedKept === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPersonnelFilesMedKept"
                                            id="formPersonnelFilesMedKept-NA"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedKept === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formPersonnelFilesAuthorization">
                                    <Form.Label>
                                        i. Who has authorization to review medical records?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesAuthorization"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesAuthorization || ""}
                                    />
                                </Form.Group>

                                <Form.Group  className="ml-5" controlId="formPersonnelFilesMedAccess">
                                    <Form.Label>
                                        ii. Who has access? (if different than above)
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesMedAccess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesMedAccess || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                    className="ml-5"
                                    name="formPersonnelFilesMedSeparate"
                                    as={Row}
                                    controlId="formPersonnelFilesMedSeparate"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            iii. Are medical records kept separate from other employee records?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPersonnelFilesMedSeparate"
                                            id="formPersonnelFilesMedSeparate-Yes"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedSeparate === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPersonnelFilesMedSeparate"
                                            id="formPersonnelFilesMedSeparate-No"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedSeparate === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPersonnelFilesMedSeparate"
                                            id="formPersonnelFilesMedSeparate-NA"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedSeparate === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group
                                    className="ml-5"
                                    name="formPersonnelFilesMedRetention"
                                    as={Row}
                                    controlId="formPersonnelFilesMedRetention"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            iv. Retention Period?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPersonnelFilesMedRetention"
                                            id="formPersonnelFilesMedRetention-Yes"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedRetention === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPersonnelFilesMedRetention"
                                            id="formPersonnelFilesMedRetention-No"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedRetention === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPersonnelFilesMedRetention"
                                            id="formPersonnelFilesMedRetention-NA"
                                            defaultChecked={
                                            this.props.formPersonnelFilesMedRetention === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group
                                    className="ml-3"
                                    name="formPersonnelFilesSeparate"
                                    as={Row}
                                    controlId="formPersonnelFilesSeparate"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            f. Are employee investigation files kept separate?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formPersonnelFilesSeparate"
                                            id="formPersonnelFilesSeparate-Yes"
                                            defaultChecked={
                                            this.props.formPersonnelFilesSeparate === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formPersonnelFilesSeparate"
                                            id="formPersonnelFilesSeparate-No"
                                            defaultChecked={
                                            this.props.formPersonnelFilesSeparate === "no"
                                            }                               
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Unknown"
                                            value="unknown"
                                            name="formPersonnelFilesSeparate"
                                            id="formPersonnelFilesSeparate-NA"
                                            defaultChecked={
                                            this.props.formPersonnelFilesSeparate === "unknown"
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formPersonnelFilesChanges">
                                    <Form.Label>
                                        2. What is the process for submitting changes to personnel information?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesChanges"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesChanges || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPersonnelFilesDesks">
                                    <Form.Label>
                                        3. Do managers/supervisors keep files in their desk? If so, which files?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesDesks"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesDesks || ""}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPersonnelFilesRetention">
                                    <Form.Label>
                                        4. Retention period for personnel files?
                                    </Form.Label>
                                    <Form.Control
                                        name="formPersonnelFilesRetention"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formPersonnelFilesRetention || ""}
                                    />
                                </Form.Group>

                        </div>

                        <div className="form-section">
                            <h5>Job Descriptions</h5>
                            <hr />

                            <Form.Group controlId="formJDResponsible">
                                <Form.Label>
                                    5. Responsible Individuals?
                                </Form.Label>
                                <Form.Control
                                    name="formJDResponsible"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formJDResponsible || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJDLegal"
                                as={Row}
                                controlId="formJDLegal"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        a. Legal compliance language?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJDLegal"
                                        id="formJDLegal-Yes"
                                        defaultChecked={
                                        this.props.formJDLegal === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJDLegal"
                                        id="formJDLegal-No"
                                        defaultChecked={
                                        this.props.formJDLegal === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJDLegal"
                                        id="formJDLegal-NA"
                                        defaultChecked={
                                        this.props.formJDLegal === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJDSignatures"
                                as={Row}
                                controlId="formJDSignatures"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        b. Employee signatures?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJDSignatures"
                                        id="formJDSignatures-Yes"
                                        defaultChecked={
                                        this.props.formJDSignatures === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJDSignatures"
                                        id="formJDSignatures-No"
                                        defaultChecked={
                                        this.props.formJDSignatures === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJDSignatures"
                                        id="formJDSignatures-NA"
                                        defaultChecked={
                                        this.props.formJDSignatures === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJDFunctions"
                                as={Row}
                                controlId="formJDFunctions"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        c. Essential vs. non-essential functions?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJDFunctions"
                                        id="formJDFunctions-Yes"
                                        defaultChecked={
                                        this.props.formJDFunctions === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJDFunctions"
                                        id="formJDFunctions-No"
                                        defaultChecked={
                                        this.props.formJDFunctions === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJDFunctions"
                                        id="formJDFunctions-NA"
                                        defaultChecked={
                                        this.props.formJDFunctions === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJDClassification"
                                as={Row}
                                controlId="formJDClassification"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        d. Employment classification?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJDClassification"
                                        id="formJDClassification-Yes"
                                        defaultChecked={
                                        this.props.formJDClassification === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJDClassification"
                                        id="formJDClassification-No"
                                        defaultChecked={
                                        this.props.formJDClassification === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJDClassification"
                                        id="formJDClassification-NA"
                                        defaultChecked={
                                        this.props.formJDClassification === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJDReview"
                                as={Row}
                                controlId="formJDReview"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        e. Periodic review?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJDReview"
                                        id="formJDReview-Yes"
                                        defaultChecked={
                                        this.props.formJDReview === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJDReview"
                                        id="formJDReview-No"
                                        defaultChecked={
                                        this.props.formJDReview === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJDReview"
                                        id="formJDReview-NA"
                                        defaultChecked={
                                        this.props.formJDReview === "unknown"
                                        }
                                    />
                                </Form.Group>   

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default PersonnelFiles;