import React, { Component } from 'react';
import { Container, Button, Modal, Form, Col, Accordion, Card, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';

const API_URL = config.url.API_URL;

const StyledButton = styled(Button)`
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background: none;
  border: none;
  &:hover {
    color: ${(props) => props.theme.colors.orange};
    background: none;
    border: none;
  }

  div {
    margin-top: 1em;
    font-size: 0.75em;
    color: yellow;
  }
`;

const StyledClientButton = styled(Button)`
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.dark_blue};
  border: 1px solid ${(props) => props.theme.colors.dark_blue};
  &:hover {
    color: ${(props) => props.theme.colors.orange};
    background: ${(props) => props.theme.colors.dark_blue};
    border: 1px solid ${(props) => props.theme.colors.dark_blue};
  }

  div {
    margin-top: 1em;
    font-size: 0.75em;
    color: orange;
  }
`;

const StyledAddButton = styled(Button)`
    margin: 0px;
    padding: 0px;
    display: flex;
    color: black;
    background: none;
    border: none;
    &:hover {
    color: ${(props) => props.theme.colors.orange};
    background: none;
    border: none;
  }
`;

const ModalDiv = styled.div`
  background: ${(props) => props.theme.colors.white} !important;
  color: black;

  .form-header {
    background: linear-gradient(
      to right top,
      #14141e,
      #181d2a,
      #1a2636,
      #193042,
      #153a4e,
      #14455b,
      #105168,
      #085d75,
      #086d88,
      #077d9c,
      #048eb0,
      #009fc4
    );
    margin-top: 1.5em;
    padding: 1.5em;
    color: ${(props) => props.theme.colors.orange};

    .heading-name{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }

    .heading-button{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .form-section {
    border: 1px solid ${(props) => props.theme.colors.light_blue};
    padding: 2em;
    margin-top: 1em;
    border-radius: 0.2em;
  }

  .text-instruction{
      color: red;
      font-size: .75em;
  }
`;

const ModalButton = styled(Button)`

    margin-right: .5em;

    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.medium_blue};
    border: 1px solid ${props => props.theme.colors.medium_blue};
    &:hover{
        color: ${props => props.theme.colors.orange};
        background: ${props => props.theme.colors.dark_blue};
        border: 1px solid ${props => props.theme.colors.dark_blue};
    }

    div{
        margin-top: 1em;
        font-size: .75em;
        color: yellow;
    }

`

const StyledAccordion = styled(Accordion)`
    margin: 1em;

    .table-text{
        font-size: .9em;
    }
    
`

const StyledCard = styled(Card)`
    color: black;
`

const DivColor = styled.div`
    display: flex;
    margin-top: .5em;
    height: 60px;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
`

class ClientDatabase extends Component {
    constructor(props) {
        super(props);

        this.state = {
                clients: [],
                contacts: [],
                clientId: "",
                client: "",
                short: "",
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                logo: "",
                color1: "#000",
                color2: "#fff",
                lead: "",
                showHide: false,
                clientContact: "client",
                addEdit: "add",
                
                contactId: " ",
                contactClient: " ",
                contactShort: " ",
                contactTitle: " ",
                contactFirst: " ",
                contactLast: " ",
                contactJobTitle: " ",
                contactEmail: " ",
                contactPhone: " ",
                contactBirthday: " "
            }
    };

    componentDidMount() {
            this.refreshClientList();
            this.refreshContactList();
    }

    //////// MODAL METHODS
    //////// MODAL METHODS
    //////// MODAL METHODS
    //////// MODAL METHODS
    //////// MODAL METHODS
    
    handleModalShowClientCreate() {
            this.setState({
                client: "",
                short: "",
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                logo: `https://pprhr.com/assets/images/logos/logo-needed.png`,
                color1: "",
                color2: "",
                lead: "",
                clientContact: "client",
                showHide: true,
                addEdit: "add"
         });
    }

    handleModalHide() {
        console.log(`Close Modal`)
        this.setState({ showHide: false });
    };

    handleModalSave() {
        this.setState({ showHide: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        console.log(`${e.target.name}: ${e.target.value}`);
    };

    /////// CLIENT METHODS
    /////// CLIENT METHODS
    /////// CLIENT METHODS
    /////// CLIENT METHODS
    /////// CLIENT METHODS

    saveClient() {
        let data = {
            client: this.state.client,
            short: this.state.short,
            address: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            lead: this.state.lead,
            logo: `https://pprhr.com/assets/images/logos/logo-needed.png`
        }
        this.createClient(data);
        this.handleModalSave();
        console.log(data);
    };

    saveEditedClient(clientId) {
        let data = {
            client: this.state.client,
            short: this.state.short,
            address: this.state.address,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phone: this.state.phone,
            lead: this.state.lead
        }
        this.updateClient(clientId, data);
        this.handleModalSave();
        console.log(data);
    };

    clientEdit(clientId, client, short, address, address2, city, state, zip, phone, lead) {
        this.setState({
                clientId: clientId,
                client: client,
                short: short,
                address: address,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                phone: phone,
                lead: lead,
                clientContact: "client",
                showHide: true,
                addEdit: "edit"
         });
    }

    createClient(data) {
        fetch(API_URL + `/client/create`, {
            method: "PUT",
            body: JSON.stringify({
                data: data
            }),
            headers: { "Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then(() => {
                console.log('Refreshing List Now!');
                this.refreshClientList();
            })
        .catch((err) => console.log(err))
    }

    updateClient(clientId, data) {
        fetch(API_URL + `/client/update/${clientId}`, {
            method: "PUT",
            body: JSON.stringify({
                data: data
            }),
            headers: { "Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then(() => {
                console.log('Refreshing List Now!');
                this.refreshClientList();
            })
        .catch((err) => console.log(err))
    }

    refreshClientList() {
            fetch(API_URL + '/clients/all')
                .then(res => {
                    if (!res.ok) {
                        throw new Error();
                    }
                    return res.json();
                })
                .then((result) => {
                    this.setState({
                        clients: result.sort((a, b) => a.client.localeCompare(b.client)),
                    });
                })
                .catch(error => {
                    console.log(error);
                })
    }
    
    sendLogoColorRequest() {
        const subject = `Logo / Color Request for ${this.state.client}`;
        const body = `Adam, please setup the colors and logo in the PPR database for " ${this.state.client}. "`;
        window.open(`mailto:anorton@pprhr.com?subject=${subject}&body=${body}`);
    }

    ////////// Contact Methods
    ////////// Contact Methods
    ////////// Contact Methods
    ////////// Contact Methods
    ////////// Contact Methods

    handleModalShowContactCreate(client, short) {
        this.setState({
            contactClient: client,
            contactShort: short,
            contactTitle: " ",
            contactFirst: " ",
            contactLast: " ",
            contactJobTitle: " ",
            contactEmail: " ",
            contactPhone: " ",
            contactBirthday: " ",
            clientContact: "contact",
            showHide: true,
            addEdit: "add"
         });
    }

    refreshContactList() {
        fetch(API_URL + `/contacts/all`)
            .then(res => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((result) => {
                this.setState({ contacts: result });
                console.log(result);
            })
            .catch(error => {
                console.log(error);
            });
    }

    editContact(contactId, client, short, title, firstName, lastName, jobTitle, email, phone, birthday) {
        this.setState({
            addEdit: "edit",
            clientContact: "contact",
            contactId: contactId,
            contactClient: client,
            contactShort: short,
            contactTitle: title,
            contactFirst: firstName,
            contactLast: lastName,
            contactJobTitle: jobTitle,
            contactEmail: email,
            contactPhone: phone,
            contactBirthday: birthday,
            showHide: true
        })
    }

    saveContact() {
        let data = {
            contactClient: this.state.contactClient,
            contactShort: this.state.contactShort,
            contactTitle: this.state.contactTitle,
            contactFirst: this.state.contactFirst,
            contactLast: this.state.contactLast,
            contactJobTitle: this.state.contactJobTitle,
            contactEmail: this.state.contactEmail,
            contactPhone: this.state.contactPhone,
            contactBirthday: this.state.contactBirthday
        }
        this.createContact(data);
        this.handleModalSave();
        console.log(data);
    };

    saveEditedContact(contactId) {
        let data = {
            contactClient: this.state.contactClient,
            contactShort: this.state.contactShort,
            contactTitle: this.state.contactTitle,
            contactFirst: this.state.contactFirst,
            contactLast: this.state.contactLast,
            contactJobTitle: this.state.contactJobTitle,
            contactEmail: this.state.contactEmail,
            contactPhone: this.state.contactPhone,
            contactBirthday: this.state.contactBirthday
        }
        this.updateContact(contactId, data);
        this.handleModalSave();
        console.log(data);
    };

    createContact(data) {
        fetch(API_URL + `/contact/create`, {
            method: "PUT",
            body: JSON.stringify({
                data: data
            }),
            headers: { "Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then(() => {
                console.log('Refreshing List Now!');
                this.refreshContactList();
            })
        .catch((err) => console.log(err))
    }

    updateContact(contactId, data) {
        fetch(API_URL + `/contact/update/${contactId}`, {
            method: "PUT",
            body: JSON.stringify({
                data: data
            }),
            headers: { "Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then(() => {
                this.refreshContactList();
            })
        .catch((err) => console.log(err))
    }

    deleteContact(contactId) {
        console.log(`Deleting Contact: ${contactId}`);
        fetch(API_URL + `/contact/delete/${contactId}`, {
            method: "DELETE",
        }).then(() => {
            this.refreshContactList();
            console.log(`Contacts Refreshed`);
        }).catch((err) => console.log(err))
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Container className="database">
                    <div>
                        <div>
                            <StyledClientButton
                                className="ml-auto"
                                onClick={() => this.handleModalShowClientCreate()}
                                >
                                <i className="fal fa-file-user fa-2x"></i>
                                <div>New Client</div>
                            </StyledClientButton>
                        </div>
                        {this.state.clients.map(client => (   
                            <StyledAccordion key={client.id}>
                                <StyledCard>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={client.short}
                                    >
                                        <Row>
                                            <Col xs={4}>
                                                <h5>{client.client}</h5>
                                            </Col>
                                            <Col>
                                                <div>{client.short}</div>
                                            </Col>
                                            <Col>
                                                <div>{client.city}, {client.state}</div>
                                            </Col>
                                            <Col>
                                                <div>{client.lead}</div>
                                            </Col>
                                            <Col xs={1} className="text-right">
                                                <StyledButton
                                                    onClick={() => this.clientEdit(client.id, client.client, client.short, client.address, client.address2, client.city, client.state, client.zip, client.phone, client.lead)}>
                                                    <i className="far fa-edit"></i>
                                                </StyledButton>
                                                
                                            </Col>
                                        </Row>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={client.short}>
                                        <StyledCard.Body>
                                            <Row>
                                                <Col xs={7}>
                                                    <div>{client.address} </div>
                                                    <div>{client.address2}</div>
                                                    <div>{client.city}, {client.state} {client.zip}</div>
                                                    
                                                </Col>
                                                <Col xs={2} className="text-right">
                                                    <DivColor style={{ background: client.color1, color: client.color2 }}>Color 1: {client.color1}</DivColor>
                                                    <DivColor style={{ background: client.color2, color: client.color1}}>Color 2: {client.color2}</DivColor>
                                                </Col>
                                                <Col xs={3} className="text-right">
                                                    <div>
                                                        <img
                                                            src={client.logo}
                                                            width={200}
                                                            alt={client.client}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="d-flex m-0 p-0">
                                                <h5 className="mr-2">Contacts</h5>
                                                <StyledAddButton
                                                onClick={() => this.handleModalShowContactCreate(client.client, client.short)}><i className="fal fa-plus-square fa-lg pt-2"
                                                ></i></StyledAddButton>
                                                
                                            </div>
                                            <hr />
                                            <div>
                                                {this.state.contacts.filter(contact => contact.short === client.short).map(contact => (
                                                    <div key={contact.id}>
                                                        <Row>
                                                        <Col>
                                                            <h5>{contact.jobtitle}</h5>
                                                            <h4>{contact.firstname} {contact.lastname}</h4>
                                                        </Col>
                                                        <Col>
                                                            <p>Email: <a href={`mailto:${contact.email}`}>{contact.email}</a></p>
                                                        </Col>
                                                        <Col>
                                                            <p>Birthday: {contact.birthday}</p>
                                                            <p>Phone: {contact.phone}</p>  
                                                        </Col>
                                                            <Col xs={2} className="d-flex text-right">
                                                                <StyledButton className="mr-1"
                                                                onClick={() => this.editContact(contact.id, contact.client, contact.short, contact.title, contact.firstname, contact.lastname, contact.jobtitle, contact.email, contact.phone, contact.birthday)}>
                                                                    <i className="far fa-edit"
                                                                    ></i>
                                                                </StyledButton>
                                                                <StyledButton onClick={() => this.deleteContact(contact.id)}>
                                                                    <i className="far fa-trash-alt">
                                                                    </i>
                                                                </StyledButton>
                                                        </Col>
                                                        </Row>
                                                        <hr className="mb-3"/>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                
                            </StyledCard.Body>
                        </Accordion.Collapse>
                                </StyledCard>
                            </StyledAccordion>
                        ))}
                    </div>
                    <Modal show={this.state.showHide} size="lg" centered>
                            <Modal.Header>
                            <Modal.Title>{this.state.addEdit === "add" ? "Add New" : "Update" } {this.state.clientContact === "client" ? "Client" : "Contact" }</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ModalDiv>
                                    {this.state.clientContact === "client" ? 
                                        <Form className="p-3">
                  
                                        <div className="form-section">
                                            <h5>Contact Information</h5>
                                            <hr />
                                            <Form.Group controlId="organizationName">
                                            <Form.Label>Organization</Form.Label>
                                            <Form.Control
                                                name="client"
                                                type="text"
                                                placeholder="Organization Name"
                                                onChange={this.handleChange}
                                                value={this.state.client || ""}
                                            />
                                            </Form.Group>

                                            <Form.Group controlId="organizationShort">
                                            <Form.Label>Organization Shortcode</Form.Label>
                                            <Form.Control
                                                name="short"
                                                type="text"
                                                placeholder="Example: VVF, SWMF, DDI, etc..."
                                                onChange={this.handleChange}
                                                value={this.state.short || ""}
                                            />
                                            </Form.Group>

                                            <Form.Group controlId="address">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control
                                                name="address"
                                                type="text"
                                                placeholder="Address"
                                                onChange={this.handleChange}
                                                value={this.state.address || ""}
                                            />
                                            </Form.Group>

                                            <Form.Group controlId="address2">
                                            <Form.Label>Address 2</Form.Label>
                                            <Form.Control
                                                name="address2"
                                                type="text"
                                                placeholder="Suite, Floor, Unit, etc..."
                                                onChange={this.handleChange}
                                                value={this.state.address2 || ""}
                                            />
                                            </Form.Group>

                                            <Form.Row>
                                                <Form.Group as={Col} controlId="city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                        name="city"
                                                        type="text"
                                                    onChange={this.handleChange}
                                                    placeholder="City"
                                                    value={this.state.city || ""}
                                                />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="state">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control
                                                        name="state"
                                                        type="text"
                                                    placeholder="State"
                                                    onChange={this.handleChange}
                                                    value={this.state.state || ""}
                                                />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="zip">
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Control
                                                        name="zip"
                                                        type="text"
                                                    placeholder="Zip Code"
                                                    onChange={this.handleChange}
                                                    value={this.state.zip || ""}
                                                />
                                                </Form.Group>

                                            </Form.Row> 
                                            <Form.Group controlId="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                name="phone"
                                                type="tel"
                                                placeholder="(xxx)-xxx-xxxx"
                                                onChange={this.handleChange}
                                                value={this.state.phone}
                                            />
                                            </Form.Group>
                                            <Form.Group controlId="lead">
                                            <Form.Label>Lead Consultant</Form.Label>
                                            <Form.Control
                                                name="lead"
                                                type="text"
                                                placeholder="Name of Lead Consultant"
                                                onChange={this.handleChange}
                                                value={this.state.lead || ""}
                                            />
                                            </Form.Group>
                                        </div>
                                        
                                        {this.state.addEdit === "add" ?
                                                <div className="form-section">
                                            <h5>Logo and Company Colors</h5>
                                            <hr />
                                            <p>1. Make sure you have entered the company name above.</p>
                                            <p>2. Click the button below to send Adam an email that will let him know to setup logo and color scheme for this client in the database.</p>
                                            <ModalButton
                                                onClick={() => this.sendLogoColorRequest()}
                                            >
                                                Click to Send Logo/Colors Setup Request to Adam
                                            </ModalButton>
                                            <br/>
                                            <p className="text-instruction">* Just click the button, and then click send on the email that pops up.  No need to type anything additional into the email.</p>
                                            </div>
                                            :
                                            null
                                        } 
                                        

                                        <div className="text-right mt-3">
                                            <ModalButton
                                            className="ml-auto"
                                            onClick={() => this.handleModalHide()}
                                        >
                                            Cancel
                                        </ModalButton>
                                        <ModalButton
                                                onClick={() => {this.state.addEdit === "add" ? this.saveClient() : this.saveEditedClient(this.state.clientId) }}
                                        >
                                            {this.state.addEdit === "add" ? "Save New Client" : "Update Client" }
                                        </ModalButton>
                                        </div>
                                        
                                    </Form>
                                    :
                                    <div>
                                        <p>{this.state.contactClient} {this.state.contactShort}</p>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col}                          controlId="contactTitle">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control
                                                    name="contactTitle"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactTitle || ""}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}                          controlId="contactFirst">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                    name="contactFirst"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactFirst || ""}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}                          controlId="contactLast">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                    name="contactLast"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactLast || ""}
                                                    />
                                                    </Form.Group>
                                                <Form.Group as={Col}                          controlId="contactJobTitle">
                                                    <Form.Label>Job Title</Form.Label>
                                                    <Form.Control
                                                    name="contactJobTitle"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactJobTitle || ""}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}                          controlId="contactEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                    name="contactEmail"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactEmail || ""}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}                          controlId="contactPhone">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control
                                                    name="contactPhone"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactPhone || ""}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col}                          controlId="contactBirthday">
                                                    <Form.Label>Birthday</Form.Label>
                                                    <Form.Control
                                                    name="contactBirthday"
                                                    onChange={this.handleChange}
                                                    value={this.state.contactBirthday || ""}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                        <div className="text-right">
                                            <Button
                                            size="sm"
                                            variant="danger"
                                            onClick={() => this.handleModalHide()}
                                            >
                                            Cancel
                                            </Button>
                                            <Button
                                            className="ml-3"
                                            size="sm"
                                            variant="primary"
                                                onClick={() => { this.state.addEdit === "add" ? this.saveContact() : this.saveEditedContact(this.state.contactId)}}
                                            >
                                                {this.state.addEdit === "add" ? "Create New Contact" : "Update Contact" }
                                            </Button>
                                        </div>
                                    </div>
                                    
                                     }
                                    
                                </ModalDiv>
                            </Modal.Body>
                        </Modal>
                </Container>
            </ThemeProvider>
        )
    }


}


export default ClientDatabase;