import React, { Component } from 'react';
import { Container, Button, Modal, Jumbotron, Row, Col, Card } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';


const StyledDivGreen = styled.div`
    padding: 2em;
    color: white;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: green;
`

const StyledDivGray = styled.div`
    padding: 2em;
    color: white;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: gray;
`

const StyledButtonGreen = styled(Button)`
    padding: 2em;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: green;
`

const StyledButtonYellow = styled(Button)`
    padding: 2em;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: orange;
`

const StyledButtonRed = styled(Button)`
    padding: 2em;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: red;
`

const StyledButtonGray = styled(Button)`
    padding: 2em;
    border: 1px solid ${props => props.theme.colors.orange};
    background-color: gray;
`


class HCA_Dash extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "adam"
        }

    }

    render() {

        return (
            <ThemeProvider theme={theme}>
                <Container>
                    <h1 className="display-4 mt-4">{this.props.clientName}: {this.props.hcaId}</h1>
                    {/* <Jumbotron className='mt-4 p-3 text-dark'>
                        <Row>
                            <Col>
                                <h1 className="display-4">Part One</h1>
                                <p className="lead"> Planning - Define HCA Objectives and Methodology</p>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row className='mt-4'>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Meetings</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">HCA Objectives</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                        </Row>
                        
                    </Jumbotron> */}
                    
                    <Jumbotron className='mt-3 p-3 text-dark'>
                        <Row>
                            <Col>
                                <h1 className="display-4">Part Two</h1>
                                <p className="lead">Fieldwork - Information Gathering</p>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row className='mt-4'>
                            <Col>
                                <Card>
                                    {/* <StyledDivGray
                                        onClick={() => this.props.showDocs()}
                                        >
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Documentation</p>
                                        </Col>
                                    </StyledDivGray> */}
                                    <StyledDivGray
                                        onClick={() => this.props.showDocs()}
                                        >
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Documentation</p>
                                        </Col>
                                    </StyledDivGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledDivGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">HR Interview</p>
                                        </Col>
                                    </StyledDivGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledButtonGreen
                                        onClick={() => this.props.showCompliance()}>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">Enter Data Here</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Compliance</p>
                                        </Col>
                                    </StyledButtonGreen>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledDivGray
                                        //onClick={() => this.props.showCulture()}
                                    >
                                        <Row>
                                            <Col className="ml-auto">
                                                {/* <p className="text-center">https://ppr-team.com/survey/emp/{this.props.hcaId}</p> */}
                                                <p className="text-center">Inactive for now</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead text-center">Culture</p>
                                        </Col>
                                    </StyledDivGray>
                                </Card>
                            </Col>
                        </Row>
                    </Jumbotron>

                    
                    {/* <Jumbotron className='p-3 text-dark'>
                        <Row>
                            <Col>
                                <h1 className="display-4">Part Three</h1>
                                <p className="lead">Reporting - Results</p>
                            </Col>
                        </Row>
                        <hr className="my-2" />
                        <Row className='mt-4'>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Risks</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Results</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Action Plan</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                            <Col>
                                <Card>
                                    <StyledButtonGray>
                                        <Row>
                                            <Col className="ml-auto">
                                                <p className="text-right">0%</p>
                                            </Col>
                                        </Row>
                                        <Col>
                                            <p className="lead">Report</p>
                                        </Col>
                                    </StyledButtonGray>
                                </Card>
                            </Col>
                        </Row>
                    </Jumbotron> */}
                    <Modal show={this.state.showHideModal} size="lg" centered>
                      <Modal.Header>
                        <Modal.Title>Employee Survey Link</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                    
                    
                        <div className="text-right">
                            <Button
                            className="m-4"
                            size="lg"
                            variant="primary"
                            onClick={() => this.surveyConfig()}
                            >
                                Ok
                            </Button>
                        </div>
                          

                      </Modal.Body>
                    </Modal>
                </Container>
                
            </ThemeProvider>
        )
    }
}

export default HCA_Dash;

