import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class EmergencyClosings extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Emergency Closings</h1>
                    <p>It is the policy of {this.props.client} that offices be open during normal working hours to provide the service our donors and other {this.props.client} visitors require and expect from us.  In the event of severe weather, security concerns, health or safety hazards or other reasons, the President and CEO will determine if the office is to be closed. Emergency closings will be communicated to staff via phone or e-mail.  </p>

                    <p>{this.props.client} realizes its obligation to its employees’ physical well-being and strives to maintain a safe place for employees to work.  Your supervisor will advise of procedures to be followed when offices are closed because of inclement weather or when emergencies arise during the day. If the office is closed for a full day or more, employees will be compensated at straight time pay.  Such pay will not be used to calculate overtime.  If the office is open or opens late, employees should make a reasonable effort to get to work.  However, if conditions are such in the employee’s living area that he/she cannot travel safely to the office, the employee must notify their supervisor immediately.  A decision as to whether such time off will be paid time off will be made at the supervisor’s discretion.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default EmergencyClosings;
