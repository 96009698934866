import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class PersonalTimeOff extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Personal Time Off (2-Hour Rule)</h1>
                    <p>Occasionally, exempt staff may be permitted to take up to two hours of paid time off to conduct personal business that may not ordinarily be conducted outside of normal business hours. Such time must be scheduled in advance and generally should be used for time off to attend medical appointments; however, time off for other personal activities that cannot be ordinarily conducted outside of normal business hours may be scheduled at the discretion of the employee’s supervisor. </p>

                    <p>If an employee's time away from work will be greater than two hours, the employee is expected to utilize their PTO bank. {this.props.clientName} will monitor employees’ use of personal time off under this rule and at the discretion of the employee’s supervisor, may limit the employee’s use of personal time off if such time off exceeds more than once a quarter or is otherwise deemed excessive. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default PersonalTimeOff;
