import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class CultureManagers extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <h3>Management Questions</h3>
                            <div className="form-section">
                                <h4>Onboarding</h4>
                                <hr />

                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsatisfied and
                                5-Very Satisfied...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManOnboardWelcome"
                                controlId="formManOnboardWelcome"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    37. How successful is the onboarding process in making new employees feel welcome?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManOnboardWelcome"
                                    id="formManOnboardWelcome-1"
                                    defaultChecked={this.props.formManOnboardWelcome === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManOnboardWelcome"
                                    id="formManOnboardWelcome-2"
                                    defaultChecked={this.props.formManOnboardWelcome === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManOnboardWelcome"
                                    id="formManOnboardWelcome-3"
                                    defaultChecked={this.props.formManOnboardWelcome === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManOnboardWelcome"
                                    id="formManOnboardWelcome-4"
                                    defaultChecked={this.props.formManOnboardWelcome === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManOnboardWelcome"
                                    id="formManOnboardWelcome-5"
                                    defaultChecked={this.props.formManOnboardWelcome === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formManOrientation"
                                controlId="formManOrientation"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    38. How successful is the orientation program at educating new employee's about the organization?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManOrientation"
                                    id="formManOrientation-1"
                                    defaultChecked={this.props.formManOrientation === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManOrientation"
                                    id="formManOrientation-2"
                                    defaultChecked={this.props.formManOrientation === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManOrientation"
                                    id="formManOrientation-3"
                                    defaultChecked={this.props.formManOrientation === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManOrientation"
                                    id="formManOrientation-4"
                                    defaultChecked={this.props.formManOrientation === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManOrientation"
                                    id="formManOrientation-5"
                                    defaultChecked={this.props.formManOrientation === 5}
                                />
                                </Form.Group>

                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManInterviewConsistent"
                                controlId="formManInterviewConsistent"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    39. Our current interview process is consistent across the organization?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManInterviewConsistent"
                                    id="formManInterviewConsistent-1"
                                    defaultChecked={this.props.formManInterviewConsistent === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManInterviewConsistent"
                                    id="formManInterviewConsistent-2"
                                    defaultChecked={this.props.formManInterviewConsistent === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManInterviewConsistent"
                                    id="formManInterviewConsistent-3"
                                    defaultChecked={this.props.formManInterviewConsistent === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManInterviewConsistent"
                                    id="formManInterviewConsistent-4"
                                    defaultChecked={this.props.formManInterviewConsistent === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManInterviewConsistent"
                                    id="formManInterviewConsistent-5"
                                    defaultChecked={this.props.formManInterviewConsistent === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formManInterviewEffective"
                                controlId="formManInterviewEffective"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    39. Our current interview process is effective in brining on high quality talent?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManInterviewEffective"
                                    id="formManInterviewEffective-1"
                                    defaultChecked={this.props.formManInterviewEffective === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManInterviewEffective"
                                    id="formManInterviewEffective-2"
                                    defaultChecked={this.props.formManInterviewEffective === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManInterviewEffective"
                                    id="formManInterviewEffective-3"
                                    defaultChecked={this.props.formManInterviewEffective === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManInterviewEffective"
                                    id="formManInterviewEffective-4"
                                    defaultChecked={this.props.formManInterviewEffective === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManInterviewEffective"
                                    id="formManInterviewEffective-5"
                                    defaultChecked={this.props.formManInterviewEffective === 5}
                                />
                                </Form.Group>
                                
                            </div>

                            <div className="form-section">
                                <h4>Strategy</h4>
                                <hr />
                                 <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManStrategyVersed"
                                controlId="formManStrategyVersed"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    41. Employees are well versed regarding your strategic plan?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManStrategyVersed"
                                    id="formManStrategyVersed-1"
                                    defaultChecked={this.props.formManStrategyVersed === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManStrategyVersed"
                                    id="formManStrategyVersed-2"
                                    defaultChecked={this.props.formManStrategyVersed === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManStrategyVersed"
                                    id="formManStrategyVersed-3"
                                    defaultChecked={this.props.formManStrategyVersed === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManStrategyVersed"
                                    id="formManStrategyVersed-4"
                                    defaultChecked={this.props.formManStrategyVersed === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManStrategyVersed"
                                    id="formManStrategyVersed-5"
                                    defaultChecked={this.props.formManStrategyVersed === 5}
                                />
                                </Form.Group>
                                
                            </div>

                            <div className="form-section">
                                <h4>Business Continuity</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Not at all Confident and
                                5-Extremely Confident...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManBusCont"
                                controlId="formManBusCont"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    42. 42.	How confident are you that your direct reports are adequately equipped to fulfill your role (or the role of other leaders on your leadership team) in the event you or another leader are unexpectedly unavailable??
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManBusCont"
                                    id="formManBusCont-1"
                                    defaultChecked={this.props.formManBusCont === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManBusCont"
                                    id="formManBusCont-2"
                                    defaultChecked={this.props.formManBusCont === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManBusCont"
                                    id="formManBusCont-3"
                                    defaultChecked={this.props.formManBusCont === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManBusCont"
                                    id="formManBusCont-4"
                                    defaultChecked={this.props.formManBusCont === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManBusCont"
                                    id="formManBusCont-5"
                                    defaultChecked={this.props.formManBusCont === 5}
                                />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h4>Performance Review</h4>
                                <hr />
                                <Form.Group
                                    name="formPerformanceMonitor"
                                    as={Row}
                                    controlId="formPerformanceMonitor"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        43.	How do you manage/monitor your team’s performance? (Check all that apply)
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="1:1 Employee/Manager Meetings"
                                        name="formPerformanceMonitorMeetings"
                                        id="formPerformanceMonitorMeetings"
                                        defaultChecked={this.props.formPerformanceMonitorMeetings}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Annual Performance Reviews"
                                        name="formPerformanceMonitorAnnual"
                                        id="formPerformanceMonitorAnnual"
                                        defaultChecked={
                                            this.props.formPerformanceMonitorAnnual
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="More Frequent Performance Reviews"
                                        name="formPerformanceMonitorFrequent"
                                        id="formPerformanceMonitorFrequent"
                                        defaultChecked={
                                            this.props.formPerformanceMonitorFrequent
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Project Completion/Team Evaluations"
                                        name="formPerformanceMonitorEvaluations"
                                        id="formPerformanceMonitorEvaluations"
                                        defaultChecked={this.props.formPerformanceMonitorEvaluations}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Individual Goal Setting"
                                        name="formPerformanceMonitorGoal"
                                        id="formPerformanceMonitorGoal"
                                        defaultChecked={
                                            this.props.formPerformanceMonitorGoal
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="360 Reviews"
                                        name="formPerformanceMonitor360"
                                        id="formPerformanceMonitor360"
                                        defaultChecked={
                                            this.props.formPerformanceMonitor360
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formPerformanceMonitorOther"
                                        id="formPerformanceMonitorOther"
                                        defaultChecked={this.props.formPerformanceMonitorOther}
                                        />
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formPerformanceMonitorOther === true ||
                                        this.props.formPerformanceMonitorOther === 1) && (
                                        <Form.Group controlId="formPerformanceMonitorOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formPerformanceMonitorOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formPerformanceMonitorOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group
                                    name="formManFlex"
                                    as={Row}
                                    controlId="formManFlex"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        44.	What types of flexible programs are available to employees? (Check all that apply)
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Flexible Hours"
                                        name="formManFlexHours"
                                        id="formManFlexHours"
                                        defaultChecked={this.props.formManFlexHours}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Remote Work"
                                        name="formManRemote"
                                        id="formManRemote"
                                        defaultChecked={
                                            this.props.formManRemote
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Compressed Work Week"
                                        name="formManCompressed"
                                        id="formManCompressed"
                                        defaultChecked={
                                            this.props.formManCompressed
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Job Sharing"
                                        name="formManJobShare"
                                        id="formManJobShare"
                                        defaultChecked={this.props.formManJobShare}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Part-Time Employment"
                                        name="formManPartTime"
                                        id="formManPartTime"
                                        defaultChecked={
                                            this.props.formManPartTime
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Seasonal"
                                        name="formManSeasonal"
                                        id="formManSeasonal"
                                        defaultChecked={
                                            this.props.formManSeasonal
                                        }
                                        />
                                        
                                    </Col>
                                </Form.Group>
                                
                            </div>

                            <div className="form-section">
                                <h4>Compensation</h4>
                                <hr />
                                <Form.Group className="ml-3" controlId="formManSalaryDecide">
                                <Form.Label>
                                    45.	How are salaries decided? 
                                </Form.Label>
                                <Form.Control
                                    name="formManSalaryDecide"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formManSalaryDecide || ""}
                                />
                                </Form.Group>
                                <Form.Group className="ml-3" controlId="formManSalaryComm">
                                <Form.Label>
                                    46.	How are salary changes communicated?
                                </Form.Label>
                                <Form.Control
                                    name="formManSalaryComm"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formManSalaryComm || ""}
                                />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h4>Corrective Action</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsatisfied and
                                5-Very Satisfied...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManCorrectives"
                                controlId="formManCorrectives"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    47.	How satisfied are you with the corrective action process as it relates to improving employee performance?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManCorrectives"
                                    id="formManCorrectives-1"
                                    defaultChecked={this.props.formManCorrectives === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManCorrectives"
                                    id="formManCorrectives-2"
                                    defaultChecked={this.props.formManCorrectives === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManCorrectives"
                                    id="formManCorrectives-3"
                                    defaultChecked={this.props.formManCorrectives === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManCorrectives"
                                    id="formManCorrectives-4"
                                    defaultChecked={this.props.formManCorrectives === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManCorrectives"
                                    id="formManCorrectives-5"
                                    defaultChecked={this.props.formManCorrectives === 5}
                                />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h4>Leadership Development</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formManLeaders"
                                controlId="formManLeaders"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    48.	The organization adequately prepares leaders for the next level of success
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formManLeaders"
                                    id="formManLeaders-1"
                                    defaultChecked={this.props.formManLeaders === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formManLeaders"
                                    id="formManLeaders-2"
                                    defaultChecked={this.props.formManLeaders === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formManLeaders"
                                    id="formManLeaders-3"
                                    defaultChecked={this.props.formManLeaders === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formManLeaders"
                                    id="formManLeaders-4"
                                    defaultChecked={this.props.formManLeaders === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formManLeaders"
                                    id="formManLeaders-5"
                                    defaultChecked={this.props.formManLeaders === 5}
                                />
                                </Form.Group>
                            </div>

                            <div className="form-section">
                                <h4>Other</h4>
                                <hr />
                                <Form.Group className="ml-3" controlId="formManOther">
                                <Form.Label>
                                   49.	Is there anything else you would like to share?
                                </Form.Label>
                                <Form.Control
                                    name="formManOther"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formManOther || ""}
                                />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default CultureManagers;