import React from 'react';
import { Button } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../Config/Theme";
import AuthNav from "../Authentication/AuthNav";
import { useAuth0 } from '@auth0/auth0-react';

const Div = styled.div`
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${"" /* justify-content: center; */}
  background: ${(props) => props.theme.colors.dark_blue};
  border-right: 1px solid ${(props) => props.theme.colors.light_blue};
  .under-dev-tools {
    border-top: 1px solid ${(props) => props.theme.colors.orange};
    p {
      color: ${(props) => props.theme.colors.orange};
    }
  }
`;

const StyledButton = styled(Button)`
    margin-top: 1em;
    ${'' /* width: 10em;
    height: 7em; */}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.dark_blue};
    border: 1px solid ${props => props.theme.colors.dark_blue};
    &:hover{
        color: ${props => props.theme.colors.orange};
        background: ${props => props.theme.colors.dark_blue};
        border: 1px solid ${props => props.theme.colors.dark_blue};
    }
    div{
        margin-top: 1em;
        font-size: .75em;
    }
    
`

const StyledGrayButton = styled(Button)`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
  background: ${(props) => props.theme.colors.dark_blue};
  border: 1px solid ${(props) => props.theme.colors.dark_blue};
  &:hover {
    color: ${(props) => props.theme.colors.orange};
    background: ${(props) => props.theme.colors.dark_blue};
    border: 1px solid ${(props) => props.theme.colors.dark_blue};
  }
  div {
    margin-top: 1em;
    font-size: 0.75em;
  }
`;

const MenuBar = () => {

  const { isAuthenticated, user } = useAuth0();


        return (
          <ThemeProvider theme={theme}>
            <Div>
              <div className="active-tools">
                <StyledButton href="/">
                  <i className="fa-duotone fa-tachometer-alt fa-2x"></i>
                  <div>Dashboard</div>
                </StyledButton>
                <StyledButton href="/database">
                  <i className="fa-duotone fa-server fa-2x"></i>
                  <div>Database</div>
                </StyledButton>
                <StyledButton href="/hca">
                  <i className="fa-duotone fa-user-chart fa-2x"></i>
                  <div>HCA</div>
                </StyledButton>
                <StyledButton href="/handbook">
                  <i className="fa-duotone fa-book fa-2x"></i>
                  <div>Handbook</div>
                </StyledButton>
                <StyledButton href="/ratesheet">
                  <i className="fa-duotone fa-money-check-dollar-pen fa-2x"></i>
                  <div>Rate Book</div>
                </StyledButton>
                {(isAuthenticated && (user.name === "anorton@pprhr.com" || user.name === "gnorton@pprhr.com" || user.name==="kbenson@pprhr.com" || user.name === "jkelly@pprhr.com") ?
                  <StyledButton href="/billingcalculator">
                  <i className="fa-duotone fa-face-tongue-money fa-2x"></i>
                  <div>Billing<br/>Calculator</div>
                </StyledButton> : null )}
              </div>
              


              <div className="under-dev-tools text-center px-2 pt-2 mb-5">
                <p>
                  Under
                  <br />
                  Development
                </p>
                
                <StyledGrayButton href="#">
                  <i className="fa-duotone fa-folder-tree fa-2x"></i>
                  <div>Template Library</div>
                </StyledGrayButton>
                
                <StyledGrayButton href="#">
                  <i className="fa-duotone fa-film fa-2x"></i>
                  <div>Video Library</div>
                </StyledGrayButton>
                <div className="login mt-5">
                  <AuthNav />
                </div>
              </div>
            </Div>
          </ThemeProvider>
        );
}

export default MenuBar;
