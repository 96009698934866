import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class JobDescriptions extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Job Descriptions</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unfamiliar and
                                5-Very Unfamiliar...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formJobDescFamiliar"
                                controlId="formJobDescFamiliar"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    20. How familiar are you with your job description?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formJobDescFamiliar"
                                    id="formJobDescFamiliar-1"
                                    defaultChecked={this.props.formJobDescFamiliar === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formJobDescFamiliar"
                                    id="formJobDescFamiliar-2"
                                    defaultChecked={this.props.formJobDescFamiliar === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formJobDescFamiliar"
                                    id="formJobDescFamiliar-3"
                                    defaultChecked={this.props.formJobDescFamiliar === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formJobDescFamiliar"
                                    id="formJobDescFamiliar-4"
                                    defaultChecked={this.props.formJobDescFamiliar === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formJobDescFamiliar"
                                    id="formJobDescFamiliar-5"
                                    defaultChecked={this.props.formJobDescFamiliar === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formJobDescUnderstand"
                                as={Row}
                                controlId="formJobDescUnderstand"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        21. Do you understand what is expected of you in your role?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formJobDescUnderstand"
                                        id="formJobDescUnderstand-Yes"
                                        defaultChecked={
                                        this.props.formJobDescUnderstand === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formJobDescUnderstand"
                                        id="formJobDescUnderstand-No"
                                        defaultChecked={
                                        this.props.formJobDescUnderstand === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formJobDescUnderstand"
                                        id="formJobDescUnderstand-NA"
                                        defaultChecked={
                                        this.props.formJobDescUnderstand === "unknown"
                                        }
                                    />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default JobDescriptions;