import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class OnOffBoarding extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>On and Off-Boarding</h4>
                                
                                <Form.Group controlId="formOnOffResponsible">
                                <Form.Label>
                                    6. Responsible Individual/s?
                                </Form.Label>
                                <Form.Control
                                    name="formOnOffResponsible"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOnOffResponsible || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formOnOffProcedures">
                                <Form.Label>
                                    a. Procedures/Schedule
                                </Form.Label>
                                <Form.Control
                                    name="formOnOffProcedures"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOnOffProcedures || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formOnOffTopics">
                                <Form.Label>
                                    b. Topics Covered
                                </Form.Label>
                                <Form.Control
                                    name="formOnOffTopics"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formOnOffTopics || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formOnOffPacket"
                                as={Row}
                                controlId="formOnOffPacket"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        c. Formal Packet?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formOnOffPacket"
                                        id="formOnOffPacket-Yes"
                                        defaultChecked={
                                        this.props.formOnOffPacket === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formOnOffPacket"
                                        id="formOnOffPacket-No"
                                        defaultChecked={
                                        this.props.formOnOffPacket === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formOnOffPacket"
                                        id="formOnOffPacket-NA"
                                        defaultChecked={
                                        this.props.formOnOffPacket === "unknown"
                                        }
                                    />
                                </Form.Group>

                            </div>
                            <div className="form-section">
                                <h5>Employment Classification</h5>
                                <hr />
                                
                                <Form.Group controlId="formClassExempt">
                                <Form.Label>
                                    7. How is exemption status determined?
                                </Form.Label>
                                <Form.Control
                                    name="formClassExempt"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassExempt || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formClassHours">
                                <Form.Label>
                                    8.How are hours tracked for nonexempt workers?
                                </Form.Label>
                                <Form.Control
                                    name="formClassHours"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassHours || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                name="formClassContractors"
                                as={Row}
                                controlId="formClassContractors"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        9. Independent contractors?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formClassContractors"
                                        id="formClassContractors-Yes"
                                        defaultChecked={
                                        this.props.formClassContractors === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formClassContractors"
                                        id="formClassContractors-No"
                                        defaultChecked={
                                        this.props.formClassContractors === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formClassContractors"
                                        id="formClassContractors-NA"
                                        defaultChecked={
                                        this.props.formClassContractors === "unknown"
                                        }
                                    />
                                </Form.Group>
                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formClassPaid">
                                <Form.Label>
                                    i. How are they paid?
                                </Form.Label>
                                <Form.Control
                                    name="formClassPaid"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassPaid || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassManages">
                                <Form.Label>
                                    ii. How and who manages their work?
                                </Form.Label>
                                <Form.Control
                                    name="formClassManages"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassManages || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassServices">
                                <Form.Label>
                                    iii. What services do they provide?
                                </Form.Label>
                                <Form.Control
                                    name="formClassServices"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassServices || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassLength">
                                <Form.Label>
                                    iv. How long do you intend to utilize the service of the independent contractors?
                                </Form.Label>
                                <Form.Control
                                    name="formClassLength"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassLength || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassContractorHours">
                                <Form.Label>
                                    v. How many hours a week do contractors work?
                                </Form.Label>
                                <Form.Control
                                    name="formClassContractorHours"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formClassContractorHours || ""}
                                />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassRequired">
                                    <Form.Label>
                                        vi. What is required for an independent contractor to work with the organization?
                                    </Form.Label>
                                    <Form.Control
                                        name="formClassRequired"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formClassRequired || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formClassEnforcement"
                                as={Row}
                                controlId="formClassEnforcement"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        10. Enforcement Activity
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formClassEnforcement"
                                        id="formClassEnforcement-Yes"
                                        defaultChecked={
                                        this.props.formClassEnforcement === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formClassEnforcement"
                                        id="formClassEnforcement-No"
                                        defaultChecked={
                                        this.props.formClassEnforcement === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formClassEnforcement"
                                        id="formClassEnforcement-NA"
                                        defaultChecked={
                                        this.props.formClassEnforcement === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">b. If yes:</p>

                                <Form.Group className="ml-5" controlId="formClassEnforcementLabor">
                                    <Form.Label>
                                        i. Department of Labor Audit?
                                    </Form.Label>
                                    <Form.Control
                                        name="formClassEnforcementLabor"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formClassEnforcementLabor || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassEnforcementComplaints">
                                    <Form.Label>
                                        ii. Wage and Hour Complaints?
                                    </Form.Label>
                                    <Form.Control
                                        name="formClassEnforcementComplaints"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formClassEnforcementComplaints || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formClassEnforcementLitigation">
                                    <Form.Label>
                                        iii. Wage and Hour Litigation?
                                    </Form.Label>
                                    <Form.Control
                                        name="formClassEnforcementLitigation"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formClassEnforcementLitigation || ""}
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-section">
                                <h5>Immigration Control Act</h5>
                                <hr />

                                <Form.Group
                                name="formImmigrationI9"
                                as={Row}
                                controlId="formImmigrationI9"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        11. Do all employees complete an I-9 from prior to starting work?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formImmigrationI9"
                                        id="formImmigrationI9-Yes"
                                        defaultChecked={
                                        this.props.formImmigrationI9 === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formImmigrationI9"
                                        id="formImmigrationI9-No"
                                        defaultChecked={
                                        this.props.formImmigrationI9 === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formImmigrationI9"
                                        id="formImmigrationI9-NA"
                                        defaultChecked={
                                        this.props.formImmigrationI9 === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationManages">
                                    <Form.Label>
                                        a. Who manages?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationManages"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationManages || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationProcess">
                                    <Form.Label>
                                        b. What is the current process?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationProcess"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationProcess || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationKept">
                                    <Form.Label>
                                        c. Where are the kept?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationKept"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationKept || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationRetention">
                                    <Form.Label>
                                        d. Retention?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationRetention"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationRetention || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formImmigrationID"
                                as={Row}
                                controlId="formImmigrationID"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        12. Are employee's identification documents verified within three (3) days of their start date?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formImmigrationID"
                                        id="formImmigrationID-Yes"
                                        defaultChecked={
                                        this.props.formImmigrationID === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formImmigrationID"
                                        id="formImmigrationID-No"
                                        defaultChecked={
                                        this.props.formImmigrationID === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formImmigrationID"
                                        id="formImmigrationID-NA"
                                        defaultChecked={
                                        this.props.formImmigrationID === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-3"
                                name="formImmigrationIDRetained"
                                as={Row}
                                controlId="formImmigrationIDRetained"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        e. Are identifying docs retained?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formImmigrationIDRetained"
                                        id="formImmigrationIDRetained-Yes"
                                        defaultChecked={
                                        this.props.formImmigrationIDRetained === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formImmigrationIDRetained"
                                        id="formImmigrationIDRetained-No"
                                        defaultChecked={
                                        this.props.formImmigrationIDRetained === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formImmigrationIDRetained"
                                        id="formImmigrationIDRetained-NA"
                                        defaultChecked={
                                        this.props.formImmigrationIDRetained === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationStored">
                                    <Form.Label>
                                        f. Where and how are those documents and I-9 forms stored?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationStored"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationStored || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                name="formImmigrationEVerify"
                                as={Row}
                                controlId="formImmigrationEVerify"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        13. E-Verify
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formImmigrationEVerify"
                                        id="formImmigrationEVerify-Yes"
                                        defaultChecked={
                                        this.props.formImmigrationEVerify === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formImmigrationEVerify"
                                        id="formImmigrationEVerify-No"
                                        defaultChecked={
                                        this.props.formImmigrationEVerify === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formImmigrationEVerify"
                                        id="formImmigrationEVerify-NA"
                                        defaultChecked={
                                        this.props.formImmigrationEVerify === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationEVerifyManage">
                                    <Form.Label>
                                        g. Who manages?
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationEVerifyManage"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationEVerifyManage || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formImmigrationEVerifyNotes">
                                    <Form.Label>
                                        h. Notes on format:
                                    </Form.Label>
                                    <Form.Control
                                        name="formImmigrationEVerifyNotes"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formImmigrationEVerifyNotes || ""}
                                    />
                                </Form.Group>
                            </div>
                            <div className="form-section">
                                <h5>EEO-1 Reporting & Affirmative Action Plans (AAP)</h5>
                                <p>(EEO-1 applicable only to those with 100+ employees for private employers and 50+ employees for public sector)</p>

                                <Form.Group
                                name="formEEO"
                                as={Row}
                                controlId="formEEO"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        14. Does any part of the organization perform work on a contract with or for any agency of the United States government?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEO"
                                        id="formEEO-Yes"
                                        defaultChecked={
                                        this.props.formEEO === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEO"
                                        id="formEEO-No"
                                        defaultChecked={
                                        this.props.formEEO === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEO"
                                        id="formEEO-NA"
                                        defaultChecked={
                                        this.props.formEEO === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formEEOContract">
                                    <Form.Label>
                                        i. What is the current amount of the contract?
                                    </Form.Label>
                                    <Form.Control
                                        name="formEEOContract"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formEEOContract || ""}
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-5"
                                name="formEEOJobBank"
                                as={Row}
                                controlId="formEEOJobBank"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        ii. Are open jobs posted with the state workforce agency job bank?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEOJobBank"
                                        id="formEEOJobBank-Yes"
                                        defaultChecked={
                                        this.props.formEEOJobBank === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEOJobBank"
                                        id="formEEOJobBank-No"
                                        defaultChecked={
                                        this.props.formEEOJobBank === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEOJobBank"
                                        id="formEEOJobBank-NA"
                                        defaultChecked={
                                        this.props.formEEOJobBank === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-5"
                                name="formEEODisclaimer"
                                as={Row}
                                controlId="formEEODisclaimer"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        iii. Do employment postings include the EEO disclaimer?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEODisclaimer"
                                        id="formEEODisclaimer-Yes"
                                        defaultChecked={
                                        this.props.formEEODisclaimer === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEODisclaimer"
                                        id="formEEODisclaimer-No"
                                        defaultChecked={
                                        this.props.formEEODisclaimer === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEODisclaimer"
                                        id="formEEODisclaimer-NA"
                                        defaultChecked={
                                        this.props.formEEODisclaimer === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-5"
                                name="formEEOData"
                                as={Row}
                                controlId="formEEOData"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        iv. How is EEO data captured? (Self ID form, visual determination)?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEOData"
                                        id="formEEOData-Yes"
                                        defaultChecked={
                                        this.props.formEEOData === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEOData"
                                        id="formEEOData-No"
                                        defaultChecked={
                                        this.props.formEEOData === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEOData"
                                        id="formEEOData-NA"
                                        defaultChecked={
                                        this.props.formEEOData === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-5"
                                name="formEEO1_Report"
                                as={Row}
                                controlId="formEEO1_Report"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        v. If 100 or more employees or 50+ employees with federal contracts of $50k+, filing the annual EEO-1 report?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEO1_Report"
                                        id="formEEO1_Report-Yes"
                                        defaultChecked={
                                        this.props.formEEO1_Report === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEO1_Report"
                                        id="formEEO1_Report-No"
                                        defaultChecked={
                                        this.props.formEEO1_Report === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEO1_Report"
                                        id="formEEO1_Report-NA"
                                        defaultChecked={
                                        this.props.formEEO1_Report === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                className="ml-5"
                                name="formEEO4212_Report"
                                as={Row}
                                controlId="formEEO4212_Report"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        vi.	If a federal contractor and subcontractor of $150k regardless of size, filing the Vets-4212 report(formerly Vets-100)?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formEEO4212_Report"
                                        id="formEEO4212_Report-Yes"
                                        defaultChecked={
                                        this.props.formEEO4212_Report === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formEEO4212_Report"
                                        id="formEEO4212_Report-No"
                                        defaultChecked={
                                        this.props.formEEO4212_Report === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formEEO4212_Report"
                                        id="formEEO4212_Report-NA"
                                        defaultChecked={
                                        this.props.formEEO4212_Report === "unknown"
                                        }
                                    />
                                </Form.Group>

                                <p className="ml-3">If yes:</p>

                                <Form.Group className="ml-5" controlId="formAAPGoals">
                                    <Form.Label>
                                        i. Goals for the current AAP plan year?
                                    </Form.Label>
                                    <Form.Control
                                        name="formAAPGoals"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formAAPGoals || ""}
                                    />
                                </Form.Group>

                                <Form.Group className="ml-5" controlId="formAAPOutreach">
                                    <Form.Label>
                                        ii. Describe outreach plan to address goals
                                    </Form.Label>
                                    <Form.Control
                                        name="formAAPOutreach"
                                        as="textarea"
                                        rows="4"
                                        onChange={this.props.handleChange}
                                        value={this.props.formAAPOutreach || ""}
                                    />
                                </Form.Group>

                                

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default OnOffBoarding;