import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Tobacco extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Tobacco-Free Workplace</h1>
                    <p>{this.props.clientName} desires to encourage a healthy lifestyle and protect the health and well-being of employees and visitors by promoting a tobacco-free workplace. The use of tobacco products including but not limited to cigarettes, e-cigarettes, cigars, pipes, and smokeless tobacco are prohibited inside any of the {this.props.clientName} offices or office building. This policy relates to all work areas at all times, including before and after normal working hours.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Tobacco;
