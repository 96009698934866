import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class CyberSecurity extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Cyber Security Policy</h1>
                    <p>{this.props.clientName} cyber security policy outlines its guidelines and provisions for preserving the security of its data and technology infrastructure. The more the Company relies on technology to collect, store and manage information, the more vulnerable we become to severe security breaches. Human errors, hacker attacks and system malfunctions could cause great financial damage and may jeopardize the Company’s reputation.</p>

                    <p>For this reason, {this.props.clientName} has implemented a number of security measures. Client Name has also prepared instructions that may help mitigate security risks. We have outlined both provisions in this policy.</p>

                    <h2>Confidential Data</h2>
                    <p>Confidential data is secret and valuable. Common examples are:
                        <ul>
                            <li>Unpublished financial information</li>
                            <li>Data of customers/partners/vendors</li>
                            <li>Personal identifiable information</li>
                            <li>Patents, formulas or new technologies</li>
                            <li>Customer lists (existing and prospective)</li>
                        </ul>
                    </p>
                    <p>All employees are obliged to protect this data. In this policy, we will give employees instructions on how to avoid security breaches.</p>

                    <h2>Confidential Data</h2>
                    <p>Confidential data is secret and valuable. Common examples are:
                        <ul>
                            <li>Unpublished financial information</li>
                            <li>Data of customers/partners/vendors</li>
                            <li>Personal identifiable information</li>
                            <li>Patents, formulas or new technologies</li>
                            <li>Customer lists (existing and prospective)</li>
                        </ul>
                    </p>
                    <p>All employees are obliged to protect this data. In this policy, we will give employees instructions on how to avoid security breaches.</p>

                    <h2>Protect Personal and Company Devices</h2>
                    <p>When employees use their digital devices to access company emails or accounts, they introduce security risk to our data. We advise our employees to keep both their personal and company-issued computer, tablet and cell phone secure. They can do this if they:
                        <ul>
                            <li>Keep all devices password protected.</li>
                            <li>Choose and upgrade a complete antivirus software.</li>
                            <li>Ensure they do not leave their devices exposed or unattended.</li>
                            <li>Install security updates of browsers and systems monthly or as soon as updates are available.</li>
                            <li>Log into company accounts and systems through secure and private networks only.</li>
                        </ul>
                    </p>
                    <p>We also advise our employees to avoid accessing internal systems and accounts from other people’s devices or lending their own devices to others.</p>

                    <h2>Keep Emails Safe</h2>
                    <p>Emails often host scams and malicious software (e.g. worms.) To avoid virus infection or data theft, we instruct employees to:
                        <ul>
                            <li>Avoid opening attachments and clicking on links when the content is not adequately explained (e.g. “watch this video, it’s amazing.”)</li>
                            <li>Be suspicious of clickbait titles (e.g. offering prizes, advice.)</li>
                            <li>Check email and names of people they received a message from to ensure they are legitimate.</li>
                            <li>Look for inconsistencies or give-aways (e.g. grammar mistakes, capital letters, excessive number of exclamation marks.)</li>
                        </ul>
                    </p>
                    <p>If an employee isn’t sure that an email they received is safe, they can refer to our IT Specialist or call the sender to confirm legitimacy. </p>

                    <h2>Manage Passwords Properly</h2>
                    <p>Password leaks are dangerous since they can compromise our entire infrastructure. Not only should passwords be secure so they won’t be easily hacked, but they should also remain secret. For this reason, we advise our employees to:
                        <ul>
                            <li>Choose passwords with at least eight characters (including capital and lower-case letters, numbers and symbols) and avoid information that can be easily guessed (e.g. birthdays.)</li>
                            <li>Remember passwords instead of writing them down. If employees need to write their passwords, they are obliged to keep the paper or digital document confidential and destroy it when their work is done.</li>
                            <li>Exchange credentials only when absolutely necessary. When exchanging them in-person isn’t possible, employees should prefer the phone instead of email, and only if they personally recognize the person they are talking to.</li>
                            <li>Change their passwords every two months.</li>
                        </ul>
                    </p>

                    <h2>Transfer Data Securely</h2>
                    <p>Transferring data introduces security risk. Employees must:
                        <ul>
                            <li>Avoid transferring sensitive data (e.g. customer information, employee records) to other devices or accounts, unless absolutely necessary. When mass transfer of such data is needed, we request employees to ask our [Security Specialists] for help</li>
                            <li>Share confidential data over the company network/system and not over public Wi-Fi or private connection. Resist the urge to access the company network/system at places like coffee shops, hotels and airports that have unsecured Wi-Fi. </li>
                            <li>Ensure that the recipients of the data are properly authorized people or organizations and have adequate security policies.</li>
                            <li>Report scams, privacy breaches and hacking attempts.</li>
                        </ul>
                    </p>
                    <p>Our [IT Specialists/ Network Engineers] need to know about scams, breaches and malware so they can better protect our infrastructure. For this reason, we advise our employees to report perceived attacks, suspicious emails or phishing attempts as soon as possible to our specialists. Our [IT Specialists/ Network Engineers] must investigate promptly, resolve the issue and send a companywide alert when necessary.</p>

                    <p>Our Security Specialists are responsible for advising employees on how to detect scam emails. We encourage our employees to reach out to them with any questions or concerns.</p>

                    <h2>Additional Measures</h2>
                    <p>To reduce the likelihood of security breaches, we also instruct our employees to:
                        <ul>
                            <li>Turn off their screens and lock their devices when leaving their desks.</li>
                            <li>Report stolen or damaged equipment as soon as possible to [HR/ IT Department].</li>
                            <li>Change all account passwords at once when a device is lost or stolen.</li>
                            <li>Report a perceived threat or possible security weakness in company systems.</li>
                            <li>Refrain from downloading suspicious, unauthorized or illegal software on their company equipment.</li>
                            <li>Avoid accessing suspicious websites.</li>
                        </ul>
                    </p>
                    <p>We also expect our employees to comply with our social media and information technology (IT) policy in the employee handbook.</p>
                    <p>Our [Security Specialists/ Network Administrators] should:
                        <ul>
                            <li>Install firewalls, anti-malware software and access authentication systems.</li>
                            <li>Arrange for security training to all employees.</li>
                            <li>Inform employees regularly about new scam emails or viruses and ways to combat them.</li>
                            <li>Investigate security breaches thoroughly.</li>
                            <li>Follow this policies provisions as other employees do.</li>
                        </ul>
                    </p>
                    <p>Our company will have all physical and digital shields to protect information.</p>

                    <h2>Working Remotely</h2>
                    <p>Employees working remotely must follow this policy’s instructions too. Since they will be accessing {this.props.clientName}’s accounts and systems from a distance, they are obliged to follow all data encryption, protection standards and settings, and ensure their private network is secure.</p>

                    <p>We encourage them to seek advice from our [Security Specialists/ IT Administrators.]</p>

                    <h2>Disciplinary Action</h2>
                    <p>We expect all our employees to always follow this policy and those who cause security breaches may face disciplinary action:
                        <ul>
                            <li>First-time, unintentional, small-scale security breach: We may issue a verbal warning and train the employee on security.</li>
                            <li>Intentional, repeated or large-scale breaches (which cause severe financial or other damage): We will invoke more severe disciplinary action up to and including termination.
                            We will examine each incident on a case-by-case basis.</li>
                        </ul>
                    </p>
                    <p>Additionally, employees who are observed to disregard our security instructions may be subject to disciplinary action, even if their behavior has not resulted in a security breach.</p>

                    <h2>Take Security Seriously</h2>
                    <p>Everyone, from our clients and partners to our employees and contractors, should feel that their data is safe. The only way to gain their trust is to proactively protect our systems and databases. We can all contribute to this by being vigilant and keeping cyber security top of mind.</p>

                </Div>
            </ThemeProvider>
        ) 
    }
}

export default CyberSecurity;
