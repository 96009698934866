import React from "react";
import styled from "styled-components";
const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

const Div = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`

const Loading = () => (
  <Div>
    <div className="spinner">
    <img src={loadingImg}
      alt="Loading..." />
    </div>
  </Div>
);

export default Loading;