import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class PerformanceManagement extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Performance Management</h4>
                                <hr />

                                <Form.Group controlId="formPerformanceProcess">
                                <Form.Label>
                                    57. Describe Process
                                </Form.Label>
                                <Form.Control
                                    name="formPerformanceProcess"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPerformanceProcess || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPerformanceResponsible">
                                <Form.Label>
                                    58. Responsible Individual(s)
                                </Form.Label>
                                <Form.Control
                                    name="formPerformanceResponsible"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPerformanceResponsible || ""}
                                />
                                </Form.Group>
                                
                                <Form.Group controlId="formPerformanceTraining">
                                <Form.Label>
                                    59. Training obtained to conduct meetings
                                </Form.Label>
                                <Form.Control
                                    name="formPerformanceTraining"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPerformanceTraining || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPerformanceCommunication">
                                <Form.Label>
                                    60. Communication of poor performance
                                </Form.Label>
                                <Form.Control
                                    name="formPerformanceCommunication"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPerformanceCommunication || ""}
                                />
                                </Form.Group>

                                <Form.Group controlId="formPerformanceGoals">
                                <Form.Label>
                                    61. Goals development - S.M.A.R.T?
                                </Form.Label>
                                <Form.Control
                                    name="formPerformanceGoals"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formPerformanceGoals || ""}
                                />
                                </Form.Group>

                                <Form.Group
                                name="formPerformanceCorrective"
                                as={Row}
                                controlId="formPerformanceCorrective"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label column sm={5}>
                                        62. Any employees currently on corrective action process?
                                    </Form.Label>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes"
                                        value="yes"
                                        name="formPerformanceCorrective"
                                        id="formPerformanceCorrective-Yes"
                                        defaultChecked={
                                        this.props.formPerformanceCorrective === "yes"
                                        }
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No"
                                        value="no"
                                        name="formPerformanceCorrective"
                                        id="formPerformanceCorrective-No"
                                        defaultChecked={
                                        this.props.formPerformanceCorrective === "no"
                                        }                               
                                    />
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Unknown"
                                        value="unknown"
                                        name="formPerformanceCorrective"
                                        id="formPerformanceCorrective-NA"
                                        defaultChecked={
                                        this.props.formPerformanceCorrective === "unknown"
                                        }
                                    />
                            </Form.Group>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default PerformanceManagement;