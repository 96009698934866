import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Confidentiality extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Confidentiality</h1>
                    <p>The protection of confidential business information and trade secrets is vital to the interests and the success of {this.props.clientName}y. Such confidential information includes, but is not limited to the following examples:
                        <ul>
                            <li>Donor/Client lists</li>
                            <li>Donor/Clients’ personal information, including contact information, date of birth and age</li>
                            <li>Donor/Client credit card information</li>
                            <li>Marketing strategies</li>
                            <li>Pending projects and proposals</li>
                            <li>Proprietary processes</li>
                            <li>Research and development strategies</li>
                            <li>Technological data</li>
                            <li>All data on {this.props.clientName} computer servers and cloud servers</li>
                        </ul>
                    </p>

                    <p>Employees may only use confidential information in connection with current or contemplated business transactions. In addition, employees who are privy to confidential information shall safeguard such information pursuant to {this.props.clientName} policies and procedures, including {this.props.clientName} Privacy policy, and any governing local, state, or federal laws. In all cases, employees must exercise a reasonable degree of care to prevent the unauthorized use, dissemination, or publication of confidential information.  Employees must take all necessary precautions to ensure information and data in their work area is “shielded from prying eyes”, and not disclosed without authorization.  Likewise, verbal disclosure of information and data must be limited to only those authorized to have access to such information.  Where inappropriate access has been provided to an individual without a need to know, the employee must notify his or her supervisor or the President and CEO so that any potential breaches are mitigated as quickly as possible.  </p>

                    <p>Except where such disclosure is made in confidence to a government official or attorney for the purpose of reporting or investigating a violation of law, employees who improperly use or disclose trade secrets or confidential information (internally or externally) will be subject to corrective action, up to and including termination of employment and legal action, even if they do not actually benefit from the disclosed information.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Confidentiality;
