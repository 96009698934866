import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Separation extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Separation From Employment</h1>
                    <h2>Employment at Will</h2>
                    <p>Employment at {this.props.clientName} is at-will. This means your employment is for an indefinite period of time and it is subject to termination by you or {this.props.clientName}, with or without cause, with or without notice, and at any time. Nothing in this policy or any other policy of {this.props.clientName} shall be interpreted to be in conflict with or to eliminate or modify in any way, the at-will employment status of {this.props.clientName}’s employees. Any written or oral statement to the contrary by any employee of {this.props.clientName} is invalid and should not be relied upon by any prospective or existing employee. Any exception to at-will employment must be in writing and signed by the CEO and must specifically state that the at-will relationship has been so altered.</p>

                    <p>{this.props.clientName} may also alter or modify any term or condition of employment, including the right to transfer or demote any employee and/or change any employee’s job duties, hours, working conditions, compensation and/or benefits at any time, with or without cause or notice.</p>

                    <h2>Notice of Separation</h2>
                    <p>{this.props.clientName} requests that departing employees give at least two weeks advance written notice of resignation. Employees are responsible for returning any all company property including keys and access cards on their last day of employment. </p>

                    <h2>Benefits Upon Separation</h2>
                    <p>Upon separation of employment, applicable group medical, dental and vision insurance coverage if any will end on the last day of the month during which your employment ended. Your life and disability coverage if any will terminate on your last day of employment.</p>
                    <p>Specifics regarding the continuation of benefits coverage for you and your eligible dependents will be provided to you within 30 days of your final day of employment.  </p>

                    <h2>References</h2>
                    <p>Only Human Resources is authorized to respond to outside requests for information on current or former employees. Human Resources will give out the following information: hire date, termination date, job position title, status of employment, and confirmation of salary only if a written authorization is received from the employee. </p>

                    <p>Human Resources will provide only factual, job related information and will not give out information regarding performance evaluations unless the employee or former employee provides a signed authorization to release additional information to specific prospective employers.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Separation;
