import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Weapons extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Weapons</h1>
                    <p>{this.props.clientName} prohibits the possession of firearms, weapons, explosives, and other hazardous materials on company property and while on duty performing company business at any location. This policy applies to all employees, including but not limited to, those who have a valid permit to carry a firearm. Employees are permitted to transport and store in a safe and discreet manner a legal firearm and ammunition in their locked, privately owned vehicle while the vehicle is on company premises. This policy is intended to comply with all applicable state laws concerning employee rights to possess and carry firearms and shall be interpreted and enforced accordingly.  </p>

                    <p>For purposes of this policy, weapons are generally defined as guns, knives, and other objects universally considered a weapon by the majority of society.  A “weapon” can also be any object which would do harm to another when used as such.  {this.props.clientName} shall deem any such object a “weapon” for the purpose of enforcing this policy.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Weapons;
