import React, { Component } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class StrategyLead extends Component {
    

    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Strategy</h5>
                    <hr />
                    

                    <Form.Group
                      name="formStrategy2"
                      as={Row}
                      controlId="formStrategy2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        61. Does the company have a strategic plan in place?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I don't know"
                          value="I don't know"
                          name="formStrategy2"
                          id="formStrategy2-1"
                          defaultChecked={
                            this.props.formStrategy2 === "I don't know"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, but we don't talk about it"
                          value="Yes, but we don't talk about it"
                          name="formStrategy2"
                          id="formStrategy2-2"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, but we don't talk about it"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, I hear about it often"
                          value="Yes, I hear about it often"
                          name="formStrategy2"
                          id="formStrategy2-3"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, I hear about it often"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy2"
                          id="formStrategy2-4"
                          defaultChecked={this.props.formStrategy2 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy2 === "Other" && (
                        <Form.Group controlId="formStrategy2Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy2Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy2Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formStrategy3">
                      <Form.Label>
                        61a. If you have a strategic plan, when was it
                        implemented?
                      </Form.Label>
                      <Form.Control
                        name="formStrategy3"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy3 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy4">
                      <Form.Label>
                        61b. If you have a strategic plan, what is it?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formStrategy4"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy4 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formStrategy5">
                      <Form.Label>
                        62. Are there any company initiatives in the works or on the horizon?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formStrategy5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy5a">
                      <Form.Label>62a. If so, what are they? </Form.Label>
                      <Form.Control
                        name="formStrategy5a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy5b">
                      <Form.Label>
                        62b. What are the specific objectives around these
                        initiatives?
                      </Form.Label>
                      <Form.Control
                        name="formStrategy5b"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5b || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy5c">
                      <Form.Label>
                        63c. What are the benefits of these new initiatives?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formStrategy5c"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy5c || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group
                      name="formStrategy6"
                      as={Row}
                      controlId="formStrategy6"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        63.	How is employee buy-in established for adoption and usage of new initiatives?  Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Employee feedback is solicited"
                          name="formStrategyFeedback"
                          id="formStrategy6-1"
                          defaultChecked={this.props.formStrategyFeedback}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employees lead or participate in committees leading the initiative"
                          name="formStrategyLead"
                          id="formStrategy6-2"
                          defaultChecked={this.props.formStrategyLead}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Leaders provide in-depth discussion explaining the “why”"
                          name="formStrategyDiscussion"
                          id="formStrategy6-3"
                          defaultChecked={this.props.formStrategyDiscussion}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formStrategyOther"
                          id="formStrategy6-4"
                          defaultChecked={this.props.formStrategyOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formStrategyOther === true ||
                        this.props.formStrategyOther === 1) && (
                        <Form.Group controlId="formStrategyOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategyOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategyOtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formStrategy7"
                      as={Row}
                      controlId="formStrategy7"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        64. How are changes communicated company-wide? Select all
                        that apply...
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Email"
                          name="formStrategyEmail"
                          id="formStrategy7-1"
                          defaultChecked={this.props.formStrategyEmail}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In face-to-face meetings with leadership/President/CEO"
                          name="formStrategyFaceMeetings"
                          id="formStrategy7-2"
                          defaultChecked={this.props.formStrategyFaceMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In department meetings"
                          name="formStrategyDeptMeetings"
                          id="formStrategy7-3"
                          defaultChecked={this.props.formStrategyDeptMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formStrategy7Other"
                          id="formStrategy7-4"
                          defaultChecked={this.props.formStrategy7Other}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formStrategy7Other === true ||
                        this.props.formStrategy7Other === 1) && (
                        <Form.Group controlId="formStrategy7OtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy7OtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy7OtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formStrategy8">
                      <Form.Label>
                        65. How is efficacy of new initiatives evaluated?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formStrategy8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy8 || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formStrategy9"
                      as={Row}
                      controlId="formStrategy9"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        66. What kind of succession/business continuity plan do
                        you have?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I do not have a succession plan"
                          value="I do not have a succession plan"
                          name="formStrategy9"
                          id="formStrategy9-1"
                          defaultChecked={
                            this.props.formStrategy9 ===
                            "I do not have a succession plan"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="I have an informal succession plan"
                          value="I have an informal succession plan"
                          name="formStrategy9"
                          id="formStrategy9-2"
                          defaultChecked={
                            this.props.formStrategy9 ===
                            "I have an informal succession plan"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="I have a formal succession plan"
                          value="I have a formal succession plan"
                          name="formStrategy9"
                          id="formStrategy9-3"
                          defaultChecked={
                            this.props.formStrategy9 ===
                            "I have a formal succession plan"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy9"
                          id="formStrategy9-4"
                          defaultChecked={this.props.formStrategy9 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy9 === "Other" && (
                        <Form.Group controlId="formStrategy9Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy9Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy9Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />
                    
                    <Form.Group controlId="formStrategy11">
                      <Form.Label>67. What HR metrics do you track?</Form.Label>
                      <Form.Control
                        name="formStrategy11"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy11 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formStrategy10">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formStrategy10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy10 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default StrategyLead;