import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class SummerFlexTime extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Summer Flextime</h1>
                    <p>The normal operating days and hours of {this.props.clientName} are Monday through Friday, 8:30 a.m. to 5:00 p.m. All employees are expected to be at work during these hours. Flexible work schedules are not permitted, except during the summer months, starting the week in which Memorial Day is observed and ending the week prior to which Labor Day is observed.</p>

                    <p>The summer flextime policy at {this.props.clientName} allows employees to work extra time Monday through Thursday in order to leave at 1:00 p.m. on Friday. The time of arrival and departure may not differ from the standard operating hours by more than two hours, except for the Friday early departure. For example, a typical summer flextime arrangement is Monday through Thursday arriving at 8:30 a.m. and departing at 6:00 p.m. and on Friday arriving at 8:30am and departing at 1:00 pm. </p>

                    <p>Supervisors approve flextime on a case-by-case basis. An employee must first discuss possible summer flextime arrangements with his or her supervisor, then submit a written request using the Summer Flextime Request Form. The supervisor will approve or deny the flextime request based on staffing needs, individual job duties, employee work record, and the employee's ability to temporarily or permanently return to a standard work schedule when needed.</p>

                    <p>A summer flextime arrangement may be suspended or cancelled at any time. Exempt employees must depart from any flextime schedule when needed to perform their job duties. Nonexempt employees may be asked to work overtime, regardless of a flextime schedule. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default SummerFlexTime;
