import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class MandatoryAbsence extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Mandatory One-Week Absence Each Year – For Financial Institutions (or similar)</h1>
                    <p>To promote good internal controls, {this.props.clientName} requires employees to take a minimum one-week absence (five consecutive business days) each calendar year.  This time period allows the organization to segregate functions so that an employee is not in a position to both perpetrate and/or conceal errors or irregularities in the normal course of their duties. During the employee’s one-week mandatory absence, management requests that all of the employee’s duties be performed and monitored by another associate. This is to promote cross training and review of procedures to uncover inadvertent errors.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default MandatoryAbsence;
