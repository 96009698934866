import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Bonus extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Bonus Policy</h1>
                    <h2>Purpose</h2>
                    <p>Formal recognition programs, including lump-sum bonuses, incentive plans and non-monetary awards, offer mechanisms to effectively motivate and retain valued employees who provide exemplary performance or meet specified targets. These programs are intended to reward staff employees whose achievements have resulted in a particular benefit to the {this.props.clientName}. </p>

                    <h2>Who is Eligible?</h2>
                    <p>This policy applies to all regular full-time and part-time. Seasonal employees, interns and temporary employees are not eligible for bonuses.</p>

                    <h2>Types of Bonuses</h2>
                    <p>The {this.props.clientName} rewards employees for outstanding individual performance, as well as their contributions that help us achieve {this.props.clientName} goals. For this reason, we award bonuses in two forms:
                        <ul>
                            <li>Lump-sum bonus</li>
                            <li>Year-end bonus</li>
                        </ul>
                    </p>

                    <h3>Lump-Sum Bonus</h3>
                    <p>The {this.props.clientName} may award discretionary lump-sum bonuses (one-time bonus payments) to employees who show exemplary performance. “Exemplary performance” is defined as:
                        <ul>
                            <li>The employee’s accomplishments exceed the normal standards/expectations for the job.</li>
                            <li>The employee has fulfilled all normal job duties in addition to performing added duties to accomplish a special project or achieve a certain goal.</li>
                            <li>The employee serves as a role model for others, displaying desirable characteristics such as outstanding customer service, positive attitude and team leadership.</li>
                        </ul>
                    </p>

                    <h3>Year-End Bonus</h3>
                    <p>The {this.props.clientName}  policy gives the leadership team the ability to decide on year-end bonuses for all employees. There are two conditions for this bonus:
                        <ul>
                            <li>The {this.props.clientName}  should have exceeded its annual financial goals.</li>
                            <li>The “CEO/CFO” must approve the bonuses.</li>
                        </ul>
                    </p>
                    <p>If these two conditions are satisfied, then the {this.props.clientName} will give bonuses to employees who:
                        <ul>
                            <li>Are employed by our company on the day when the bonus must be paid.</li>
                            <li>Have not announced they intend to resign either verbally or in writing.</li>
                            <li>Are not on a Performance Improvement Plan.</li>
                        </ul>
                    </p>
                    <p>Bonuses will be paid out according to a tiered system:
                        <ul>
                            <li>Employees who are employed by the {this.props.clientName}- for the entire year will receive a year-end bonus payment of [10%] of their annual salary.</li>
                            <li>Employees who are employed for at least [six] months will receive a prorated amount.</li>
                            <li>Employees who are employed for fewer than [six months] will receive a [2.5%] bonus.</li>
                        </ul>
                    </p>
                    <p>These programs are not intended to replace informal and spontaneous recognition or praise of staff achievements and work performance. Informal recognition of employees should take place every day. Saying “thank you” to employees and complimenting an employee on a job well done are part of supervisors’ responsibilities.</p>
                    <p>Keep in mind that bonuses are subject to applicable taxation. This policy may be modified at any time without notice.</p>
                    <p>Only written promises of bonuses will be considered valid. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Bonus;
