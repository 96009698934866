import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class JobPerformance extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Drug-Free Workplace</h1>
                    <p>Employees may be disciplined up to and including termination for poor job performance, as determined by {this.props.clientName}.  Examples of poor performance include, but are not limited to the following:
                        <ul>
                            <li>Below-average work quality or quantity;</li>
                            <li>Poor attitude, including rudeness, or lack of cooperation;</li>
                            <li>Excessive absenteeism, tardiness, or abuse of break and lunch privileges;</li>
                            <li>Failure to follow instructions or procedures; and</li>
                            <li>Failure to correct job performance deficiencies </li>
                        </ul>
                    </p>

                    <p>This is not an all-inclusive list.  Disciplinary action, including immediate termination, may result from other types of poor performance.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default JobPerformance;
