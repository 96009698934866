import React from 'react';
import { Table, Container, Jumbotron } from 'react-bootstrap';
import styled from 'styled-components';

const Div = styled.div`
    color: black;
`


const RateSheet = () => {

    return (
        <Container>
            <Div>
                <Jumbotron>
                    <h1>Hourly Rate Pricing</h1>

                    <div className='mt-3'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr >
                                    <th className="col-sm-6">Performance Management & Training</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>90-Days</td>
                                    <td>3 Hours Minimum</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    
                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Policy and Procedure Formation</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Policy Creation</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Standard Operation Procedures</td>
                                    <td>6 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Development of Leave Admin Process</td>
                                    <td>6 Hours Minimum</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                        
                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Policy and Procedure Formation</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Policy Creation</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Standard Operation Procedures</td>
                                    <td>6 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Development of Leave Admin Process</td>
                                    <td>6 Hours Minimum</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    

                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Risk Management</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Review of Employment Classification</td>
                                    <td>per each is no less than 3 hours and more than 5 requires new proposal.</td>
                                </tr>
                                <tr>
                                    <td>Unemployment Matters/Support with Texas Workforce Commission</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>State Tax Registration</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Support to EEOC, DOL, or Other Regulatory Matters</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Investigations</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                        
                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Workforce Planning & Talent Attraction</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Job Description</td>
                                    <td>2 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Onboarding - for Talent Acquisition Clients</td>
                                    <td>3 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Phone Screen</td>
                                    <td>2 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Reference Check</td>
                                    <td>2 Hours Minimum</td>
                                </tr>
                                <tr>
                                    <td>Talent Search Survey</td>
                                    <td>3 Hours Minimum</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                        
                        

                    


                    <h1 className='mt-5'>Flat Rate Pricing</h1>
                    
                    <div className='mt-3'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Performance Management & Training</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Performance Management System</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Training</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Policy and Procedure Formation</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Handbook(*includes 2 rounds of editing feedback)
                                    * additional feeds may apply based on scope of work</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Standard Operation Procedures</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Development of Leave Admin Process</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Risk Management</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Human Capital Analysis (Audit)</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Unemployment Matters/Support with Texas Workforce Commission</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Strategy and Change Management</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Succession Planning</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>SWOT Analysis</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Talent Planning Analysis</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                        
                    <div className='mt-5'>
                        <Table striped bordered variant='dark'>
                            <thead>
                                <tr>
                                    <th className="col-sm-6">Compensation Analysis</th>
                                    <th className="col-sm-6">Minimum Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Exec Comp Analysis Project</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                                <tr>
                                    <td>Comp Analysis Project</td>
                                    <td>Requires New Proposal</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>   
                </Jumbotron>
            </Div>
            
                
               
        </Container>
    )
}

export default RateSheet;