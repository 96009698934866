import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class DEI extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Diversity, Equity, & Inclusion (DEI)</h4>
                                <hr />
                                <p>Diversity: the organization employs with a range of human differences, e.g. race, ethnicity, gender, gender identity, sexual orientation, age, social class, physical ability or attributes, religious or ethical values system, national origin, and political beliefs.</p>
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formDEIDiversity"
                                controlId="formDEIDiversity"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    5. I am satisfied with the level of diversity within the organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDEIDiversity"
                                    id="formDEIDiversity-1"
                                    defaultChecked={this.props.formDEIDiversity === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDEIDiversity"
                                    id="formDEIDiversity-2"
                                    defaultChecked={this.props.formDEIDiversity === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDEIDiversity"
                                    id="formDEIDiversity-3"
                                    defaultChecked={this.props.formDEIDiversity === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDEIDiversity"
                                    id="formDEIDiversity-4"
                                    defaultChecked={this.props.formDEIDiversity === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDEIDiversity"
                                    id="formDEIDiversity-5"
                                    defaultChecked={this.props.formDEIDiversity === 5}
                                />
                                </Form.Group>

                                
                                <p>Equity: everyone in the organization has the same opportunities for advancement, professional development, recognition, and awards.</p>
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>
                                <Form.Group
                                as={Row}
                                name="formDEIEquity"
                                controlId="formDEIEquity"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    6. I am satisfied with the level of equity of the organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDEIEquity"
                                    id="formDEIEquity-1"
                                    defaultChecked={this.props.formDEIEquity === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDEIEquity"
                                    id="formDEIEquity-2"
                                    defaultChecked={this.props.formDEIEquity === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDEIEquity"
                                    id="formDEIEquity-3"
                                    defaultChecked={this.props.formDEIEquity === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDEIEquity"
                                    id="formDEIEquity-4"
                                    defaultChecked={this.props.formDEIEquity === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDEIEquity"
                                    id="formDEIEquity-5"
                                    defaultChecked={this.props.formDEIEquity === 5}
                                />
                                </Form.Group>

                                <p>Inclusion: ensuring that everyone has an equal opportunity to contribute to and influence every part and level of a workplace</p>
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>
                                <Form.Group
                                as={Row}
                                name="formDEIInclusion"
                                controlId="formDEIInclusion"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    7. I am satisfied with the level of inclusion of the organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formDEIInclusion"
                                    id="formDEIInclusion-1"
                                    defaultChecked={this.props.formDEIInclusion === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formDEIInclusion"
                                    id="formDEIInclusion-2"
                                    defaultChecked={this.props.formDEIInclusion === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formDEIInclusion"
                                    id="formDEIInclusion-3"
                                    defaultChecked={this.props.formDEIInclusion === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formDEIInclusion"
                                    id="formDEIInclusion-4"
                                    defaultChecked={this.props.formDEIInclusion === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formDEIInclusion"
                                    id="formDEIInclusion-5"
                                    defaultChecked={this.props.formDEIInclusion === 5}
                                />
                                </Form.Group>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default DEI;