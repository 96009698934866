import React, { Component } from "react";
import Interview from "./Interview";

class HCA_Survey extends Component {
  constructor(props) {
    super(props);

    this.state = {
        auditComponent: " ",
        selectedAudit: 0,
        selectedClient: "",
        employee: "",
        employeeId: 0,
        employment_level: "",
        linkInterview: true      
    };

    }
    
    componentDidMount() {
        // let sampleString = "this%20needs%20to%20have%20spaces!%20Does%20it?!";
        // sampleString = decodeURI(sampleString);
        // console.log(sampleString);
        if (this.props.match.params) {
            this.setState({
                employeeId: this.props.match.params.id,
                employee: this.props.match.params.name,
                employment_level: this.props.match.params.level,
                selectedAudit: this.props.match.params.audit,
                selectedClient: this.props.match.params.client
            }, this.setState({auditComponent : "interview"}));            
        } else {
            console.log("it didn't work");
        }
    }

  render() {
    let auditComponent = this.state.auditComponent;
    switch (auditComponent) {
      case "interview":
            return <Interview
                        employee={this.state.employee}
                        employeeId={this.state.employeeId}
                        employment_level={this.state.employment_level}
                        auditId={this.state.selectedAudit}
                        clientName={this.state.selectedClient}
                        showList={this.showList}
                        linkInterview={this.state.linkInterview}
                    />;
      default:
        return <h1>Something is broken!</h1>
    }
  }
}

export default HCA_Survey;
