import React, { Component } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
    
`

class RewardsLead extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  

                  <div className="form-section">
                    <h5>Benefits</h5>
                    <hr />

                    <Form.Group
                      name="formRewardsBenefits"
                      as={Row}
                      controlId="formRewardsBenefits"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        68. What types of benefits does your organization provide
                        employees? Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Medical Plan"
                          name="formRewardsBenefitsMedical"
                          id="formRewardsBenefits-1"
                          defaultChecked={this.props.formRewardsBenefitsMedical}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Dental Plan"
                          name="formRewardsBenefitsDental"
                          id="formRewardsBenefits-2"
                          defaultChecked={this.props.formRewardsBenefitsDental}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Vision Plan"
                          name="formRewardsBenefitsVision"
                          id="formRewardsBenefits-3"
                          defaultChecked={this.props.formRewardsBenefitsVision}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Prescription Drug Plans"
                          name="formRewardsBenefitsRX"
                          id="formRewardsBenefits-4"
                          defaultChecked={this.props.formRewardsBenefitsRX}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Flexible Spending Account (FSA)"
                          name="formRewardsBenefitsFSA"
                          id="formRewardsBenefits-5"
                          defaultChecked={this.props.formRewardsBenefitsFSA}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Health Reimbursement Account (HRA)"
                          name="formRewardsBenefitsHRA"
                          id="formRewardsBenefits-6"
                          defaultChecked={this.props.formRewardsBenefitsHRA}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Health Savings Account (HSA)"
                          name="formRewardsBenefitsHSA"
                          id="formRewardsBenefits-7"
                          defaultChecked={this.props.formRewardsBenefitsHSA}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Life Insurance"
                          name="formRewardsBenefitsLife"
                          id="formRewardsBenefits-8"
                          defaultChecked={this.props.formRewardsBenefitsLife}
                        />
                        <Form.Check
                          type="checkbox"
                          label="AD & D Insurance"
                          name="formRewardsBenefitsADD"
                          id="formRewardsBenefits-9"
                          defaultChecked={this.props.formRewardsBenefitsADD}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Short-Term Disability"
                          name="formRewardsBenefitsSTD"
                          id="formRewardsBenefits-10"
                          defaultChecked={this.props.formRewardsBenefitsSTD}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Long-Term Disability"
                          name="formRewardsBenefitsLTD"
                          id="formRewardsBenefits-11"
                          defaultChecked={this.props.formRewardsBenefitsLTD}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formRewardsBenefitsOther"
                          id="formRewardsBenefits-12"
                          defaultChecked={this.props.formRewardsBenefitsOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formRewardsBenefitsOther === true ||
                        this.props.formRewardsBenefitsOther === 1) && (
                        <Form.Group controlId="formRewardsBenefitsOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsBenefitsOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formRewardsBenefitsOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formRewardsDependents"
                      as={Row}
                      controlId="formRewardsDependents"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        69. Is dependent coverage available, and if so, how much
                        does the organization supplement? Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="No dependent coverage available"
                          name="formRewardsDependentsNone"
                          id="formRewardsDependents-1"
                          defaultChecked={this.props.formRewardsDependentsNone}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Dependent coverage available – Employee Pays Full Amount of Coverage"
                          name="formRewardsDependentsFull"
                          id="formRewardsDependents-2"
                          defaultChecked={this.props.formRewardsDependentsFull}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Dependent coverage available – Employee Pays 50% or more of Coverage"
                          name="formRewardsDependentsHalf"
                          id="formRewardsDependents-3"
                          defaultChecked={this.props.formRewardsDependentsHalf}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formRewardsDependentsOther"
                          id="formRewardsDependents-4"
                          defaultChecked={this.props.formRewardsDependentsOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formRewardsDependentsOther === true ||
                        this.props.formRewardsDependentsOther === 1) && (
                        <Form.Group controlId="formRewardsDependentsOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsDependentsOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formRewardsDependentsOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <Form.Group controlId="formRewardsBenefits3">
                      <Form.Label>
                        70. How long have you worked with your benefits broker?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBenefits3"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits3 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsBenefits4">
                      <Form.Label>
                        71. What type of customer service does your broker
                        provide employees?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBenefits4"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits4 || ""}
                      />
                    </Form.Group>

                    
                    <Form.Group controlId="formRewardsBenefits7">
                      <Form.Label>72. When is open enrollment? </Form.Label>
                      <Form.Control
                        name="formRewardsBenefits7"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits7 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsBenefits8">
                      <Form.Label>
                        73. How do employees access benefits information?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBenefits8"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits8 || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRewardsBenefits10">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsBenefits10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBenefits10 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Paid Leave</h5>
                    <hr />

                    

                    <Form.Group
                      name="formRewardsPaidLeave"
                      as={Row}
                      controlId="formRewardsPaidLeave"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                       74. What types of Paid Leave is available, if any? Check all
                        that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Vacation"
                          name="formRewardsPaidLeaveVacation"
                          id="formRewardsPaidLeave-1"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveVacation
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Paid Time Off"
                          name="formRewardsPaidLeaveTimeOff"
                          id="formRewardsPaidLeave-2"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveTimeOff
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Holiday"
                          name="formRewardsPaidLeaveHoliday"
                          id="formRewardsPaidLeave-3"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveHoliday
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Sick Leave"
                          name="formRewardsPaidLeaveSick"
                          id="formRewardsPaidLeave-4"
                          defaultChecked={this.props.formRewardsPaidLeaveSick}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Bereavement Leave"
                          name="formRewardsPaidLeaveBereavement"
                          id="formRewardsPaidLeave-5"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveBereavement
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Family and/or Medical Leave (Paid)"
                          name="formRewardsPaidLeaveMedical"
                          id="formRewardsPaidLeave-6"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveMedical
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Sabbatical"
                          name="formRewardsPaidLeaveSabbatical"
                          id="formRewardsPaidLeave-7"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveSabbatical
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Adoption Leave"
                          name="formRewardsPaidLeaveAdoption"
                          id="formRewardsPaidLeave-8"
                          defaultChecked={
                            this.props.formRewardsPaidLeaveAdoption
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formRewardsPaidLeaveOther"
                          id="formRewardsPaidLeave-9"
                          defaultChecked={this.props.formRewardsPaidLeaveOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formRewardsPaidLeaveOther === true ||
                        this.props.formRewardsPaidLeaveOther === 1) && (
                        <Form.Group controlId="formRewardsPaidLeaveOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsPaidLeaveOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formRewardsPaidLeaveOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <hr />


                    <Form.Group controlId="formRewardsPaidLeave2">
                      <Form.Label>
                        75. Are these paid leave programs spelled out in your
                        Employee Handbook/HR policies? 
                      </Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsPaidLeave2a">
                      <Form.Label>
                        75a. If not, where are they
                        documented?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave2a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave2a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsPaidLeave3">
                      <Form.Label>
                        76. How do you keep track of your leave balances?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave3 || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRewardsPaidLeave5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsPaidLeave5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPaidLeave5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Work/Home Balance/Integration</h5>
                    <hr />
                    

                    <Form.Group
                      name="formRewardsBalance2"
                      as={Row}
                      controlId="formRewardsBalance2"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        77. What types of flexible work programs are available to
                        you? Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Flexible Hours"
                          name="formRewardsBalance2Flex"
                          id="formRewardsBalance2-1"
                          defaultChecked={this.props.formRewardsBalance2Flex}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Remote Work"
                          name="formRewardsBalance2Remote"
                          id="formRewardsBalance2-2"
                          defaultChecked={this.props.formRewardsBalance2Remote}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Compressed work week"
                          name="formRewardsBalance2Compressed"
                          id="formRewardsBalance2-3"
                          defaultChecked={
                            this.props.formRewardsBalance2Compressed
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Job Sharing"
                          name="formRewardsBalance2Sharing"
                          id="formRewardsBalance2-4"
                          defaultChecked={this.props.formRewardsBalance2Sharing}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Part-time employment"
                          name="formRewardsBalance2PartTime"
                          id="formRewardsBalance2-5"
                          defaultChecked={
                            this.props.formRewardsBalance2PartTime
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Seasonal Schedules"
                          name="formRewardsBalance2Seasonal"
                          id="formRewardsBalance2-6"
                          defaultChecked={
                            this.props.formRewardsBalance2Seasonal
                          }
                        />
                      </Col>
                    </Form.Group>
                    <hr />

                    

                    <Form.Group
                      name="formRewardsBalance4"
                      as={Row}
                      controlId="formRewardsBalance4"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        78. Are these flexible work programs spelled out in your
                        Employee Handbook/HR policies?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          value="yes"
                          name="formRewardsBalance4"
                          id="formRewardsBalance4-1"
                          defaultChecked={
                            this.props.formRewardsBalance4 === "yes"
                          }
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          value="no"
                          name="formRewardsBalance4"
                          id="formRewardsBalance4-2"
                          defaultChecked={
                            this.props.formRewardsBalance4 === "no"
                          }
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Unknown"
                          value="unknown"
                          name="formRewardsBalance4"
                          id="formRewardsBalance4-3"
                          defaultChecked={
                            this.props.formRewardsBalance4 === "unknown"
                          }
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group controlId="formRewardsBalance4a">
                      <Form.Label>
                        78a. If not, where are they documented?
                      </Form.Label>
                      <Form.Control
                        name="formRewardsBalance4a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBalance4a || ""}
                      />
                    </Form.Group>

                    
                    <hr />
                    <Form.Group controlId="formRewardsBalance6">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsBalance6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsBalance6 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Payroll</h5>
                    <hr />
                    
                    
                    <Form.Group
                      name="formRewardsPayroll3"
                      as={Row}
                      controlId="formRewardsPayroll3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        79. Do you have a second reviewer to review payroll prior
                        to submission?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          inline
                          type="radio"
                          label="Yes"
                          value="yes"
                          name="formRewardsPayroll3"
                          id="formRewardsPayroll3-1"
                          defaultChecked={
                            this.props.formRewardsPayroll3 === "yes"
                          }
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="No"
                          value="no"
                          name="formRewardsPayroll3"
                          id="formRewardsPayroll3-2"
                          defaultChecked={
                            this.props.formRewardsPayroll3 === "no"
                          }
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label="Unknown"
                          value="unknown"
                          name="formRewardsPayroll3"
                          id="formRewardsPayroll3-3"
                          defaultChecked={
                            this.props.formRewardsPayroll3 === "unknown"
                          }
                        />
                      </Col>
                    </Form.Group>
                    <hr />
                    <Form.Group controlId="formRewardsPayroll4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsPayroll4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsPayroll4 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* /
/
/
/
/ */}

                  <div className="form-section">
                    <h5>Human Resources information System(HRIS)</h5>
                    <hr />

                    <Form.Group
                      name="formRewardsHRIS"
                      as={Row}
                      controlId="formRewardsHRIS"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        80. What types of products and tools do you utilize through
                        your HRIS? Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Payroll services"
                          name="formRewardsHRISPayroll"
                          id="formRewardsHRIS-1"
                          defaultChecked={this.props.formRewardsHRISPayroll}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Self-Service Portal"
                          name="formRewardsHRISSelf"
                          id="formRewardsHRIS-2"
                          defaultChecked={this.props.formRewardsHRISSelf}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Company Communications"
                          name="formRewardsHRISCompany"
                          id="formRewardsHRIS-3"
                          defaultChecked={this.props.formRewardsHRISCompany}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Timekeeping"
                          name="formRewardsHRISTimekeeping"
                          id="formRewardsHRIS-4"
                          defaultChecked={this.props.formRewardsHRISTimekeeping}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Applicant Tracking"
                          name="formRewardsHRISApplicant"
                          id="formRewardsHRIS-5"
                          defaultChecked={this.props.formRewardsHRISApplicant}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Performance Management"
                          name="formRewardsHRISPerformance"
                          id="formRewardsHRIS-6"
                          defaultChecked={this.props.formRewardsHRISPerformance}
                        />
                        <Form.Check
                          type="checkbox"
                          label="OSHA Tracking"
                          name="formRewardsHRISOSHA"
                          id="formRewardsHRIS-7"
                          defaultChecked={this.props.formRewardsHRISOSHA}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Training/Learning Management System"
                          name="formRewardsHRISTraining"
                          id="formRewardsHRIS-8"
                          defaultChecked={this.props.formRewardsHRISTraining}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formRewardsHRISOther"
                          id="formRewardsHRIS-9"
                          defaultChecked={this.props.formRewardsHRISOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formRewardsHRISOther === true ||
                        this.props.formRewardsHRISOther === 1) && (
                        <Form.Group controlId="formRewardsHRISOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsHRISOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formRewardsHRISOtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>
                    

                    <Form.Group controlId="formRewardsHRIS5">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRewardsHRIS5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsHRIS5 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                            /
                            /
                            / */}

                  <div className="form-section">
                    <h5>Pay / Compensation</h5>
                    <hr />

                    <Form.Group
                      name="formRewardsCompPremium"
                      as={Row}
                      controlId="formRewardsCompPremium"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        81. What types of Premium Pay does your organization offer?
                        Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Shift Differential"
                          name="formRewardsCompPremiumShift"
                          id="formRewardsCompPremium-1"
                          defaultChecked={
                            this.props.formRewardsCompPremiumShift
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Weekend Pay"
                          name="formRewardsCompPremiumWeekend"
                          id="formRewardsCompPremium-2"
                          defaultChecked={
                            this.props.formRewardsCompPremiumWeekend
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Holiday Pay"
                          name="formRewardsCompPremiumHoliday"
                          id="formRewardsCompPremium-3"
                          defaultChecked={
                            this.props.formRewardsCompPremiumHoliday
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="On-Call Pay"
                          name="formRewardsCompPremiumOnCall"
                          id="formRewardsCompPremium-4"
                          defaultChecked={
                            this.props.formRewardsCompPremiumOnCall
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Hazard Pay"
                          name="formRewardsCompPremiumHazard"
                          id="formRewardsCompPremium-5"
                          defaultChecked={
                            this.props.formRewardsCompPremiumHazard
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Bi-Lingual Pay"
                          name="formRewardsCompPremiumBiLingual"
                          id="formRewardsCompPremium-6"
                          defaultChecked={
                            this.props.formRewardsCompPremiumBiLingual
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Skill-Based Pay"
                          name="formRewardsCompPremiumSkill"
                          id="formRewardsCompPremium-7"
                          defaultChecked={
                            this.props.formRewardsCompPremiumSkill
                          }
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      name="formRewardsCompVariable"
                      as={Row}
                      controlId="formRewardsCompVariable"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        82. What types of Variable Pay does your organization offer?
                        Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Commission"
                          name="formRewardsCompVariableCommission"
                          id="formRewardsCompVariable-1"
                          defaultChecked={
                            this.props.formRewardsCompVariableCommission
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Profit Sharing"
                          name="formRewardsCompVariableSharing"
                          id="formRewardsCompVariable-2"
                          defaultChecked={
                            this.props.formRewardsCompVariableSharing
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Referral Bonus"
                          name="formRewardsCompVariableReferral"
                          id="formRewardsCompVariable-3"
                          defaultChecked={
                            this.props.formRewardsCompVariableReferral
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Hiring Bonus"
                          name="formRewardsCompVariableHiring"
                          id="formRewardsCompVariable-4"
                          defaultChecked={
                            this.props.formRewardsCompVariableHiring
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Retention Bonus"
                          name="formRewardsCompVariableRetention"
                          id="formRewardsCompVariable-5"
                          defaultChecked={
                            this.props.formRewardsCompVariableRetention
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Project Completion Bonus"
                          name="formRewardsCompVariableCompletion"
                          id="formRewardsCompVariable-6"
                          defaultChecked={
                            this.props.formRewardsCompVariableCompletion
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formRewardsCompVariableOther"
                          id="formRewardsCompVariable-7"
                          defaultChecked={
                            this.props.formRewardsCompVariableOther
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formRewardsCompVariableOther === true ||
                        this.props.formRewardsCompVariableOther === 1) && (
                        <Form.Group controlId="formRewardsCompVariableOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsCompVariableOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formRewardsCompVariableOtherAnswer ||
                              ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formRewardsCompTerms"
                      as={Row}
                      controlId="formRewardsCompTerms"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        83. Which terms best describe your annual employee salary
                        increases, if any? Select all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Performance-based increase"
                          name="formRewardsCompTermsPerformance"
                          id="formRewardsCompTerms-1"
                          defaultChecked={
                            this.props.formRewardsCompTermsPerformance
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Cost-of-living increase"
                          name="formRewardsCompTermsLiving"
                          id="formRewardsCompTerms-2"
                          defaultChecked={this.props.formRewardsCompTermsLiving}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Market-driven increase"
                          name="formRewardsCompTermsMarket"
                          id="formRewardsCompTerms-3"
                          defaultChecked={this.props.formRewardsCompTermsMarket}
                        />
                      </Col>
                    </Form.Group>

                    
                    <hr />

                    <Form.Group controlId="formRewardsCompensation2">
                      <Form.Label>
                        84. How would you describe your compensation philosophy
                        (i.e. pay above/below market?)?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation3">
                      <Form.Label>
                        85. Are compensation decisions based on a formal
                        performance review, such as a merit matrix?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation3 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation4">
                      <Form.Label>
                        86. How do you determine advancement and promotions?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation4 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation5">
                      <Form.Label>
                        87. How are salary changes communicated?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRewardsCompensation6">
                      <Form.Label>
                        88.	How do you determine starting salaries?   {" "}
                      </Form.Label>
                      <Form.Control
                        name="formRewardsCompensation6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation6 || ""}
                      />
                    </Form.Group>
                    <hr />

                    

                    
                    <hr />
                    <Form.Group controlId="formRewardsCompensation9">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRewardsCompensation9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsCompensation9 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* ///
///
///
                  //
                  // */}

                  <div className="form-section">
                    <h5>Retirement</h5>
                    <hr />
                    

                    <Form.Group
                      name="formRewardsRetirement5"
                      as={Row}
                      controlId="formRewardsRetirement5"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        89. What type of retirement plan, if any, do you provide
                        employees?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="401k"
                          value="401k"
                          name="formRewardsRetirement5"
                          id="formRewardsRetirement5-1"
                          defaultChecked={
                            this.props.formRewardsRetirement5 === "401k"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="403b"
                          value="403b"
                          name="formRewardsRetirement5"
                          id="formRewardsRetirement5-2"
                          defaultChecked={
                            this.props.formRewardsRetirement5 === "403b"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="SIMPLE IRA"
                          value="SIMPLE IRA"
                          name="formRewardsRetirement5"
                          id="formRewardsRetirement5-3"
                          defaultChecked={
                            this.props.formRewardsRetirement5 === "SIMPLE IRA"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="SEP-IRA"
                          value="SEP-IRA"
                          name="formRewardsRetirement5"
                          id="formRewardsRetirement5-4"
                          defaultChecked={
                            this.props.formRewardsRetirement5 === "SEP-IRA"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formRewardsRetirement5"
                          id="formRewardsRetirement5-5"
                          defaultChecked={
                            this.props.formRewardsRetirement5 === "Other"
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formRewardsRetirement5 === "Other" && (
                        <Form.Group controlId="formRewardsRetirement5Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formRewardsRetirement5Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formRewardsRetirement5Answer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <hr />

                    <Form.Group
                      name="formRewardsRetirement6"
                      as={Row}
                      controlId="formRewardsRetirement6"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        90. Do you provide an employer match?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRewardsRetirement6"
                        id="formRewardsRetirement6-1"
                        defaultChecked={
                          this.props.formRewardsRetirement6 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRewardsRetirement6"
                        id="formRewardsRetirement6-2"
                        defaultChecked={
                          this.props.formRewardsRetirement6 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRewardsRetirement6"
                        id="formRewardsRetirement6-3"
                        defaultChecked={
                          this.props.formRewardsRetirement6 === "unknown"
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formRewardsRetirement6a">
                      <Form.Label>91. When is an employee vested? </Form.Label>
                      <Form.Control
                        name="formRewardsRetirement6a"
                        as="textarea"
                        rows="2"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsRetirement6a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRewardsRetirement7"
                      as={Row}
                      controlId="formRewardsRetirement7"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        92. Are employees automatically enrolled in the plan upon
                        hire?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRewardsRetirement7"
                        id="formRewardsRetirement7-1"
                        defaultChecked={
                          this.props.formRewardsRetirement7 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRewardsRetirement7"
                        id="formRewardsRetirement7-2"
                        defaultChecked={
                          this.props.formRewardsRetirement7 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRewardsRetirement7"
                        id="formRewardsRetirement7-3"
                        defaultChecked={
                          this.props.formRewardsRetirement7 === "unknown"
                        }
                      />
                    </Form.Group>

                    
                    <hr />

                    <Form.Group controlId="formRewardsRetirement9">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRewardsRetirement9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRewardsRetirement9 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default RewardsLead;