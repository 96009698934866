import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class EmployeeClassifications extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Employee Classifications</h1>
                    <p>Employees of {this.props.clientName} are assigned a regular work schedule. Employees regularly scheduled to work 30 or more hours per week are classified as full-time.  Employees regularly scheduled to work less than 30 hours per week are classified as part-time. </p>

                    <p>Temporary employees are those engaged to work either part-time or full-time but have been hired with the understanding that their employment will be terminated upon completion of a specific assignment or time period.  This category includes interns.  Such employees may be exempt or nonexempt but are not eligible for {this.props.clientName} benefits except as mandated by law.  </p>

                    <p>Full-time, part-time and temporary employees are classified as “exempt” or “nonexempt.”</p>

                    <h2>Nonexempt Employees</h2>
                    <p>Nonexempt employees are eligible for overtime pay for any hours worked in excess of 40 hours per week.  To ensure nonexempt employees receive appropriate pay, nonexempt employees are required to record all actual time worked and any applicable paid time off per workday within the workweek.  All hours should be submitted through Paylocity. </p>

                    <p>To ensure the office has adequate employee resources, supervisors are given discretion to determine the schedules to be worked during a given work week to ensure all duties are covered during the hours of operation.  Subject to supervisor approval, nonexempt employees may on rare occasions adjust their regular schedule either during the workday, during the work week, or on the weekend, to attend or facilitate various work-related events. This allows employees to be more effectively utilized without increasing their total daily or weekly hours of work. Should the need arise for a nonexempt employee to work more than 40 hours per week, this time is considered overtime and should be scheduled with supervisory approval. Employees may not take time off the following week or another time in the future because of extra time worked during the current week (often referred to as “comp” or “flex” time).</p>

                    <h2>Exempt Employees</h2>
                    <p>All exempt employees at “Company” are paid a regular salary and are “exempt” or not eligible for overtime pay.   Exempt employees are not restricted to working 40 hours per week or 8 hours per day; however, regular office hours should be observed, and any changes should always be discussed with the supervisor.</p>
                    <p>Because exempt employees receive pay on a salary basis, an exempt employee will receive his or her salary for any week in which the employee performs work, regardless of the hours worked, except in the following circumstances:
                        <ul>
                            <li>When an exempt employee takes one or more full days off for personal reasons, or due to sickness or disability, the employee will not be paid for such day(s) of absence, except where paid time off is available to make up for the reduction in salary;</li>
                            <li>When an exempt employee works only part of the week during his or her first and last week with the Company, the Company will only pay for days actually worked; and,</li>
                            <li>When an exempt employee takes unpaid Family Medical Leave or other qualifying leave, the Company will not pay for such days/hours of absence, unless paid time off is taken in concurrence with other paid time off.</li>
                        </ul>
                    </p>
                    <p>An exempt employee’s salary will not be reduced due to partial weeks of work due to service as a juror, witness or in the military, or for lack of work.</p>
                    <p>Exempt employees may also be required to record their time worked and report full days of absence from work due to vacation, sick leave, personal business, etc.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default EmployeeClassifications;
