import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class NonSolicitation extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Non-Solicitation / Non-Distribution Policy</h1>
                    <p>To avoid disruption of business operations or disturbance of team members, visitors, and others, the {this.props.clientName} has implemented a Non-solicitation/Non-distribution Policy. For purposes of this policy, "solicitation" includes, but is not limited to, selling items or services, requesting contributions, and soliciting or seeking to obtain membership in or support for any organization. Solicitation performed through verbal, written, or electronic means is covered by the Non-solicitation/Non-distribution policy.</p>

                    <p>You are prohibited from soliciting other team members during your work hours and on work premises.</p>

                    <p>To avoid inappropriate litter, clutter, and safety risks, you may not distribute literature or other items on work premises. Electronic distribution of materials is prohibited during work time and also through the Company’s equipment. Literature that violates the company's equal employment opportunity (EEO) and non-harassment policies (including threats of violence), or is knowingly and recklessly false, is never permitted. Non-employees are not permitted to distribute tangible or electronic materials on company premises or through company equipment at any time.</p>

                    <p>This policy is not intended to restrict the statutory rights of team members, including the right to discuss terms and conditions of employment.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default NonSolicitation;
