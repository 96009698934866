import React, { Component } from 'react';
import {Form} from 'react-bootstrap';
import { config } from '../../Config/Constants';
import debounce from 'lodash.debounce';

const API_URL = config.url.API_URL;


class Breakdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            employee_type: "Choose",
            employee_name: "Choose",
            rate: 0,
            cost: 0,
            hours1: 0,
            hours2: 0,
            hours3: 0,
            hours4: 0,
            hours5: 0,
            hours6: 0,
            hours7: 0,
            hours8: 0,
            hours9: 0,
            hours10: 0,
            hours11: 0,
            hours12: 0,
            total_hours: 0,
            total_costs: 0,
            total_bill: 0,
            margin: 0,

            // Cost Chart
            employeeTable: [
                { name: "Choose", cost: 0, type:"Choose"},
                { name: "Adam N", cost: 106.35, type: "Executive" },
                { name: "Amber H", cost: 55.60, type: "Senior" },
                { name: "Candace A", cost: 56.97, type: "Senior" },
                { name: "Cory R", cost: 47.81, type: "Mid-Level" },
                { name: "Denise M", cost: 31.36, type: "Coordinator" },
                { name: "Gabriela N", cost: 150.00, type: "Executive" },
                { name: "Hayley T", cost: 26.84, type: "Associate" },
                { name: "Jazmin K", cost: 60.68, type: "Executive" },
                { name: "Kacye B", cost: 74.91, type: "Executive" },
                { name: "Kathy B", cost: 26.18, type: "Mid-Level" },
                { name: "Kellee C", cost: 54.51, type: "Senior" },
                { name: "Kristen W", cost: 75.33, type: "Executive" },
                { name: "Lauren L", cost: 43.27, type: "Mid-Level" },
                { name: "Luke B", cost: 48.05, type: "Mid-level" },
                { name: "Mercedes C", cost: 49.15, type: "Mid-level"},
                { name: "Sherri G", cost: 76.15, type: "Senior" },
                { name: "Tracy M", cost: 49.06, type: "Mid-Level" }
            ],

            // Rate Charts
            // rateChartNonProfit: [
            //     { type: "Choose", rate: 0 },
            //     { type: "Executive", rate: 300 },
            //     { type: "Senior", rate: 250 },
            //     { type: "Mid-Level", rate: 200 },
            //     { type: "Associate", rate: 150 },
            //     { type: "Coordinator", rate: 150 }
            // ], 
            rateChartNonProfit: [
                { type: "Choose", rate: 0 },
                { type: "Executive", rate: 250 },
                { type: "Senior", rate: 225 },
                { type: "Mid-Level", rate: 180 },
                { type: "Associate", rate: 150 },
                { type: "Coordinator", rate: 150 }
            ], 
            // rateChartForProfit: [
            //     { type: "Choose", rate: 0 },
            //     { type: "Executive", rate: 400 },
            //     { type: "Senior", rate: 275 },
            //     { type: "Mid-Level", rate: 225 },
            //     { type: "Associate", rate: 175 },
            //     { type: "Coordinator", rate: 175 }
            // ], 
            rateChartForProfit: [
                { type: "Choose", rate: 0 },
                { type: "Executive", rate: 350 },
                { type: "Senior", rate: 250 },
                { type: "Mid-Level", rate: 225 },
                { type: "Associate", rate: 175 },
                { type: "Coordinator", rate: 175 }
            ],
            // rateChartExisting: [
            //     { type: "Choose", rate: 0 },
            //     { type: "Executive", rate: 400 },
            //     { type: "Senior", rate: 275 },
            //     { type: "Mid-Level", rate: 225 },
            //     { type: "Associate", rate: 175 },
            //     { type: "Coordinator", rate: 175 }
            // ],
             rateChartExisting: [
                { type: "Choose", rate: 0 },
                { type: "Executive", rate: 350 },
                { type: "Senior", rate: 250 },
                { type: "Mid-Level", rate: 225 },
                { type: "Associate", rate: 175 },
                { type: "Coordinator", rate: 175 }
            ]


            
        }
        

    }

    componentDidMount() {
        this.getBreakdownData(this.props.breakdownId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.rateType !== prevProps.rateType) {
            this.updateRate();
        }
    }

    getBreakdownData(breakdownId) {
        fetch(API_URL + `/billingcalculator/breakdowndata/${breakdownId}`)
            .then((res) => {
                if (!res.ok) {
                throw new Error()
                }
                return res.json()
            })
            .then((result) => {
                this.setState({ ...result[0], });
            })
            .catch((error) => {
                console.log(error);
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => this.updateTotals());
    };

    updateTotals() {
        this.setState({ total_hours: parseInt(this.state.hours1) + parseInt(this.state.hours2) + parseInt(this.state.hours3) + parseInt(this.state.hours4) + parseInt(this.state.hours5) + parseInt(this.state.hours6) + parseInt(this.state.hours7) + parseInt(this.state.hours8) + parseInt(this.state.hours9) + parseInt(this.state.hours10) + parseInt(this.state.hours11) + parseInt(this.state.hours12) },
            
            () => this.setState({ total_costs: parseInt(this.state.total_hours) * parseInt(this.state.cost) },
            
                () => this.setState({ total_bill: parseInt(this.state.total_hours * parseInt(this.state.rate)) },
                
                    () => this.setState({ margin: (parseFloat(this.state.total_bill) - parseFloat(this.state.total_costs)) / parseFloat(this.state.total_bill) },
                        () => this.updateDB()
                    )
                )
            )
        );
    };


    selectEmployee = (e) => {
        this.setState({ employee_name: e.target.value },
        () => this.updateCost()
        )
    }

    updateCost() {
        const index = this.state.employeeTable.findIndex(name => name.name === this.state.employee_name);
        this.setState({ cost: this.state.employeeTable[index].cost },
        () => this.updateTotals());
    }

    selectType = (e) => {
        this.setState({ employee_type: e.target.value },
        () => this.updateRate()
        )
    }

    updateRate() {
        let rateType = this.props.rateType;
        if (rateType === "non-profit") {
            const index = this.state.rateChartNonProfit.findIndex(type => type.type === this.state.employee_type);
            this.setState({ rate: this.state.rateChartNonProfit[index].rate },
            () => this.updateTotals());
        } else if (rateType === "existing") {
            const index = this.state.rateChartExisting.findIndex(type => type.type === this.state.employee_type);
            this.setState({ rate: this.state.rateChartExisting[index].rate },
            () => this.updateTotals());
        } else {
            const index = this.state.rateChartForProfit.findIndex(type => type.type === this.state.employee_type);
            this.setState({ rate: this.state.rateChartForProfit[index].rate },
            () => this.updateTotals());
        } 
    }

    updateDB = debounce(() => {
        let data = {
            employee_type: this.state.employee_type,
            employee_name: this.state.employee_name,
            rate: this.state.rate,
            cost: this.state.cost,
            hours1: this.state.hours1,
            hours2: this.state.hours2,
            hours3: this.state.hours3,
            hours4: this.state.hours4,
            hours5: this.state.hours5,
            hours6: this.state.hours6,
            hours7: this.state.hours7,
            hours8: this.state.hours8,
            hours9: this.state.hours9,
            hours10: this.state.hours10,
            hours11: this.state.hours11,
            hours12: this.state.hours12,
            total_hours: this.state.total_hours,
            total_costs: this.state.total_costs,
            total_bill: this.state.total_bill,
            margin: this.state.margin
        };
        this.updateBreakdown(data);
    }, 1500);

    updateBreakdown(data) {
        fetch(API_URL + `/billingcalculator/breakdown/update`, {
            method: "PUT",
            body: JSON.stringify({
                breakdownId: this.props.breakdownId,
                data: data,
            }),
            headers: {"Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .then(() => this.props.getSectionBreakdowns(this.props.secId)) 
            .catch((err) => console.log(err))
    }

    handleBreakdownDelete = (breakdownId) => {
        console.log(`Deleting Contact: ${breakdownId}`)
        fetch(API_URL + `/billingcalculator/breakdown/delete/${breakdownId}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        })
        .then(() => this.props.getSectionBreakdowns(this.props.secId))
        .catch((err) => console.log(err));
    }

    render() {
        return (
        

            <tr key={this.props.breakdownId}>
                <td>
                    <select name="employee_type" value={this.state.employee_type} onChange={this.selectType}>
                        <option value="Choose">Choose</option>
                        <option value="Executive">Executive</option>
                        <option value="Senior">Senior</option>
                        <option value="Mid-Level">Mid-Level</option>
                        <option value="Associate">Associate</option>
                        <option value="Coordinator">Coordinator</option>
                    </select>
                </td>
                <td>
                    <select name="employee_name" value={this.state.employee_name} onChange={this.selectEmployee}>
                        <option value="Choose">Choose</option>
                        <option value="Adam N">Adam N</option>
                        <option value="Amber H">AmberH</option>
                        <option value="Candace A">Candace A</option>
                        <option value="Cory R">Cory R</option>
                        <option value="Denise M">Denise M</option>
                        <option value="Gabriela N">Gabriela N</option>
                        <option value="Hayley T">Hayley T</option>
                        <option value="Jazmin K">Jazmin K</option>
                        <option value="Kacye B">Kacye B</option>
                        <option value="Kathy B">Kathy B</option>
                        <option value="Kellee C">Kellee C</option>
                        <option value="Kristen W">Kristen W</option>
                        <option value="Lauren L">Lauren L</option>
                        <option value="Luke B">Luke B</option>
                        <option value="Mercedes C">Mercedes C</option>
                        <option value="Sherri G">Sherri G</option>
                        <option value="Tracy M">Tracy M</option>
                    </select>
                </td>
                <td>
                    {this.state.rate}
                </td>
                <td>
                    {this.state.cost}
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours1}
                        onChange={this.handleChange}
                        name="hours1"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours2}
                        onChange={this.handleChange}
                        name="hours2"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours3}
                        onChange={this.handleChange}
                        name="hours3"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours4}
                        onChange={this.handleChange}
                        name="hours4"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours5}
                        onChange={this.handleChange}
                        name="hours5"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours6}
                        onChange={this.handleChange}
                        name="hours6"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours7}
                        onChange={this.handleChange}
                        name="hours7"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours8}
                        onChange={this.handleChange}
                        name="hours8"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours9}
                        onChange={this.handleChange}
                        name="hours9"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours10}
                        onChange={this.handleChange}
                        name="hours10"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours11}
                        onChange={this.handleChange}
                        name="hours11"
                    />
                </td>
                <td>
                    <Form.Control
                        size="sm"
                        type="text"
                        value={this.state.hours12}
                        onChange={this.handleChange}
                        name="hours12"
                    />
                </td>
                <td>
                    {this.state.total_hours}
                </td>
                <td>
                    <button
                        className='ml-auto border-0'
                        onClick={() => this.handleBreakdownDelete(this.props.breakdownId)}
                    >
                        <i className="fa-duotone fa-trash-can"></i>
                    </button>
                </td>
            </tr>        
        )
    }
}

export default Breakdown;