import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Gifts extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Gift Acceptance</h1>
                    <p>{this.props.clientName} is committed to treating all people and organizations, with whom we come into contact or conduct business, impartially. To do so, employees must practice and demonstrate equal treatment, unbiased professionalism, and non-discriminatory actions in relation to all vendors, suppliers, customers, employees, potential employees, potential vendors or suppliers, and any other individual or organization.</p>

                    <p>Employees should not seek or accept for themselves or others any gifts, discounts, favors, entertainment, or payments without a legit {this.props.clientName} business purpose nor should they seek or accept personal loans (other than conventional loans at market rates from lending institutions) from any persons or business organizations that do or seek to do business with Company.  </p>

                    <p>Employees may, however, accept for themselves and members of their families, common courtesies usually associated with customary business practices. These include but are not limited to:
                        <ul>
                            <li>Lunch and/or dinner with vendors sometimes including spouses as long as the invitation is extended by the vendor.</li>
                            <li>Gifts of small value from vendors such as t-shirts, calendars, pens, pads, etc.</li>
                            <li>Tickets to events (such as sports, arts, etc.) if offered by the vendor and the vendor accompanies the employee to the event. These are not to be solicited by the employee and must be approved by the appropriate supervisor.</li>
                            <li>Outings such as golf or other day or overnight outings under the condition that individuals from either other companies or the vendor are in attendance. </li>
                            <li>Gifts of perishable items usually given during the holidays such as hams, cookies, nuts, etc.</li> 
                        </ul>
                    </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Gifts;
