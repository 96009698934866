import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from  "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Privacy extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Privacy Policy</h1>
                    <p>{this.props.clientName} believes that a workplace that is free of drugs, alcohol, and other harmful materials is vital to the health and safety of its employees and to the success of the organization. {this.props.clientName} must also protect against the unauthorized removal of {this.props.clientName} property. Accordingly, {this.props.clientName} reserves the right to maintain access at all times to the {this.props.clientName} premises, property, records, documents and files and may conduct routine inspections or search at any time for {this.props.clientName} property or prohibited materials on {this.props.clientName} premises. Employees may also be requested to display personal property (such as purses, boxes, briefcases, etc.) for visual inspection upon the {this.props.clientName}’s request, and failure to consent to a search or to display personal property for visual inspection will be grounds for termination or denial of access to the {this.props.clientName}’s premises. Employees should have no expectation of privacy on {this.props.clientName} premises, except where otherwise provided by law.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Privacy;
