import React, { Component } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

    
`

class PerformanceExec extends Component {
    


    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Performance Management</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceReviews1"
                      as={Row}
                      controlId="formPerformanceReviews1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        20. How effective is your performance review process at
                        improving organizational performance?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-1"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-2"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-3"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-4"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-5"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews1"
                        id="formPerformanceReviews1-6"
                        defaultChecked={
                          this.props.formPerformanceReviews1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceReviews2"
                      as={Row}
                      controlId="formPerformanceReviews2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        21. How consistently is the performance review process
                        used?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-1"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-2"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-3"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-4"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-5"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceReviews2"
                        id="formPerformanceReviews2-6"
                        defaultChecked={
                          this.props.formPerformanceReviews2 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceReviews5">
                      <Form.Label>22. What types of performance management activities would you like to see implemented in the organization?</Form.Label>
                      <Form.Control
                        name="formPerformanceReviews5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceReviews5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceReviews4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceReviews4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceReviews4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Goal Setting</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceGoals3"
                      as={Row}
                      controlId="formPerformanceGoals3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        23. How effective is your goal setting process at
                        achieving outcomes?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-1"
                        defaultChecked={this.props.formPerformanceGoals3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-2"
                        defaultChecked={this.props.formPerformanceGoals3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-3"
                        defaultChecked={this.props.formPerformanceGoals3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-4"
                        defaultChecked={this.props.formPerformanceGoals3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-5"
                        defaultChecked={this.props.formPerformanceGoals3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals3"
                        id="formPerformanceGoals3-6"
                        defaultChecked={this.props.formPerformanceGoals3 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceGoals4"
                      as={Row}
                      controlId="formPerformanceGoals4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        24. How in line are the organization/department and
                        individual goals?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-1"
                        defaultChecked={this.props.formPerformanceGoals4 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-2"
                        defaultChecked={this.props.formPerformanceGoals4 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-3"
                        defaultChecked={this.props.formPerformanceGoals4 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-4"
                        defaultChecked={this.props.formPerformanceGoals4 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-5"
                        defaultChecked={this.props.formPerformanceGoals4 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceGoals4"
                        id="formPerformanceGoals4-6"
                        defaultChecked={this.props.formPerformanceGoals4 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceGoals6">
                      <Form.Label>25.	What types of goal setting practices would you like to see implemented in the organization, if any?</Form.Label>
                      <Form.Control
                        name="formPerformanceGoals6"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceGoals6 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceGoals5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceGoals5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceGoals5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Employee Development</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceDevelopment1"
                      as={Row}
                      controlId="formPerformanceDevelopment1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        26. How effective is your organization at developing it's
                        employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-1"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-2"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-3"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-4"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-5"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceDevelopment1"
                        id="formPerformanceDevelopment1-6"
                        defaultChecked={
                          this.props.formPerformanceDevelopment1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceDevelopment5">
                      <Form.Label>27.	What types of employee development activities would you like to see implemented in the organization?</Form.Label>
                      <Form.Control
                        name="formPerformanceDevelopment5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceDevelopment5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                    

                    <Form.Group controlId="formPerformanceDevelopment4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceDevelopment4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceDevelopment4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Corrective Action Process (Managers / Leaders Only)</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formPerformanceCorrective1"
                      as={Row}
                      controlId="formPerformanceCorrective1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        28. How satisfied are you with your corrective action
                        process as it relates to improving employee performance?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-1"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-2"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-3"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-4"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-5"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceCorrective1"
                        id="formPerformanceCorrective1-6"
                        defaultChecked={
                          this.props.formPerformanceCorrective1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceCorrective3"
                      as={Row}
                      controlId="formPerformanceCorrective3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        29. Are any employees currently being managed through the
                        corrective action process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-1"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-2"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPerformanceCorrective3"
                        id="formPerformanceCorrective3-3"
                        defaultChecked={
                          this.props.formPerformanceCorrective3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceCorrective5">
                      <Form.Label>30. What types of improvements could be made to the corrective action/disciplinary process?</Form.Label>
                      <Form.Control
                        name="formPerformanceCorrective5"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceCorrective5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceCorrective4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceCorrective4"
                        as="textarea"
                        rows="4"
                        value={this.props.formPerformanceCorrective4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Leadership Development</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    

                    <Form.Group
                      name="formPerformanceLeadershipDevelopment2"
                      as={Row}
                      controlId="formPerformanceLeadershipDevelopment2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        31. How satisfied are you with the organization’s
                        leadership pipeline (i.e. growing current employees into
                        the company’s future leaders)?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-1"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-2"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-3"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-4"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-5"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceLeadershipDevelopment2"
                        id="formPerformanceLeadershipDevelopment2-6"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment2 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPerformanceLeadershipDevelopment3"
                      as={Row}
                      controlId="formPerformanceLeadershipDevelopment3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        32.	How confident are you that your organization adequately equips leaders to the next level of success?   
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-1"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-2"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-3"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-4"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-5"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPerformanceLeadershipDevelopment3"
                        id="formPerformanceLeadershipDevelopment3-6"
                        defaultChecked={
                          this.props.formPerformanceLeadershipDevelopment3 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPerformanceLeadershipDevelopment5">
                      <Form.Label>33.	What types of leadership development opportunities would you like to see implemented? </Form.Label>
                      <Form.Control
                        name="formPerformanceLeadershipDevelopment5"
                        as="textarea"
                        rows="4"
                        value={
                          this.props.formPerformanceLeadershipDevelopment5 || ""
                        }
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                    
                    <Form.Group controlId="formPerformanceLeadershipDevelopment4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPerformanceLeadershipDevelopment4"
                        as="textarea"
                        rows="4"
                        value={
                          this.props.formPerformanceLeadershipDevelopment4 || ""
                        }
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PerformanceExec;