import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Leave extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Leave</h4>
                                <hr />
                                <p>Leave describes benefits paid or unpaid that cover a period when medical or personal necessity occurs and is supported by an employer. Examples: Paid time off (PTO), vacation, sick, holidays, leave because of medical necessity such as birth or adoption of a child?</p>
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsatisfied and
                                5-Very Satisfied...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formLeave"
                                controlId="formLeave"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    29. How satisfied are you with your leave programs?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formLeave"
                                    id="formLeave-1"
                                    defaultChecked={this.props.formLeave === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formLeave"
                                    id="formLeave-2"
                                    defaultChecked={this.props.formLeave === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formLeave"
                                    id="formLeave-3"
                                    defaultChecked={this.props.formLeave === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formLeave"
                                    id="formLeave-4"
                                    defaultChecked={this.props.formLeave === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formLeave"
                                    id="formLeave-5"
                                    defaultChecked={this.props.formLeave === 5}
                                />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Leave;