import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Rewards extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Rewards and Recognition</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsatisfied and
                                5-Very Satisfied...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formRewardsActivities"
                                controlId="formRewardsActivities"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    8. How satisfied are you with the rewards and recognition activities offered by the origination?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formRewardsActivities"
                                    id="formRewardsActivities-1"
                                    defaultChecked={this.props.formRewardsActivities === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formRewardsActivities"
                                    id="formRewardsActivities-2"
                                    defaultChecked={this.props.formRewardsActivities === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formRewardsActivities"
                                    id="formRewardsActivities-3"
                                    defaultChecked={this.props.formRewardsActivities === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formRewardsActivities"
                                    id="formRewardsActivities-4"
                                    defaultChecked={this.props.formRewardsActivities === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formRewardsActivities"
                                    id="formRewardsActivities-5"
                                    defaultChecked={this.props.formRewardsActivities === 5}
                                />
                                </Form.Group>

                                <Form.Group className="ml-3" controlId="formRewardsRecognition">
                                <Form.Label>
                                    9. What types of rewards and recognition programs are available to you?
                                </Form.Label>
                                <Form.Control
                                    name="formRewardsRecognition"
                                    as="textarea"
                                    rows="4"
                                    onChange={this.props.handleChange}
                                    value={this.props.formRewardsRecognition || ""}
                                />
                                </Form.Group>
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Rewards;