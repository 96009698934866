import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class FamilyLeave extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Family and Medical Leave (FML)</h1>
                    <p>Employees who have been employed for at least 12 months and have worked at least 1,250 hours during the preceding 12-month period are eligible for unpaid family or medical leave.  </p>

                    <p>Employees should contact Human Resources for additional information about FML leave.  {this.props.clientName} will inform the employee if the employee is eligible for leave, any additional information required, employee’s rights and responsibilities, and if ineligible the reason for the ineligibility.</p>

                    <p>An eligible employee would qualify for job-protected leave under our Family and Medical Leave policy (FML) for qualified reasons as set forth below.
                    </p>

                    <h2>Eligibility</h2>
                    <p>Employees who have been employed for at least 12 months and have worked at least 1,250 hours during the preceding 12-month period are eligible for unpaid family or medical leave. </p>

                    <h2>Basic Leave Entitlement</h2>
                    <p>An employee who meets the applicable eligibility requirements will be granted leave for the following reasons:
                        <ul>
                            <li>The birth of the employee’s child and to care for that child;</li>
                            <li>The placement of a child with the employee for adoption or foster care;</li>
                            <li>To care for a spouse, minor child or parent who has a serious health condition;</li>
                            <li>A serious health condition that renders the employee incapable of performing the functions of his/her job;</li>
                        </ul>
                    </p>

                    <h2>Military Family Leave Entitlements</h2>
                    <p>Eligible employees whose spouse, son, daughter or parent is on covered active duty or call to covered active duty status may use their 12-week leave entitlement to address certain qualifying exigencies. Qualifying exigencies may include attending certain military events, arranging for alternative childcare, addressing certain financial and legal arrangements, attending certain counseling sessions, and attending post-deployment reintegration briefings. FML also includes a special leave entitlement that permits eligible employees to take up to 26 weeks of leave to care for a covered service member during a single 12-month period. A covered service member is:
                        <ul>
                            <li>a current member of the Armed Forces, including a member of the National Guard or Reserves, who is undergoing medical treatment, recuperation or therapy, is otherwise in outpatient status, or is otherwise on the temporary disability retired list, for a serious injury or illness; or </li>
                            <li>a veteran who was discharged or released under conditions other than dishonorable at any time during the five-year period prior to the first date the eligible employee takes FML to care for the covered veteran, and who is undergoing medical treatment, recuperation, or therapy for a serious injury or illness (NOTE: the definition of a “serious injury or illness” is different than the definition of the definition of a “serious health condition under basic FML).</li>
                        </ul>
                    </p>

                    <h2>Leave Period</h2>
                    <p>An eligible employee is entitled to take up to 12 weeks of family leave, medical leave, or military exigency leave in any 12-month period.  The 12-month period shall be a rolling 12-month period measured backward from the date the leave is used for all types of leave other than military caregiver leave. Entitlement to leave for the birth or placement of a child for adoption or foster care will expire 12 months from the date of birth or placement. If a husband and wife both work for {this.props.clientName}, the aggregate leave taken by both employees is limited to a total of 12 weeks if the leave is taken for the birth or placement of a child or to care for a parent with a serious health condition.  However, if the leave is taken by either spouse to care for the other who is seriously ill and unable to work, to care for a child with a serious health condition, or for his or her own serious health condition, then each employee is eligible for 12 weeks of leave.  </p>

                    <h2>Definitions</h2>
                    <p>
                        <ul>
                            <li>Son or Daughter – Son or daughter means a biological, adopted or foster child, a step child, a legal ward or a child of a person standing in place of a parent with the rights, responsibilities and duties of a parent.</li>
                            <li>Spouse – Spouse means a husband or a wife as defined or recognized under the laws of any jurisdiction in which the marriage was entered into, including common law marriage in jurisdictions where it is recognized.  </li>
                            <li>Parent –Parent means a biological parent or person who stands or stood in place of a parent with the rights, responsibilities and duties of a parent to an employee when the employee was a child.  Parent does not include parents “in-law”.</li>
                            <li>Serious Health Condition - A serious health condition is an illness, injury, impairment, or physical or mental condition that involves either an overnight stay in a medical care facility, or continuing treatment by a health care provider for a condition that either prevents the employee from performing the functions of the employee’s job, or prevents the qualified family member from participating in school or other daily activities.  Subject to certain conditions, the continuing treatment requirement may be met by a period of incapacity of more than 3 consecutive calendar days combined with at least two visits to a health care provider or one visit and a regimen of continuing treatment, or incapacity due to pregnancy, or incapacity due to a chronic condition. Other conditions may meet the definition of continuing treatment.</li>
                        </ul>
                    </p>

                    <h2>Notice of Leave</h2>
                    <p>An employee intending to take family or medical leave must provide 30 days advance notice when the need to take the leave is foreseeable.  If the leave is not foreseeable, the employee must provide notice as soon as practicable and generally must comply with {this.props.clientName}’s normal call-in procedures.  In no event should the employee notify {this.props.clientName} of the need for leave more than two days after the need for leave is known.  {this.props.clientName} will inform the employee if he/she is eligible for leave, any additional information required, employee’s rights and responsibilities, and if ineligible the reason for the ineligibility.</p>

                    <h2>Employee Responsibilities </h2>
                    <p>Employees must provide sufficient information for the employer to determine if the leave may qualify for FML protection and the anticipated timing and duration of the leave. Sufficient information may include that the employee is unable to perform job functions, the family member is unable to perform daily activities, the need for hospitalization or continuing treatment by a health care provider, or circumstances supporting the need for military family leave. Employees also must inform the employer if the requested leave is for a reason for which FML leave was previously taken or certified. Employees also may be required to provide a certification and periodic recertification supporting the need for leave.  {this.props.clientName} will not interfere with, restrain, or deny the exercise of any employee’s right to take FML.  {this.props.clientName} will not discharge or discriminate against any person for opposing any practice made unlawful by FML or for involvement in any proceeding under or relating to FML.  </p>

                    <h2>Intermittent Leave</h2>
                    <p>Intermittent or reduced leave is not available in case of birth or placement of a child unless agreed in advance by {this.props.clientName}.  Intermittent or reduced schedule leave is available when medically necessary for serious health conditions and is available for military exigency and military caregiver leave.  Employees are expected to make a reasonable effort to schedule intermittent leave so as not to disrupt the operations of {this.props.clientName}.</p>

                    <h2>Certification</h2>
                    <p>Employees requesting leave under the FML are required to provide the appropriate certification documents within the time requested to be eligible for coverage.  Medical certification is required for leaves for an employee’s serious health condition, care of a family member or for a service member’s serious health condition.  The appropriate paperwork will be provided by THE COMPANY to the employee for the certification.  Certification paperwork is also required for exigency leave and will be provided by {this.props.clientName} to the employee for completion.  Proof of birth, adoption or foster placement can be certified by appropriate legal documents.  {this.props.clientName} will require certification documents provided by {this.props.clientName} to be returned within fifteen (15) calendar days of {this.props.clientName}’s request.  THE COMPANY may request re-certification of the need for leave within the guidelines allowed under the law.</p>

                    <h2>Benefits Coverage During Leave</h2>
                    <p>During a qualifying FML leave, the employee will be retained on {this.props.clientName} health plan under the same conditions that applied before the leave began.  To continue benefits coverage, the employee must continue to make any contributions that he/she made for group insurance before taking the leave.  Failure of the employee to pay his/her share of the health insurance premium may result in loss of coverage.  An employee who takes qualifying leave will not lose any previously accrued seniority or benefits. If an employee fails to return to work after the expiration of the leave, the employee will be required to reimburse {this.props.clientName} for payment of insurance premiums during the family or medical leave.  Reimbursement will not be required if the employee does not return to work because of a serious health condition or other circumstances beyond the employee’s control.</p>

                    <h2>Restoration to Employment</h2>
                    <p>An employee who completes a qualifying leave will be returned to the same position held when the leave began or to a position equivalent in pay, benefits, and other terms and conditions of employment.  However, the highest paid ten percent of salaried employees are not guaranteed reinstatement if reinstatement will cause THE COMPANY economic injury.  In such a case, {this.props.clientName} will notify the employee as soon as it determines that reinstatement is not available.  {this.props.clientName} cannot guarantee that an employee will be returned to his/her same former job.</p>

                    <h2>Return from Leave</h2>
                    <p>{this.props.clientName} will require an employee taking a qualifying leave to report every four weeks on his/her status and intent to return to work upon completion of the leave.  Employees returning from a medical leave are required to provide certification from a health care provider indicating that the employee is able to resume work, with or without reasonable accommodation of the employee’s disability, if any.  Employees who do not return to work upon the expiration of a qualifying leave will be treated as having voluntarily terminated their employment.  An employee who fraudulently obtains leave under this provision is not protected under the reinstatement provisions of this policy.  An employee who requests an extension of a qualifying leave must submit the request in writing and include the reason for the requested extension.  Additional certifications may be necessary before any decision on the approval is made.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default FamilyLeave;
