import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Notices extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Bulletin Board/Intranet Notices to Employees</h1>
                    <p>{this.props.clientName} posts information to team members on the bulletin board located in the employee break room. You are responsible for being informed about this material by periodically reviewing the bulletin board. Only authorized personnel may add and remove notices from the bulletin board.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Notices;
