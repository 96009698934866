import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class PersonnelData extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Change in Personnel Data</h1>
                    <p>{this.props.clientName} creates a file for all newly hired employees.  This file is the property of {this.props.clientName} and is maintained for the length of the employee’s employment. Employees are responsible for ensuring that information in the file is updated and correct. Any changes in personnel information such as personal mailing addresses, telephone numbers, number and names of dependents, marital status, military status, individuals to be contacted in the event of an emergency, work history, training, education or experience, W-4 tax forms and other information pertinent to the employee should be updated by the employee online or submitted to Human Resources as soon as possible.   </p>
                    
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default PersonnelData;
