import React, { Component } from 'react';
import { Row, Col, Table } from 'react-bootstrap';


class StatsKathyB extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teamMember: "KathyB",
            filteredList: [],

            //Consulting
            talentedCandScore: 0,
            talentedCandDiv: 0,
            talEfficiencyScore: 0,
            talEfficiencyDiv: 0,
            talStakeholdersScore: 0,
            talStakeholdersDiv: 0,
            talCommsScore: 0,
            talCommsDiv: 0,
            talMethodScore: 0,
            talMethodDiv: 0,
            talPrioritiesScore: 0,
            talPrioritiesDiv: 0,
            talExpectScore: 0,
            talExpectDiv: 0
        }
    }

    componentDidMount() {
        const companies = this.props.surveyData;
        const partialList = companies.filter(company => company.formKathyB_Rating > 0);
        this.setState({ filteredList: partialList}, () => this.calculateScores());
    }

    calculateScores() {
        console.log(`Calculating scores for ${this.state.teamMember}...`);
        const result = this.state.filteredList;

        let talentedCandScore = 0;
        let talentedCandDiv = 0;
        let talEfficiencyScore = 0;
        let talEfficiencyDiv = 0;
        let talStakeholdersScore = 0;
        let talStakeholdersDiv = 0;
        let talCommsScore = 0;
        let talCommsDiv = 0;
        let talMethodScore = 0;
        let talMethodDiv = 0;
        let talPrioritiesScore = 0;
        let talPrioritiesDiv = 0;
        let talExpectScore = 0;
        let talExpectDiv = 0;

        for (let i = 0; i < result.length; i++){
            talentedCandScore = talentedCandScore + +result[i].formCandidates;
                    talEfficiencyScore = talEfficiencyScore + +result[i].formEfficiency;
                    talStakeholdersScore = talStakeholdersScore + +result[i].formTalentStakeholders;
                    talCommsScore = talCommsScore + +result[i].formTalentCommunications;
                    talMethodScore = talMethodScore + +result[i].formTalentMethod;
                    talPrioritiesScore = talPrioritiesScore + +result[i].formTalentPriorities;
                    talExpectScore = talExpectScore + +result[i].formTalentExpectations;
                    if (result[i].formCandidates !== "") {
                        talentedCandDiv = talentedCandDiv + 1;
                    }
                    if (result[i].formEfficiency !== "") {
                        talEfficiencyDiv = talEfficiencyDiv + 1;
                    }
                    if (result[i].formTalentStakeholders !== "") {
                        talStakeholdersDiv = talStakeholdersDiv + 1;
                    }
                    if (result[i].formTalentCommunications !== "") {
                        talCommsDiv = talCommsDiv + 1;
                    }
                    if (result[i].formTalentMethod !== "") {
                        talMethodDiv = talMethodDiv + 1;
                    }
                    if (result[i].formTalentPriorities !== "") {
                        talPrioritiesDiv = talPrioritiesDiv + 1;
                    }
                    if (result[i].formTalentExpectations !== "") {
                        talExpectDiv = talExpectDiv + 1;
                    }
                    
                    this.setState({
                        talentedCandScore: talentedCandScore,
                        talentedCandDiv: talentedCandDiv,
                        talEfficiencyScore: talEfficiencyScore,
                        talEfficiencyDiv: talEfficiencyDiv,
                        talStakeholdersScore: talStakeholdersScore,
                        talStakeholdersDiv: talStakeholdersDiv,
                        talCommsScore: talCommsScore,
                        talCommsDiv: talCommsDiv,
                        talMethodScore: talMethodScore,
                        talMethodDiv: talMethodDiv,
                        talPrioritiesScore: talPrioritiesScore,
                        talPrioritiesDiv: talPrioritiesDiv,
                        talExpectScore: talExpectScore,
                        talExpectDiv: talExpectDiv
                    })
            }
        }


    render() {
        return (
            <div className='p-2 m-0'>
                <Table striped bordered variant='dark'>
                    <thead className='thead-dark'>
                        <tr>
                            <th>Client</th>
                            <th>Respondent</th>
                            <th>Score</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.filteredList.map((client) => (
                                <tr key={client.id}>
                                    <td>{client.orgName}</td>
                                    <td>{client.name}</td>
                                    <td>{client.formKathyB_Rating}</td>
                                    <td>{client.formKathyB_Comments}</td>
                                </tr>
                            ))

                            
                        }
                    </tbody>
                </Table>

                <h4>Talent Acquisition Categories - Client Ratings</h4>
                <Row>
                    <Col className="text-center score-cards-sml">
                        <div>Talented</div>
                        <div>Candidates</div>
                        {
                            this.state.talentedCandScore > 0 ? <h4>{(this.state.talentedCandScore/this.state.talentedCandDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    <Col className="text-center score-cards-sml">
                        <div>Quality &</div>
                        <div>Efficiency</div>
                        {
                            this.state.talEfficiencyScore > 0 ? <h4>{(this.state.talEfficiencyScore/this.state.talEfficiencyDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    <Col className="text-center score-cards-sml">
                        <div>Stakeholder</div>
                        <div>Cooperation</div>
                        {
                            this.state.talStakeholdersScore > 0 ? <h4>{(this.state.talStakeholdersScore/this.state.talStakeholdersDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    <Col className="text-center score-cards-sml">
                        <div>Timely</div>
                        <div>Communication</div>
                        {
                            this.state.talCommsScore > 0 ? <h4>{(this.state.talCommsScore/this.state.talCommsDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    
                </Row>
                <Row>
                    <Col className="text-center score-cards-sml">
                        <div>Unique</div>
                        <div>Methodologies</div>
                        {
                            this.state.talMethodScore > 0 ? <h4>{(this.state.talMethodScore/this.state.talMethodDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    <Col className="text-center score-cards-sml">
                        <div>Learn</div>
                        <div>Culture</div>
                        {
                            this.state.talPrioritiesScore > 0 ? <h4>{(this.state.talPrioritiesScore/this.state.talPrioritiesDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                    <Col className="text-center score-cards-sml">
                        <div>Overall</div>
                        <div>Value</div>
                        {
                            this.state.talExpectScore > 0 ? <h4>{(this.state.talExpectScore/this.state.talExpectDiv).toFixed(2)}</h4> : <h4>--</h4>
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

export default StatsKathyB;