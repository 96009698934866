import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class SocialMedia extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Social Media</h1>
                    <p>At {this.props.clientName}, we understand that social media can be a fun and rewarding way to share your life and opinions with family, friends, and co-workers around the world. However, use of social media also presents certain risks and carries with it certain responsibilities. To assist you in making responsible decisions about your use of social media, we have established these guidelines for appropriate use of social media.</p>

                    <h2>Guidelines</h2>
                    <p>In the expanding world of electronic communication, social media can mean many things. Social media includes all means of communicating or posting information or content of any sort on the internet, including but not limited to your own or someone else's web log or blog, journal or diary, personal website, social networking or affinity website, web bulletin board or a chat room, whether or not associated or affiliated with {this.props.clientName}, as well as any other form of electronic communication.</p>

                    <p>The same principles and guidelines found in {this.props.clientName} policies and these basic beliefs apply to your activities online. Ultimately, you are solely responsible for what you post online. You may be personally responsible for any litigation that may arise should you make unlawful defamatory, slanderous, or libelous statements against any customer, manager, participant, or employee of {this.props.clientName}. Before creating online content, you may want to consider some of the risks and rewards that are involved.</p>

                    <h2>Know and Follow the Rules</h2>
                    <p>Carefully read and familiarize yourself with {this.props.clientName} policies and procedures to ensure your postings are consistent with these policies. Postings that include unlawful discriminatory remarks, harassment (as defined by our EEO policy), and threats of violence or other unlawful conduct will not be tolerated and may subject you to disciplinary action up to and including termination.</p>

                    <h2>You Are Encouraged to Show Respect</h2>
                    <p>{this.props.clientName} cannot force or mandate respectful and courteous activity by employees on social media during nonworking time. However, everyone should be aware of the negative impact comments of this nature can have on the workplace and relationships with others. In addition, please keep in mind that you may be more likely to resolve work-related disputes by speaking directly with your co-workers or by utilizing our Open Door Policy than by posting complaints to a social media outlet. Nevertheless, if you decide to post complaints or criticism, avoid using statements, photographs, video, or audio that reasonably could be viewed as unlawful, slanderous, threatening, or that might constitute unlawful harassment (as defined by our EEO policies). Examples of such conduct might include defamatory or slanderous posts meant to harm someone's reputation or posts that could contribute to a hostile work environment on the basis of race, sex, disability, age, national origin, religion, veteran status, or any other status or class protected by law or {this.props.clientName} policy.</p>

                    <h2>Honesty and Accuracy</h2>
                    <p>You should understand that honesty and accuracy are important when posting information or news, and that it is good practice to correct a mistake quickly. You may want to be open about any previous posts you have altered. Remember that the internet archives almost everything; therefore, even deleted postings often can be searched.</p>

                    <h2>Posting Information</h2>
                    <p>When posting information:
                        <ul>
                            <li>Maintain the confidentiality of {this.props.clientName} related information (i.e. participant information, financial records/reports, marketing or business strategies/plans, donor names, names of volunteers, vendors, employee lists, etc.).</li>
                            <li>Do not create a link from your blog, website, or other social networking site to an {this.props.clientName} website that identifies you as speaking on behalf of {this.props.clientName} unless authorized by the CEO.</li>
                            <li>Never represent yourself as a spokesperson for {this.props.clientName}, unless authorized by the CEO or Chief Development Officer. If {this.props.clientName} is a subject of the content you are creating, do not represent yourself as speaking on {this.props.clientName}'s behalf.</li>
                            <li>Respect copyright, trademark, and similar laws, and use such protected information in compliance with applicable legal standards.</li>
                        </ul>
                    </p>

                    <h2>Using Social Media at Work</h2>
                    <p>Refrain from using social media while on your work time; unless it is work related as authorized by your manager or consistent with the Company Equipment Policy.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default SocialMedia;
