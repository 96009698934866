import React, { Component } from 'react';
import {Accordion, Card} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';
import Attendance from './Policies/4_Conduct/Attendance';
import Conduct from './Policies/4_Conduct/Conduct';
import Confidentiality from './Policies/4_Conduct/Confidentiality';
import Ethics from './Policies/4_Conduct/Ethics';
import Gifts from './Policies/4_Conduct/Gifts';
import Harassment from './Policies/4_Conduct/Harassment';
import InfectionControl from './Policies/4_Conduct/InfectionControl';
import Misconduct from './Policies/4_Conduct/Misconduct';
import OutsideEmployment from './Policies/4_Conduct/OutsideEmployment';
import PersonalAppearance from './Policies/4_Conduct/PersonalAppearance';
import Recording from './Policies/4_Conduct/Recording';
import SocialMedia from './Policies/4_Conduct/SocialMedia';
import Weapons from './Policies/4_Conduct/Weapons';
import Whistleblower from './Policies/4_Conduct/Whistleblower';


const Div = styled.div`
     
    .accordion{
        color: white;
        background: black;
        border: 1px solid black;
        
    }

`

class SectionConduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionName: 'Introduction'
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Accordion className="my-5">
                                <Card>
                                    <Accordion.Toggle className="accordion" as={Card.Header} eventKey="0">
                                        4. Conduct
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Attendance & Punctuality
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Attendance clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        How We Conduct Ourselves
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Conduct clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Confidentiality
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Confidentiality clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Ethics
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Ethics clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>

                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Gift Acceptance
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Gifts clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Harassment & Discrimination 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Harassment clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Infection Control
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><InfectionControl clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Misconduct
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Misconduct clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Outside Employment
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><OutsideEmployment clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Personal Appearance
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><PersonalAppearance clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Recording
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Recording clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Social Media 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><SocialMedia clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Weapons
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Weapons clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Whistleblower Safety
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Whistleblower clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                </Div>
            </ThemeProvider>
        )
    }
}

export default SectionConduct;