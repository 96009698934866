import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";;

const Div = styled.div`
    color: black;
`

class MediaInquiries extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Media inquiries</h1>
                    <p>To ensure that all confidential business information remains confidential, media inquiries regarding {this.props.clientName} and its operations must be referred to the President and CEO.  Only the President and CEO are authorized to make or approve public statements on behalf of {this.props.clientName}.  No employees, unless specifically designated by the President and CEO, are authorized to make statements on behalf of or as a representative of {this.props.clientName}.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default MediaInquiries;
