import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class DrugFree extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Drug-Free Workplace</h1>
                    <p>{this.props.clientName} desires to maintain a workplace that is free of drugs and alcohol, and to discourage drug or alcohol abuse by its employees. Except when allowed for approved business-related events, the consumption of alcoholic beverages on {this.props.clientName} premises is strictly prohibited. Employees who are under the influence of a drug or alcohol while on the job compromises the {this.props.clientName} interests and endanger their own health and safety and the health and safety of others.  As such, the possession, consumption, manufacture or distribution of alcohol or non-prescribed drugs is strictly prohibited.  Employees are also prohibited from reporting for duty or remaining on duty with any alcohol and/or drugs that may cause impairment in their systems.</p>

                    <p>Employees who are required to use prescription or over-the-counter drugs, and who know or should know that their use of the drugs might result in their working while impaired by the drug are encouraged to contact their own physician and Human Resources, and to find out whether it is advisable for them to continue working while using the drug. In such cases, {this.props.clientName} reserves the right to have a physician of its choice determine whether it is advisable for the employee to continue working while taking the drug. </p>

                    <p>Drug Testing: Employees may be required to submit to a drug test if a supervisor has a reasonable suspicion that he or she is working under the influence of drugs or alcohol.  Factors that give rise to reasonable suspicion include the personal observation of indications of drug use in the employee's behavior (such as speech and appearance), work habits, productivity or accident record.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default DrugFree;
