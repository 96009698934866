import React, { Component } from 'react';
import { Jumbotron, Row, Col, Table } from 'react-bootstrap';
import { config } from '../../Config/Constants';
import styled from 'styled-components';


const API_URL = config.url.API_URL;

const Div = styled.div `

    .score-cards-big{
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 1rem;
    }
`

class StatsSummaryCurrentYear extends Component {
    constructor(props) {
        super(props);

        this.state = {
            surveyData: [],
            surveyYear: 0,
            surveyScoreConsulting: 0,
            consultingScoreDiv: 0,
            surveyScoreTalent: 0,
            talentScoreDiv: 0
        }
    }

    componentDidMount() {
        console.log("grabbing survey data");
        this.grabSurveyData();
    }

    grabSurveyData() {
        fetch(API_URL + "/client/surveydata/currentyear")
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((result) => {
                let consultingScore = 0;
                let consultingScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempConScore = +result[i].formTalentedConsultants + +result[i].formConsultantUnderstands + +result[i].formTimely + +result[i].formSolutionAttitude + +result[i].formFairPrice + +result[i].formStakeholders + +result[i].formCommunications + +result[i].formProjects + +result[i].formOwnership + +result[i].formLearn + +result[i].formExpectations;
                    consultingScore = +consultingScore + +tempConScore;
                    this.setState({ surveyScoreConsulting: consultingScore });
                    if (result[i].formTalentedConsultants !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formConsultantUnderstands !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formTimely !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formSolutionAttitude !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formFairPrice !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formStakeholders !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formCommunications !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formProjects !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formOwnership !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formLearn !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formExpectations !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    this.setState({ consultingScoreDiv: consultingScoreDiv });
                }
                let talentScore = 0;
                let talentScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempTalentScore = +result[i].formCandidates + +result[i].formEfficiency + +result[i].formTalentStakeholders + +result[i].formTalentCommunications + +result[i].formTalentMethod + +result[i].formTalentPriorities + +result[i].formTalentExpectations;
                    talentScore = +talentScore + +tempTalentScore;
                    this.setState({ surveyScoreTalent: talentScore });
                    if (result[i].formCandidates !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formEfficiency !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentStakeholders!== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentCommunications !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentMethod !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentPriorities !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentExpectations !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    this.setState({ talentScoreDiv: talentScoreDiv });
                }

                this.setState({
                    surveyData: result,
                    surveyScoreTalent: talentScore
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getClientHCScore(client) {
        let consultingScoreDiv = 0;
        let consultingScore = +client.formTalentedConsultants + +client.formConsultantUnderstands + +client.formTimely + +client.formSolutionAttitude + +client.formFairPrice + +client.formStakeholders + +client.formCommunications + +client.formProjects + +client.formOwnership + +client.formLearn + +client.formExpectations;
        if (client.formTalentedConsultants !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formConsultantUnderstands !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formTimely !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formSolutionAttitude !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formFairPrice !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formStakeholders !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formCommunications !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formProjects !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formOwnership !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formLearn !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        if (client.formExpectations !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
        let score = (consultingScore / consultingScoreDiv).toFixed(2);
        if (isNaN(score)) {
            return `---`
        } else {
            return `${score}`
        }
    }

    getClientTAScore(client) {
        let talentScoreDiv = 0;
        let talentScore = +client.formCandidates + +client.formEfficiency + +client.formTalentStakeholders + +client.formTalentCommunications + +client.formTalentMethod + +client.formTalentPriorities + +client.formTalentExpectations;
        if (client.formCandidates !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formEfficiency !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formTalentStakeholders!== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formTalentCommunications !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formTalentMethod !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formTalentPriorities !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        if (client.formTalentExpectations !== "") { talentScoreDiv = +talentScoreDiv + 1 };
        let score = (talentScore / talentScoreDiv).toFixed(2);
        if (isNaN(score)) {
            return `---`
        } else {
            return `${score}`
        }
    }


    render() {
        
        return (
            <Jumbotron className="text-dark">
                <Div>
                    <Row>
                        <Col>
                            <h3 className='display-4'>Client Satisfaction Survey 2024</h3>
                            <Row>
                                <Col className="text-center score-cards-big">
                                    <h4>Overall Consulting Score:</h4>
                                    {isNaN(this.state.surveyScoreConsulting/this.state.consultingScoreDiv) ?
                                        <h1 className='display-1'>
                                            0.0
                                        </h1>
                                         :
                                        <h1 className="display-1">
                                            {(this.state.surveyScoreConsulting / this.state.consultingScoreDiv).toFixed(2)}
                                        </h1>
                                    }
                                </Col>
                                <Col className="text-center score-cards-big">
                                    <h4>Overall Talent Score:</h4>
                                    {isNaN(this.state.surveyScoreTalent/this.state.talentScoreDiv) ?
                                        <h1 className='display-1'>
                                            0.0
                                        </h1>
                                         :
                                        <h1 className="display-1">
                                            {(this.state.surveyScoreTalent / this.state.talentScoreDiv).toFixed(2)}
                                        </h1>
                                    }

                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Table striped bordered>
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Name</th>
                                        <th>HC Score</th>
                                        <th>TA Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.surveyData.map((client) => {
                                            
                                            return (
                                                <tr key = { client.id } >
                                                    <td>{client.orgName}</td>
                                                    <td>{client.name}</td>
                                                    <td>
                                                        {this.getClientHCScore.call(this, client)}
                                                    </td>
                                                    <td>
                                                        {this.getClientTAScore.call(this, client)}
                                                    </td>
                                                </tr>
                                            )
                                        }                                        
                                    )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </Div>
            </Jumbotron>
        )
    }
}

export default StatsSummaryCurrentYear;