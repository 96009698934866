import React, { Component } from "react";
import { Button } from "react-bootstrap";

class LinkInterviewButtons extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(this.props.questionSection);
    }

    render() {
        
        return (
            <div className=" d-flex justify-content-end mr-4 mb-3">
                <div >
                    {
                this.props.questionSection === "audit_general" ? null :
                  <Button className="mx-1"
                  size="sm"
                  variant="primary"
                  onClick={() => this.props.linkDisplaySectionsBack()}
                >
                            Previous Section
                </Button> 
                  
                }
                </div>

                {this.props.employment_level === "Employee" && (
                    this.props.questionSection === "audit_rewards" ?
                        <Button className="mx-1"
                  size="sm"
                  variant="danger"
                  href="/survey-hca/thankyou"
                >
                            Submit Responses
                </Button>
                        :
                  <Button className="mx-1"
                  size="sm"
                  variant="primary"
                  onClick={() => this.props.linkDisplaySectionsNext()}
                >
                            Next Section
                </Button> 
                )}

                {this.props.employment_level !== "Employee" && (
                    this.props.questionSection === "audit_workforce" ?
                        <Button className="mx-1"
                  size="sm"
                  variant="danger"
                  href="/survey-hca/thankyou"
                >
                            Submit Responses
                </Button>
                        :
                  <Button className="mx-1"
                  size="sm"
                  variant="primary"
                  onClick={() => this.props.linkDisplaySectionsNext()}
                >
                            Next Section
                </Button> 
                )}

                {/* {this.props.questionSection === "audit_rewards" ? 
                <Button className="mx-1"
                  size="sm"
                  variant="danger"
                  href="/survey-hca/thankyou"
                >
                            Submit Responses
                </Button>
                        :
                  <Button className="mx-1"
                  size="sm"
                  variant="primary"
                  onClick={() => this.props.linkDisplaySectionsNext()}
                >
                            Next Section
                </Button>  
                } */}
        </div>
            
            
            
        )
}

}

export default LinkInterviewButtons;