import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class WorkersComp extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Workers' Compensation</h1>
                    <p>Injuries occurring in the course of employment are paid for by workers' compensation insurance. Workers' compensation insurance pays all medical costs and is paid for exclusively by {this.props.clientName}.  There are well-defined provisions that must be met to ensure that employees qualify for workers’ compensation benefits.</p>

                    <p>All instances of work-related illness or injury, even if such injury or illness does not require medical attention, must be reported to People Performance Resources by the end of the day or as soon as reasonably possible. No matter how minor an on-the-job injury may appear, it is important that it be reported as soon as possible. The workers’ compensation insurer will investigate all late reported claims. Where facts cannot be verified, the claim will be denied. Any claim for an injury or illness caused by an employee's willful misconduct, alcohol or drug usage, or that occurs during the employee’s voluntary participation in any off-duty recreational, social, or athletic activity sponsored by {this.props.clientName} will not be compensable.   </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default WorkersComp;
