import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class MedicalEvaluations extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Employee Medical Evaluations</h1>
                    <p>Medical examinations are not required as part of the normal hiring procedure. However, {this.props.clientName} requires certain employees to adhere to immunization policies identified below. The {this.props.clientName} periodically offers the Tuberculosis (TB) testing on an “entire staff” basis. Employees are encouraged to participate or to consult their own physicians to meet the testing requirements.</p>

                    <h2>Hepatitis B Immunization</h2>
                    <p>This policy establishes guidelines for providing Hepatitis B immunization to the {this.props.clientName} employees who are known to be at occupational risk of exposure to hepatitis B infection.</p>
                    <p>All employees, contractors or volunteers, who have frequent, direct contact with blood or blood products during the course of their employment are considered to be at high or moderate risk of hepatitis B infection. These employees are eligible for free vaccination. It will be the employee’s  responsibility to complete the scheduled three-dose series.</p>
                    <p>Employees  who do not return for their subsequent doses within the required time, but still wish to continue with the vaccine, will be required to purchase any doses in excess of the initial three necessary to complete the series.</p>
                    <p>All staff, contractors, and volunteers who handle blood which includes [list job titles as applicable, e.g. phlebotomists, and nurses, physician assistants, dental assistants, dental hygienists, physicians and dentists].
                        <h3>Implementation</h3>
                        <ol>
                            <li>This hepatitis B vaccination is a series of three I.M. (intramuscular) injections of 1cc Recombivax of Heptavax B. The first injection is given and then followed by reinforcing doses at one month and at six months. It will be the employee’s responsibility to complete the scheduled three-dose series.</li>
                            <li>Employees who terminate their employment from the Center prior to the completion of the series may purchase the remaining doses from the Clinic at cost plus handling. Employees not eligible for free vaccination may purchase it at cost plus handling through the Clinic.</li>
                            <li>Employees designated to be at risk of exposure who do not wish to receive the vaccine will sign a form acknowledging the vaccine’s availability to them and their refusal to accept it.</li>
                            <li>Employees who have received the vaccine previously will be requested to sign a statement to that effect, and provide documentation attesting to the receipt of the vaccine.</li>
                            <li>Prior to administering the vaccine, the administering nurse will discuss and perform the following steps with the employee.</li>
                        </ol>
                    </p>

                    <h2>Employee PPD Testing</h2>
                    <p>In the interest of employee safety, health and welfare, all employees (paid or volunteer) of the Company, who have a Category I Classification based on the OSHA Standard for Blood Borne Pathogens, should be tested and monitored for possible, potential exposure to Tuberculosis (TB) through close client contact. It should not be necessary to test employees/volunteers who have only casual contact with clients.</p>
                    <p>All employees/contractors/volunteers shall receive a baseline PPD test prior to potential exposure and annually thereafter. Current employees will be given a baseline PPD test.
                        <ol>
                            <li>Seronegative (HIV Negative) employees shall receive 0.1 ml Tuberculin Purified Protein Derivative (Mantoux PPD).</li>
                            <li>Seropositive (HIV Positive) employees shall receive 0.1 ml Tuberculin Purified Protein Derivative (Mantoux PPD), with Mumps Antigen as the control.</li>
                            <li>The test material shall be infected intradermally to the volar aspect of the forearm. Hairy areas or areas without adequate subcutaneous tissue should be avoided.</li>
                            <li>The test shall be read by a licensed nurse or physician when available within 48 hours of test administration.</li>
                            <li>Employees with a positive reading shall be immediately referred for a chest x-ray.</li>
                        </ol>
                    </p>

                    <p> If an employee is HIV POSITIVE, the CompanyPPD Testing Protocol with the control must be followed. Failure to do so is a violation of this policy.</p>
                    
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default MedicalEvaluations;
