import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class IT extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Use of the Company's Information Technologies(IT)</h1>
                    <p>{this.props.clientName} provides employees with communications equipment, services, and passwords necessary to promote business efficiencies and protect company data (referred to here as “Information Technologies”). All Information Technologies, including but not limited to computers, laptops, tablets, telephone systems, the computer network, internet, online services, the email system and messages transmitted or stored by them are the property of {this.props.clientName}.  </p>

                    <p>{this.props.clientName} (or its designated representatives) maintains the right and ability, with or without notice to the employee, to access and review any information contained on its Information Technologies, even if protected by private password. Those individuals using company Information Technologies should have no expectation of privacy in connection with the use of such technologies or transmission, receipt or storage through the use of such technologies. This includes all Information Technologies, including email messages sent or received through company email account or through a private web-based email account accessed using company Information Technologies.  </p>

                    <p>Employees are responsible for adhering to the procedures and policies related to the proper use of all Information Technologies. Only employees and other authorized individuals may access company Information Technologies.  Employees must not share their passwords with others.  </p>

                    <p>Employees should limit their use of company Information Technologies for personal purposes except in emergencies or when extenuating circumstances warrant it.  {this.props.clientName} Information Technologies may not be removed from the premises without authorization from the employee’s supervisor. </p>

                    <p>Employees should ensure that no personal correspondence appears to be an official communication of company since employees may be perceived as representatives of {this.props.clientName} and, therefore, damage or create liability for {this.props.clientName}.  All outgoing messages, whether by mail, facsimile, e-mail, Internet transmission, or any other means must be accurate, appropriate, and work-related.</p>

                    <p>Employees with access to the company’s Information Technologies should not:
                        <ul>
                            <li>Download software without approval from IT Partner. Software that is approved for downloading must be registered to {this.props.clientName}.</li>
                            <li>Copy software unless authorized by the IT Partner;</li>
                            <li>Download files from the Internet except for an express business purpose;</li>
                            <li>Download files from company network or email onto personal computer devices; </li>
                            <li>Use e-mail or the Internet for any purpose that is illegal, against company policy, or contrary to the company best interest;</li>
                            <li>Transmit or disseminate company confidential information, proprietary materials, or trade secrets to any outside source without an express business purpose or authorization;</li>
                            <li>Send or forward any chain e-mail, broadcast e-mail, or spam utilizing company Information Technologies;</li>
                            <li>Gamble and/or participate in fantasy sport leagues utilizing company Information Technologies;</li>
                            <li>Solicit non-company business or use company e-mail or Internet for personal gain including outside employment, self-employment, and family-owned businesses;</li>
                            <li>Use company e-mail or computer system to solicit for personal causes without prior authorization;</li>
                            <li>Use the company e-mail or computer system to solicit or encourage other employees to join, support, or contribute to any outside organization without prior authorization;.  </li>
                            <li>Receive or forward unsolicited e-mails that violate company policy;</li>
                            <li>Download radio, video, or music transmissions from Internet sites utilizing company Information Technologies;</li>
                            <li>Attempt to defeat any security mechanisms to gain unauthorized access to computer files or other information on company Information Technologies; </li>
                            <li>Attempt to read, intercept, copy, or delete e-mails between other users;</li>
                            <li>Post or transmit any message anonymously or under a false name or permit any other individual to do so;</li>
                            <li>Collect information about others, including e-mail addresses, without their consent.</li>
                        </ul>
                    </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default IT;
