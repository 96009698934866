import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class SummerTimeOff extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Summer Time-Off</h1>
                    <p>Regular, full-time employees have the opportunity to take up to two (2) additional paid days off each summer, as long as the employee is in good standing. To ensure proper coverage, one summer day must be taken between Memorial Day and Independence Day, and the other summer day must be taken between Independence Day and Labor Day.</p>

                    <p>Summer days are extra benefits and are not considered part of an employee’s PTO accrual, nor may they be accrued in any way. Therefore, they must be used as stated during the summer months or are lost. Approval by a supervisor is required. The employee must submit a time-off request according to normal procedures in order to gain approval for the time off.    </p>

                    <p>Employees hired between January 1st and June 30th are only eligible for one (1) summer day during their first calendar year of employment. Employees hired July 1st or later are not eligible for any summer days during their first calendar year of employment. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default SummerTimeOff;
