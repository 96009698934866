import React, { Component } from 'react';
import {Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

`

class CultureSupervisor extends Component {
     


    render() {



        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Company Culture</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      as={Row}
                      name="formCulture1"
                      controlId="formCulture1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        1. How would you rate your company culture?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture1"
                        id="formCulture1-1"
                        defaultChecked={this.props.formCulture1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture1"
                        id="formCulture1-2"
                        defaultChecked={this.props.formCulture1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture1"
                        id="formCulture1-3"
                        defaultChecked={this.props.formCulture1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture1"
                        id="formCulture1-4"
                        defaultChecked={this.props.formCulture1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture1"
                        id="formCulture1-5"
                        defaultChecked={this.props.formCulture1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture1"
                        id="formCulture1-6"
                        defaultChecked={this.props.formCulture1 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      name="formCulture2"
                      controlId="formCulture2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                          {/*remove*/}
                        2. How well does the culture here reflect the mission,
                        vision, and values of the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture2"
                        id="formCulture2-1"
                        defaultChecked={this.props.formCulture2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture2"
                        id="formCulture2-2"
                        defaultChecked={this.props.formCulture2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture2"
                        id="formCulture2-3"
                        defaultChecked={this.props.formCulture2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture2"
                        id="formCulture2-4"
                        defaultChecked={this.props.formCulture2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture2"
                        id="formCulture2-5"
                        defaultChecked={this.props.formCulture2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture2"
                        id="formCulture2-6"
                        defaultChecked={this.props.formCulture2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCulture3"
                      as={Row}
                      controlId="formCulture3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        3. How likely would you consider this organization a
                        "best place to work" amongst similar organizations?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture3"
                        id="formCulture3-1"
                        defaultChecked={this.props.formCulture3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture3"
                        id="formCulture3-2"
                        defaultChecked={this.props.formCulture3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture3"
                        id="formCulture3-3"
                        defaultChecked={this.props.formCulture3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture3"
                        id="formCulture3-4"
                        defaultChecked={this.props.formCulture3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture3"
                        id="formCulture3-5"
                        defaultChecked={this.props.formCulture3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture3"
                        id="formCulture3-6"
                        defaultChecked={this.props.formCulture3 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCulture4"
                      as={Row}
                      controlId="formCulture4"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        4. If nothing about your current culture changes, how
                        likely would it be that you would still be working here
                        in five years?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCulture4"
                        id="formCulture4-1"
                        defaultChecked={this.props.formCulture4 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCulture4"
                        id="formCulture4-2"
                        defaultChecked={this.props.formCulture4 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCulture4"
                        id="formCulture4-3"
                        defaultChecked={this.props.formCulture4 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCulture4"
                        id="formCulture4-4"
                        defaultChecked={this.props.formCulture4 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCulture4"
                        id="formCulture4-5"
                        defaultChecked={this.props.formCulture4 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCulture4"
                        id="formCulture4-6"
                        defaultChecked={this.props.formCulture4 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCulture5">
                      <Form.Label>
                        5. Give me three words that describe your company
                        culture.
                      </Form.Label>
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formCulture5a">
                        <Form.Control
                          name="formCulture5a"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5a || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture5b">
                        <Form.Control
                          name="formCulture5b"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5b || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture5c">
                        <Form.Control
                          name="formCulture5c"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture5c || ""}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formCulture6">
                      <Form.Label>
                        6. Give me three words that describe what it means to be
                        a successful employee in this organization.
                      </Form.Label>
                    </Form.Group>

                    <Form.Row>
                      <Form.Group as={Col} controlId="formCulture6a">
                        <Form.Control
                          name="formCulture6a"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6a || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture6b">
                        <Form.Control
                          name="formCulture6b"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6b || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formCulture6c">
                        <Form.Control
                          name="formCulture6c"
                          onChange={this.props.handleChange}
                          value={this.props.formCulture6c || ""}
                        />
                      </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formCulture7">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCulture7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCulture7 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Employee Engagement</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureEngagement2"
                      as={Row}
                      controlId="formCultureEngagement2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        7. How engaged are employees with the organization
                        (satisfied and committed to the organization)?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-1"
                        defaultChecked={this.props.formCultureEngagement2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-2"
                        defaultChecked={this.props.formCultureEngagement2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-3"
                        defaultChecked={this.props.formCultureEngagement2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-4"
                        defaultChecked={this.props.formCultureEngagement2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-5"
                        defaultChecked={this.props.formCultureEngagement2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureEngagement2"
                        id="formCultureEngagement2-6"
                        defaultChecked={this.props.formCultureEngagement2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureEngagement3"
                      as={Row}
                      controlId="formCultureEngagement3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        8. How likely are significant changes to be made as a
                        result of an engagement survey, if applicable?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-1"
                        defaultChecked={this.props.formCultureEngagement3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-2"
                        defaultChecked={this.props.formCultureEngagement3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-3"
                        defaultChecked={this.props.formCultureEngagement3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-4"
                        defaultChecked={this.props.formCultureEngagement3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-5"
                        defaultChecked={this.props.formCultureEngagement3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureEngagement3"
                        id="formCultureEngagement3-6"
                        defaultChecked={this.props.formCultureEngagement3 === 0}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureEngagement4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureEngagement4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureEngagement4 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Diversity, Equity, and Inclusion(DEI)</h5>
                    <hr />

                    

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureDEI3"
                      as={Row}
                      controlId="formCultureDEI3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        9. How satisfied are you with the diversity, equity, and
                        inclusion of the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureDEI3"
                        id="formCultureDEI3-1"
                        defaultChecked={this.props.formCultureDEI3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureDEI3"
                        id="formCultureDEI3-2"
                        defaultChecked={this.props.formCultureDEI3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureDEI3"
                        id="formCultureDEI3-3"
                        defaultChecked={this.props.formCultureDEI3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureDEI3"
                        id="formCultureDEI3-4"
                        defaultChecked={this.props.formCultureDEI3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureDEI3"
                        id="formCultureDEI3-5"
                        defaultChecked={this.props.formCultureDEI3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureDEI3"
                        id="formCultureDEI3-6"
                        defaultChecked={this.props.formCultureDEI3 === 0}
                      />
                    </Form.Group>
                    
                    <Form.Group controlId="formCultureDEI5">
                      <Form.Label>10. What types of diversity, equity, and inclusion programs would you like to see within the organization?</Form.Label>
                      <Form.Control
                        name="formCultureDEI5"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureDEI5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureDEI4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureDEI4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureDEI4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Rewards and Recognition</h5>
                    <hr />
                    <Form.Group
                      name="formCultureRewards1"
                      as={Row}
                      controlId="formCultureRewards1"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        11.What types of employee recognition programs are
                        utilized within your department? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Service Awards"
                          name="formCultureRewardsService"
                          id="formCultureRewards1-1"
                          defaultChecked={this.props.formCultureRewardsService}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Retirement Awards"
                          name="formCultureRewardsRetirement"
                          id="formCultureRewards1-2"
                          defaultChecked={
                            this.props.formCultureRewardsRetirement
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Peer Recognition Awards"
                          name="formCultureRewardsPeerRecognition"
                          id="formCultureRewards1-3"
                          defaultChecked={
                            this.props.formCultureRewardsPeerRecognition
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Spot Awards"
                          name="formCultureRewardsSpot"
                          id="formCultureRewards1-4"
                          defaultChecked={this.props.formCultureRewardsSpot}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Managerial Recognition Programs"
                          name="formCultureRewardsManagerial"
                          id="formCultureRewards1-5"
                          defaultChecked={
                            this.props.formCultureRewardsManagerial
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Organization-wide Recognition Programs"
                          name="formCultureRewardsOrganization"
                          id="formCultureRewards1-6"
                          defaultChecked={
                            this.props.formCultureRewardsOrganization
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Performance Awards"
                          name="formCultureRewardsPerformance"
                          id="formCultureRewards1-7"
                          defaultChecked={
                            this.props.formCultureRewardsPerformance
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employee of the Month/Year Awards"
                          name="formCultureRewardsEmployeeMonth"
                          id="formCultureRewards1-8"
                          defaultChecked={
                            this.props.formCultureRewardsEmployeeMonth
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Appreciation Lunches, Outings, Formal Events"
                          name="formCultureRewardsAppreciation"
                          id="formCultureRewards1-9"
                          defaultChecked={
                            this.props.formCultureRewardsAppreciation
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Goal-Specific Awards"
                          name="formCultureRewardsGoal"
                          id="formCultureRewards1-10"
                          defaultChecked={this.props.formCultureRewardsGoal}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employee Suggestion Program"
                          name="formCultureRewardsSuggestion"
                          id="formCultureRewards1-11"
                          defaultChecked={
                            this.props.formCultureRewardsSuggestion
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formCultureRewardsOther"
                          id="formCultureRewards1-12"
                          defaultChecked={this.props.formCultureRewardsOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formCultureRewardsOther === true ||
                        this.props.formCultureRewardsOther === 1) && (
                        <Form.Group controlId="formCultureRewardsOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formCultureRewardsOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formCultureRewardsOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureRewards2"
                      as={Row}
                      controlId="formCultureRewards2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        12. How effective are the rewards and recognition
                        activities offered by the organization?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureRewards2"
                        id="formCultureRewards2-1"
                        defaultChecked={this.props.formCultureRewards2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureRewards2"
                        id="formCultureRewards2-2"
                        defaultChecked={this.props.formCultureRewards2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureRewards2"
                        id="formCultureRewards2-3"
                        defaultChecked={this.props.formCultureRewards2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureRewards2"
                        id="formCultureRewards2-4"
                        defaultChecked={this.props.formCultureRewards2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureRewards2"
                        id="formCultureRewards2-5"
                        defaultChecked={this.props.formCultureRewards2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureRewards2"
                        id="formCultureRewards2-6"
                        defaultChecked={this.props.formCultureRewards2 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCultureRewards3">
                      <Form.Label>
                        13. What types of reward and recognition activities would
                        you like to see implemented in the organization?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formCultureRewards3"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureRewards3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureRewards4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureRewards4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureRewards4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Employee Communication</h5>
                    <hr />
                    
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureCommunication1"
                      as={Row}
                      controlId="formCultureCommunication1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        14. How satisfied are you with the communication from the
                        organization to managers?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-1"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-2"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-3"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-4"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-5"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication1"
                        id="formCultureCommunication1-6"
                        defaultChecked={
                          this.props.formCultureCommunication1 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureCommunication2"
                      as={Row}
                      controlId="formCultureCommunication2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        15. How satisfied are you with the frequency of company
                        to manager communication?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-1"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-2"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-3"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-4"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-5"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication2"
                        id="formCultureCommunication2-6"
                        defaultChecked={
                          this.props.formCultureCommunication2 === 0
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureCommunication3"
                      as={Row}
                      controlId="formCultureCommunication3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        16. How satisfied are you with your ability to
                        communicate ideas, share comments, or talk about
                        concerns with your executive leadership?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-1"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-2"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-3"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-4"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-5"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureCommunication3"
                        id="formCultureCommunication3-6"
                        defaultChecked={
                          this.props.formCultureCommunication3 === 0
                        }
                      />
                    </Form.Group>

                    

                    <Form.Group controlId="formCultureCommunication6">
                      <Form.Label>17. What types of communication activities would you like to see implemented in the organization?</Form.Label>
                      <Form.Control
                        name="formCultureCommunication6"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureCommunication6 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>

                                <Form.Group controlId="formCultureCommunication5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureCommunication5"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureCommunication5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Conflict Resolution</h5>
                    <hr />

                    <Form.Group
                      name="formCultureConflict"
                      as={Row}
                      controlId="formCultureConflict"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        18. How are conflicts in the organization generally
                        resolved? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Peer-to-Peer"
                          name="formConflictPeer"
                          id="formCultureConflict-1"
                          defaultChecked={this.props.formConflictPeer}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Involving Manager"
                          name="formConflictManager"
                          id="formCultureConflict-2"
                          defaultChecked={this.props.formConflictManager}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Chain of Command"
                          name="formConflictChain"
                          id="formCultureConflict-3"
                          defaultChecked={this.props.formConflictChain}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Human Resources"
                          name="formConflictHR"
                          id="formCultureConflict-4"
                          defaultChecked={this.props.formConflictHR}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal Grievance Process"
                          name="formConflictGrievance"
                          id="formCultureConflict-5"
                          defaultChecked={this.props.formConflictGrievance}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formConflictOther"
                          id="formCultureConflict-6"
                          defaultChecked={this.props.formConflictOther}
                        />
                      </Col>
                    </Form.Group>

                    <div>
                      {(this.props.formConflictOther === 1 ||
                        this.props.formConflictOther === true) && (
                        <Form.Group controlId="formConflictOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formConflictOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={this.props.formConflictOtherAnswer || ""}
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formCultureConflict1"
                      as={Row}
                      controlId="formCultureConflict1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        19. How effective is the organization's conflict
                        resolution process?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureConflict1"
                        id="formCultureConflict1-1"
                        defaultChecked={this.props.formCultureConflict1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureConflict1"
                        id="formCultureConflict1-2"
                        defaultChecked={this.props.formCultureConflict1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureConflict1"
                        id="formCultureConflict1-3"
                        defaultChecked={this.props.formCultureConflict1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureConflict1"
                        id="formCultureConflict1-4"
                        defaultChecked={this.props.formCultureConflict1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureConflict1"
                        id="formCultureConflict1-5"
                        defaultChecked={this.props.formCultureConflict1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureConflict1"
                        id="formCultureConflict1-6"
                        defaultChecked={this.props.formCultureConflict1 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formCultureConflict2"
                      as={Row}
                      controlId="formCultureConflict2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        20. How satisfied are you with the process for submitting
                        grievances and disputes?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formCultureConflict2"
                        id="formCultureConflict2-1"
                        defaultChecked={this.props.formCultureConflict2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formCultureConflict2"
                        id="formCultureConflict2-2"
                        defaultChecked={this.props.formCultureConflict2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formCultureConflict2"
                        id="formCultureConflict2-3"
                        defaultChecked={this.props.formCultureConflict2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formCultureConflict2"
                        id="formCultureConflict2-4"
                        defaultChecked={this.props.formCultureConflict2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formCultureConflict2"
                        id="formCultureConflict2-5"
                        defaultChecked={this.props.formCultureConflict2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formCultureConflict2"
                        id="formCultureConflict2-6"
                        defaultChecked={this.props.formCultureConflict2 === 0}
                      />
                    </Form.Group>

                    <hr />

                    <Form.Group controlId="formCultureConflict3">
                      <Form.Label>
                        21. What types of grievances/formal complaints have you
                        received in the last two years, if any?
                      </Form.Label>
                      <Form.Control
                        name="formCultureConflict3"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureConflict3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCultureConflict4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureConflict4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureConflict4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default CultureSupervisor;