import React, { Component } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';

const API_URL = config.url.API_URL;

const Div = styled.div`
  color: white;

  .audit-section {
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding: 1em;
    background: ${props => props.theme.colors.bg_trans};
    border-radius: 0.25em;
    h5 {
      color: ${(props) => props.theme.colors.dark_blue};
    }

    .button-section {
      display: flex;
    }
  }
`;

const StyledButton = styled(Button)`
    margin-top: 1em;
    width: 6em;
    height: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.dark_blue};
    border: 1px solid ${props => props.theme.colors.dark_blue};
    &:hover{
        color: ${props => props.theme.colors.orange};
        background: ${props => props.theme.colors.dark_blue};
        border: 1px solid ${props => props.theme.colors.dark_blue};
    }

    div{
        margin-top: 1em;
        font-size: .75em;
        color: yellow;
    }

`

const SmallStyledButton = styled(Button)`
  margin: .5em;

  ${'' /* width: 6em;
  height: 6em; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.colors.dark_blue};
  border: 1px solid ${(props) => props.theme.colors.dark_blue};
  &:hover {
    color: ${(props) => props.theme.colors.orange};
    background: ${(props) => props.theme.colors.dark_blue};
    border: 1px solid ${(props) => props.theme.colors.dark_blue};
  }

  div {
    margin-top: 1em;
    font-size: 0.75em;
  }
`;

class HCA_Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            client: "Choose Client",
            short: "Choose Client Above",
            hcas: [],
            clients_with_hcas: [],
            showHide: false,
            lastHCA_Id: 0
        };

        this.selectClient = this.selectClient.bind(this);
        this.createHCA = this.createHCA.bind(this);
        this.handleModalHide = this.handleModalHide.bind(this);
    }

    

  componentDidMount() {
    this.getAllHCAs();
    }

    getAllHCAs() {
    fetch(API_URL + "/clients/all")
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((result) => {
        this.setState({
          clients: result.sort((a, b) => a.client.localeCompare(b.client)),
        });
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      })
      .then(
        fetch(API_URL + `/hcas/all`)
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((result) => {
            this.setState({ hcas: result });
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          })
          .then(
            fetch(API_URL + `/hcas/distinct`)
              .then((res) => {
                if (!res.ok) {
                  throw new Error();
                }
                return res.json();
              })
              .then((result) => {
                this.setState({ clients_with_hcas: result });
                console.log(result);
              })
              .catch((error) => {
                console.log(error);
              })
          )
      );
    }
    
    handleModalShow() {
        this.setState({ showHide: true });
    }

    handleModalHide() {
        this.setState({ showHide: false });
    }

    handleModalSave() {
        this.setState({ showHide: false });
    }

    selectClient(e) {
    this.setState({ client: e.target.value }, () => {
      const selectedClient = this.state.client;
      console.log(selectedClient);
      for (let i = 0; i < this.state.clients.length; i++) {
        if (this.state.clients[i].client === selectedClient) {
          const client = this.state.clients[i];
          console.log(client);
          this.setState({
            client: client.client,
            short: client.short,
          });
        }
      }
    });
  }

    createHCA() {
      fetch(API_URL + `/hca/create`, {
        method: "PUT",
        body: JSON.stringify({
          client: this.state.client,
          short: this.state.short,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error();
          }
          return res.json();
        })
        .then((data) => console.log(data))
        .catch((err) => console.log(err))
        .then(() => this.getHCAid())
        .then(() => this.getAllHCAs());
        this.setState({ showHide: false});
  }

  

  getHCAid() {
    fetch(API_URL + `/hca/getLastId`)
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((result) => {
        const id = result[0].max_hca_id;
        this.setState({ lastHCA_Id: result[0].max_hca_id }, () => this.createComplianceQuestions(this.state.lastHCA_Id));
        console.log(`Latest HCA Id:`);
        console.log(id);
      }).catch((error) => {
        console.log(error);
      }
    )
  }

  

  createComplianceQuestions(hca_id) {
    fetch(
      API_URL + `/compliance/create/questions/${hca_id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          hca_id: hca_id,
          table: "hca_compliance_personnel",
        }),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((data) => console.log(data))
      .catch((err) => console.log(err))
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_onoff",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_risk",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_benefits",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_performance",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_payroll",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_hris",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      )
      .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_talent",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
    )
    .then(
        fetch(
          API_URL + `/compliance/create/questions/${hca_id}`,
          {
            method: "PUT",
            body: JSON.stringify({
              hca_id: hca_id,
              table: "hca_compliance_hr",
            }),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw new Error();
            }
            return res.json();
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err))
      );
  }


  
    addDocsList() {
      fetch(
        API_URL + `/hca/documents/add/${this.state.lastHCA_Id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            lastHCA_Id: this.state.lastHCA_Id
          }),
          headers: { "Content-Type": "application/json" },
        }
      ).then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
        .then((data) => console.log(data))
      .catch((err) => console.log(err))
      
    }

    render() {
        const today = new Date();
        const date = (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + ' ' + time;

        return (
            <ThemeProvider theme={theme}>
            <Container>
              <Div>
                <div>
                    <StyledButton
                        className="ml-auto"
                        onClick={() => this.handleModalShow()}
                    >
                        <i className="fal fa-plus-hexagon fa-2x"></i>
                        <div>New HCA</div>
                    </StyledButton>
                </div>
                {/* <div>
                    <StyledButton
                        className="ml-auto"
                        onClick={() => this.addDocsList()}
                    >
                        <i className="fal fa-plus-hexagon fa-2x"></i>
                        <div>Generate Docs List</div>
                    </StyledButton>
                </div> */}
                <div>
                  <h3>Last HCA ID: {this.state.lastHCA_Id}</h3>
                </div>
                <div>
                    {this.state.clients_with_hcas.map((hcaClient) => (
                        <div
                        className="audit-section"
                        key={hcaClient.client}
                        client={hcaClient.client}
                        >
                            <h5>{hcaClient.client}</h5>
                            <div className="button-section">
                                {this.state.hcas
                                .filter((hca) => hca.client === hcaClient.client)
                                .map((filteredHCA) => (
                                    <SmallStyledButton
                                    key={filteredHCA.hca_id}
                                    id={filteredHCA.hca_id}
                                    client={filteredHCA.client}
                                    short={filteredHCA.short}
                                    date_created={filteredHCA.created_at}
                                    onClick={() => this.props.showDash(filteredHCA.hca_id, filteredHCA.client)}
                                    >
                                    <i className="fal fa-file-chart-line fa-2x"></i>
                                    <div>
                                      {filteredHCA.hca_id}
                                      <br/>
                                        {filteredHCA.short}
                                        <br />
                                        {new Date(
                                        filteredHCA.created_at
                                        ).toLocaleDateString()}
                                    </div>
                                    </SmallStyledButton>
                                ))}
                            </div>
                        </div>
                    ))}
              </div>
            </Div>
                    {/* //Modal 
                        //Modal 
                        //Modal 
                        //Modal
                        //Modal */}

                    <Modal show={this.state.showHide} size="lg" centered>
                        <Modal.Header>
                        <Modal.Title>Create New HCA</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <select onChange={this.selectClient} value={this.state.id}>
                                <option default>Select Client...</option>
                                {this.state.clients.map((client) => (
                                <option key={client.client} id={client.id}>
                                    {client.client}
                                </option>
                                ))}
                            </select>
                            <p className="mt-4">{this.state.short}</p>
                            <p className="mt-4">{dateTime}</p>
                            <div className="text-right">
                                <Button
                                size="sm"
                                variant="danger"
                                onClick={() => this.handleModalHide()}
                                >
                                Cancel
                                </Button>
                                <Button
                                className="ml-3"
                                size="sm"
                                variant="primary"
                                onClick={() =>
                                    this.createHCA(this.state.client, this.state.short)
                                }
                                >
                                Create New HCA
                                </Button>
                            </div>                                                
                        </Modal.Body>
                    </Modal>
                </Container>
            </ThemeProvider>
        )
  }


}

export default HCA_Menu;