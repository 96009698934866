import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './App.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from '../Authentication/Protected-Routes';


import BillingCalculator from '../../Pages/BillingCalculator/BillingCalculator';
import ClientDatabase from '../../Pages/Database/ClientDatabase';
import ClientSatisfactionSurvey from '../../Pages/ClientSatisfactionSurvey/ClientSatisfactionSurvey';
import CandidateSatisfactionSurvey from '../../Pages/CandidateSatisfactionSurvey/CandidateSatisfactionSurvey';
import Dashboard from '../../Pages/Dashboard/Dashboard';
// import Financial from '../../Pages/Dashboard/Financial';
import Loading from '../Loading/Loading';
import Handbook from '../../Pages/Handbook/Handbook';
import HCA from "../../Pages/HCA/HCA";
import HCA_Culture from "../../Pages/HCA/HCA_Culture";
import HCA_Survey from '../../Pages/Audit/HCA_Survey';
import MenuBar from '../MenuBar/MenuBar';
import RateSheet from '../../Pages/RateSheet/Ratesheet';
import SpecialProjects from '../../Pages/Dashboard/SpecialProjects';
import ClientSurveyStats from '../../Pages/ClientSurveyStats/ClientSurveyStats';
import CandSurveyStats from '../../Pages/CandidateSurveyStats/CandSurveyStats';
import ThankYou from '../../Pages/Audit/ThankYou';
import FullStatsBreakdown from '../../Pages/ClientSurveyStats/FullStatsBreakdown';


const App = () => {
  const isHCASurvey = useRouteMatch("/hca-survey/:id/:name/:level/:audit/:client");
  const isHCAEmployeeSurvey = useRouteMatch("/survey-hca/employee");
  const isHCASurveyThanks = useRouteMatch("/survey-hca/thankyou");
  const isClientSatSurvey = useRouteMatch("/css");
  const isCandidateSatSurvey = useRouteMatch("/candss");
  let renderMenu = true;
  const { isLoading, error } = useAuth0();
  const {
    // user,
    // isAuthenticated,
    // loginWithRedirect,
    logout,
  } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
  
  if (error) {
    logoutWithRedirect();
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isHCASurvey || isHCASurveyThanks || isClientSatSurvey || isHCAEmployeeSurvey || isCandidateSatSurvey) {
    renderMenu = false;
  } else {
    renderMenu = true;
  }

    
  return (
    <div className="App">
      <div className="background-logo">
        <img src="/logo_background.png" alt="background logo"></img>
      </div>

      <Row>
        {renderMenu && (
          <Col xs={1}>
          <MenuBar />
        </Col>
        )}
        
        <Col className="area-content">
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <ProtectedRoute path="/database" component={ClientDatabase} />
              {/* <ProtectedRoute path="/financial" component={Financial} /> */}
              <ProtectedRoute path="/hca" component={HCA} />
              <ProtectedRoute path="/specialprojects" component={SpecialProjects} />
              <ProtectedRoute path="/clientsurveystats" component={ClientSurveyStats} />
              <ProtectedRoute path="/candsurveystats" component={CandSurveyStats} />
              <ProtectedRoute path="/handbook" component={Handbook} />
              <ProtectedRoute path="/ratesheet" component={RateSheet} />
              <ProtectedRoute path="/billingcalculator" component={BillingCalculator} />
              <ProtectedRoute path="/clientsurveyresults" component={FullStatsBreakdown} />
              <Route exact path="/survey/emp/:hca_id" component={HCA_Culture} />
              <Route exact path="/hca-survey/:id/:name/:level/:audit/:client" component={HCA_Survey} />
              <Route exact path="/survey-hca/thankyou" component={ThankYou} />
              <Route exact path="/css" component={ClientSatisfactionSurvey} />
              <Route exact path={"/candss"} component={CandidateSatisfactionSurvey} />
            {/* <Route path="/clientsatisfaction/survey" component={ClientSatisfactionSurvey} /> */}
            
            </Switch>
        </Col>
      </Row>
    </div>
  );
}

export default App;
