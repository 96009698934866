import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Safety extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Safety Procedures & Preventing Injuries in the Workplace</h1>
                    <p>For an accident prevention plan to be successful, it is essential that rules be developed and enforced.  The rules must be understood and followed by all employees, contractors and volunteers.  Supervisors/managers must enforce the rules as part of their duties.</p>

                    <p>Employees are expected to use common sense and to ask for guidance from their manager or director prior to performing a task with which they are not familiar.</p>

                    <p>Employees are required to know and comply with the following safety rules. Failure to comply with these safety rules may result in disciplinary action up to and including termination.</p>

                    <h2>General Safety Policies</h2>
                    <p>
                        <ul>
                            <li>Do not use and/or possess alcoholic beverages or non-prescription controlled substances on the job.  Doing so will result in disciplinary action up to and including termination.</li>
                            <li>Immediately notify Human Resources, your manager or director of any on-the-job accident, injury or incident.  Such notification must be given within 24 hours.</li>
                            <li>Inspect all ladders, tools and equipment before using and avoid using any defective tool or equipment. Tag and remove defective equipment from service until properly repaired.</li>
                            <li>Report hazardous conditions to your manager or director.</li>
                            <li>When in doubt about how to do something, ask your manager or director.</li>
                            <li>Do not operate machines or equipment without safety guards in place.</li>
                            <li>Do not leave equipment unattended while in operation or motion.</li>
                            <li>Do not wear loose or torn clothing around rotating or moving machinery or equipment.</li>
                            <li>Wear closed toe shoes with recommended steel toes for work requiring heavy lifting.</li>
                            <li>Running, horseplay, or other unsafe behavior will not be tolerated.</li>
                            <li>Do not leave tools, scrap materials, or other objects strewn about or left where they may cause tripping hazards.</li>
                            <li>Remove and properly discard scrap materials, whether combustible or not, daily.</li>
                        </ul>
                    </p>

                    <h2>Personal Safety</h2>
                    <p>
                        <ul>
                            <li>Store personal belongings such as purses and other valuables out of sight, rather than on top of furniture or on the floor to avoid any temptation of stealing by people walking through.</li>
                            <li>Keep your work area safe.  Do not stack boxes precariously; keep desk and file drawers closed unless being accessed, do not run power cords across access ways, and in general use your common sense to protect yourself and others.</li>
                            <li>Make sure your car is locked and your personal belongings are hidden under the car seats or trunk.</li>
                            <li>Do not walk to your car alone at night and always have your keys readily available.</li>
                            <li>You must have prior written approval from your manager or director to work during hours the facility is normally closed.</li>
                            <li>If you observe persons exhibiting suspicious behavior in or around the buildings, notify your manager or director, or call 911.</li>
                        </ul>
                    </p>

                    <h2>Use of Electrical Equipment</h2>
                    <p>
                        <ul>
                            <li>Do not use tools, equipment and extension cords with broken or defective connectors, or frayed electrical cords or cables.</li>
                            <li>Except for battery operated or double-insulated portable electric hand tools, use only electrical equipment that is grounded.  Three-conductor plugs and receptacles are required on extension and equipment cords.</li>
                            <li>Unplug any portable electric equipment when not in use.</li>
                            <li>Before erecting or placing ladders, inspect the area for low-hanging overhead electrical wires.</li>
                        </ul>
                    </p>

                    <h2>Ladders</h2>
                    <p>
                        <ul>
                            <li>Ladder users must inspect ladders before using them each day. </li>
                            <li>Do not use ladders with structural defects, such as broken rungs or split side rails. Tag these as “out of service” and report same to facilities staff.</li>
                            <li>Set ladders on a firm center before use.</li>
                            <li>Open stepladders fully before use.</li>
                            <li>Position straight and extension ladders a distance from the vertical wall or support equal to one forth the working length of the ladder, and so that the top extends at least 36 inches above the roof or top support.</li>
                            <li>Keep areas around the top and bottom of ladders clear.</li>
                            <li>Do not use the top step of a step ladder as a step.</li>
                            <li>Always face the ladder when ascending or descending.</li>
                            <li>Do not carry any object or load that could cause a fall or a loss of balance from a ladder.</li>
                        </ul>
                    </p>

                    <h2>Motor Vehicles</h2>
                    <p>Employees shall not use a cell/mobile phone while company vehicle is being operated.</p>

                    <h2>Blood Borne Pathogens</h2>
                    <p>The scope of this plan covers all employees who could be "reasonably anticipated" as the result of performing their job duties to encounter blood and other potentially infectious materials. Infectious materials include blood, urine, stool, semen, vaginal secretions and saliva in dental procedures, any bodily fluid visibly contaminated with blood and all bodily fluids in situations where it is difficult or impossible to differentiate between body fluids. They also include any unfixed tissue or organ other than intact skin from a human (living or dead), human immunodeficiency virus (HIV)- containing cell or tissue cultures, organ cultures and HIV or hepatitis B (HBV)-containing culture medium or other solutions as well as blood. "Good Samaritan" acts such as assisting a co-worker with a nosebleed would not be considered occupational exposure.</p>

                    <h2>Exposure Plan</h2>
                    <p>Requires employers to identify, in writing, tasks and procedures as well as job classifications where occupational exposure to blood occurs--without regard to personal protective clothing and equipment. The plan is updated/reviewed at least annually--more often if necessary, to accommodate workplace changes.
                        <ul>
                            <li>Limit occupational exposure to blood and other potentially infectious materials since any exposure could result in transmission of blood borne pathogens which could lead to disease or death.</li>
                            <li>Mandates universal precautions, (treating bodily fluids/materials as if infectious) emphasizing engineering and work practice controls. </li>
                            <li>Stresses hand washing and requires employers to provide facilities and ensure that employees use them following exposure to blood. </li>
                            <li>Employees must follow precautions to minimize needle sticks, minimize splashing and spraying of blood, ensure appropriate packaging of specimens and regulated wastes and decontaminate equipment or label it as contaminated before shipping to servicing facilities.</li>
                            <li>At no cost to the employee, require employees to use appropriate personal protective equipment such as gloves, gowns, and masks. </li>
                            <li>There is a written schedule for cleaning, identifying the method of decontamination to be used, in addition to cleaning following contact with blood or other potentially infectious materials. </li>
                            <li>There are specifies methods for disposing of contaminated sharps and set standards for containers for these items and other regulated waste. </li>
                        </ul>
                    </p>

                    <h2>Hepatitis B Vaccination</h2>
                    <p>Hepatitis B, A/B vaccinations are made available to all employees who have occupational exposure to blood within 10 working days of assignment, at no cost, at a reasonable time and place, under the supervision of licensed physician/licensed healthcare professional and according to the latest recommendations of the U.S. Public Health Service (USPHS). Prescreening may not be required as a condition of receiving the vaccine. Employees must sign a declination form if they choose not to be vaccinated but may later opt to receive the vaccine at no cost to the employee. Should booster doses later be recommended by the USPHS, they must be offered to employees.</p>

                    <h2>Post-Exposure Evaluation and Follow Up</h2>
                    <p>{this.props.clientName} has procedures available to all employees who have had an exposure incident at no cost to the employee. Follow-up will be done by a medical professional at a private office and will include a confidential medical evaluation documenting the circumstances of exposure, identifying and testing the source individual if feasible, testing the exposed employee's blood if they consent, post-exposure prophylaxis, counseling and evaluation of reported illnesses. Healthcare professionals must be provided specified information to facilitate the evaluation and their written opinion on the need for hepatitis B vaccination following the exposure. Information such as the employee's ability to receive the hepatitis B vaccine must be supplied to the employer. All diagnoses must remain confidential.</p>

                    <h2>Hazard Communication</h2>
                    <p>Requires warning labels including the orange or orange-red biohazard symbol affixed to containers of regulated waste, refrigerators and freezers and other containers which are used to store or transport blood or other potentially infectious materials. Red bags or containers may be used instead of labeling. </p>

                    <h2>Information and Training</h2>
                    <p>Mandates training within 90 days of effective date, initially upon assignment and annually - employees who have received appropriate training within the past year need only receive additional training in items not previously covered. Training must include making accessible a copy of the regulatory text of the standard and explanation of its contents, general discussion on blood borne diseases and their transmission, exposure control plan, personal protective equipment, hepatitis B vaccine, response to emergencies involving blood, how to handle exposure incidents, the post-exposure evaluation and follow-up program, signs/labels/color-coding. There must be opportunity for questions and answers, and the trainer must be knowledgeable in the subject matter. Laboratory and production facility workers must receive additional specialized initial training.</p>

                    <h2>Record Keeping</h2>
                    <p>Medical records are kept for each employee with occupational exposure for the duration of employment plus 30 years, must be confidential and must include name and social security number; hepatitis B vaccination status (including dates); results of any examinations, medical testing and follow-up procedures; a copy of the healthcare professional's written opinion; and a copy of information provided to the healthcare professional. Training records must be maintained for three years and must include dates, contents of the training program or a summary, trainer's name and qualifications, names and job titles of all persons attending the sessions. Medical records must be made available to the subject employee, anyone with written consent of the employee, OSHA and NIOSH--they are not available to the employer. Disposal of records must be in accord with OSHA's standard covering access to records.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Safety;
