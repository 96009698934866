import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class ExtendedSickLeave extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Extended Sick Leave - ESL</h1>
                    <p>In accordance with Company Paid Time Off (PTO) policy, the accrued and unused PTO of eligible full-time employees will rollover into an Extended Sick Leave (ESL) bank on January 31st following the calendar year in which the PTO was earned.  Employees may access the ESL bank when they are unable to perform their job duties due to a serious medical condition or due to the care-taking responsibilities for a spouse, child or parent who has a serious medical condition.  ESL may also be used for the birth of a newborn child or the placement with the employee of a child for adoption or foster care.  </p>

                    <p>The ESL benefit provides an option for income protection during the 90-calendar day waiting period for Long Term Disability eligibility.  ESL will be paid out at the employee’s regular pay per day, up to a maximum of 60 working days. Upon termination, employees will not be compensated for unused ESL benefits.</p>

                    <p>The following procedures must be followed to receive ESL benefits:
                        <ol>
                            <li>Notify your supervisor or Human Resources in writing of your need to take ESL.  The request should include the anticipated length of leave and anticipated date that you will be released to return to work.  </li>
                            <li>Provide People Performance Resources a written certification from a physician or licensed health care provider stating the general nature of the medical condition, anticipated length of leave and anticipated date that you will be released to return to work.  </li>
                        </ol>
                    </p>

                    <p>{this.props.clientName} retains the right to require an independent medical examination by a physician or licensed health care provider of its choice for an employee to receive or continue to receive ESL benefits.</p>

                    <p>During your absence, you will be paid in accordance with established payroll procedures.  The amount of benefits paid will be reduced by any workers’ compensation or disability payments. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default ExtendedSickLeave;
