import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Onboarding extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Onboarding</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsuccessful and
                                5-Very Successful...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formOnboarding"
                                controlId="formOnboarding"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    26. How successful was your onboarding process in making you feel welcomed?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formOnboarding"
                                    id="formOnboarding-1"
                                    defaultChecked={this.props.formOnboarding === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formOnboarding"
                                    id="formOnboarding-2"
                                    defaultChecked={this.props.formOnboarding === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formOnboarding"
                                    id="formOnboarding-3"
                                    defaultChecked={this.props.formOnboarding === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formOnboarding"
                                    id="formOnboarding-4"
                                    defaultChecked={this.props.formOnboarding === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formOnboarding"
                                    id="formOnboarding-5"
                                    defaultChecked={this.props.formOnboarding === 5}
                                />
                                </Form.Group>

                                
                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Onboarding;