import React, { Component } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class StrategySupervisor extends Component {
    

    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Strategy</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formStrategy1"
                      as={Row}
                      controlId="formStrategy1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        54. How confident are you that your direct reports are
                        adequately equipped to fulfill your role (or the role of
                        other leaders on your leadership team) in the event you
                        or another leader are unexpectedly incapacitated?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formStrategy1"
                        id="formStrategy1-1"
                        defaultChecked={this.props.formStrategy1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formStrategy1"
                        id="formStrategy1-2"
                        defaultChecked={this.props.formStrategy1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formStrategy1"
                        id="formStrategy1-3"
                        defaultChecked={this.props.formStrategy1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formStrategy1"
                        id="formStrategy1-4"
                        defaultChecked={this.props.formStrategy1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formStrategy1"
                        id="formStrategy1-5"
                        defaultChecked={this.props.formStrategy1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formStrategy1"
                        id="formStrategy1-6"
                        defaultChecked={this.props.formStrategy1 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group
                      name="formStrategy2"
                      as={Row}
                      controlId="formStrategy2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        55. Does the company have a strategic plan in place?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I don't know"
                          value="I don't know"
                          name="formStrategy2"
                          id="formStrategy2-1"
                          defaultChecked={
                            this.props.formStrategy2 === "I don't know"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, but we don't talk about it"
                          value="Yes, but we don't talk about it"
                          name="formStrategy2"
                          id="formStrategy2-2"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, but we don't talk about it"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, I hear about it often"
                          value="Yes, I hear about it often"
                          name="formStrategy2"
                          id="formStrategy2-3"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, I hear about it often"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy2"
                          id="formStrategy2-4"
                          defaultChecked={this.props.formStrategy2 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy2 === "Other" && (
                        <Form.Group controlId="formStrategy2Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy2Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy2Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formStrategy4">
                      <Form.Label>
                        56. If you have a strategic plan, what is it?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formStrategy4"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy4 || ""}
                      />
                    </Form.Group>
                    <hr />

                    

                    <Form.Group controlId="formStrategy10">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formStrategy10"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formStrategy10 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default StrategySupervisor;