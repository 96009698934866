import React, { Component } from 'react';
import { Jumbotron, Row, Container, Form } from 'react-bootstrap';
import FullStatsCompany from './FullStatsCompany';
import FullStatsEmployee from './FullStatsEmployee';
import { config } from '../../Config/Constants';
import styled from 'styled-components'

const API_URL = config.url.API_URL;

const Div = styled.div `

    .score-cards-big{
        ${'' /* border: 1px solid black; */}
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 1rem;
    }

    .score-cards-sml{
        ${'' /* border: 1px solid black; */}
        border-radius: 0.25rem;
        padding: .5rem;
        margin: 1rem;
    }
`

class FullStatsBreakdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterToggle: "company",

            surveyData: [],
            surveyYear: 0,
            surveyScoreConsulting: 0,
            consultingScoreDiv: 0,
            surveyScoreTalent: 0,
            talentScoreDiv: 0,

            
            candace: false,
            candaceScore: 0,
            candaceScoreDiv: 0,
            denise: false,
            deniseScore: 0,
            deniseScoreDiv: 0,
            gabriela: false,
            gabrielaScore: 0,
            gabrielaDiv: 0,
            hayley: false,
            hayleyScore: 0,
            hayleyScoreDiv: 0,
            jazmin: false,
            jazminScore: 0,
            jazminScoreDiv: 0,
            kacye: false,
            kacyeScore: 0,
            kacyeScoreDiv: 0,
            kathy: false,
            kathyScore: 0,
            kathyScoreDiv: 0,
            mercedes: false,
            mercedesScore: 0,
            mercedesScoreDiv: 0,
            lauren: false,
            laurenScore: 0,
            laurenScoreDiv: 0,
            luke: false,
            lukeScore: 0,
            lukeScoreDiv: 0,
            tracy: false,
            tracyScore: 0,
            tracyScoreDiv: 0,

            //Consulting
            talentedConScore: 0,
            talentedConDiv: 0,
            conUnderstandingScore: 0,
            conUnderstandingDiv: 0,
            timelyDelScore: 0,
            timelyDelDiv: 0,
            solutionScore: 0,
            solutionScoreDiv: 0,
            fairPriceScore: 0,
            fairPriceDiv: 0,
            stakeholderScore: 0,
            stakeholderDiv: 0,
            timelyCommScore: 0,
            timelyCommDiv: 0,
            customScore: 0,
            customDiv: 0,
            ownershipScore: 0,
            ownershipDiv: 0,
            learnScore: 0,
            learnDiv: 0,
            overallValScore: 0,
            overallValDiv: 0,

            //Talent
            talentedCandScore: 0,
            talentedCandDiv: 0,
            talEfficiencyScore: 0,
            talEfficiencyDiv: 0,
            talStakeholdersScore: 0,
            talStakeholdersDiv: 0,
            talCommsScore: 0,
            talCommsDiv: 0,
            talMethodScore: 0,
            talMethodDiv: 0,
            talPrioritiesScore: 0,
            talPrioritiesDiv: 0,
            talExpectScore: 0,
            talExpectDiv: 0,

            //Refer
            consultingTotal: 0,
            consultingYes: 0,
            consultingNo: 0,
            consultingMaybe: 0,
            talentTotal: 0,
            talentYes: 0,
            talentNo: 0,
            talentMaybe: 0
        }
    }

    handleCheck = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {
        console.log("grabbing survey data");
        this.grabSurveyData();
    }

    grabSurveyData() {
        fetch(API_URL + "/client/surveydata/currentyear")
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((result) => {
                let consultingScore = 0;
                let consultingScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempConScore = +result[i].formTalentedConsultants + +result[i].formConsultantUnderstands + +result[i].formTimely + +result[i].formSolutionAttitude + +result[i].formFairPrice + +result[i].formStakeholders + +result[i].formCommunications + +result[i].formProjects + +result[i].formOwnership + +result[i].formLearn + +result[i].formExpectations;
                    consultingScore = +consultingScore + +tempConScore;
                    this.setState({ surveyScoreConsulting: consultingScore });
                    if (result[i].formTalentedConsultants !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formConsultantUnderstands !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formTimely !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formSolutionAttitude !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formFairPrice !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formStakeholders !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formCommunications !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formProjects !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formOwnership !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formLearn !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    if (result[i].formExpectations !== "") { consultingScoreDiv = +consultingScoreDiv + 1 };
                    this.setState({ consultingScoreDiv: consultingScoreDiv });
                }
                let talentScore = 0;
                let talentScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    let tempTalentScore = +result[i].formCandidates + +result[i].formEfficiency + +result[i].formTalentStakeholders + +result[i].formTalentCommunications + +result[i].formTalentMethod + +result[i].formTalentPriorities + +result[i].formTalentExpectations;
                    talentScore = +talentScore + +tempTalentScore;
                    this.setState({ surveyScoreTalent: talentScore });
                    if (result[i].formCandidates !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formEfficiency !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentStakeholders!== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentCommunications !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentMethod !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentPriorities !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    if (result[i].formTalentExpectations !== "") { talentScoreDiv = +talentScoreDiv + 1 };
                    this.setState({ talentScoreDiv: talentScoreDiv });
                }

                
                let candaceScore = 0;
                let candaceScoreDiv = 0;
                let deniseScore = 0;
                let deniseScoreDiv = 0;
                let gabrielaScore = 0;
                let gabrielaScoreDiv = 0;
                let hayleyScore = 0;
                let hayleyScoreDiv = 0;
                let jazminScore = 0;
                let jazminScoreDiv = 0;
                let kacyeScore = 0;
                let kacyeScoreDiv = 0;
                let kathyScore = 0;
                let kathyScoreDiv = 0;
                
                let laurenScore = 0;
                let laurenScoreDiv = 0;
                let lukeScore = 0;
                let lukeScoreDiv = 0;
                let mercedesScore = 0;
                let mercedesScoreDiv = 0;
                let tracyScore = 0;
                let tracyScoreDiv = 0;
                for (let i = 0; i < result.length; i++){
                    
                    candaceScore = candaceScore + +result[i].formCandaceA_Rating;
                    deniseScore = deniseScore + +result[i].formDeniseMF_Rating;
                    gabrielaScore = gabrielaScore + +result[i].formGabrielaN_Rating;
                    hayleyScore = hayleyScore + +result[i].formHayleyT_Rating;
                    jazminScore = jazminScore + +result[i].formJazminK_Rating;
                    kacyeScore = kacyeScore + +result[i].formKacyeB_Rating;
                    kathyScore = kathyScore + +result[i].formKathyB_Rating;
                    
                    laurenScore = laurenScore + +result[i].formLaurenL_Rating;
                    lukeScore = lukeScore + +result[i].formLukeB_Rating;
                    mercedesScore = mercedesScore + +result[i].formMercedesC_Rating;
                    
                    tracyScore = tracyScore + +result[i].formTracyM_Rating;
                    
                    if (result[i].formCandaceA_Rating !== "") {
                        candaceScoreDiv = candaceScoreDiv + 1;
                    }
                    if (result[i].formDeniseMF_Rating !== "") {
                        deniseScoreDiv = deniseScoreDiv + 1;
                    }
                    if (result[i].formGabrielaN_Rating !== "") {
                        gabrielaScoreDiv = gabrielaScoreDiv + 1;
                    }
                    if (result[i].formHayleyT_Rating !== "") {
                        hayleyScoreDiv = hayleyScoreDiv + 1;
                    }
                    if (result[i].formJazminK_Rating !== "") {
                        jazminScoreDiv = jazminScoreDiv + 1;
                    }
                    if (result[i].formKacyeB_Rating !== "") {
                        kacyeScoreDiv = kacyeScoreDiv + 1;
                    }
                    if (result[i].formKathyB_Rating !== "") {
                        kathyScoreDiv = kathyScoreDiv + 1;
                    }
                    
                    if (result[i].formLaurenL_Rating !== "") {
                        laurenScoreDiv = laurenScoreDiv + 1;
                    }
                    if (result[i].formLukeB_Rating !== "") {
                        lukeScoreDiv = lukeScoreDiv + 1;
                    }
                    if (result[i].formMercedesC_Rating !== "") {
                        mercedesScoreDiv = mercedesScoreDiv + 1;
                    }
                    
                    if (result[i].formTracyM_Rating !== "") {
                        tracyScoreDiv = tracyScoreDiv + 1;
                    }
                    this.setState({
                        
                        candaceScore: candaceScore,
                        candaceScoreDiv: candaceScoreDiv,
                        deniseScore: deniseScore,
                        deniseScoreDiv: deniseScoreDiv,
                        gabrielaScore: gabrielaScore,
                        gabrielaScoreDiv: gabrielaScoreDiv,
                        hayleyScore: hayleyScore,
                        hayleyScoreDiv: hayleyScoreDiv,
                        jazminScore: jazminScore,
                        jazminScoreDiv: jazminScoreDiv,
                        kacyeScore: kacyeScore,
                        kacyeScoreDiv: kacyeScoreDiv,
                        kathyScore: kathyScore,
                        kathyScoreDiv: kathyScoreDiv,
                        
                        laurenScore: laurenScore,
                        laurenScoreDiv: laurenScoreDiv,
                        lukeScore: lukeScore,
                        lukeScoreDiv: lukeScoreDiv,
                        mercedesScore: mercedesScore,
                        mercedesScoreDiv: mercedesScoreDiv,
                        
                        tracyScore: tracyScore,
                        tracyScoreDiv: tracyScoreDiv,
                    });
                }

                let talentedConScore = 0;
                let talentedConDiv = 0;
                let conUnderstandingScore = 0;
                let conUnderstandingDiv = 0;
                let timelyDelScore = 0;
                let timelyDelDiv = 0;
                let solutionScore = 0;
                let solutionScoreDiv = 0;
                let fairPriceScore = 0;
                let fairPriceDiv = 0;
                let stakeholderScore = 0;
                let stakeholderDiv = 0;
                let timelyCommScore = 0;
                let timelyCommDiv = 0;
                let customScore = 0;
                let customDiv = 0;
                let ownershipScore = 0;
                let ownershipDiv = 0;
                let learnScore = 0;
                let learnDiv = 0;
                let overallValScore = 0;
                let overallValDiv = 0;
                let consultingTotal = 0;
                let consultingYes = 0;
                let consultingNo = 0;
                let consultingMaybe = 0;

                let talentedCandScore = 0;
                let talentedCandDiv = 0;
                let talEfficiencyScore = 0;
                let talEfficiencyDiv = 0;
                let talStakeholdersScore = 0;
                let talStakeholdersDiv = 0;
                let talCommsScore = 0;
                let talCommsDiv = 0;
                let talMethodScore = 0;
                let talMethodDiv = 0;
                let talPrioritiesScore = 0;
                let talPrioritiesDiv = 0;
                let talExpectScore = 0;
                let talExpectDiv = 0;
                
                let talentTotal = 0;
                let talentYes = 0;
                let talentNo = 0;
                let talentMaybe = 0;
                for (let i = 0; i < result.length; i++){
                    talentedConScore = talentedConScore + +result[i].formTalentedConsultants;
                    conUnderstandingScore = conUnderstandingScore + +result[i].formConsultantUnderstands;
                    timelyDelScore = timelyDelScore + +result[i].formTimely;
                    solutionScore = solutionScore + +result[i].formSolutionAttitude;
                    fairPriceScore = fairPriceScore + +result[i].formFairPrice;
                    stakeholderScore = stakeholderScore + +result[i].formStakeholders;
                    timelyCommScore = timelyCommScore + +result[i].formCommunications;
                    customScore = customScore + +result[i].formProjects;
                    ownershipScore = ownershipScore + +result[i].formOwnership;
                    learnScore = learnScore + +result[i].formLearn;
                    overallValScore = overallValScore + +result[i].formExpectations;
                    if (result[i].formConsultingRefer === "yes") {
                        consultingYes++;
                        consultingTotal++;
                    } else if (result[i].formConsultingRefer === "no") {
                        consultingNo++;
                        consultingTotal++;
                    } else if (result[i].formConsultingRefer === "other") {
                        consultingMaybe++;
                        consultingTotal++;
                    }
                    if (result[i].formTalentedConsultants !== "") {
                        talentedConDiv = talentedConDiv + 1;
                    }
                    if (result[i].formConsultantUnderstands !== "") {
                        conUnderstandingDiv = conUnderstandingDiv + 1;
                    }
                    if (result[i].formTimely !== "") {
                        timelyDelDiv = timelyDelDiv + 1;
                    }
                    if (result[i].formSolutionAttitude !== "") {
                        solutionScoreDiv = solutionScoreDiv + 1;
                    }
                    if (result[i].formFairPrice !== "") {
                        fairPriceDiv = fairPriceDiv + 1;
                    }
                    if (result[i].formStakeholders !== "") {
                        stakeholderDiv = stakeholderDiv + 1;
                    }
                    if (result[i].formCommunications !== "") {
                        timelyCommDiv = timelyCommDiv + 1;
                    }
                    if (result[i].formProjects !== "") {
                        customDiv = customDiv + 1;
                    }
                    if (result[i].formOwnership !== "") {
                        ownershipDiv = ownershipDiv + 1;
                    }
                    if (result[i].formLearn !== "") {
                        learnDiv = learnDiv + 1;
                    }
                    if (result[i].formExpectations !== "") {
                        overallValDiv = overallValDiv + 1;
                    }
                    talentedCandScore = talentedCandScore + +result[i].formCandidates;
                    talEfficiencyScore = talEfficiencyScore + +result[i].formEfficiency;
                    talStakeholdersScore = talStakeholdersScore + +result[i].formTalentStakeholders;
                    talCommsScore = talCommsScore + +result[i].formTalentCommunications;
                    talMethodScore = talMethodScore + +result[i].formTalentMethod;
                    talPrioritiesScore = talPrioritiesScore + +result[i].formTalentPriorities;
                    talExpectScore = talExpectScore + +result[i].formTalentExpectations;
                    if (result[i].formTalentRefer === "yes") {
                        talentYes++;
                        talentTotal++;
                    } else if (result[i].formTalentRefer === "no") {
                        talentNo++;
                        talentTotal++;
                    } else if (result[i].formTalentRefer === "other") {
                        talentMaybe++;
                        talentTotal++;
                    }
                    if (result[i].formCandidates !== "") {
                        talentedCandDiv = talentedCandDiv + 1;
                    }
                    if (result[i].formEfficiency !== "") {
                        talEfficiencyDiv = talEfficiencyDiv + 1;
                    }
                    if (result[i].formTalentStakeholders !== "") {
                        talStakeholdersDiv = talStakeholdersDiv + 1;
                    }
                    if (result[i].formTalentCommunications !== "") {
                        talCommsDiv = talCommsDiv + 1;
                    }
                    if (result[i].formTalentMethod !== "") {
                        talMethodDiv = talMethodDiv + 1;
                    }
                    if (result[i].formTalentPriorities !== "") {
                        talPrioritiesDiv = talPrioritiesDiv + 1;
                    }
                    if (result[i].formTalentExpectations !== "") {
                        talExpectDiv = talExpectDiv + 1;
                    }
                    
                    this.setState({
                        talentedConScore: talentedConScore,
                        talentedConDiv: talentedConDiv,
                        conUnderstandingScore: conUnderstandingScore,
                        conUnderstandingDiv: conUnderstandingDiv,
                        timelyDelScore: timelyDelScore,
                        timelyDelDiv: timelyDelDiv,
                        solutionScore: solutionScore,
                        solutionScoreDiv: solutionScoreDiv,
                        fairPriceScore: fairPriceScore,
                        fairPriceDiv: fairPriceDiv,
                        stakeholderScore: stakeholderScore,
                        stakeholderDiv: stakeholderDiv,
                        timelyCommScore: timelyCommScore,
                        timelyCommDiv: timelyCommDiv,
                        customScore: customScore,
                        customDiv: customDiv,
                        ownershipScore: ownershipScore,
                        ownershipDiv: ownershipDiv,
                        learnScore: learnScore,
                        learnDiv: learnDiv,
                        overallValScore: overallValScore,
                        overallValDiv: overallValDiv,
                        consultingTotal: consultingTotal,
                        consultingYes: consultingYes,
                        consultingNo: consultingNo,
                        consultingMaybe: consultingMaybe,

                        talentedCandScore: talentedCandScore,
                        talentedCandDiv: talentedCandDiv,
                        talEfficiencyScore: talEfficiencyScore,
                        talEfficiencyDiv: talEfficiencyDiv,
                        talStakeholdersScore: talStakeholdersScore,
                        talStakeholdersDiv: talStakeholdersDiv,
                        talCommsScore: talCommsScore,
                        talCommsDiv: talCommsDiv,
                        talMethodScore: talMethodScore,
                        talMethodDiv: talMethodDiv,
                        talPrioritiesScore: talPrioritiesScore,
                        talPrioritiesDiv: talPrioritiesDiv,
                        talExpectScore: talExpectScore,
                        talExpectDiv: talExpectDiv,
                        talentTotal: talentTotal,
                        talentYes: talentYes,
                        talentNo: talentNo,
                        talentMaybe: talentMaybe
                    })
                }

                this.setState({
                    surveyData: result,
                    surveyScoreTalent: talentScore
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        return (
            <Container>
                <Jumbotron className='text-dark'>
                    <Div>
                        <h3 className='display-4'>Detailed Stats Report</h3>
                        <Form className='pl-4'>
                            <Form.Group
                                as={Row}
                                name="filterToggle"
                                controlId="filterToggle"
                                onChange={this.handleCheck}
                            >
                                <Form.Check
                                inline
                                label="by client"
                                name="filterToggle"
                                type="radio"
                                id="radio-company"
                                value="company"
                                defaultChecked={this.state.filterToggle === "company"}
                                >
                                </Form.Check>
                                <Form.Check
                                    inline
                                    label="by employee"
                                    name="filterToggle"
                                    type="radio"
                                    id="radio-employee"
                                    value="employee"
                                    defaultChecked={this.state.filterToggle === "employee"}
                                >
                                </Form.Check>
                            </Form.Group>
                        </Form>
                        {this.state.filterToggle === "company" ?
                            <FullStatsCompany
                                data={this.state.surveyData}
                            /> :
                            <FullStatsEmployee
                                {...this.state}
                            />
                        }
                        
                        
                    </Div>
                </Jumbotron>
            </Container>
            
        )
    }
}

export default FullStatsBreakdown;