import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class TalentQuestions extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Executive Search & Talent Attraction</h4>
                                <hr />
                                
                                <Form.Group controlId="formPositions">
                                        <Form.Label className="font-weight-bold px-0" column sm={5}>
                                            1. What positions did we help you fill?
                                        </Form.Label>
                                        <Form.Control
                                            name="formPositions"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formPositions || ""
                                            }
                                        />
                                </Form.Group>

                                <p className="font-weight-bold">
                                2. On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formCandidates"
                                controlId="formCandidates"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR presents highly talented candidates with the right experience, skills and culture fit
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formCandidates"
                                    id="formCandidates-1"
                                    defaultChecked={this.props.formCandidates === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formCandidates"
                                    id="formCandidates-2"
                                    defaultChecked={this.props.formCandidates === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formCandidates"
                                    id="formCandidates-3"
                                    defaultChecked={this.props.formCandidates === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formCandidates"
                                    id="formCandidates-4"
                                    defaultChecked={this.props.formCandidates === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formCandidates"
                                    id="formCandidates-5"
                                    defaultChecked={this.props.formCandidates === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formEfficiency"
                                controlId="formEfficiency"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR completed our talent search at a high degree of quality and efficiency
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formEfficiency"
                                    id="formEfficiency-1"
                                    defaultChecked={this.props.formEfficiency === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formEfficiency"
                                    id="formEfficiency-2"
                                    defaultChecked={this.props.formEfficiency === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formEfficiency"
                                    id="formEfficiency-3"
                                    defaultChecked={this.props.formEfficiency === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formEfficiency"
                                    id="formEfficiency-4"
                                    defaultChecked={this.props.formEfficiency === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formEfficiency"
                                    id="formEfficiency-5"
                                    defaultChecked={this.props.formEfficiency === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentStakeholders"
                                controlId="formTalentStakeholders"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is able to work well with a wide variety of stakeholders
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-1"
                                    defaultChecked={this.props.formTalentStakeholders === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-2"
                                    defaultChecked={this.props.formTalentStakeholders === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-3"
                                    defaultChecked={this.props.formTalentStakeholders === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-4"
                                    defaultChecked={this.props.formTalentStakeholders === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentStakeholders"
                                    id="formTalentStakeholders-5"
                                    defaultChecked={this.props.formTalentStakeholders === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentCommunications"
                                controlId="formTalentCommunications"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR is open, honest and timely with their communications
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-1"
                                    defaultChecked={this.props.formTalentCommunications === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-2"
                                    defaultChecked={this.props.formTalentCommunications === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-3"
                                    defaultChecked={this.props.formTalentCommunications === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-4"
                                    defaultChecked={this.props.formTalentCommunications === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentCommunications"
                                    id="formTalentCommunications-5"
                                    defaultChecked={this.props.formTalentCommunications === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentMethod"
                                controlId="formTalentMethod"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR suggested best practice methodologies to meet the unique needs of our organization
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentMethod"
                                    id="formTalentMethod-1"
                                    defaultChecked={this.props.formTalentMethod === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentMethod"
                                    id="formTalentMethod-2"
                                    defaultChecked={this.props.formTalentMethod === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentMethod"
                                    id="formTalentMethod-3"
                                    defaultChecked={this.props.formTalentMethod === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentMethod"
                                    id="formTalentMethod-4"
                                    defaultChecked={this.props.formTalentMethod === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentMethod"
                                    id="formTalentMethod-5"
                                    defaultChecked={this.props.formTalentMethod === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentPriorities"
                                controlId="formTalentPriorities"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    PPR takes time to learn about our business, people, culture and priorities
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-1"
                                    defaultChecked={this.props.formTalentPriorities === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-2"
                                    defaultChecked={this.props.formTalentPriorities === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-3"
                                    defaultChecked={this.props.formTalentPriorities === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-4"
                                    defaultChecked={this.props.formTalentPriorities === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentPriorities"
                                    id="formTalentPriorities-5"
                                    defaultChecked={this.props.formTalentPriorities === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formTalentExpectations"
                                controlId="formTalentExpectations"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    In regards to overall value, I believe PPR meets or exceeds my expectations
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-1"
                                    defaultChecked={this.props.formTalentExpectations === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-2"
                                    defaultChecked={this.props.formTalentExpectations === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-3"
                                    defaultChecked={this.props.formTalentExpectations === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-4"
                                    defaultChecked={this.props.formTalentExpectations === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formTalentExpectations"
                                    id="formTalentExpectations-5"
                                    defaultChecked={this.props.formTalentExpectations === 5}
                                />
                                </Form.Group>
                                <hr />
                                
                                <Form.Group
                                    name="formTalentRefer"
                                    as={Row}
                                controlId="formTalentRefer"
                                onChange={this.props.handleChange}
                                >
                                    <Form.Label className="font-weight-bold" column sm={5}>
                                        3. Based on your experience with our performance, would you engage or refer PPR for future executive search and talent attraction needs?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                            <Form.Check
                                        inline
                                        type="radio"
                                        label="Yes, I would re-engage or refer PPR"
                                        value="yes"
                                        name="formTalentRefer"
                                        id="formTalentRefer-yes"
                                        defaultChecked={
                                        this.props.formTalentRefer === "yes"
                                        }
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="No, I would NOT re-engage or refer PPR"
                                        value="no"
                                        name="formTalentRefer"
                                        id="formTalentRefer-no"
                                        defaultChecked={
                                        this.props.formTalentRefer === "no"
                                        }                               
                                    /></Row><Row>
                                    <Form.Check
                                        inline
                                        type="radio"
                                        label="Other"
                                        value="other"
                                        name="formTalentRefer"
                                        id="formTalentRefer-other"
                                        defaultChecked={
                                        this.props.formTalentRefer === "other"
                                        }
                                    /></Row></Col>
                                </Form.Group>

                                <div>
                                    {(this.props.formTalentRefer === "other") && (
                                        <Form.Group controlId="formTalentReferOtherAnswer">
                                        <Form.Label className="font-weight-bold px-0" column sm={5}>
                                            Please elaborate:
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentReferOtherAnswer"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentReferOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>



                                <p  className="font-weight-bold px-0">If yes, we appreciate your trust and referrals.</p>
                                

                                <Form.Group controlId="formTalentApprove">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            If not, please describe areas where you would like to see us improve. Your guidance is both appreciated and it is music to our ears. 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentApprove"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentApprove || ""
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formTalentReasons">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            4. In your opinion, what were the three reasons PPR was selected to help fill this role? 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentReasons"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentReasons || ""
                                            }
                                        />
                                </Form.Group>

                                <Form.Group controlId="formTalentQuote">
                                        <Form.Label className='font-weight-bold px-0' column sm={5}>
                                            5. Could you please provide us a quote for us to share on our website or with future clients considering our services? 
                                        </Form.Label>
                                        <Form.Control
                                            name="formTalentQuote"
                                            as="textarea"
                                            rows="4"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formTalentQuote || ""
                                            }
                                        />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default TalentQuestions;