import React, { Component } from 'react';
import { Jumbotron, Accordion, Card, ProgressBar} from 'react-bootstrap';
import styled, {ThemeProvider} from 'styled-components';
import theme from '../../Config/Theme';


const path = `/v2/reports/project_budget`;

    const https = require('https');
    const options = {
        protocol: "https:",
        hostname: "api.harvestapp.com",
        path: path,
        headers: {
        "User-Agent": "PPR Profile",
        "Authorization": "Bearer 2543244.pt.UP6XkfWi3ktAnPob9oBSB8Qtx7MLq2XoLrhTTJ3AGiYaEfszeKSZnb6WtUbPGMxS-s2eCUJswsW2G4GkwEMEgw",
        "Harvest-Account-ID": "1400811"
        }
    }

const Div = styled.div`

        .projectCards{
            border-radius: 0.25rem;
            margin: 0px;
        }
        
        .progressBar{
            background-color: blue;
        }

        .accordion{
            background-color: ${props => props.theme.colors.medium_blue};
        }

    `


class Budgets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recruitingProjectIds: [33015807, 32755532, 31972730, 31061872],
            recruitingProjects: [],
            compProjectIds: [32142423],
            compProjects: [],
            flatRateProjectIds: [32311814],
            flatRateProjects: []
        }
    }

    componentDidMount() {
        console.log("grabbing Harvest budget data");
        this.grabHarvestData();
    }

    grabHarvestData() {
        
    https
    .get(options, (res) => {
      const { statusCode } = res;

      if (statusCode !== 200) {
        console.error(`Request failed with status: ${statusCode}`);
        return;
      }

      res.setEncoding("utf8");
      let rawData = "";
      res.on("data", (chunk) => {
        rawData += chunk;
      });
        res.on("end", () => {
            try {
                const parsedData = JSON.parse(rawData);
                console.log(parsedData.results);

                for (let i = 0; i < this.state.recruitingProjectIds.length; i++){
                    const indexi = parsedData.results.findIndex(object => { return object.project_id === this.state.recruitingProjectIds[i] });
                    if (indexi !== -1) {
                        let tempiIds = [...this.state.recruitingProjects];
                        tempiIds.push(parsedData.results[indexi]);
                        this.setState({ recruitingProjects: tempiIds });
                        console.log(this.state.recruitingProjects);
                    }
                }

                for (let j = 0; j < this.state.compProjectIds.length; j++){
                    const indexj = parsedData.results.findIndex(object => { return object.project_id === this.state.compProjectIds[j] });
                    if (indexj !== -1) {
                        let tempjIds = [...this.state.compProjects];
                        tempjIds.push(parsedData.results[indexj]);
                        this.setState({ compProjects: tempjIds });
                        console.log(this.state.compProjects);
                    }
                }


                for (let k = 0; k < this.state.flatRateProjectIds.length; k++){
                    const indexk = parsedData.results.findIndex(object => { return object.project_id === this.state.flatRateProjectIds[k] });
                    if (indexk !== -1) {
                        let tempkIds = [...this.state.flatRateProjects];
                        tempkIds.push(parsedData.results[indexk]);
                        this.setState({ flatRateProjects: tempkIds });
                        console.log(this.state.flatRateProjects);
                    }
                }

            } catch (e) {
                console.error(e.message);
            }
        });
    })
    .on("error", (e) => {
      console.error(`Got error: ${e.message}`);
    });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Jumbotron>
                    <h3 className="display-4 text-dark">Budget Status by Project</h3>
                <Div className="mt-4">
                
                    <Accordion className="my-1 projectCards">
                        <Card>
                            <Accordion.Toggle className="accordion" as={Card.Header} defaultactivekey="0" eventKey="0" alwaysopen="true">
                                <strong>Project Categories</strong> (click to expand)
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    
                                    <Accordion className="projectCards">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                <strong>Comp Projects</strong>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    {this.state.compProjects.map(project => (
                                                        <div className='mb-3' key={project.project_id}>
                                                            <p className='text-dark' key={project.project_id}><strong>{project.client_name}</strong> {project.project_name}</p>
                                                            <ProgressBar className='progressBar' variant="danger" now={Math.round((project.budget_spent / project.budget) * 100)} label={`${Math.round((project.budget_spent / project.budget) * 100)}% used`} />
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                            
                                    <Accordion className="my-3 projectCards">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="2" className="accordion " >
                                                <strong>Other Flat Rate</strong>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    {this.state.flatRateProjects.map(project => (
                                                        <div className='mb-3' key={project.project_id}>
                                                            <p className='text-dark' key={project.project_id}><strong>{project.client_name}</strong> {project.project_name}</p>
                                                            <ProgressBar className='progressBar' variant="danger" now={Math.round((project.budget_spent / project.budget) * 100)} label={`${Math.round((project.budget_spent / project.budget) * 100)}% used`} />
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                            
                                    <Accordion className="my-3 projectCards">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="3" className="accordion " >
                                                <strong>Talent Acquisition</strong>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    {this.state.recruitingProjects.map(project => (
                                                        <div className='mb-3' key={project.project_id}>
                                                            <p className='text-dark' key={project.project_id}><strong>{project.client_name}</strong> {project.project_name}</p>
                                                            <ProgressBar className='progressBar' variant="danger" now={Math.round((project.budget_spent / project.budget) * 100)} label={`${Math.round((project.budget_spent / project.budget) * 100)}% used`} />
                                                            <hr />
                                                        </div>
                                                    ))}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>

                
                
                    </Div>
                </Jumbotron>
            </ThemeProvider>
            
            
        )
    }
}

export default Budgets;
