import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class PersonalElectronics extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Use of Personal Electronic Devices - "BYOD"</h1>
                    <p>Employees of {this.props.clientName} may have the opportunity to use their personal electronic devices, including personally owned cellphones, smartphones, tablets, laptops and computers to conduct {this.props.clientName} business either remotely or in the {this.props.clientName} offices. To ensure the safety and security of {this.props.clientName} Information Technologies, employees who access 	{this.props.clientName} network, services and other resources via their personal electronic devices must adhere to {this.props.clientName} Information Technologies policies and procedures as well as the following guidelines:
                        <ul>
                            <li>Password protect any personal electronic devices with strong passwords</li>
                            <li>Avoid cloud-based apps or backups that allow {this.props.clientName} - related data to be transferred to unsecure parties. </li>
                            <li>Do not synchronize any {this.props.clientName} device with any personal computer device or other personal electronic device </li>
                            <li>Refrain from sharing the device with other individuals or family members, due to the business use of the device</li>
                            <li>Delete any sensitive business files that may be inadvertently downloaded and stored on the device through the process of viewing e-mail attachments</li>
                            <li>Nonexempt employees may not use their personal devices for work purposes outside of their normal work schedule without authorization in advance from management. This includes reviewing, sending and responding to e-mails or text messages, responding to phone calls, or making phone calls. </li>
                        </ul>
                    </p>

                    <h2>Privacy / {this.props.clientName} Access</h2>
                    <p>{this.props.clientName} (or its designated representatives) maintains the right and ability, with or without notice to the employee, to access and review any information contained on its Information Technologies, even if located on an employee’s personal electronic device.  Those individuals using their personal electronic device to access company Information Technologies should have no expectation of privacy in connection with the use of their personal device to conduct company business.  </p>

                    <h2>Lost, Stolen, Hacked, or Damaged Equipment</h2>
                    <p>Employees should make every effort to protect personal devices used for work-related purposes from loss, damage or theft.  In the event an employee’s personal electronic device is lost or stolen, the employee should report the loss to their manager immediately.  </p>

                    <h2>Separation From Employment</h2>
                    <p>Upon resignation or termination of employment, or at any time on request, the employee may be asked to produce the personal device for inspection. All {this.props.clientName} data on personal devices will be removed by the IT Partner upon termination of employment.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default PersonalElectronics;
