import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Relatives extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Employment of Relatives</h1>
                    <p>{this.props.clientName} permits the employment of qualified relatives of employees if such employment does not, in the opinion of {this.props.clientName}, create actual or perceived conflicts of interest. Hiring managers must obtain Executive-level approval prior to hire. </p>

                    <p>In no instance will an employee be permitted to work under the direct or indirect supervision of a relative or person with whom the employee has a close familial relationship.  Employees in this situation should not influence or make decisions about the job responsibilities, wages, hours, career opportunities, or any other work term or condition for the other employee. For purposes of this policy, the term relative is subject to the interpretation of {this.props.clientName}, but is generally defined as a spouse, parent, step parent, child, step child, brother, sister, niece, nephew, grandparent, grandchild, cousin, in-laws or any other person with whom the employee has a close familial relationship.</p>

                    <p>This policy also applies to dating relationships. Dating relationships between a Supervisor and employee are strictly prohibited. </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Relatives;
