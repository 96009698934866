import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class HRIS extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Human Resources Information System (HRIS)</h4>
                                <hr />

                                <Form.Group
                                    name="formHRIS"
                                    as={Row}
                                    controlId="formHRIS"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        96. What types of products and tools do you utilize through your HRIS? (Check all that apply)
                                    </Form.Label>
                                    <Col>
                                        <Form.Check
                                        type="checkbox"
                                        label="Payroll Services"
                                        name="formHRISPayroll"
                                        id="formHRIS-1"
                                        defaultChecked={this.props.formHRISPayroll}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Self-Service Portal"
                                        name="formHRISSelf"
                                        id="formHRIS-2"
                                        defaultChecked={
                                            this.props.formHRISSelf
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Company Communications"
                                        name="formHRISCompany"
                                        id="formHRIS-3"
                                        defaultChecked={
                                            this.props.formHRISCompany
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Timekeeping"
                                        name="formHRISTime"
                                        id="formHRIS-4"
                                        defaultChecked={this.props.formHRISTime}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Applicant Tracking"
                                        name="formHRISApplicant"
                                        id="formHRIS-5"
                                        defaultChecked={
                                            this.props.formHRISApplicant
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Performance Management"
                                        name="formHRISPerformance"
                                        id="formHRIS-6"
                                        defaultChecked={
                                            this.props.formHRISPerformance
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="OSHA Tracking"
                                        name="formHRISTracking"
                                        id="formHRIS-7"
                                        defaultChecked={
                                            this.props.formHRISTracking
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Training/Learning Management System"
                                        name="formHRISTraining"
                                        id="formHRIS-8"
                                        defaultChecked={
                                            this.props.formHRISTraining
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Personnel Files"
                                        name="formHRISPersonnel"
                                        id="formHRIS-9"
                                        defaultChecked={
                                            this.props.formHRISPersonnel
                                        }
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Company Document Portal (ex. Handbook)"
                                        name="formHRISPortal"
                                        id="formHRIS-10"
                                        defaultChecked={this.props.formHRISPortal}
                                        />
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formHRISOther"
                                        id="formHRIS-12"
                                        defaultChecked={this.props.formHRISOther}
                                        />
                                    </Col>
                                    </Form.Group>
                                    <div>
                                    {(this.props.formHRISOther === true ||
                                        this.props.formHRISOther === 1) && (
                                        <Form.Group controlId="formHRISOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formHRISOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formHRISOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                    </div>

                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default HRIS;