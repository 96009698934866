import React, { Component } from 'react';
import {Accordion, Card} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';
import CyberSecurity from './Policies/5_IT/CyberSecurity';
import Email from './Policies/5_IT/Email';
import IT from './Policies/5_IT/IT';
import PersonalElectronics from './Policies/5_IT/PersonalElectronics';
import UpkeepVehicle from './Policies/5_IT/UpkeepVehicle';
import Vehicle from './Policies/5_IT/Vehicle';


const Div = styled.div`
     
    .accordion{
        color: white;
        background: black;
        border: 1px solid black;
        
    }

`

class SectionInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionName: 'Introduction'
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Accordion className="my-5">
                                <Card>
                                    <Accordion.Toggle className="accordion" as={Card.Header} eventKey="0">
                                        5. IT & Company Policies
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Cyber Security
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><CyberSecurity clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Email Communication
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Email clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Use of Company's Information Technology
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><IT clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Personal Electronic Devices
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><PersonalElectronics clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>

                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Maintenance and Upkeep of Company Vehicle
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><UpkeepVehicle clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Use of Company Vehicle 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Vehicle clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                </Div>
            </ThemeProvider>
        )
    }
}

export default SectionInfo;