import React, { Component } from 'react';
import {Container } from 'react-bootstrap';
// import Policy from '../../Pages/Handbook/Policy/Policy';
// import PolicyButton from '../../Pages/Handbook/PolicyButton/PolicyButton';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';
import SectionIntro from './Sections/SectionIntro';
import SectionInfo from './Sections/SectionInformation';
import SectionEmployment from './Sections/SectionEmployment';
import SectionConduct from './Sections/SectionConduct';
import SectionIT from './Sections/SectionIT';
import SectionBenefits from './Sections/SectoinBenefits';


const API_URL = config.url.API_URL;

const Div = styled.div`

    .HandbookHeader{
        text-align: center;

        .logo{
            margin-bottom: 3em;
        }
    }



    .button-container{
        justify-content: center !important;
    }

    .buttons{
        padding: 0.5em;
    }

    .policies{
        background: ${props => props.theme.colors.white} !important;
        padding-bottom: 5em;
        margin-top: 5em;
    }

`



class Handbook extends Component {


    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            policies: [],
            usedPolicies: [],
            tempArray: [],
            policy: '',
            clientId: '',
            client: 'Choose Client',
            logo: '',
            color1: '#000',
            color2: '#fff',
            showLogo: false,
            sections: [{ name: 'Intro', id: 1 }, { name: 'Information', id: 2 }, { name: 'Employment', id: 3 }, { name: 'Conduct', id: 4 }, { name: 'IT', id: 5 }, { name: 'Benefits', id: 6 }]
        };

        this.handleSavePolicyEdits = this.handleSavePolicyEdits.bind(this);
        this.handleMoveDown = this.handleMoveDown.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleTemplatePolicyReload = this.handleTemplatePolicyReload.bind(this);
        this.selectTheme = this.selectTheme.bind(this);

    }

    componentDidMount() {
        fetch(API_URL + '/clients/all')
            .then(res => {
                if(!res.ok){
                    throw new Error();
                }
                return res.json();
            })
            .then((result) => {
                this.setState({ 
                    clients: result.sort((a, b) => a.client.localeCompare(b.client)), 
                });
                console.log(result);
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleAddPolicy = policyId => {
        for (var i = 0; i < this.state.policies.length; i++) {
            if (this.state.policies[i].id === policyId) {
                const policies = this.state.policies;
                const usedPolicies = this.state.usedPolicies;
                usedPolicies.push(policies[i]);
                this.setState({ usedPolicies: usedPolicies });
                policies.splice(i, 1);
                this.setState({ policies: policies });

            }
        }
    }

    handleDeletePolicy = policyId => {

        for (var i = 0; i < this.state.usedPolicies.length; i++) {
            if (this.state.usedPolicies[i].id === policyId) {
                const policies = this.state.policies;
                const usedPolicies = this.state.usedPolicies;
                policies.push(usedPolicies[i]);
                this.setState({ policies: policies });
                usedPolicies.splice(i, 1);
                this.setState({ usedPolicies: usedPolicies });

            }
        }
    }


    handleSavePolicyEdits = (policyContents, policyId) => {

        for (let i = 0; i < this.state.usedPolicies.length; i++) {
            if (this.state.usedPolicies[i].id === policyId) {
                const index = i;
                const usedPolicies = this.state.usedPolicies;
                usedPolicies[index].contents = policyContents;
                this.setState({ usedPolices: usedPolicies });
            }

        }
    }


    handleMoveUp(policyId) {
        console.log("Move Up!");
        let from = 0;
        let to = 0;

        for (let i = 0; i < this.state.usedPolicies.length; i++) {
            if (this.state.usedPolicies[i].id === policyId) {

                from = i;
                to = i - 1;
                if (to < 0) {
                    to = 0;
                }

                let usedPolicies = this.state.usedPolicies;
                const tempArray = this.state.tempArray;
                tempArray.push(usedPolicies[from]);
                this.setState({ tempArray: tempArray });
                usedPolicies.splice(from, 1);
                usedPolicies.splice(to, 0, tempArray[0]);
                this.setState({ usedPolices: usedPolicies });
                this.setState({ tempArray: [] });

                break;
            }
        }
    }

    handleMoveDown(policyId) {
        console.log("Move Down!");
        let from = 0;
        let to = 0;

        for (let i = 0; i < this.state.usedPolicies.length; i++) {
            if (this.state.usedPolicies[i].id === policyId) {

                from = i;
                to = i + 1;
                if (to > this.state.usedPolicies.length - 1) {
                    to = to - 1;
                }
                console.log(`From: ${from}`);
                console.log(`To: ${to}`);

                let usedPolicies = this.state.usedPolicies;
                const tempArray = this.state.tempArray;
                tempArray.push(usedPolicies[from]);
                this.setState({ tempArray: tempArray });
                console.log(this.state.tempArray);
                usedPolicies.splice(from, 1);
                usedPolicies.splice(to, 0, tempArray[0]);
                this.setState({ usedPolices: usedPolicies });
                this.setState({ tempArray: [] });

                break;
            }
        }
    }

    handleTemplatePolicyReload(policyId) {
        console.log("Reload Template Policy Clicked.");
        for (let i = 0; i < this.state.usedPolicies.length; i++) {
            if (this.state.usedPolicies[i].id === policyId) {
                const index = i;
                let usedPolicies = this.state.usedPolicies;
                const policy = usedPolicies[index].policy;
                console.log(`Policy is ${policy}`);

                this.setState({
                    policy: policy,
                }, () => {
                        fetch(API_URL + `/policies/${this.state.policy}`)
                        .then(res => {
                            if (!res.ok) {
                                throw new Error();
                            }
                            return res.json();
                        })
                        .then((result) => {
                            console.log(result[0].contents);
                            usedPolicies[index].contents = result[0].contents;
                            this.setState({ usedPolicies: usedPolicies });

                        })
                        .catch(error => {
                            console.log(error);
                        })
                });

            }
        }
    }

    selectTheme(e) {
        this.setState({ client: e.target.value }, () => {
            const selectedClient = this.state.client;
            console.log(selectedClient);
            for (let i = 0; i < this.state.clients.length; i++) {
                if (this.state.clients[i].client === selectedClient) {
                    const client = this.state.clients[i];
                    console.log(client);
                    this.setState({
                        client: client.client,
                        logo: client.logo,
                        color1: client.color1,
                        color2: client.color2,
                        showLogo: true
                    });

                }

            }
        })
    }


    render() {
        return (
            <ThemeProvider theme={theme} >
                <Div>
                    
                    <Container className="policies">

                        <div>
                            <select onChange={this.selectTheme}
                                value={this.state.id}>
                                <option default>Select Client...</option>
                                {this.state.clients.map(client => (
                                    <option key={client.client} id={client.id}>{client.client}</option>
                                ))}
                            </select>
                            
                            <div className='HandbookHeader'>
                                {/* <h3 dangerouslySetInnerHTML={{ __html: this.state.client }}></h3> */}
                                <img className="img-fluid logo" src={this.state.logo} style={{ visibility: this.state.showLogo ? 'visible' : 'hidden' }} alt="Chosen Client Logo" />
                                <h1 style={{color: this.state.color1}}>{this.state.client}</h1>
                                <h1 className="title" style={{ color: this.state.color1 }}>Employee Handbook</h1>
                            </div>
                        </div> 

                        <SectionIntro clientName={this.state.client} />
                        <SectionInfo clientName={this.state.client} />
                        <SectionEmployment clientName={this.state.client} />
                        <SectionConduct clientName={this.state.client} />
                        <SectionIT clientName={this.state.client} />
                        <SectionBenefits clientName={this.state.client} />

                        
                    </Container>
                </Div>
            </ThemeProvider>

        );
    }
}

export default Handbook;