import React, { Component } from 'react';
import { Row, Col, Form, Modal, Button, Jumbotron, Table} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';
import Section from './Section';
import debounce from 'lodash.debounce';

const API_URL = config.url.API_URL;

const Div = styled.div`
    color: black;

    .project{
        border:1px solid ${(props) => props.theme.colors.dark_blue};
        padding: 1rem;
    }
`

class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sections: [],
            showSectionModal: false,
            newSectionTitle: "",
            totalProjectHours: 0,
            totalProjectCosts: 0,
            totalProjectBill: 0,
            totalProjectMargin: 0,
            render: 1,
            rateType: ""
        }

        this.addNewSection = this.addNewSection.bind(this);
    }

    componentDidMount() {
        this.getRateType(this.props.billProjId);
        // this.getProjectSections(this.props.billProjId);
    }

    getRateType = (projId) => {
        fetch(API_URL + `/billingcalculator/projects/${projId}`)
            .then((res) => {
                if (!res.ok) {
                throw new Error()
                }
                return res.json()
            })
            .then((result) => {
                this.setState({ rateType: result[0].project_type },
                () => this.getProjectSections(this.props.billProjId));
                console.log(this.state.rateType);
                
            })
            .catch((error) => {
                console.log(error);
            })
    }

    getProjectSections = (projId) => {
        fetch(API_URL + `/billingcalculator/sections/${projId}`)
            .then((res) => {
                if (!res.ok) {
                throw new Error()
                }
                return res.json()
            })
            .then((result) => {
                let listedSections = [...result];
                this.setState({ sections: listedSections },
                () => this.updateProjectTotals());
            })
            .catch((error) => {
                console.log(error);
        })
    }

    updateProjectTotals = () => {
        let tempHoursTotal = 0;
        let tempCostsTotal = 0;
        let tempBillTotal = 0;
        for (let i = 0; i < this.state.sections.length; i++){
            tempHoursTotal = tempHoursTotal + +this.state.sections[i].total_sec_hours;
            tempCostsTotal = tempCostsTotal + +this.state.sections[i].total_sec_cost;
            tempBillTotal = tempBillTotal + +this.state.sections[i].total_sec_bill;
            this.setState({
                totalProjectHours: tempHoursTotal,
                totalProjectCosts: tempCostsTotal,
                totalProjectBill: tempBillTotal
            },
            () => this.setState({totalProjectMargin: (parseFloat(this.state.totalProjectBill) - parseFloat(this.state.totalProjectCosts)) / parseFloat(this.state.totalProjectBill)}))
        }
    }
    

    addNewSection() {
        this.handleSectionCreateShow();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSectionCreateShow() {
        this.setState({ showSectionModal: true });
    }

    handleSectionCreateClose() {
        this.setState({ showSectionModal: false });
    }

    handleSectionCreateSave() {
        fetch(API_URL + `/billingcalculator/section/create`, {
            method: "PUT",
            body: JSON.stringify({
                projectId: this.props.billProjId,
                sectionTitle: this.state.newSectionTitle
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err))
            .then(() => this.getProjectSections(this.props.billProjId))
            .then(() => this.setState({ showSectionModal: false }))
            .catch((err) => console.log(err));
    }

    handleSectionDelete = (sectionId) => {
        console.log(`Deleting Contact: ${sectionId}`)
        fetch(API_URL + `/billingcalculator/section/delete/${sectionId}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        })
        .then(() => this.getProjectSections(this.props.billProjId))
        .catch((err) => console.log(err));
    }

    handleCheck = (e) => {
        this.setState({ [e.target.name]: e.target.value },
        () => this.updateDB())
    }

    updateDB = debounce(() => {
        let data = {
            rateType: this.state.rateType
        };
        this.updateRateType(data);
    }, 1500);

    updateRateType(data) {
        fetch(API_URL + `/billingcalculator/project/update`, {
            method: "PUT",
            body: JSON.stringify({
                projectId: this.props.billProjId,
                data: data,
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Jumbotron className="mt-3">
                        <h1 className='display-4'>Project: {this.props.projectName}</h1>
                        <p>Project ID:{this.props.billProjId}</p>
                        <Form>
                            <Form.Group
                                as={Row}
                                name="rateType"
                                controlId="rateType"
                                onChange={this.handleCheck}
                            >
                                <Form.Check
                                    inline
                                    label="profit"
                                    name="rateType"
                                    type="radio"
                                    id="rateType-profit"
                                    value="profit"
                                    checked={this.state.rateType === "profit"}
                                />
                                <Form.Check
                                    inline
                                    label="non-profit"
                                    name="rateType"
                                    type="radio"
                                    id="rateType-non-profit"
                                    value="non-profit"
                                    checked={this.state.rateType === "non-profit"}
                                />
                                
                                <Form.Check
                                    inline
                                    label="existing-client"
                                    name="rateType"
                                    type="radio"
                                    id="rateType-existing"
                                    value="existing"
                                    checked={this.state.rateType === "existing"}
                                />
                            </Form.Group>
                        </Form>
                        <Table striped bordered size="sm" responsive="x1">
                            <thead>
                                <tr>
                                    <th>Project Sections</th>
                                    <th>Total Hours</th>
                                    <th>Total Cost</th>
                                    <th>Total Bill</th>
                                    <th>Profit Margin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sections.map(section => (
                                    <tr key={section.section_id}>
                                        <td>{section.section_title}</td>
                                        <td>{section.total_sec_hours}</td>
                                        <td>${section.total_sec_cost?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}</td>
                                        <td>${section.total_sec_bill?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}</td>
                                        <td>{section.total_sec_margin?.toFixed(2)*100}%</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className='font-weight-bold'>Totals</td>
                                    <td className='font-weight-bold'>{this.state.totalProjectHours}</td>
                                    <td className='font-weight-bold'>${this.state.totalProjectCosts?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}
                                    </td>
                                    <td className='font-weight-bold'>${this.state.totalProjectBill?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}
                                    </td>
                                    <td className='font-weight-bold'>{this.state.totalProjectMargin?.toFixed(2)*100}%</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Jumbotron>




                    <Jumbotron className="mt-5">
                        <h1 className='display-5'>Sections Detail</h1>

                        <div>
                            {this.state.sections.map(section => (
                            <div key={section.section_id}>
                                    <div className='d-flex mb-1'>
                                        <button
                                            className='ml-auto border-0'
                                            onClick={() => this.handleSectionDelete(section.section_id)}>
                                            <i className="fa-duotone fa-trash-can"></i>
                                        </button>
                                        
                                    </div>
                                <Section className='mb-3 project'
                                    sectionId={section.section_id}
                                    sectionTitle={section.section_title}
                                    projectId={this.props.billProjId}
                                    updateProjects={this.getProjectSections}
                                    rateType={this.state.rateType}
                                />
                            </div>
                        ))}
                    </div>
                    <button className='sml-btn' onClick={this.addNewSection}>
                        <p className='mt-3'>+ Add Section</p>
                        </button>
                        
                        <Modal show={this.state.showSectionModal}>
                        <Modal.Header>
                        <Modal.Title>New Project Section</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="newSectionTitle">
                                    <Form.Label>Section Title</Form.Label>
                                    <Form.Control
                                        name="newSectionTitle"
                                        required
                                        type="text"
                                        placeholder="Section Title Goes Here (*please be more creative than this) "
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a section title.
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.handleSectionCreateClose()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => this.handleSectionCreateSave()}>
                                Create Section
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    </Jumbotron>
                    

                    
                </Div>
            </ThemeProvider>
        )
    }
}    


export default Project;