export default {
    colors: {
        orange: "#ff8f1c",
        light_blue: "#009fc4",
        medium_blue: "#07485c",
        dark_blue: "#14141e",
        bg_trans: "rgba(255, 255, 255, 0.8)",
        bg_gray: "#f4f4f4",
        black: "#000",
        white: "#fff"
    },
    sizes: {
        tiny_phone: "414px;",
        portrait_phone: "575.98px",
        landscape_phone: "768.89px;",
        tablet: "991.98px",
        desktop: "1199.98px"
    }

}


// npm 