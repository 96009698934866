import React, { Component } from 'react';
import {Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

`

class CultureLead extends Component {
     


    render() {



        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  

                  <div className="form-section">
                    <h5>Employee Engagement</h5>
                    <hr />
                    <Form.Group
                      name="formCultureEngagement1"
                      as={Row}
                      controlId="formCultureEngagement1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        1. Has the organization conducted an employee survey in the last two years?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formCultureEngagement1"
                        id="formCultureEngagement1-Yes"
                        defaultChecked={
                          this.props.formCultureEngagement1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formCultureEngagement1"
                        id="formCultureEngagement1-No"
                        defaultChecked={
                          this.props.formCultureEngagement1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formCultureEngagement1"
                        id="formCultureEngagement1-NA"
                        defaultChecked={
                          this.props.formCultureEngagement1 === "unknown"
                        }
                      />
                    </Form.Group>
                    
                    <Form.Group controlId="formCultureEngagement5">
                      <Form.Label>2. If yes, can you explain how you created the survey or was this outsourced?</Form.Label>
                      <Form.Control
                        name="formCultureEngagement5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureEngagement5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureEngagement6">
                      <Form.Label>3. What did you do with the survey results? </Form.Label>
                      <Form.Control
                        name="formCultureEngagement6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureEngagement6 || ""}
                      />
                    </Form.Group>
                    

                    <Form.Group controlId="formCultureEngagement4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureEngagement4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureEngagement4 || ""}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Diversity, Equity, and Inclusion(DEI)</h5>
                    <hr />

                    <Form.Group controlId="formCultureDEI1">
                      <Form.Label>
                        4. What type Diversity, Equity & Inclusion programs does
                        the organization utilize, if any?
                      </Form.Label>
                      <Form.Control
                        name="formCultureDEI1"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureDEI1 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCultureDEI4">
                      <Form.Label>
                        5. How do you measure its success?
                      </Form.Label>
                      <Form.Control
                        name="formCultureDEI4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formCultureDEI4 || ""}
                      />
                    </Form.Group>
                    

                    <Form.Group controlId="formCultureDEI5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureDEI5"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureDEI5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Rewards and Recognition</h5>
                    <hr />
                    <Form.Group
                      name="formCultureRewards1"
                      as={Row}
                      controlId="formCultureRewards1"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        6. What types of employee recognition programs are
                        utilized within the organization? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Service Awards"
                          name="formCultureRewardsService"
                          id="formCultureRewards1-1"
                          defaultChecked={this.props.formCultureRewardsService}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Retirement Awards"
                          name="formCultureRewardsRetirement"
                          id="formCultureRewards1-2"
                          defaultChecked={
                            this.props.formCultureRewardsRetirement
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Peer Recognition Awards"
                          name="formCultureRewardsPeerRecognition"
                          id="formCultureRewards1-3"
                          defaultChecked={
                            this.props.formCultureRewardsPeerRecognition
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Spot Awards"
                          name="formCultureRewardsSpot"
                          id="formCultureRewards1-4"
                          defaultChecked={this.props.formCultureRewardsSpot}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Managerial Recognition Programs"
                          name="formCultureRewardsManagerial"
                          id="formCultureRewards1-5"
                          defaultChecked={
                            this.props.formCultureRewardsManagerial
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Organization-wide Recognition Programs"
                          name="formCultureRewardsOrganization"
                          id="formCultureRewards1-6"
                          defaultChecked={
                            this.props.formCultureRewardsOrganization
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Performance Awards"
                          name="formCultureRewardsPerformance"
                          id="formCultureRewards1-7"
                          defaultChecked={
                            this.props.formCultureRewardsPerformance
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employee of the Month/Year Awards"
                          name="formCultureRewardsEmployeeMonth"
                          id="formCultureRewards1-8"
                          defaultChecked={
                            this.props.formCultureRewardsEmployeeMonth
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Appreciation Lunches, Outings, Formal Events"
                          name="formCultureRewardsAppreciation"
                          id="formCultureRewards1-9"
                          defaultChecked={
                            this.props.formCultureRewardsAppreciation
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Goal-Specific Awards"
                          name="formCultureRewardsGoal"
                          id="formCultureRewards1-10"
                          defaultChecked={this.props.formCultureRewardsGoal}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employee Suggestion Program"
                          name="formCultureRewardsSuggestion"
                          id="formCultureRewards1-11"
                          defaultChecked={
                            this.props.formCultureRewardsSuggestion
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formCultureRewardsOther"
                          id="formCultureRewards1-12"
                          defaultChecked={this.props.formCultureRewardsOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formCultureRewardsOther === true ||
                        this.props.formCultureRewardsOther === 1) && (
                        <Form.Group controlId="formCultureRewardsOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formCultureRewardsOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formCultureRewardsOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group controlId="formCultureRewards4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureRewards4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureRewards4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Employee Communication</h5>
                    <hr />
                    <Form.Group
                      name="formCultureCommunication"
                      as={Row}
                      controlId="formCultureCommunication"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                       7. What types of employee recognition programs are utilized
                        within the organization? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Employee Newsletters"
                          name="formCommunicationNewsletter"
                          id="formCultureCommunication-1"
                          defaultChecked={
                            this.props.formCommunicationNewsletter
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Email Announcements"
                          name="formCommunicationEmail"
                          id="formCultureCommunication-2"
                          defaultChecked={this.props.formCommunicationEmail}
                        />
                        <Form.Check
                          type="checkbox"
                          label="All-Staff or Town Hall Meetings"
                          name="formCommunicationTownHall"
                          id="formCultureCommunication-3"
                          defaultChecked={this.props.formCommunicationTownHall}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Department Meetings"
                          name="formCommunicationDepartment"
                          id="formCultureCommunication-4"
                          defaultChecked={
                            this.props.formCommunicationDepartment
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Intranet or Internal Company Website"
                          name="formCommunicationWebsite"
                          id="formCultureCommunication-5"
                          defaultChecked={this.props.formCommunicationWebsite}
                        />
                        <Form.Check
                          type="checkbox"
                          label="No Communication is distributed from the company to employees"
                          name="formCommunicationNone"
                          id="formCultureCommunication-6"
                          defaultChecked={this.props.formCommunicationNone}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formCommunicationOther"
                          id="formCultureCommunication-7"
                          defaultChecked={this.props.formCommunicationOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formCommunicationOther === true ||
                        this.props.formCommunicationOther === 1) && (
                        <Form.Group controlId="formCommunicationOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formCommunicationOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={
                              this.props.formCommunicationOtherAnswer || ""
                            }
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    

                    <Form.Group controlId="formCultureCommunication5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureCommunication5"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureCommunication5 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>

                  {/* //
                  //
                  //
                  //
                  // */}

                  <div className="form-section">
                    <h5>Conflict Resolution</h5>
                    <hr />

                    <Form.Group
                      name="formCultureConflict"
                      as={Row}
                      controlId="formCultureConflict"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        8. How are conflicts in the organization generally
                        resolved? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Peer-to-Peer"
                          name="formConflictPeer"
                          id="formCultureConflict-1"
                          defaultChecked={this.props.formConflictPeer}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Involving Manager"
                          name="formConflictManager"
                          id="formCultureConflict-2"
                          defaultChecked={this.props.formConflictManager}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Chain of Command"
                          name="formConflictChain"
                          id="formCultureConflict-3"
                          defaultChecked={this.props.formConflictChain}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Human Resources"
                          name="formConflictHR"
                          id="formCultureConflict-4"
                          defaultChecked={this.props.formConflictHR}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal Grievance Process"
                          name="formConflictGrievance"
                          id="formCultureConflict-5"
                          defaultChecked={this.props.formConflictGrievance}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formConflictOther"
                          id="formCultureConflict-6"
                          defaultChecked={this.props.formConflictOther}
                        />
                      </Col>
                    </Form.Group>

                    <div>
                      {(this.props.formConflictOther === 1 ||
                        this.props.formConflictOther === true) && (
                        <Form.Group controlId="formConflictOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formConflictOtherAnswer"
                            as="textarea"
                            rows="1"
                            value={this.props.formConflictOtherAnswer || ""}
                            onChange={this.props.handleChange}
                          />
                        </Form.Group>
                      )}
                    </div>
                    <hr />

                    <Form.Group controlId="formCultureConflict3">
                      <Form.Label>
                        9. What types of grievances/formal complaints have you
                        received in the last two years, if any?
                      </Form.Label>
                      <Form.Control
                        name="formCultureConflict3"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureConflict3 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formCultureConflict4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formCultureConflict4"
                        as="textarea"
                        rows="4"
                        value={this.props.formCultureConflict4 || ""}
                        onChange={this.props.handleChange}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default CultureLead;