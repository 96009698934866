import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class GettingPaid extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Getting Paid</h1>
                    <p>All employees are paid twice-monthly, on the 15th and final day of each month.  Each paycheck will include earnings for all work performed through the end of the current payroll period.  Employees are encouraged to view their paychecks each pay period to ensure the accuracy of both pay and deductions.  If a regular payday falls on a holiday, employees will be paid the day before the holiday.  If a regular payday falls on a weekend, employees will be paid on Friday. </p>

                    <h2>Overtime</h2>
                    <p>All nonexempt employees who work more than forty hours in one workweek will receive overtime compensation at the rate of 1.5 times the employee’s pay rate.  The workweek on which overtime calculations will be based begins each Sunday at midnight.  Only those hours that are actually worked are added together to determine an employee’s overtime pay.  PTO hours and holidays, for example, are not hours worked and, therefore, are not factored into making overtime calculations.</p>

                    <p>All overtime must be authorized prior to its occurrence by your supervisor and clearly noted in your Payroll tool</p>

                    <h2>Time Reporting</h2>
                    <p>It is the policy of {this.props.clientName} to pay nonexempt employees overtime for all hours worked in excess of 40 hours per week (See Overtime Policy above.).  To do so, nonexempt employees must record their daily hours worked in Paylocity.  Hours worked must be reported accurately.  At no time should employees perform work while “off the clock.”  Nonexempt employees are responsible for properly reporting all time spent working.  If given a directive to perform work “off the clock,” please promptly notify the President and CEO or Human Resources.  </p>

                    <h2>Break Periods</h2>
                    <p>Employees are encouraged to leave their work area during their unpaid break period to receive the full benefit of the break or meal period.  Nonexempt employees are responsible for ensuring that any work that is performed during a break period is recorded as time worked.  Break periods of less than 20 minutes should be recorded as time worked.</p>

                    <h2>Payroll Error</h2>
                    <p>{this.props.clientName} always makes a good faith effort to pay in accordance with federal, state, and local laws.  Inadvertent errors may arise, including the possibility that a deduction will be made from an employee paycheck that should not have been made. Employees should examine their paychecks, viewable on Paylocity, and raise any questions or concerns they may have about payroll with their supervisor or PPR as soon as possible. Questions or concerns regarding a potential error will be investigated and if any improper payroll deduction or error was made, the employee will be reimbursed as quickly as possible and {this.props.clientName} will review its processes to help reduce the possibility of a similar payroll error in the future.</p>

                    <h2>Deductions</h2>
                    <p>Funds are deducted from employee pay for a variety of reasons, such as legally required deductions for the payment of taxes and to pay an employee’s portion of a voluntary benefit program.  Funds may also be deducted for the payment of child support or other debt as required by law.  If you believe a deduction has been made in error, please refer to the “Payroll Error” section within this guideline.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default GettingPaid;
