import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
   
`

class PoliciesEmployee extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Employee Handbook</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    

                    <Form.Group
                      name="formPolicyHandbook2"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyHandbook2"
                    >
                      <Form.Label column sm={5}>
                        23. How effective is your Employee Handbook at
                        communicating important company information about
                        policies and benefits?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-1"
                        defaultChecked={this.props.formPolicyHandbook2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-2"
                        defaultChecked={this.props.formPolicyHandbook2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-3"
                        defaultChecked={this.props.formPolicyHandbook2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-4"
                        defaultChecked={this.props.formPolicyHandbook2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-5"
                        defaultChecked={this.props.formPolicyHandbook2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyHandbook2"
                        id="formPolicyHandbook2-6"
                        defaultChecked={this.props.formPolicyHandbook2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyHandbook6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyHandbook6"
                    >
                      <Form.Label column sm={5}>
                        24.	Do you know where to access a copy of the handbook?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-Yes"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-No"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formPolicyHandbook6"
                        id="formPolicyHandbook6-Unknown"
                        defaultChecked={
                          this.props.formPolicyHandbook6 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formPolicyHandbook5">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formPolicyHandbook5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formPolicyHandbook5 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Onboarding Procedures</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    

                    <Form.Group
                      name="formPolicyOnboarding2"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding2"
                    >
                      <Form.Label column sm={5}>
                        25. How satisfied are you with the topics/information
                        covered during orientation?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-1"
                        defaultChecked={this.props.formPolicyOnboarding2 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-2"
                        defaultChecked={this.props.formPolicyOnboarding2 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-3"
                        defaultChecked={this.props.formPolicyOnboarding2 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-4"
                        defaultChecked={this.props.formPolicyOnboarding2 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-5"
                        defaultChecked={this.props.formPolicyOnboarding2 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding2"
                        id="formPolicyOnboarding2-6"
                        defaultChecked={this.props.formPolicyOnboarding2 === 0}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding3"
                      onChange={this.props.handleChangeAsInt}
                      as={Row}
                      controlId="formPolicyOnboarding3"
                    >
                      <Form.Label column sm={5}>
                        26. How satisfied were you with the feedback received
                        from your manager during your first 90 days?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-1"
                        defaultChecked={this.props.formPolicyOnboarding3 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-2"
                        defaultChecked={this.props.formPolicyOnboarding3 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-3"
                        defaultChecked={this.props.formPolicyOnboarding3 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-4"
                        defaultChecked={this.props.formPolicyOnboarding3 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-5"
                        defaultChecked={this.props.formPolicyOnboarding3 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formPolicyOnboarding3"
                        id="formPolicyOnboarding3-6"
                        defaultChecked={this.props.formPolicyOnboarding3 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group
                      name="formPolicyOnboarding4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding4"
                    >
                      <Form.Label column sm={5}>
                        27. How long have you been with the organization?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Less than a year"
                          value="Less than a year"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-1"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than a year"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Less than 5 years"
                          value="Less than 5 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-2"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "Less than 5 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="5-10 years"
                          value="5-10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-3"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 === "5-10 years"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="More than 10 years"
                          value="More than 10 years"
                          name="formPolicyOnboarding4"
                          id="formPolicyOnboarding4-4"
                          defaultChecked={
                            this.props.formPolicyOnboarding4 ===
                            "More than 10 years"
                          }
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      name="formPolicyOnboarding"
                      as={Row}
                      controlId="formPolicyOnboarding"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        28. What did your orientation to the new job look like?
                        Check all that apply...
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Non-existent"
                          name="formPolicyOnboardingNonExistent"
                          id="formPolicyOnboarding-1"
                          defaultChecked={
                            this.props.formPolicyOnboardingNonExistent
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Informal with my Manager/Supervisor"
                          name="formPolicyOnboardingInformalManager"
                          id="formPolicyOnboarding-2"
                          defaultChecked={
                            this.props.formPolicyOnboardingInformalManager
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal with my Manager/Supervisor"
                          name="formPolicyOnboardingFormalManager"
                          id="formPolicyOnboarding-3"
                          defaultChecked={
                            this.props.formPolicyOnboardingFormalManager
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Formal with HR"
                          name="formPolicyOnboardingFormalHR"
                          id="formPolicyOnboarding-4"
                          defaultChecked={
                            this.props.formPolicyOnboardingFormalHR
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Informal with HR"
                          name="formPolicyOnboardingInformalHR"
                          id="formPolicyOnboarding-5"
                          defaultChecked={
                            this.props.formPolicyOnboardingInformalHR
                          }
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formPolicyOnboardingOther"
                          id="formPolicyOnboarding-6"
                          defaultChecked={this.props.formPolicyOnboardingOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formPolicyOnboardingOther === true ||
                        this.props.formPolicyOnboardingOther === 1) && (
                        <Form.Group controlId="formPolicyOnboardingOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPolicyOnboardingOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={
                              this.props.formPolicyOnboardingOtherAnswer || ""
                            }
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formPolicyOnboarding6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formPolicyOnboarding6"
                    >
                      <Form.Label as="legend" column sm={5}>
                        29. Describe the first 90 days of employment and how it
                        was either helpful/not helpful in orienting you to the
                        organization and your position?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="Little to no input or training was provided"
                          value="Little to no input or training was provided"
                          name="formPolicyOnboarding6"
                          id="formPolicyOnboarding6-1"
                          defaultChecked={
                            this.props.formPolicyOnboarding6 ===
                            "Little to no input or training was provided"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Informal but helpful"
                          value="Informal but helpful"
                          name="formPolicyOnboarding6"
                          id="formPolicyOnboarding6-2"
                          defaultChecked={
                            this.props.formPolicyOnboarding6 ===
                            "Informal but helpful"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Informal but not helpful"
                          value="Informal but not helpful"
                          name="formPolicyOnboarding6"
                          id="formPolicyOnboarding6-3"
                          defaultChecked={
                            this.props.formPolicyOnboarding6 ===
                            "Informal but not helpful"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="My manager/supervisor scheduled regular meetings with me to train me"
                          value="My manager/supervisor scheduled regular meetings with me to train me"
                          name="formPolicyOnboarding6"
                          id="formPolicyOnboarding6-4"
                          defaultChecked={
                            this.props.formPolicyOnboarding6 ===
                            "My manager/supervisor scheduled regular meetings with me to train me"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formPolicyOnboarding6"
                          id="formPolicyOnboarding6-5"
                          defaultChecked={
                            this.props.formPolicyOnboarding6 === "Other"
                          }
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formPolicyOnboarding6 === "Other" && (
                        <Form.Group controlId="formPolicyOnboarding6Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formPolicyOnboarding6Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formPolicyOnboarding6Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default PoliciesEmployee;