import React, { Component } from 'react';
import {Accordion, Card} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../Config/Theme';
import Benefits from './Policies/6_Benefits/Benefits';
import Bereavement from './Policies/6_Benefits/Bereavement';
import CivicDuty from './Policies/6_Benefits/CivicDuty';
import ExtendedSickLeave from './Policies/6_Benefits/ExtendedSickLeave';
import FamilyLeave from './Policies/6_Benefits/FamilyLeave';
import Holidays from './Policies/6_Benefits/Holidays';
import MandatoryAbsence from './Policies/6_Benefits/MandatoryAbsence';
import PersonalTimeOff from './Policies/6_Benefits/PersonalTimeOff';
import PTO from './Policies/6_Benefits/PTO';
import SummerFlexTime from './Policies/6_Benefits/SummerFlexTime';
import SummerTimeOff from './Policies/6_Benefits/SummerTimeOff';
import UnpaidLOA from './Policies/6_Benefits/UnpaidLOA';
import WorkersComp from './Policies/6_Benefits/WorkersComp';


const Div = styled.div`
     
    .accordion{
        color: white;
        background: black;
        border: 1px solid black;
        
    }

`

class SectionBenefits extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sectionName: 'Introduction'
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Accordion className="my-5">
                                <Card>
                                    <Accordion.Toggle className="accordion" as={Card.Header} eventKey="0">
                                        6. Benefits
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            
                                            <Accordion>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                       Benefits
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Benefits clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Bereavement Leave
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Bereavement clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                    
                                            <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Civic Duty
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><CivicDuty clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Extended Sick Leave
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><ExtendedSickLeave clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>

                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Family Leave
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><FamilyLeave clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Holidays 
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><Holidays clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Mandatory One-Week Absence
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><MandatoryAbsence clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Personal Time-Off
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><PersonalTimeOff clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Paid Time Off
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><PTO clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        SummerFlexTime
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><SummerFlexTime clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Summer Time-Off
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><SummerTimeOff clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Unpaid Leave of Absence
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><UnpaidLOA clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                    </Accordion>
                                    
                                    <Accordion className="my-3">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="accordion" >
                                                        Workers' Compensation
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body><WorkersComp clientName={this.props.clientName} /></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                </Div>
            </ThemeProvider>
        )
    }
}

export default SectionBenefits;