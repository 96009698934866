import React, { Component } from 'react';
import { Container, Form} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class HR extends Component {

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>HR Policies & Procedures</h4>
                                <hr />

                                <Form.Group controlId="formHRLocation">
                                    <Form.Label>
                                        111. Location
                                    </Form.Label>
                                    <Form.Control
                                        name="formHRPoliciesLocation"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formHRPoliciesLocation || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formHRSchedule">
                                    <Form.Label>
                                        112. Update schedule
                                    </Form.Label>
                                    <Form.Control
                                        name="formHRPoliciesSchedule"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formHRPoliciesSchedule || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formHRResponsible">
                                    <Form.Label>
                                        113. Responsible Individual(s)?
                                    </Form.Label>
                                    <Form.Control
                                        name="formHRPoliciesResponsible"
                                        as="textarea"
                                        rows="4"
                                        value={this.props.formHRPoliciesResponsible || ""}
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }

}

export default HR;