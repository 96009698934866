import React, { Component } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class StrategyExec extends Component {
    

    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Strategy</h5>
                    <hr />
                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formStrategy1"
                      as={Row}
                      controlId="formStrategy1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        45. How confident are you that your direct reports are
                        adequately equipped to fulfill your role (or the role of
                        other leaders on your leadership team) in the event you
                        or another leader are unexpectedly incapacitated?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formStrategy1"
                        id="formStrategy1-1"
                        defaultChecked={this.props.formStrategy1 === 1}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formStrategy1"
                        id="formStrategy1-2"
                        defaultChecked={this.props.formStrategy1 === 2}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formStrategy1"
                        id="formStrategy1-3"
                        defaultChecked={this.props.formStrategy1 === 3}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formStrategy1"
                        id="formStrategy1-4"
                        defaultChecked={this.props.formStrategy1 === 4}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formStrategy1"
                        id="formStrategy1-5"
                        defaultChecked={this.props.formStrategy1 === 5}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formStrategy1"
                        id="formStrategy1-6"
                        defaultChecked={this.props.formStrategy1 === 0}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group
                      name="formStrategy2"
                      as={Row}
                      controlId="formStrategy2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        46. Does the company have a strategic plan in place?
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="radio"
                          label="I don't know"
                          value="I don't know"
                          name="formStrategy2"
                          id="formStrategy2-1"
                          defaultChecked={
                            this.props.formStrategy2 === "I don't know"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, but we don't talk about it"
                          value="Yes, but we don't talk about it"
                          name="formStrategy2"
                          id="formStrategy2-2"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, but we don't talk about it"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Yes, I hear about it often"
                          value="Yes, I hear about it often"
                          name="formStrategy2"
                          id="formStrategy2-3"
                          defaultChecked={
                            this.props.formStrategy2 ===
                            "Yes, I hear about it often"
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Other"
                          value="Other"
                          name="formStrategy2"
                          id="formStrategy2-4"
                          defaultChecked={this.props.formStrategy2 === "Other"}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {this.props.formStrategy2 === "Other" && (
                        <Form.Group controlId="formStrategy2Answer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy2Answer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy2Answer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    
                    <hr />

                    <Form.Group
                      name="formStrategy6"
                      as={Row}
                      controlId="formStrategy6"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        47.	How is employee buy-in established for adoption and usage of new initiatives? Check all that apply.
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Employee feedback is solicited"
                          name="formStrategyFeedback"
                          id="formStrategy6-1"
                          defaultChecked={this.props.formStrategyFeedback}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Employees lead or participate in committees leading the initiative"
                          name="formStrategyLead"
                          id="formStrategy6-2"
                          defaultChecked={this.props.formStrategyLead}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Leaders provide in-depth discussion explaining the “why”"
                          name="formStrategyDiscussion"
                          id="formStrategy6-3"
                          defaultChecked={this.props.formStrategyDiscussion}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formStrategyOther"
                          id="formStrategy6-4"
                          defaultChecked={this.props.formStrategyOther}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formStrategyOther === true ||
                        this.props.formStrategyOther === 1) && (
                        <Form.Group controlId="formStrategyOtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategyOtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategyOtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    <Form.Group
                      name="formStrategy7"
                      as={Row}
                      controlId="formStrategy7"
                      onChange={this.props.handleCheck}
                    >
                      <Form.Label column sm={5}>
                        7. How are changes communicated company-wide? Select all
                        that apply...
                      </Form.Label>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Email"
                          name="formStrategyEmail"
                          id="formStrategy7-1"
                          defaultChecked={this.props.formStrategyEmail}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In face-to-face meetings with leadership/President/CEO"
                          name="formStrategyFaceMeetings"
                          id="formStrategy7-2"
                          defaultChecked={this.props.formStrategyFaceMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="In department meetings"
                          name="formStrategyDeptMeetings"
                          id="formStrategy7-3"
                          defaultChecked={this.props.formStrategyDeptMeetings}
                        />
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          name="formStrategy7Other"
                          id="formStrategy7-4"
                          defaultChecked={this.props.formStrategy7Other}
                        />
                      </Col>
                    </Form.Group>
                    <div>
                      {(this.props.formStrategy7Other === true ||
                        this.props.formStrategy7Other === 1) && (
                        <Form.Group controlId="formStrategy7OtherAnswer">
                          <Form.Label column sm={5}>
                            Other:
                          </Form.Label>
                          <Form.Control
                            name="formStrategy7OtherAnswer"
                            as="textarea"
                            rows="1"
                            onChange={this.props.handleChange}
                            value={this.props.formStrategy7OtherAnswer || ""}
                          />
                        </Form.Group>
                      )}
                    </div>

                    
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default StrategyExec;