import React, { Component } from "react";
import HCA_Menu from "./HCA_Menu";
import HCA_Dash from "./HCA_Dash";
import HCA_Docs from "./HCA_Docs";
import HCA_Compliance from "./HCA_Compliance";
import HCA_Culture from "./HCA_Culture";

class HCA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hcaComponent: "menu",
            selectedHCA: 0,
            selectedClient: "",
            employee: "",
            employeeId: 0,
            employment_level: "",
            linkInterview: false
        }

        this.showDash = this.showDash.bind(this);
        this.showDocs = this.showDocs.bind(this);
        this.showCompliance = this.showCompliance.bind(this);
        this.showCulture = this.showCulture.bind(this);
    }

    showDash(hcaId, clientName) {
        this.setState({ selectedHCA: hcaId });
        this.setState({ selectedClient: clientName });
        this.setState({ hcaComponent: 'hca_dash' });
    }

    showDocs() {
        this.setState({ hcaComponent: 'hca_docs' });
    }

    showCompliance() {
        this.setState({ hcaComponent: 'hca_compliance'})
    }

    showCulture() {
        this.setState({ hcaComponent: 'hca_culture'})
    }

    render() {
        let hcaComponent = this.state.hcaComponent;

        switch (hcaComponent) {
            case 'menu':
                return <HCA_Menu showDash={this.showDash}/>;
            case 'hca_dash':
                return (
                    <HCA_Dash
                        hcaId={this.state.selectedHCA}
                        clientName={this.state.selectedClient}
                        showDocs={this.showDocs}
                        showCompliance={this.showCompliance}
                        showCulture={this.showCulture}
                    />
                );
            case 'hca_docs':
                return (
                    <HCA_Docs
                        hcaId={this.state.selectedHCA}
                        clientName={this.state.selectedClient}
                        
                    />
                );
            case 'hca_compliance':
                return (
                    <HCA_Compliance
                        hcaId={this.state.selectedHCA}
                        clientName={this.state.selectedClient}
                    />
                );
            case 'hca_culture':
                return (
                    <HCA_Culture
                        hcaId={this.state.selectedHCA}
                        clientName={this.state.selectedClient}
                    />
                );
            default:
                return <h1>Something is Broken!</h1>
        }

    }
}

export default HCA;