import React, { Component } from 'react';
import { Container, Form, Row, Col} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Demographics extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            <div className="form-section">
                                <h4>Demographics</h4>
                                <hr />
                                <p>The following questions are for analytic purposes only. Responses will only be seen by PPR and will only be reported in aggregate. Information gathered here will not be used to try to identify any individual. However, if you feel uncomfortable about answering any of them, do not do so.</p>
                                <p>Whether or not you leave any question blank, please submit your questionnaire.</p>

                                <Form.Group
                                as={Row}
                                name="formDemoYears"
                                controlId="formDemoYears"
                                onChange={this.props.handleChangeAsInt}
                                >
                                    
                                    
                                <Form.Label column sm={5}>
                                    1. How long have you worked for the organization?
                                    </Form.Label>
                                    <Col>
                                        <Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Less than one year"
                                    value="Less than one year"
                                    name="formDemoYears"
                                    id="formDemoYears-1"
                                    defaultChecked={this.props.formDemoYears === "Less than one year"}
                                />
                                </Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="One year to less than two years"
                                    value="One year to less than two years"
                                    name="formDemoYears"
                                    id="formDemoYears-2"
                                    defaultChecked={this.props.formDemoYears === "One year to less than two years"}
                                />
                                </Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Two years to less than five years"
                                    value="Two years to less than five years"
                                    name="formDemoYears"
                                    id="formDemoYears-3"
                                    defaultChecked={this.props.formDemoYears === "Two years to less than five years"}
                                />
                                </Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Five years to less than ten years"
                                    value="Five years to less than ten years"
                                    name="formDemoYears"
                                    id="formDemoYears-4"
                                    defaultChecked={this.props.formDemoYears === "Five years to less than ten years"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Ten years or more"
                                    value="Ten years or more"
                                    name="formDemoYears"
                                    id="formDemoYears-5"
                                    defaultChecked={this.props.formDemoYears === "Ten years or more"}
                                /></Row>
                                </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                name="formDemoAge"
                                controlId="formDemoAge"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    2. What is your age?
                                    </Form.Label>
                                    <Col>
                                <Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Under 21"
                                    value="Under 21"
                                    name="formDemoAge"
                                    id="formDemoAge-1"
                                    defaultChecked={this.props.formDemoAge === "Under 21"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="21 to 34"
                                    value="21 to 34"
                                    name="formDemoAge"
                                    id="formDemoAge-2"
                                    defaultChecked={this.props.formDemoAge === "21 to 34"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="35 to 44"
                                    value="35 to 44"
                                    name="formDemoAge"
                                    id="formDemoAge-3"
                                    defaultChecked={this.props.formDemoAge === "35 to 44"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="45 to 54"
                                    value="45 to 54"
                                    name="formDemoAge"
                                    id="formDemoAge-4"
                                    defaultChecked={this.props.formDemoAge === "45 to 54"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="55 or older"
                                    value="55 or older"
                                    name="formDemoAge"
                                    id="formDemoAge-5"
                                    defaultChecked={this.props.formDemoAge === "55 or older"}
                                /></Row>
                                </Col>
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                name="formDemoGender"
                                controlId="formDemoGender"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    3. What is your gender/gender identity?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Agender"
                                    value="Agender"
                                    name="formDemoGender"
                                    id="formDemoGender-1"
                                    defaultChecked={this.props.formDemoGender === "Agender"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Androgynous"
                                    value="Androgynous"
                                    name="formDemoGender"
                                    id="formDemoGender-2"
                                    defaultChecked={this.props.formDemoGender === "Androgynous"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Bigender"
                                    value="Bigender"
                                    name="formDemoGender"
                                    id="formDemoGender-3"
                                    defaultChecked={this.props.formDemoGender === "Bigender"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Man"
                                    value="Man"
                                    name="formDemoGender"
                                    id="formDemoGender-4"
                                    defaultChecked={this.props.formDemoGender === "Man"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Woman"
                                    value="Woman"
                                    name="formDemoGender"
                                    id="formDemoGender-5"
                                    defaultChecked={this.props.formDemoGender === "Woman"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Other"
                                    value="Other"
                                    name="formDemoGender"
                                    id="formDemoGender-6"
                                    defaultChecked={this.props.formDemoGender === "Other"}
                                /></Row></Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formDemoGender === true ||
                                        this.props.formDemoGender === 1) && (
                                        <Form.Group controlId="formDemoGenderAnswer">
                                        <Form.Label column sm={5}>
                                            Please Specify:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDemoGenderAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDemoGenderAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group
                                    as={Row}
                                name="formDemoOrientation"
                                controlId="formDemoOrientation"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    4.	What is your sexual orientation?
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Asexual"
                                    value="Asexual"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-1"
                                    defaultChecked={this.props.formDemoOrientation === "Asexual"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Bisexual"
                                    value="Bisexual"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-2"
                                    defaultChecked={this.props.formDemoOrientation === "Bisexual"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Gay"
                                    value="Gay"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-3"
                                    defaultChecked={this.props.formDemoOrientation === "Gay"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Heterosexual or Straight"
                                    value="Heterosexual or Straight"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-4"
                                    defaultChecked={this.props.formDemoOrientation === "Heterosexual or Straight"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Lesbian"
                                    value="Lesbian"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-5"
                                    defaultChecked={this.props.formDemoOrientation === "Lesbian"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Pansexual"
                                    value="Pansexual"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-6"
                                    defaultChecked={this.props.formDemoOrientation === "Pansexual"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Queer"
                                    value="Queer"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-7"
                                    defaultChecked={this.props.formDemoOrientation === "Queer"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="None of the above"
                                    value="None of the above"
                                    name="formDemoOrientation"
                                    id="formDemoOrientation-8"
                                    defaultChecked={this.props.formDemoOrientation === "None of the above"}
                                /></Row></Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formDemoOrientation === true ||
                                        this.props.formDemoOrientation === 1) && (
                                        <Form.Group controlId="formDemoOrientationAnswer">
                                        <Form.Label column sm={5}>
                                            Please Specify:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDemoOrientationAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDemoOrientationAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group
                                    name="formRace"
                                    as={Row}
                                    controlId="formRace"
                                    onChange={this.props.handleCheck}
                                    >
                                    <Form.Label column sm={5}>
                                        5.	Please indicate the racial or ethnic groups with which you identify (Check all that apply.)
                                    </Form.Label>
                                    <Col>
                                        <Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Black or African American"
                                        name="formRaceBlack"
                                        id="formRaceBlack"
                                        defaultChecked={this.props.formRaceBlack}
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Asian or Asian American"
                                        name="formRaceAsian"
                                        id="formRaceAsian"
                                        defaultChecked={
                                            this.props.formRaceAsian
                                        }
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Hispanic or Latino/a"
                                        name="formRaceLatino"
                                        id="formRaceLatino"
                                        defaultChecked={
                                            this.props.formRaceLatino
                                        }
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Middle Eastern/North African"
                                        name="formRaceMiddleEastern"
                                        id="formRaceMiddleEastern"
                                        defaultChecked={this.props.formRaceMiddleEastern}
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="American Indian or Alaska Native"
                                        name="formRaceIndian"
                                        id="formRaceIndian"
                                        defaultChecked={
                                            this.props.formRaceIndian
                                        }
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Native Hawaiian or Other Pacific Islander"
                                        name="formRaceHawaiian"
                                        id="formRaceHawaiian"
                                        defaultChecked={
                                            this.props.formRaceHawaiian
                                        }
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="White or Caucasian"
                                        name="formRaceWhite"
                                        id="formRaceWhite"
                                        defaultChecked={
                                            this.props.formRaceWhite
                                        }
                                        /></Row><Row>
                                        <Form.Check
                                        type="checkbox"
                                        label="Other"
                                        name="formRaceOther"
                                        id="formRaceOther"
                                        defaultChecked={this.props.formRaceOther}
                                        /></Row>
                                    </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formRaceOther === true ||
                                        this.props.formRaceOther === 1) && (
                                        <Form.Group controlId="formRaceOtherAnswer">
                                        <Form.Label column sm={5}>
                                            Other:
                                        </Form.Label>
                                        <Form.Control
                                            name="formRaceOtherAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formRaceOtherAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>

                                <Form.Group
                                    name="formDemoDisabled"
                                    as={Row}
                                    controlId="formDemoDisabled"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            6. Are you a person with a disability?
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="yes"
                                            name="formDemoDisabled"
                                            id="formDemoDisabled-Yes"
                                            defaultChecked={
                                            this.props.formDemoDisabled === "yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="no"
                                            name="formDemoDisabled"
                                            id="formDemoDisabled-No"
                                            defaultChecked={
                                            this.props.formDemoDisabled === "no"
                                            }                               
                                        />
                                </Form.Group>

                                <Form.Group
                                    as={Row}
                                name="formDemoEducation"
                                controlId="formDemoEducation"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    7.	What is the highest academic degree you have earned?
                                    </Form.Label>
                                    <Col>
                                        <Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="High School Diploma or GED"
                                    value="High School Diploma or GED"
                                    name="formDemoEducation"
                                    id="formDemoEducation-1"
                                    defaultChecked={this.props.formDemoEducation === "High School Diploma or GED"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Two-year college degree(Associate's)"
                                    value="Two-year college degree(Associate's)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-2"
                                    defaultChecked={this.props.formDemoEducation === "Two-year college degree(Associate's)"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Four-year college degree(Bachelor's)"
                                    value="Four-year college degree(Bachelor's)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-3"
                                    defaultChecked={this.props.formDemoEducation === "Four-year college degree(Bachelor's)"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Master's degree in Arts and Sciences (MA, MS)"
                                    value="Master's degree in Arts and Sciences (MA, MS)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-4"
                                    defaultChecked={this.props.formDemoEducation === "Master's degree in Arts and Sciences (MA, MS)"}
                                /></Row><Row>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="Professional Master's degree (MBA, MPA, MSW, MSE, MSN, MPH, MFA, etc)"
                                    value="Professional Master's degree (MBA, MPA, MSW, MSE, MSN, MPH, MFA, etc)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-5"
                                    defaultChecked={this.props.formDemoEducation === "Professional Master's degree (MBA, MPA, MSW, MSE, MSN, MPH, MFA, etc)"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Ph.D"
                                    value="Ph.D"
                                    name="formDemoEducation"
                                    id="formDemoEducation-6"
                                    defaultChecked={this.props.formDemoEducation === "Ph.D"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Other Doctoral degree (e.g., EdD, DDiv, DrPH, DBA, etc."
                                    value="Other Doctoral degree (e.g., EdD, DDiv, DrPH, DBA, etc."
                                    name="formDemoEducation"
                                    id="formDemoEducation-7"
                                    defaultChecked={this.props.formDemoEducation === "Other Doctoral degree (e.g., EdD, DDiv, DrPH, DBA, etc."}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Medical Degree(MD, DO, DDS, DVM)"
                                    value="Medical Degree(MD, DO, DDS, DVM)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-8"
                                    defaultChecked={this.props.formDemoEducation === "Medical Degree(MD, DO, DDS, DVM)"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Law Degree(JD, LLM, SJD)"
                                    value="Law Degree(JD, LLM, SJD)"
                                    name="formDemoEducation"
                                    id="formDemoEducation-8"
                                    defaultChecked={this.props.formDemoEducation === "Law Degree(JD, LLM, SJD)"}
                                    /></Row><Row>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label="Other"
                                    value="Other"
                                    name="formDemoEducation"
                                    id="formDemoEducation-8"
                                    defaultChecked={this.props.formDemoEducation === "Other"}
                                /></Row>
                                </Col>
                                </Form.Group>
                                <div>
                                    {(this.props.formDemoEducation === true ||
                                        this.props.formDemoEducation === 1) && (
                                        <Form.Group controlId="formDemoEducationAnswer">
                                        <Form.Label column sm={5}>
                                            Please Specify:
                                        </Form.Label>
                                        <Form.Control
                                            name="formDemoEducationAnswer"
                                            as="textarea"
                                            rows="1"
                                            onChange={this.props.handleChange}
                                            value={
                                            this.props.formDemoEducationAnswer || ""
                                            }
                                        />
                                        </Form.Group>
                                    )}
                                </div>
                                
                                <Form.Group
                                    as={Row}
                                    name="formDemoFamily"
                                    controlId="formDemoFamily"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            8. Family Status
                                    </Form.Label>
                                    <Col>
                                    <Row>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No Children"
                                            value="No Children"
                                            name="formDemoFamily"
                                            id="formDemoFamily-No Children"
                                            defaultChecked={
                                            this.props.formDemoFamily === "No Children"
                                            }
                                        /></Row><Row>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Partnered Parent/Legal Guardian"
                                            value="Partnered Parent/Legal Guardian"
                                            name="formDemoFamily"
                                            id="formDemoFamily-Partnered Parent/Legal Guardian"
                                            defaultChecked={
                                            this.props.formDemoFamily === "Partnered Parent/Legal Guardian"
                                            }                               
                                        /></Row><Row>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Single Parent/Legal Guardian"
                                            value="Single Parent/Legal Guardian"
                                            name="formDemoFamily"
                                            id="formDemoFamily-NA"
                                            defaultChecked={
                                            this.props.formDemoFamily === "Single Parent/Legal Guardian"
                                            }
                                        /></Row></Col>
                                </Form.Group>

                                <Form.Group
                                    name="formDemoCaregiver"
                                    controlId="formDemoCaregiver"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            9. Caregiver Status
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="Yes"
                                            name="formDemoCaregiver"
                                            id="formDemoCaregiver-Yes"
                                            defaultChecked={
                                            this.props.formDemoCaregiver === "Yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="No"
                                            name="formDemoCaregiver"
                                            id="formDemoCaregiver-No"
                                            defaultChecked={
                                            this.props.formDemoCaregiver === "No"
                                            }                               
                                        />

                                </Form.Group>

                                <Form.Group
                                    name="formDemoVeteran"
                                    controlId="formDemoVeteran"
                                    onChange={this.props.handleChange}
                                    >
                                        <Form.Label column sm={5}>
                                            10. Veteran Status
                                        </Form.Label>
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="Yes"
                                            value="Yes"
                                            name="formDemoVeteran"
                                            id="formDemoVeteran-Yes"
                                            defaultChecked={
                                            this.props.formDemoVeteran === "Yes"
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            type="radio"
                                            label="No"
                                            value="No"
                                            name="formDemoVeteran"
                                            id="formDemoVeteran-No"
                                            defaultChecked={
                                            this.props.formDemoVeteran === "No"
                                            }                               
                                        />

                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Demographics;