import React, { Component } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
`

class WorkforceExec extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Talent Attraction</h5>
                    <hr />

                    <p className="font-italic font-weight-bold">
                      On a scale from 1 to 5 (1-Lowest/Worst and
                      5-Highest/Best)...
                    </p>
                    <Form.Group
                      name="formWorkforceAttraction1"
                      as={Row}
                      controlId="formWorkforceAttraction1"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        60. How effective is the organization’s efforts in
                        planning ahead for potential skills gaps?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-4"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-5"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formWorkforceAttraction1"
                        id="formWorkforceAttraction1-6"
                        defaultChecked={
                          this.props.formWorkforceAttraction1 === 0
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      name="formWorkforceAttraction2"
                      as={Row}
                      controlId="formWorkforceAttraction2"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        61. How effective is your organization in attracting top
                        talent?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-4"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-5"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formWorkforceAttraction2"
                        id="formWorkforceAttraction2-6"
                        defaultChecked={
                          this.props.formWorkforceAttraction2 === 0
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      name="formWorkforceAttraction3"
                      as={Row}
                      controlId="formWorkforceAttraction3"
                      onChange={this.props.handleChangeAsInt}
                    >
                      <Form.Label column sm={5}>
                        62. How effective is your current interview process in
                        screening potential hires?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="1"
                        value="1"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 1
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="2"
                        value="2"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 2
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="3"
                        value="3"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 3
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="4"
                        value="4"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-4"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 4
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="5"
                        value="5"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-5"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 5
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="N/A"
                        value="0"
                        name="formWorkforceAttraction3"
                        id="formWorkforceAttraction3-6"
                        defaultChecked={
                          this.props.formWorkforceAttraction3 === 0
                        }
                      />
                    </Form.Group>
                    <hr />

                    

                    <Form.Group controlId="formWorkforceAttraction8">
                      <Form.Label>
                        63. What improvements, if any, would you like to
                        see made to your talent acquisition/recruitment process?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction8 || ""}
                      />
                    </Form.Group>

                    <hr />
                    

                    <Form.Group controlId="formWorkforceAttraction15">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction15"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction15 || ""}
                      />
                    </Form.Group>
                  </div>

                  
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default WorkforceExec;