import React, { Component } from 'react';
import {Button, Row, Col, Jumbotron } from 'react-bootstrap';
import styled from 'styled-components';



const Div = styled.div`
    .docs-list{
        color: black;
        border: 1px solid black;
        padding: .25em;
        margin-bottom: .25em;;
    }

    .warning-list{
        color: red;
    }
`



class HCA_Docs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unverifiedDocuments: ["Personnel Files", "Time Keeping System", "Posting Requirements", "Employee Data Spreadsheet", "Time and Attendance Forms", "Compensation, Bonus, Etc", "Employee Change Form"],
            sections: [],
            verifiedYesDocs: ["Pre-Employment Paperwork", "Offer Letter", "New Hire Packet", "Onboarding Templates", "Termination Forms", "Job Postings", 'Orientation Prep Materials', 'Interview Questions', 'Background Check Release Forms'],
            verifiedNoDocs: ["I-9",'Leave of Absence Process', 'Employee Handbook', 'Other Manuals/Procedures', 'Management/Supervisor Manual', 'Job Description', 'Reasonable Accommodation Requests'],
            tempArray: [],
            totalVerified: 0,
            totalUnverified: 49,
            percentageComplete: 0
        }
    }
    
    componentDidMount() {
        
    }

    render() {
        return (
            <Div>
                <div>
                <h1>Docs Go Here!</h1>
                    <p>{this.props.clientName}: {this.props.hcaId}</p>
                <Row>
                    <Col>
                    <p>Docs List</p>
                    <Jumbotron>
                        <div>
                            {this.state.unverifiedDocuments.map((unVeriDoc) => (
                                <div className="docs-list d-flex"
                                key={unVeriDoc}>
                                    <p className="flex-grow-1">{unVeriDoc}</p>
                                    <Button>Yes</Button>
                                    <Button className="ml-1">No</Button>
                                </div>
                            ))}    
                        </div>
                    </Jumbotron>  
                    </Col>
                    <Col>
                    <p>No Docs</p>
                    <Jumbotron>
                        <div>
                            {this.state.verifiedNoDocs.map((noVeriDoc) => (
                                <div className="docs-list d-flex"
                                key={noVeriDoc}>
                                    <p className="flex-grow-1">{noVeriDoc}</p>
                                    <Button>Yes</Button>
                                    <Button className="ml-1">No</Button>
                                </div>
                            ))}    
                        </div>
                    </Jumbotron>  
                    </Col>
                    <Col>
                    <p>Verified Docs</p>
                    <Jumbotron>
                        <div>
                            {this.state.verifiedYesDocs.map((yesVeriDoc) => (
                                <div className="docs-list d-flex"
                                key={yesVeriDoc}>
                                    <p className="flex-grow-1">{yesVeriDoc}</p>
                                    <Button>Yes</Button>
                                    <Button className="ml-1">No</Button>
                                </div>
                            ))}    
                        </div>
                    </Jumbotron>  
                </Col>
                </Row>
                    <Row>
                        <Col>
                            <p>Warnings</p>
                            <Jumbotron className="warning-list">
                                <p>Warning: They do not keep I-9's.</p>
                                <p>Warning: No Employee Handbook.</p>
                                <p>Warning: No Current Job Descriptions.</p>
                            </Jumbotron>
                        </Col>
                </Row>
                </div>
            </Div>
            
            
        )
    }

}

export default HCA_Docs;