import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class DEI extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Diversity, Equity, and Inclusion Policy</h1>
                    <p>At {this.props.clientName}, we believe that the combination and variety of different experiences, knowledge and perspectives are one of the keys to our organization’s success.  To that end, we embrace diversity, equity, and inclusion practices not only because they are morally important, but also because these efforts strengthen the organization so that we may better serve our partners and help them towards their goals.</p>

                    <p>We become stronger, more innovative, more efficient and responsive, and better able to navigate new challenges when individuals from diverse races, ethnicities, viewpoints, experiences, physical abilities, economic backgrounds, genders, and sexual identities and orientations share their experiences, and we all listen, value, and learn from one another. These ideals of diversity, equity, and inclusion guide our staffing, partnership, and strategic decisions every day.  </p>
                    
                </Div>
            </ThemeProvider>
            
        )
            
    }
}

export default DEI;