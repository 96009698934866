import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class PTO extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Paid Time Off</h1>
                    <p>Every full-time employee has a “fund” of time to use for vacation, sick, and personal reasons based on eligible years of service with {this.props.clientName}. This fund is referred to as Paid Time Off (PTO). PTO does not include designated paid holidays; rather, holiday benefit time is given to employees in addition to PTO.</p>

                    <p>PTO days accrue on a weekly basis according to an employee’s length of service, as outlined in the chart below. The annual PTO balance is available for employees to use at the beginning of the calendar year and may be used in half day increments. For example, for an employee with 8 years of service, 29 PTO days will be made available for the employee to use in January. However, once the balance is used, no more PTO time is available for the remainder of the calendar year. </p>

                    <h2>Using and Scheduling PTO</h2>
                    <p>Employees are encouraged to take no more than two weeks of PTO at one time and submit a request via Paylocity at least two weeks in advance. Last minute requests will be considered on a case-by-case basis. When possible, PTO requests will be granted, taking into account operating requirements. Multiple employees may not be able to use PTO simultaneously unless there is proper coverage or approved by the President and CEO. </p>

                    <p>Employees are also encouraged to maintain a PTO balance for any unexpected absences, such as illnesses. If an employee takes PTO for illness reasons, they must contact their supervisor at least one hour before the start of their workday or as soon as possible in the case of an emergency.  In addition, time off must be submitted via Paylocity. Any employee who does not have sufficient PTO to cover an absence may be required to take day(s) off without pay. </p>

                    <h2>New Employees </h2>
                    <p>New employees begin to accrue PTO upon hire; however, under most circumstances PTO is not available to use until after 90 days of service. Following the employee’s first 90 days of service, the full balance of PTO accrued from the employee’s first day of employment to the last day of the calendar year will be made available to the employee to use, subject to supervisory approval.  For example, an employee who begins employment on July 1 will accrue PTO starting July 1 through December 31 for a total of 12 days of PTO to be used following 90 days of employment, but not later than January 31st of the following calendar year. </p>

                    <h2>PTO Rollover</h2>
                    <p>PTO must be used as actual time off within the calendar year it is earned. Unused PTO cannot be converted to payment; however, up to 40 hours of PTO that is earned in one year can be carried over to the following year but must be used no later than February 28th following the calendar year in which the PTO was earned. PTO that is not used by February 28th of the following calendar year will be lost, except where required by applicable federal, state, or local law.</p>

                    <h2>PTO at Separation from Employment</h2>
                    <p>PTO days are accrued on a weekly basis at the rate of total days available divided by 52, although the organization makes the full balance of PTO available in January of each year. Employees who leave {this.props.clientName} will be paid for accrued but unused PTO computed at the rate of pay earned upon separation, provided the employee gives two weeks written notice and is not being terminated for misconduct. Used but unaccrued PTO will be deducted from the employee’s final paycheck.</p>
                    <p>Temporary and part-time employees who are scheduled to work less than 30 hours per week are not eligible for PTO.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default PTO;
