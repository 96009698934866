import React, { Component } from 'react';
import { Form, Container, Row } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;

`

class WorkforceLead extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">

                  <div className="form-section">
                    <h5>Talent Attraction</h5>
                    <hr />

                    

                    <Form.Group controlId="formWorkforceAttraction4">
                      <Form.Label>
                        93. Describe your full recruiting process and who manages
                        what pieces?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction4 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceAttraction5">
                      <Form.Label>
                        94. Where do you keep records of your recruitment
                        activities such as job postings, interview records, job
                        application, resumes?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction5 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceAttraction6">
                      <Form.Label>
                        95. How many open positions do you currently have? 
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction6"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction6 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceAttraction7">
                      <Form.Label>95a. How long have they been open?</Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction7"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction7 || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group
                      name="formWorkforceAttraction9"
                      as={Row}
                      controlId="formWorkforceAttraction9"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        96. Do you have an internal recruiter or outside agency?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Internal"
                        value="internal recruiter"
                        name="formWorkforceAttraction9"
                        id="formWorkforceAttraction9-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction9 === "internal recruiter"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Outside Agency"
                        value="outside agency"
                        name="formWorkforceAttraction9"
                        id="formWorkforceAttraction9-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction9 === "outside agency"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceAttraction9"
                        id="formWorkforceAttraction9-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction9 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceAttraction9a">
                      <Form.Label>
                        96a. If using an outside agency: What company? What
                        type of positions do you use them for?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction9a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction9a || ""}
                      />
                    </Form.Group>
                                
                    <Form.Group controlId="formWorkforceAttraction14a">
                      <Form.Label>97. Who is involved in the interview process and how are these individuals trained?  </Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction14a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction14a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formWorkforceAttraction11"
                      as={Row}
                      controlId="formWorkforceAttraction11"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                       98. Do you use a standard offer letter?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formWorkforceAttraction11"
                        id="formWorkforceAttraction11-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction11 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formWorkforceAttraction11"
                        id="formWorkforceAttraction11-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction11 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceAttraction11"
                        id="formWorkforceAttraction11-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction11 === "unknown"
                        }
                      />
                    </Form.Group>

                    

                    <Form.Group
                      name="formWorkforceAttraction13"
                      as={Row}
                      controlId="formWorkforceAttraction13"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        99. Do you use non-compete/ non-disclosure agreements?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formWorkforceAttraction13"
                        id="formWorkforceAttraction13-1"
                        defaultChecked={
                          this.props.formWorkforceAttraction13 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formWorkforceAttraction13"
                        id="formWorkforceAttraction13-2"
                        defaultChecked={
                          this.props.formWorkforceAttraction13 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceAttraction13"
                        id="formWorkforceAttraction13-3"
                        defaultChecked={
                          this.props.formWorkforceAttraction13 === "unknown"
                        }
                      />
                    </Form.Group>

                   <hr />

                    <Form.Group controlId="formWorkforceAttraction15">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formWorkforceAttraction15"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceAttraction15 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>IndependentContractors</h5>
                    <hr />
                    <Form.Group
                      name="formWorkforceContractors1"
                      as={Row}
                      controlId="formWorkforceContractors1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        100. Do you have any workers who are consultants or
                        independent contractors?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formWorkforceContractors1"
                        id="formWorkforceContractors1-1"
                        defaultChecked={
                          this.props.formWorkforceContractors1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formWorkforceContractors1"
                        id="formWorkforceContractors1-2"
                        defaultChecked={
                          this.props.formWorkforceContractors1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceContractors1"
                        id="formWorkforceContractors1-3"
                        defaultChecked={
                          this.props.formWorkforceContractors1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceContractors1a">
                      <Form.Label>
                        100a. If so, what services do they provide?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceContractors1a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceContractors1a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceContractors1b">
                      <Form.Label>
                        100b. How long do you intend to utilize the services of
                        the independent contractor, if applicable?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceContractors1b"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceContractors1b || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceContractors1c">
                      <Form.Label>
                        100c. How many hours a week does the contractor work?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceContractors1c"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceContractors1c || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formWorkforceContractors2">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formWorkforceContractors2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceContractors2 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Temp Agencies</h5>
                    <hr />
                   
                    <Form.Group
                      name="formWorkforceTemp2"
                      as={Row}
                      controlId="formWorkforceTemp2"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        101. Do you utilize any temporary employment agencies?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formWorkforceTemp2"
                        id="formWorkforceTemp2-1"
                        defaultChecked={this.props.formWorkforceTemp2 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formWorkforceTemp2"
                        id="formWorkforceTemp2-2"
                        defaultChecked={this.props.formWorkforceTemp2 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceTemp2"
                        id="formWorkforceTemp2-3"
                        defaultChecked={
                          this.props.formWorkforceTemp2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <p>If yes:</p>
                    <Form.Group controlId="formWorkforceTemp2a">
                      <Form.Label>
                        101a. How many temporary employees (or perhaps this means
                        agencies) currently being used?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceTemp2a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceTemp2a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceTemp2b">
                      <Form.Label>
                        101b. How long do you keep your temporary employees?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceTemp2b"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceTemp2b || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceTemp2c">
                      <Form.Label>
                        101c. Are temp employees provided any company benefits?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceTemp2c"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceTemp2c || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formWorkforceTemp3">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formWorkforceTemp3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceTemp3 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Interns</h5>
                    <hr />
                    <Form.Group
                      name="formWorkforceInterns1"
                      as={Row}
                      controlId="formWorkforceInterns1"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        102. Do you employ interns throughout the year?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formWorkforceInterns1"
                        id="formWorkforceInterns1-1"
                        defaultChecked={
                          this.props.formWorkforceInterns1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formWorkforceInterns1"
                        id="formWorkforceInterns1-2"
                        defaultChecked={
                          this.props.formWorkforceInterns1 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formWorkforceInterns1"
                        id="formWorkforceInterns1-3"
                        defaultChecked={
                          this.props.formWorkforceInterns1 === "unknown"
                        }
                      />
                    </Form.Group>
                    <p>If yes:</p>
                    <Form.Group controlId="formWorkforceInterns1a">
                      <Form.Label>102a. Are they paid or unpaid?</Form.Label>
                      <Form.Control
                        name="formWorkforceInterns1a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceInterns1a || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formWorkforceInterns1b">
                      <Form.Label>
                        102b. If unpaid, are they working through an accredited
                        college or high school?
                      </Form.Label>
                      <Form.Control
                        name="formWorkforceInterns1b"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceInterns1b || ""}
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formWorkforceInterns2">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formWorkforceInterns2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formWorkforceInterns2 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default WorkforceLead;