import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class GeneralHiringReq extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>General Hiring Requirements for Healthcare Employees</h1>
                    <h2>Employee Licensure</h2>
                    <p>Some positions require specific licensure that may involve ongoing training in order to keep licensure in effect.  It is the employee’s responsibility to maintain required licensure as set forth in their job description and to pay any associated fees with required licensure.  Failure to maintain required licensure may result in additional action, up to and including termination.</p>

                    <h2>Employee Medical Examinations</h2>
                    <p>Medical examinations are not required as part of the normal hiring procedure.  However, {this.props.clientName} requires certain employees to adhere to immunization policies identified below.  {this.props.clientName} periodically offers the Tuberculosis (TB) testing on an “entire staff” basis.  Employees are encouraged to participate or to consult their own physicians to meet the testing requirements.</p>

                    <h2>Employee Immunizations</h2>
                    <h3><i>Hepatitis B Immunization: Preexposure</i></h3>
                    <p>This policy establishes guidelines for providing Hepatitis B immunization to {this.props.clientName} employees who are known to be at occupational risk of exposure to hepatitis B infection.</p>
                    <p>All employees, -contractors or volunteer, who have frequent, direct contact with blood or blood products during the course of their employment are considered to be at high or moderate risk of hepatitis B infection.  These employees are eligible for free vaccination.   It will be the employee’s responsibility to complete the scheduled three-dose series.  Employees who do not return for their subsequent doses within the required time, but still wish to continue with the vaccine, will be required to purchase any doses in excess of the initial three necessary to complete the series.</p>
                    <p>All employees, contractors and volunteers who handle blood includes:  phlebotomists, and nurses, physician assistants, dental assistants, dental hygienists, physicians and dentists.</p>
                    <h3><i>Implementation</i></h3>
                    <p>
                        <ol>
                            <li>This hepatitis B vaccination is a series of three I.M. (intramuscular) injections of 1cc Recombivax of Heptavax B.  The first injection is given and then followed by reinforcing doses at one month and at six months.  It will be the employee’s responsibility to complete the scheduled three-dose series.</li>
                            <li>Employees who terminate their employment from Company Name prior to the completion of the series may purchase the remaining doses from the Clinic at cost plus handling.  Employees not eligible for free vaccination may purchase it at cost plus handling through the Clinic</li>
                            <li>Employees designated to be at risk of exposure who do not wish to receive the vaccine will sign a form acknowledging the vaccine’s availability to them and their refusal to accept it.</li>
                            <li>Employees who have received the vaccine previously will be requested to sign a statement to that effect, and provide documentation attesting to the receipt of the vaccine.</li>
                            <li>Prior to administering the vaccine, the administering nurse will discuss and perform the following steps with the employee.
                                <ol>
                                    <li>
                                        <ul>Obtain the following information to determine whether there are any contraindications to administering hepatitis B immunization:
                                            <li>History of hepatitis B and/or history of receiving the vaccine.</li>
                                            <li>Current upper respiratory infection or other active infection.</li>
                                        </ul>
                                    </li>
                                    <li>Obtain a history of immunodeficiency or of immunosuppressive therapy.</li>
                                    <li>Ensure that the employee has received the information on the vaccine and responds to questions.</li>
                                    <li>Obtain employee consent for administration.  Recombinant vaccine, Recombivax, will be given unless contraindicated.  All three doses must be of the same vaccine, they cannot be interchanged.</li>
                                    <li>Shake the vial before withdrawal and use.  Administer Recombivax 1cc I.M.</li>
                                    <li>Observe employee for any systemic allergic reaction.</li>
                                </ol>
                            </li>
                        </ol>
                    </p>
                    <p>In case of reaction, notify emergency personnel immediately.</p>
                    <h3><i>Employee PPD Testing</i></h3>
                    <p>In the interest of employee safety, health and welfare, all employees, contractors and volunteers of Company Name, who have a Category I Classification based on the OSHA Standard for Blood Borne Pathogens, should be tested and monitored for possible, potential exposure to Tuberculosis (TB) through close client contact.  It should not be necessary to test employees, contractors and volunteers who have only casual contact with clients.</p>
                    <p>All employees, contractors, and volunteers shall receive a baseline PPD test prior to potential exposure and annually thereafter.  Current employees will be given a baseline PPD test.
                        <ol>
                            <li>1.	Seronegative (HIV Negative) employees shall receive 0.1 ml Tuberculin Purified Protein Derivative (Mantoux PPD).</li>
                            <li>2.	Seropositive (HIV Positive) employees shall receive 0.1 ml Tuberculin Purified Protein Derivative (Mantoux PPD), with Mumps Antigen as the control.</li>
                            <li>3.	The test material shall be infected intradermally to the volar aspect of the forearm.  Hairy areas or areas without adequate subcutaneous tissue should be avoided.</li>
                            <li>4.	The test shall be read by a licensed nurse or physician when available within 48 hours of test administration.</li>
                            <li>5.	Employees with a positive reading shall be immediately referred for a chest x-ray.</li>
                        </ol>
                    </p>
                    <p>*NOTE When employees are HIV POSITIVE, Company Name PPD Testing Protocol with the control must be followed</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default GeneralHiringReq;
