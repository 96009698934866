import React, { Component } from 'react';


class BreakdownTotals extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalHours: 0
        }
    }

    render() {
        return (
        

            <tr key={this.props.breakdownId}>
                <td>
                    {this.props.employee}
                </td>
                <td>
                    {this.props.total_hours}
                </td>
                <td>
                    ${this.props.total_costs?.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </td>
                <td>
                    ${this.props.total_bill?.toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </td>
                <td>
                    {this.props.margin?.toFixed(2)*100}%
                </td>       
            </tr>        
        )
    }
}

export default BreakdownTotals;