import React, { Component } from 'react';
import { Form, Container, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../Config/Theme";

const Div = styled.div`
    background-color: white;
    color: black;
    
    
`

class RiskLead extends Component {
    

    render() {
        return (
          <ThemeProvider theme={theme}>
            <Div>
              <Container>
                <Form className="p-3">
                  
                  <div className="form-section">
                    <h5>Posters/Required Notices</h5>
                    <hr />
                    <Form.Group controlId="formRiskNotices1">
                      <Form.Label>
                        41. Where do you place the postings of federal and state
                        wage and hour laws, reporting injuries, workers'
                        compensation and similar required posting?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskNotices1"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskNotices1 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskNotices2">
                      <Form.Label>
                        41a. Is the location frequented by all employees?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskNotices2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskNotices2 || ""}
                      />
                    </Form.Group>
                                
                    <Form.Group controlId="formRiskNotices4">
                      <Form.Label>
                        42.	How do you provide these notices to remote workers, if applicable?
                      </Form.Label>
                      <Form.Control
                        name="formRiskNotices4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskNotices4 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskNotices3">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRiskNotices3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskNotices3 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>
                      EEO-1 Reporting (applicable only to those with 100+
                      employees) & Affirmative Action Plans(AAP)
                    </h5>
                    <hr />
                    <Form.Group
                      name="formRiskEEO1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskEEO1"
                    >
                      <Form.Label column sm={5}>
                        43. Does any part of the organization perform work on a
                        contract with or for any agency of the United State
                        government?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO1"
                        id="formRiskEEO1-1"
                        defaultChecked={this.props.formRiskEEO1 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO1"
                        id="formRiskEEO1-2"
                        defaultChecked={this.props.formRiskEEO1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO1"
                        id="formRiskEEO1-3"
                        defaultChecked={this.props.formRiskEEO1 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskEEO2">
                      <Form.Label>
                        43a. What is the current amount of the contract?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskEEO2"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskEEO2 || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskEEO3"
                      as={Row}
                      controlId="formRiskEEO3"
                      onChange={this.props.handleChange}
                    >
                      <Form.Label column sm={5}>
                        43b. Do you have AAP?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO3"
                        id="formRiskEEO3-1"
                        defaultChecked={this.props.formRiskEEO3 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO3"
                        id="formRiskEEO3-2"
                        defaultChecked={this.props.formRiskEEO3 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO3"
                        id="formRiskEEO3-3"
                        defaultChecked={this.props.formRiskEEO3 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskEEO4">
                      <Form.Label>
                        43c. What are your goals for the current AAP plan year?{" "}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="4"
                        name="formRiskEEO4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskEEO4 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskEEO5">
                      <Form.Label>
                        44d. Describe your outreach plan to address goals?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskEEO5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskEEO5 || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskEEO6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskEEO6"
                    >
                      <Form.Label column sm={5}>
                        43e. Are you posting your open jobs with the state
                        workforce agency job bank?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO6"
                        id="formRiskEEO6-1"
                        defaultChecked={this.props.formRiskEEO6 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO6"
                        id="formRiskEEO6-2"
                        defaultChecked={this.props.formRiskEEO6 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO6"
                        id="formRiskEEO6-3"
                        defaultChecked={this.props.formRiskEEO6 === "unknown"}
                      />
                    </Form.Group>
                    <Form.Group
                      name="formRiskEEO7"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskEEO7"
                    >
                      <Form.Label column sm={5}>
                        43f. Do all of your employment postings include the EEO
                        tagline?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO7"
                        id="formRiskEEO7-1"
                        defaultChecked={this.props.formRiskEEO7 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO7"
                        id="formRiskEEO7-2"
                        defaultChecked={this.props.formRiskEEO7 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO7"
                        id="formRiskEEO7-3"
                        defaultChecked={this.props.formRiskEEO7 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskEEO8">
                      <Form.Label>
                        43g. How are you capturing EEO data(self ID form, visual
                        determination)?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskEEO8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskEEO8 || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskEEO9"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskEEO9"
                    >
                      <Form.Label column sm={5}>
                        43h. Are you filing the annual EEO-1 report(if 100 or more
                        employees or 50+ employees with federal contracts of
                        $50k+)?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO9"
                        id="formRiskEEO9-1"
                        defaultChecked={this.props.formRiskEEO9 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO9"
                        id="formRiskEEO9-2"
                        defaultChecked={this.props.formRiskEEO9 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO9"
                        id="formRiskEEO9-3"
                        defaultChecked={this.props.formRiskEEO9 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskEEO10"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskEEO10"
                    >
                      <Form.Label column sm={5}>
                        43i. Are you filing the Vets-4212 report(formerly
                        Vets-100), (if a federal contractor and subcontractor of
                        $150k regardless of size?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskEEO10"
                        id="formRiskEEO10-1"
                        defaultChecked={this.props.formRiskEEO10 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskEEO10"
                        id="formRiskEEO10-2"
                        defaultChecked={this.props.formRiskEEO10 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskEEO10"
                        id="formRiskEEO10-3"
                        defaultChecked={this.props.formRiskEEO10 === "unknown"}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskEEO11">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRiskEEO11"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskEEO11 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Employment Classification</h5>
                    <hr />
                    

                    <Form.Group controlId="formRiskClassification2">
                      <Form.Label>
                        44. How do you determine which employees are exempt from
                        overtime?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskClassification2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskClassification2 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskClassification4">
                      <Form.Label>
                        45. How do you track the hours worked by nonexempt
                        workers?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskClassification4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskClassification4 || ""}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskClassification5">
                      <Form.Label>
                        46. How do you pay your independent contractors?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskClassification5"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskClassification5 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group
                      name="formRiskClassification6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskClassification6"
                    >
                      <Form.Label column sm={5}>
                        47.	Do you automatically deduct meal and rest breaks?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskClassification6"
                        id="formRiskClassification6-1"
                        defaultChecked={
                          this.props.formRiskClassification6 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskClassification6"
                        id="formRiskClassification6-2"
                        defaultChecked={
                          this.props.formRiskClassification6 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskClassification6"
                        id="formRiskClassification6-3"
                        defaultChecked={
                          this.props.formRiskClassification6 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskClassification7"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskClassification7"
                    >
                      <Form.Label column sm={5}>
                        48. Do you practice the use of compensatory time?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskClassification7"
                        id="formRiskClassification7-1"
                        defaultChecked={
                          this.props.formRiskClassification7 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskClassification7"
                        id="formRiskClassification7-2"
                        defaultChecked={
                          this.props.formRiskClassification7 === "no"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskClassification7"
                        id="formRiskClassification7-3"
                        defaultChecked={
                          this.props.formRiskClassification7 === "unknown"
                        }
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskClassification8">
                      <Form.Label>
                        49. If you have independent contractors, who supervises
                        their work?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskClassification8"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskClassification8 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskClassification9">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRiskClassification9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskClassification9 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Americans with Disabilities Act(ADA)</h5>
                    <hr />
                    <Form.Group
                      name="formRiskADA1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskADA1"
                    >
                      <Form.Label column sm={5}>
                        50. Do you have any employees with a known or suspected
                        disability?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskADA1"
                        id="formRiskADA1-1"
                        defaultChecked={this.props.formRiskADA1 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskADA1"
                        id="formRiskADA1-2"
                        defaultChecked={this.props.formRiskADA1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskADA1"
                        id="formRiskADA1-3"
                        defaultChecked={this.props.formRiskADA1 === "unknown"}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskADA2">
                      <Form.Label>
                        50a. If yes to the previous question, how do you
                        communicate to an employee that they have been approved
                        or denied for a specific accommodation request?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskADA2"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskADA2 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskADA3">
                      <Form.Label>
                        51. Who oversees the management of medical issue
                        notices/requests within your company?{" "}
                      </Form.Label>
                      <Form.Control
                        name="formRiskADA3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskADA3 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskADA4">
                      <Form.Label>Comments: </Form.Label>
                      <Form.Control
                        name="formRiskADA4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskADA4 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Compliance Training</h5>
                    <hr />
                    <Form.Group
                      name="formRiskCompliance1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskCompliance1"
                    >
                      <Form.Label column sm={5}>
                        52. Do you provide harassment prevention training to
                        employees and managers?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-1"
                        defaultChecked={
                          this.props.formRiskCompliance1 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-2"
                        defaultChecked={this.props.formRiskCompliance1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskCompliance1"
                        id="formRiskCompliance1-3"
                        defaultChecked={
                          this.props.formRiskCompliance1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskCompliance1a">
                      <Form.Label>52a. If so, how often </Form.Label>
                      <Form.Control
                        name="formRiskCompliance1a"
                        as="textarea"
                        rows="1"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance1a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskCompliance2"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskCompliance2"
                    >
                      <Form.Label column sm={5}>
                        52b. Do you have a separate training for managers?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskCompliance2"
                        id="formRiskCompliance2-1"
                        defaultChecked={
                          this.props.formRiskCompliance2 === "yes"
                        }
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskCompliance2"
                        id="formRiskCompliance2-2"
                        defaultChecked={this.props.formRiskCompliance2 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskCompliance2"
                        id="formRiskCompliance2-3"
                        defaultChecked={
                          this.props.formRiskCompliance2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <hr />
                    <Form.Group controlId="formRiskCompliance3">
                      <Form.Label>
                        53. What other types of training do you provide
                        employees?
                      </Form.Label>
                      <Form.Control
                        name="formRiskCompliance3"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance3 || ""}
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskCompliance4">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRiskCompliance4"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskCompliance4 || ""}
                      />
                    </Form.Group>
                  </div>

                  <div className="form-section">
                    <h5>Safety</h5>
                    <hr />
                    <Form.Group
                      name="formRiskSafety1"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety1"
                    >
                      <Form.Label column sm={5}>
                        54. Does your work environment create any inherent risks
                        to employees?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety1"
                        id="formRiskSafety1-1"
                        defaultChecked={this.props.formRiskSafety1 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety1"
                        id="formRiskSafety1-2"
                        defaultChecked={this.props.formRiskSafety1 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety1"
                        id="formRiskSafety1-3"
                        defaultChecked={
                          this.props.formRiskSafety1 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskSafety1a">
                      <Form.Label>54a. If so, what are those risks?</Form.Label>
                      <Form.Control
                        name="formRiskSafety1a"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskSafety1a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety2"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety2"
                    >
                      <Form.Label column sm={5}>
                        55. Are you a workers’ compensation subscriber?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety2"
                        id="formRiskSafety2-1"
                        defaultChecked={this.props.formRiskSafety2 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety2"
                        id="formRiskSafety2-2"
                        defaultChecked={this.props.formRiskSafety2 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety2"
                        id="formRiskSafety2-3"
                        defaultChecked={
                          this.props.formRiskSafety2 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group controlId="formRiskSafety2a">
                      <Form.Label>
                        55a. If not, what type of insurance do you maintain for
                        employee injuries?
                      </Form.Label>
                      <Form.Control
                        name="formRiskSafety2a"
                        as="textarea"
                        rows="2"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskSafety2a || ""}
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety3"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety3"
                    >
                      <Form.Label column sm={5}>
                        56. Do you have established procedures for employees to
                        follow in the event of a workplace injury or illness?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety3"
                        id="formRiskSafety3-1"
                        defaultChecked={this.props.formRiskSafety3 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety3"
                        id="formRiskSafety3-2"
                        defaultChecked={this.props.formRiskSafety3 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety3"
                        id="formRiskSafety3-3"
                        defaultChecked={
                          this.props.formRiskSafety3 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety4"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety4"
                    >
                      <Form.Label column sm={5}>
                        57. Is your business exempt from OSHA record keeping?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety4"
                        id="formRiskSafety4-1"
                        defaultChecked={this.props.formRiskSafety4 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety4"
                        id="formRiskSafety4-2"
                        defaultChecked={this.props.formRiskSafety4 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety4"
                        id="formRiskSafety4-3"
                        defaultChecked={
                          this.props.formRiskSafety4 === "unknown"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      name="formRiskSafety4a"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety4a"
                    >
                      <Form.Label column sm={5}>
                        57a. If not, does your company currently complete and/ or
                        post the OSHA 300, 301 and 300A Log for all locations?
                        Exempt industries are those with 10 or less employees or
                        in exempt industries.
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety4a"
                        id="formRiskSafety4a-1"
                        defaultChecked={this.props.formRiskSafety4a === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety4a"
                        id="formRiskSafety4a-2"
                        defaultChecked={this.props.formRiskSafety4a === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety4a"
                        id="formRiskSafety4a-3"
                        defaultChecked={
                          this.props.formRiskSafety4a === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety5"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety5"
                    >
                      <Form.Label column sm={5}>
                        58. Do you provide safety training?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety5"
                        id="formRiskSafety5-1"
                        defaultChecked={this.props.formRiskSafety5 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety5"
                        id="formRiskSafety5-2"
                        defaultChecked={this.props.formRiskSafety5 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety5"
                        id="formRiskSafety5-3"
                        defaultChecked={
                          this.props.formRiskSafety5 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety6"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety6"
                    >
                      <Form.Label column sm={5}>
                        59. Do you have an infection control or COVID-19 specific
                        policy?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety6"
                        id="formRiskSafety6-1"
                        defaultChecked={this.props.formRiskSafety6 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety6"
                        id="formRiskSafety6-2"
                        defaultChecked={this.props.formRiskSafety6 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety6"
                        id="formRiskSafety6-3"
                        defaultChecked={
                          this.props.formRiskSafety6 === "unknown"
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      name="formRiskSafety7"
                      onChange={this.props.handleChange}
                      as={Row}
                      controlId="formRiskSafety7"
                    >
                      <Form.Label column sm={5}>
                        60. Do you have a safety manual?
                      </Form.Label>
                      <Form.Check
                        inline
                        type="radio"
                        label="Yes"
                        value="yes"
                        name="formRiskSafety7"
                        id="formRiskSafety7-1"
                        defaultChecked={this.props.formRiskSafety7 === "yes"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="No"
                        value="no"
                        name="formRiskSafety7"
                        id="formRiskSafety7-2"
                        defaultChecked={this.props.formRiskSafety7 === "no"}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Unknown"
                        value="unknown"
                        name="formRiskSafety7"
                        id="formRiskSafety7-3"
                        defaultChecked={
                          this.props.formRiskSafety7 === "unknown"
                        }
                      />
                    </Form.Group>
                    <hr />

                    <Form.Group controlId="formRiskSafety9">
                      <Form.Label>Comments:</Form.Label>
                      <Form.Control
                        name="formRiskSafety9"
                        as="textarea"
                        rows="4"
                        onChange={this.props.handleChange}
                        value={this.props.formRiskSafety9 || ""}
                      />
                    </Form.Group>
                  </div>
                </Form>
              </Container>
            </Div>
          </ThemeProvider>
        );
    }
}

export default RiskLead;