import React, {Component} from 'react';
import { Row, Col, Jumbotron, Button, Form} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { config } from '../../Config/Constants';
import Project from './Project';

const API_URL = config.url.API_URL;


const Div = styled.div`
    color: black;
    padding: 2em;

    .big-btn{
        color: #07485c;
        border: 2px solid #07485c;
        border-radius: 1rem;
        &:hover{
            color: ${(props) => props.theme.colors.orange};
            border: 2px solid ${(props) => props.theme.colors.orange};
        }
    }

    .sml-btn{
        color: #07485c;
        border: none;
        &:hover{
            color: ${(props) => props.theme.colors.orange};
        }
    }

    .project{
        border:1px solid ${(props) => props.theme.colors.dark_blue};
        padding: 1rem;
    }
`

const ProjectButton = styled(Button)`
    margin: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.dark_blue};
    border: 1px solid ${(props) => props.theme.colors.dark_blue};
    &:hover {
        color: ${(props) => props.theme.colors.orange};
        background: ${(props) => props.theme.colors.dark_blue};
        border: 1px solid ${(props) => props.theme.colors.dark_blue};
    }
`


class BillingCalculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: "start",
            cost: 0,
            toBill: 0,
            formValidation: false,

            billProjId: 0,
            lastBillProjId: 0,
            projectRateType: "",

            //Create
            client: "",
            source: "",
            projectName: "",

            //Select
            allProjects: [],
            clientsWithProjects: []

        }

        this.newProject = this.newProject.bind(this);
        this.loadProject = this.loadProject.bind(this);
        this.cancelProject = this.cancelProject.bind(this);
        this.selectProject = this.selectProject.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }


    newProject() {
        this.setState({ mode: "new" });
    }

    selectProject() {
        this.setState({ mode: "select" });
        this.getAllProjects();
    }

    loadProject(projectId, projectName, projectRateType) {
        this.setState({ billProjId: projectId });
        this.setState({ projectName: projectName });
        this.setState({ projectRateType: projectRateType }, this.setState({mode:"project"}));
    }

    cancelProject() {
        this.setState({ mode: "start" });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit(event){
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({ formValidation: true });
        if (form.checkValidity() === true) {
            this.createBillProject();
        }
    }

    // CREATE NEW BILLING PROJECT

    createBillProject() {
        fetch(API_URL + `/billingcalculator/create`, {
            method: "PUT",
            body: JSON.stringify({
                client: this.state.client,
                source: this.state.source,
                projectName: this.state.projectName
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err))
            .then(() => this.getBillProjLastId())
            .then(() => this.loadProject());
    }
    
    getBillProjLastId() {
    fetch(API_URL + `/billingcalculator/getLastId`)
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }
        return res.json();
      })
      .then((result) => {
          const id = result[0].max_project_id;
          this.setState({ lastBillProjId: result[0].max_project_id });
          this.setState({ billProjId: id });
          console.log(`Latest Bill Project Id: ${id}`);
          console.log(`Project Id in state is now: ${this.state.billProjId}`);
      }).catch((error) => {
        console.log(error);
      }
    )
    }
    
    // Load Projects
    getAllProjects() {
        fetch(API_URL + `/billingcalculator/projects/all`)
            .then((res) => {
                if (!res.ok) {
                throw new Error()
                }
                return res.json()
            })
            .then((result) => {
                this.setState({ allProjects: result });
                console.log(result);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(
            fetch(API_URL + `/billingcalculator/clients/distinct`)
              .then((res) => {
                if (!res.ok) {
                  throw new Error();
                }
                return res.json();
              })
              .then((result) => {
                this.setState({ clientsWithProjects: result});
                console.log(result);
              })
              .catch((error) => {
                console.log(error);
              })
          )            
    }

    

    render() {

        
        return (
            <ThemeProvider theme={theme}>

                    <Div>
                        

                    {this.state.mode === "start" && 
                        <div>
                            <Jumbotron className="mt-3">
                                <h1 className='display-4'>Billing Calculator</h1>
                                <p className='lead'>Bill your project perfectly every time!<i className="fa-duotone fa-money-bill-1-wave fa-2x ml-3"></i> </p>
                            </Jumbotron>
                                <Jumbotron className="mt-5">
                                <h1 className='lead'>What would you like to do?</h1>
                                <Row className='mt-5'>
                                    
                                    <Col className='text-center'>
                                        <button className='big-btn px-5 py-5' onClick={this.newProject}>
                                            <i className="fa-duotone fa-file-circle-plus fa-10x fa-fw"></i>
                                            <p className='lead mt-3'>New Project</p>
                                        </button>
                                    </Col>
                                    <Col className='text-center'>
                                        <button className='big-btn px-5 py-5' onClick={this.selectProject}>
                                            <i className="fa-duotone fa-floppy-disk-pen fa-10x fa-fw"></i>
                                            <p className='lead mt-3'>Load Project</p>
                                        </button>
                                        
                                    </Col>
                                </Row>
                            </Jumbotron>
                        </div>
                            
                        }

                    {this.state.mode === "new" &&
                        <div>
                            <Jumbotron className="mt-3">
                                <h1 className='display-4'>Billing Calculator</h1>
                                <p className='lead'>Bill your project perfectly every time!<i className="fa-duotone fa-money-bill-1-wave fa-2x ml-3"></i> </p>
                            </Jumbotron>
                        
                            <Jumbotron className="mt-5">
                                <h1 className='lead'>Make a New Billing Project</h1>
                                <hr />
                                <Form noValidate validated={this.state.formValidation} onSubmit={this.handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="validationClientName">
                                        <Form.Label>Client Name</Form.Label>
                                        <Form.Control
                                            name="client"
                                            required
                                            type="text"
                                            placeholder="Client Name"
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide the Client's name.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="validationSource">
                                        <Form.Label>Source</Form.Label>
                                        <Form.Control
                                            name='source'
                                            required
                                            type="text"
                                            placeholder="Where did client come from?"
                                            onChange={this.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a where the client originated from.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationProjectName">
                                        <Form.Label>Project Name - do not include a date in the name</Form.Label>
                                        <Form.Control
                                            name='projectName'
                                            type="text" placeholder="Project Name"
                                            onChange={this.handleChange} required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a Project Name.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                    </Form>
                                <hr />
                                <div className='text-right'>
                                    <Button variant="danger mr-3" onClick={this.cancelProject}>Cancel</Button>
                                    <Button variant="primary" onClick={this.handleSubmit} type="submit">Create Project</Button>
                                </div>
                            </Jumbotron>
                        </div>
                        }

                        
                    {this.state.mode === "select" &&
                        <div>
                            <Jumbotron className="mt-3">
                                <h1 className='display-4'>Billing Calculator</h1>
                                <p className='lead'>Bill your project perfectly every time!<i className="fa-duotone fa-money-bill-1-wave fa-2x ml-3"></i> </p>
                            </Jumbotron>
                        
                            <Jumbotron className="mt-5">
                                <h1 className='lead'>Load a Billing Project</h1>
                                <hr />
                                <div>
                                    {this.state.clientsWithProjects.map((client) => (
                                        <div className="mb-3 project"
                                            key={client.client_name}>
                                            <Row>
                                                <Col>
                                                    <h5>{client.client_name}</h5>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className='d-flex'>
                                                    {this.state.allProjects.filter((project) => project.client_name === client.client_name).map((filteredProject) => (
                                                        
                                                            <ProjectButton
                                                                key={filteredProject.project_id}
                                                                onClick={() => this.loadProject(filteredProject.project_id, filteredProject.project_name, filteredProject.project_type)}
                                                            >
                                                            <div>
                                                                <p>
                                                                {filteredProject.project_name}
                                                                </p>
                                                                <p>{new Date(
                                                                    filteredProject.created_at).toLocaleDateString(
                                                                )}</p>
                                                            </div>
                                                        </ProjectButton>
                                                    
                                                    ))}
                                                </div>
                                            </Row>
                                        </div>
                                    ))}
                                </div>
                                
                                <hr />
                                <div className='text-right'>
                                    <Button variant="danger mr-3" onClick={this.cancelProject}>Cancel</Button>
                                </div>
                            </Jumbotron>
                        </div>
                        }

                    {this.state.mode === "project" &&
                        <div>
    
                                <Project
                                    projectName={this.state.projectName}
                                    billProjId={this.state.billProjId}
                                />
                                <div className='text-right'>
                                    <Button variant="danger mr-3" onClick={this.cancelProject}>Start Over</Button>
                                </div>
                        </div>
                            
                        }
                    </Div>
                
            </ThemeProvider>
        )
    }
}

export default BillingCalculator;