import React from 'react';
// import StatsFullCurrentYear from './StatsFullCurrentYear';
// import StatsFullLastYear from './StatsFullLastYear';
// import StatsSummaryCurrentYear from './StatsSummaryCurrentYear';
// import StatsSummaryLastYear from './StatsSummaryLastYear';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import { useAuth0 } from '@auth0/auth0-react';

const CandSurveyStats = () => {

    const { user } = useAuth0();
    

    if (user.name === "anorton@pprhr.com" || user.name === "gnorton@pprhr.com" || user.name === "kbenson@pprhr.com" || user.name === "jkelly@pprhr.com") {
        return (
            <ThemeProvider theme={theme}>
                <div>
                    Under Development:  Will be here by 8/10/2023
                </div>
            </ThemeProvider>
            )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <div>
                    Under Development:  Will be here by 8/10/2023
                </div>
            </ThemeProvider>
        )
    }

}

export default CandSurveyStats;