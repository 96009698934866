import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Letter extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Welcome to {this.props.clientName}</h1>
                    <p>You are now a member of the team of the exclusive importer and distributor of the Client brand for the U.S., one of the best and most well-known brands of corn based products. We are also a key distributor and representative for other brands of non-perishable foods in the U.S.</p>
                    <p>Since 1995, we at Client have been dedicated to the distribution and marketing of high quality food items within the global market. Quality is not just a policy but a standard upon which we continually seek to improve.</p>
                    <p>For more than 20 years Client has demonstrated a continual and consistent growth within the Hispanic and mainstream consumer base through our professional group of employees, distributors and brokers servicing more than 45 states in the U.S. and Canada.</p>
                    <p>By joining Client, we hope you will take pride in our rich history and use this opportunity to enrich your career and further your personal goals and ambitions. Your success is our success. We look forward to a mutually rewarding relationship. </p>
                    <p>Welcome to our winning team!</p>
                    <p>John Doe</p>
                    <p>General Manager</p>
                </Div>
            </ThemeProvider>
            
        )
            
    }
}

export default Letter;
