import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Whistleblower extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Whistleblower Protection</h1>
                    <p>{this.props.clientName} has an Open-Door Communication policy and encourages all employees to share their questions, concerns, suggestions, or complaints internally so that {this.props.clientName} can address and correct inappropriate conduct and actions.  It is the responsibility of all employees to report concerns and about suspected violations of law or regulations that govern {this.props.clientName} operations.  Employees should first express concerns to their supervisor.  Employees who are not comfortable speaking with a supervisor or who are not satisfied with a supervisor’s response, are encouraged to speak with Human Resources or the President and CEO.  If any employee reasonably believes that some policy, practice, or activity of {this.props.clientName} is in violation of law, a written complaint may be filed by that employee with the Chairman of the Audit Committee.</p>

                    <p>It is contrary to the values of {this.props.clientName} for anyone to retaliate against any employee who in good faith reports an alleged unlawful activity, policy, or practice to the attention of {this.props.clientName} and provides {this.props.clientName} with a reasonable opportunity to investigate and correct the alleged unlawful activity.  </p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Whistleblower;
