import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Benefits extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Benefits</h1>
                    <p>Eligible employees are provided a range of benefits including medical, dental, vision, 401(k), long-term disability, short-term disability, life, AD&D and paid time off benefits. These benefits are only briefly referenced here.  Temporary employees and part-time employees who are scheduled to work less than 30 hours per week are not eligible for medical, dental, long term disability, life and AD&D benefits.  If an eligible employee has questions concerning any of the benefits plans they should:
                        <ul>
                            <li>Ask People Performance Resources for a copy of the plan document or pamphlet,</li>
                            <li>Contact the appropriate customer service department of the insurance carrier</li>
                            <li>Consult the appropriate insurance or plan pamphlet or documents, and/or</li>
                            <li>Access available insurance or plan information online, to the extent available.</li>
                        </ul>
                    </p>

                    <p>Open enrollment for benefits occurs each September.  Employees who have declined coverage in the past for themselves or dependents may enroll during the open enrollment period and coverage will go into effect on October 1.  Otherwise, eligible employees who have declined coverage for themselves or dependents will have to wait until the next open enrollment period to join the plan.   </p>

                    <p>An employee who has a qualifying event—divorce, death of a spouse, change in employment status (for employee or dependent), birth or adoption of a child—throughout the year, has 30 days from the time of the event to join the plan.  </p>

                    <p>{this.props.clientName} reserves the right to change, revise and/or discontinue any and all employee benefits, with or without notice, subject to any limitations imposed by law. The actual plan documents for the benefits described in this handbook contain the final word about plan terms, conditions, benefits and eligibility and supersede this handbook and any of the company’s policies or statements to the extent they are inconsistent with such plan documents.</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Benefits;
