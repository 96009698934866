import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class OutsideEmployment extends Component {
   

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Outside Employment</h1>
                    <p>Employees agree to devote their full working day exclusively to the business of {this.props.clientName}, and unless an employee has received written consent from the President and CEO, the employee may not engage in any activity which conflicts with the interests of {this.props.clientName} or the employee’s duties at {this.props.clientName}.  Outside employment will not be considered an excuse for poor job performance, absenteeism, tardiness, leaving early, refusal to travel, or refusal to work overtime or different hours. If outside work activity causes or contributes to job-related problems at {this.props.clientName}, the employee will be asked to discontinue the outside employment, and the employee may be subject to the normal disciplinary procedures for dealing with the resulting job-related problem(s). Employees may not use {this.props.clientName} paid sick leave to perform work for another employer. The employee may not use his or her association with {this.props.clientName} as information to support activities related to the outside employment and may not use the {this.props.clientName} name or letterhead or any facilities or other resources of {this.props.clientName} in activities related to the outside employment, unless such use is specifically approved in writing by the President and CEO.  </p>

                </Div>
            </ThemeProvider>
        ) 
    }
}

export default OutsideEmployment;
