import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class EligForEmploy extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Eligibility</h1>
                    <p>Each new employee, as a condition of employment, must complete the Employment Eligibility Verification Form I-9 and present documentation establishing identity and employment eligibility on the first day of work.  If a new employee is unable to provide the necessary documentation within three working days from the date of hire, they must provide proof that they have applied for the required documents. If this is not provided, the employee will be terminated.</p>
                    <p>If an employee is authorized to work in this country for a limited time period, the individual will be required to submit proof of renewed employment eligibility prior to expiration of that period to remain employed by {this.props.clientName}</p>
                    
                </Div>
            </ThemeProvider>
            
        )
            
    }
}

export default EligForEmploy;
