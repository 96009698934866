import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class EmployeeEngagement extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Employee Engagement</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Strongly Disagree and
                                5-Strongly Agree...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formEngagementCommitted"
                                controlId="formEngagementCommitted"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    3. Employees are committed to the organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formEngagementCommitted"
                                    id="formEngagementCommitted-1"
                                    defaultChecked={this.props.formEngagementCommitted === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formEngagementCommitted"
                                    id="formEngagementCommitted-2"
                                    defaultChecked={this.props.formEngagementCommitted === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formEngagementCommitted"
                                    id="formEngagementCommitted-3"
                                    defaultChecked={this.props.formEngagementCommitted === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formEngagementCommitted"
                                    id="formEngagementCommitted-4"
                                    defaultChecked={this.props.formEngagementCommitted === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formEngagementCommitted"
                                    id="formEngagementCommitted-5"
                                    defaultChecked={this.props.formEngagementCommitted === 5}
                                />
                                </Form.Group>

                                <Form.Group
                                as={Row}
                                name="formEngagementSatisfied"
                                controlId="formEngagementSatisfied"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    3. Employees are committed to the organization.
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formEngagementSatisfied"
                                    id="formEngagementSatisfied-1"
                                    defaultChecked={this.props.formEngagementSatisfied === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formEngagementSatisfied"
                                    id="formEngagementSatisfied-2"
                                    defaultChecked={this.props.formEngagementSatisfied === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formEngagementSatisfied"
                                    id="formEngagementSatisfied-3"
                                    defaultChecked={this.props.formEngagementSatisfied === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formEngagementSatisfied"
                                    id="formEngagementSatisfied-4"
                                    defaultChecked={this.props.formEngagementSatisfied === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formEngagementSatisfied"
                                    id="formEngagementSatisfied-5"
                                    defaultChecked={this.props.formEngagementSatisfied === 5}
                                />
                                </Form.Group>
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default EmployeeEngagement;