import React, { Component } from 'react';
import { Row, Col, Modal, Table, Form, Button } from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../Config/Theme';
import Breakdown from './Breakdown';
import BreakdownTotals from './BreakdownTotals';
import { config } from '../../Config/Constants';


const API_URL = config.url.API_URL;

const Div = styled.div`
    color: black;
    border:1px solid ${(props) => props.theme.colors.dark_blue};
    padding: 1rem;
    margin-bottom: 3rem;
`

class Section extends Component {
    constructor(props) {
        super(props);

        this.state = {
            breakdowns: [],
            totalSecHours: 0,
            totalSecCost: 0,
            totalSecBill: 0,
            totalSecMargin: 0,
            showTitleModal: false,
            sectionTitle: "",
        }

        this.addNewBreakdown = this.addNewBreakdown.bind(this);
    }

    componentDidMount() {
        this.getSectionBreakdowns(this.props.sectionId);
    }

    getSectionBreakdowns = (secId) => {
        fetch(API_URL + `/billingcalculator/breakdowns/${secId}`)
            .then((res) => {
                if (!res.ok) {
                throw new Error()
                }
                return res.json()
            })
            .then((result) => {
                let listedBreakdowns = [...result];
                this.setState({ breakdowns: listedBreakdowns });
            })
            .then(() => this.calculateSectionTotals())
            .catch((error) => {
                console.log(error);
        })
    }

    addNewBreakdown() {
        fetch(API_URL + `/billingcalculator/breakdown/create`, {
            method: "PUT",
            body: JSON.stringify({
                sectionId: this.props.sectionId
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .catch((err) => console.log(err))
            .then(() => this.getSectionBreakdowns(this.props.sectionId))
            .catch((err) => console.log(err));
    }

    calculateSectionTotals = () => {
        this.addTotalHours()
    }

    addTotalHours = () => {
        let tempHoursTotal = 0;
        let tempCostsTotal = 0;
        let tempBillTotal = 0;
        for (let i = 0; i < this.state.breakdowns.length; i++){
            tempHoursTotal = tempHoursTotal + +this.state.breakdowns[i].total_hours;
            tempCostsTotal = tempCostsTotal + +this.state.breakdowns[i].total_costs;
            tempBillTotal = tempBillTotal + +this.state.breakdowns[i].total_bill;
            this.setState({
                totalSecHours: tempHoursTotal,
                totalSecCost: tempCostsTotal,
                totalSecBill: tempBillTotal
            },
            () => this.setState({totalSecMargin: (parseFloat(this.state.totalSecBill) - parseFloat(this.state.totalSecCost)) / parseFloat(this.state.totalSecBill)}, () => this.updateDB()))
        }
    }

    updateDB = () => {
        let data = {
            totalSecHours: this.state.totalSecHours,
            totalSecCost: this.state.totalSecCost,
            totalSecBill: this.state.totalSecBill,
            totalSecMargin: this.state.totalSecMargin
        };
        this.updateSectionTotals(data);
    };

    updateSectionTotals(data) {
        fetch(API_URL + `/billingcalculator/section/update`, {
            method: "PUT",
            body: JSON.stringify({
                sectionId: this.props.sectionId,
                data: data,
            }),
            headers: {"Content-Type": "application/json"},
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then((data) => console.log(data))
            .then(() => this.props.updateProjects(this.props.projectId)) 
            .catch((err) => console.log(err))
    }

    handleTitleModalShow() {
        this.setState({ showTitleModal: true });
    }

    handleTitleModalClose() {
        this.setState({ showTitleModal: false });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    updateSectionTitle() {
        fetch(API_URL + `/billingcalculator/section/titlechange`, {
            method: "PUT",
            body: JSON.stringify({
                sectionId: this.props.sectionId,
                sectionTitle: this.state.sectionTitle
            }),
            headers: { "Content-Type": "application/json" },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error();
                }
                return res.json();
            })
            .then(() => this.setState({ showTitleModal: false }))
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <ThemeProvider theme={theme}>             
                <Div>
                    <Row className='d-flex p-3'>
                        <Col>
                            <div className='d-flex flex-row'>
                                <h3>{this.state.sectionTitle === "" ? this.props.sectionTitle : this.state.sectionTitle}</h3>
                                <button className='border-0'
                                    onClick={() => this.handleTitleModalShow()}
                                >
                                    <i className="fa-duotone fa-pen-to-square"></i>
                                </button>
                            </div>
                            
                            <p>Section Id: {this.props.sectionId}</p>
                            <p>Rate Type: {this.props.rateType}</p>
                        </Col>
                        <Col>
                            <Table striped bordered size="sm" responsive="xl">
                                <thead>
                                    <tr>
                                        <th>Employee</th>
                                        <th>Total Hours</th>
                                        <th>Total Cost</th>
                                        <th>Total Bill</th>
                                        <th>Profit Margin</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.breakdowns.map(breakdown => (
                                        <BreakdownTotals
                                            key={breakdown.breakdown_id}
                                            breakdownId={breakdown.breakdown_id}
                                            employee={breakdown.employee_name}
                                            rate={breakdown.rate}
                                            cost={breakdown.cost}
                                            total_hours={breakdown.total_hours}
                                            total_costs={breakdown.total_costs}
                                            total_bill={breakdown.total_bill}
                                            margin={breakdown.margin}
                                            
                                        />
                                    ))}
                                    <tr>
                                        <td className='font-weight-bold'>Totals</td>
                                    <td className='font-weight-bold'>{this.state.totalSecHours}</td>
                                    <td className='font-weight-bold'>${this.state.totalSecCost?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}
                                    </td>
                                    <td className='font-weight-bold'>${this.state.totalSecBill?.toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                                })}
                                    </td>
                                    <td className='font-weight-bold'>{this.state.totalSecMargin?.toFixed(2)*100}%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Table striped bordered size="sm" responsive="xl">
                        <thead>
                            <tr>
                                <th>Emp Type</th>
                                <th>Employee</th>
                                <th>Bill Rate</th>
                                <th>PPR Cost</th>
                                <th>M1</th>
                                <th>M2</th>
                                <th>M3</th>
                                <th>M4</th>
                                <th>M5</th>
                                <th>M6</th>
                                <th>M7</th>
                                <th>M8</th>
                                <th>M9</th>
                                <th>M10</th>
                                <th>M11</th>
                                <th>M12</th>
                                <th>Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.breakdowns.map(breakdown => (
                                <Breakdown
                                    key={breakdown.breakdown_id}
                                    breakdownId={breakdown.breakdown_id}
                                    breakdownType="full"
                                    getSectionBreakdowns={this.getSectionBreakdowns}
                                    secId={this.props.sectionId}
                                    rateType={this.props.rateType}
                                />
                            ))}
                        </tbody>
                            </Table>
                            
                        </Col>
                    </Row>
                                       
                   
                    <hr />
                    <button className='sml-btn' onClick={this.addNewBreakdown}>
                        <p className='mt-3'>+ Add Row</p>
                    </button>

                    <Modal show={this.state.showTitleModal}>
                        <Modal.Header>
                        <Modal.Title>Edit Section Title</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="sectionTitle">
                                    <Form.Label>Section Title</Form.Label>
                                    <Form.Control
                                        name="sectionTitle"
                                        required
                                        type="text"
                                        placeholder={this.props.sectionTitle}
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Edit the section title.
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.handleTitleModalClose()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => this.updateSectionTitle()}>
                                Save Title
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Section;