import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from "../../../../../Config/Theme";

const Div = styled.div`
    color: black;
`

class Recording extends Component {
    

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <h1>Prohibition of Recording</h1>
                    <p>The {this.props.clientName} seeks to operate in an atmosphere of trust and confidence among employees. This trust and confidence can be destroyed, however, if employees make tape, video, digital or other recordings of conversations with other employees, particularly if the other employees are not aware that they are being recorded. Moreover, recording conversations in such a manner may constitute a crime in certain circumstances. Therefore, with the exception of messages left on voicemail or recordings made on behalf of the {this.props.clientName} during the course of {this.props.clientName} business such as training classes, it is the {this.props.clientName}’s policy to prohibit employees from making tape, digital or other recordings of conversations in the workplace. Any exceptions to this policy must be authorized in advance by the VP of Human Resources and/or CEO. Exceptions to this policy will be made only in exceptional circumstances. If an exception to this policy is authorized and a recording is to be made, the employee who is being recorded will usually be informed of the recording. Employees who violate this policy will be subject to disciplinary action, up to and including termination</p>
                </Div>
            </ThemeProvider>
        ) 
    }
}

export default Recording;
