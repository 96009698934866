import React from 'react';
import { Row, Col} from 'react-bootstrap';

import styled, {ThemeProvider} from 'styled-components';
import theme from "../../Config/Theme";

const Div = styled.div `
    height: 100vh;
    padding: 2em;

    .widgets{
      padding: 2em;
      color: black;
      background-color: rgba(255,255,255, 0.85);
    }

    .widget-button{
    background-color: rgba(30, 30, 45, 0.5);
    border: 1px solid ${(props) => props.theme.colors.orange};
    border-radius: .25em;
    margin-top: 3em;
    padding: 4em;
  }

  .titles{
      border: solid black 1px;
      padding: 1em;
      background: ${props => props.theme.colors.medium_blue};
      color: ${props => props.theme.colors.orange};
  }

  .categories{
      border: solid black 1px;
      padding: 1em;
      color: ${props => props.theme.colors.dark_blue};
  }

`

const SpecialProjects = () => {
    return (
        <ThemeProvider theme={theme}>
            <Div>
              <div className="widgets">
                <div>
                  <h4>Special Projects</h4>
                  <p>Special projects are those that are not included in a client’s original
                        service agreement. These projects require an amendment to be signed before work can begin. If a client
                        is interested in these services, please communicate with a Senior Consultant for next steps.
                    </p>
                </div>
              </div>
              <div className="widgets mt-3">
                    <div className="text-center">
                        <Row>
                            <Col className="titles"><h5>Performance Management System</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">First 90 Day Onboarding Plan</Col>
                            <Col className="categories">Performance Management System</Col>
                            <Col className="categories">Training - Hourly</Col>
                            <Col className="categories">Training - Half Day</Col>
                            <Col className="categories">Training - Full Day</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Policy & Procedure Formation</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Policy Creation</Col>
                            <Col className="categories">Standard Operation Procedures</Col>
                            <Col className="categories">Development of Leave Admin Process</Col>
                            <Col className="categories">Employee Handbook</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Risk Management</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Review of Employment Classification</Col>
                            <Col className="categories">Unemployment/Support with TWC</Col>
                            <Col className="categories">State Tax Registration</Col>
                            <Col className="categories">EEOC, DOL, or Other Regulatory Matters</Col>
                            <Col className="categories">Human Capital Analysis (Audit)</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Policy & Procedure Formation</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Policy Creation</Col>
                            <Col className="categories">Standard Operation Procedures</Col>
                            <Col className="categories">Development of Leave Admin Process</Col>
                            <Col className="categories">Employee Handbook</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Strategy and Change Management</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Succession Planning</Col>
                            <Col className="categories">SWOT Analysis</Col>
                            <Col className="categories">Talent Planning Analysis</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Compensation Analysis</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Exec Compensation Analysis</Col>
                            <Col className="categories">Compensation Analysis</Col>
                        </Row>

                        <Row className="mt-4">
                            <Col className="titles"><h5>Workforce Planning & Talent Attraction</h5></Col>
                        </Row>
                        <Row>
                            <Col className="categories">Job Description</Col>
                            <Col className="categories">Phone Screen</Col>
                            <Col className="categories">Reference Check</Col>
                            <Col className="categories">Talent Search Survey</Col>
                            <Col className="categories">Talent Acquisition and Executive Searches</Col>
                        </Row>
                                         
                    </div>
                </div>
            </Div>
          </ThemeProvider>
    )
}

export default SpecialProjects;