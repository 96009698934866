import React, { Component } from 'react';
import { Container, Form, Row} from 'react-bootstrap';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../../../Config/Theme';

const Div = styled.div`
    background-color: white;
    color: black;
`

class Compensation extends Component{

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Div>
                    <Container>
                        <Form className="p-3">
                            
                            <div className="form-section">
                                <h4>Compensation</h4>
                                <hr />
                                <p className="font-italic font-weight-bold">
                                On a scale from 1 to 5 (1-Very Unsatisfied and
                                5-Very Satisfied...)
                                </p>

                                <Form.Group
                                as={Row}
                                name="formPay"
                                controlId="formPay"
                                onChange={this.props.handleChangeAsInt}
                                >
                                <Form.Label column sm={5}>
                                    34. How satisfied are you with your pay?
                                </Form.Label>
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="1"
                                    value="1"
                                    name="formPay"
                                    id="formPay-1"
                                    defaultChecked={this.props.formPay === 1}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="2"
                                    value="2"
                                    name="formPay"
                                    id="formPay-2"
                                    defaultChecked={this.props.formPay === 2}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="3"
                                    value="3"
                                    name="formPay"
                                    id="formPay-3"
                                    defaultChecked={this.props.formPay === 3}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="4"
                                    value="4"
                                    name="formPay"
                                    id="formPay-4"
                                    defaultChecked={this.props.formPay === 4}
                                />
                                <Form.Check
                                    inline
                                    type="radio"
                                    label="5"
                                    value="5"
                                    name="formPay"
                                    id="formPay-5"
                                    defaultChecked={this.props.formPay === 5}
                                />
                                </Form.Group>

                                
                            </div>
                        </Form>
                    </Container>
                </Div>
            </ThemeProvider>
        )
    }
}

export default Compensation;